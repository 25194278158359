.gallery-wrapper {
  max-width: 1352px;
  margin: 0 auto;
}

.gallery-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.gallery__media-region {
  column-gap: 4rem;
  row-gap: 4rem;
}

.gallery-item {
  width: 19.2rem;
  height: 28.8rem;
}

.gallery-overlay {
  .gallery__title-region {
    padding-left: 4.5rem;
  }

  .swiper-wrapper {
    min-height: 63.5rem;
  }

  .swiper-slide {
    width: 31rem;
    height: 46.5rem;

    transition: height ease 0.4s;

    &.swiper-slide-active {
      width: 42.4rem;
      height: 63.5rem;
    }
  }

  .swiper-pagination {
    text-align: left;
    left: 4.5rem;
    width: auto;
  }

  .swiper-arrows {
    position: absolute;
    bottom: 10px;
    right: 4.5rem;
    display: flex;
    width: 9.5rem;
    justify-content: space-between;
    z-index: 11;
  }

  .swiper-arrow-next,
  .swiper-arrow-prev {
    position: relative;
    cursor: pointer;
    padding: 0.6rem;
    width: 3rem;
    height: 3rem;
    &:after {
      content: '';
      @include fixedSprite('back');
      display: inline-block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) rotate(0);
      width: 2.4rem;
      height: 2.4rem;
    }
    &.swiper-button-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .swiper-arrow-next {
    &:after {
      transform: translateY(-50%) rotate(-180deg);
    }
  }
}

.gallery-close {
  top: 1.7rem;
  right: 1.7rem;
}
