// /* demo style */

.image-carousel-global-container {
  position: relative;
  height: 100%;
  width: 100%;
  margin-bottom: 16px;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    img {
      display: block;
      width: 100%;
      cursor: zoom-in;
    }
  }

  .swiper-container {
    z-index: 0;
  }

  .swiper-pagination {
    display: flex;
    align-items: center;
    height: 48px;
    justify-content: space-around;
    padding: 0 69px;
    .swiper-pagination-bullet {
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      color: black;
      opacity: 1;
      background: transparent;
      border: 1px solid black;

      width: 9px;
      height: 12px;
      margin: 0 39px 0 2px;
      transform: rotate(-270deg);
      border-radius: 7.5px;
    }

    .swiper-pagination-bullet-active {
      color: #fff;
      background: black;
    }
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    top: auto;
    bottom: 0;
  }
}
