.quantity-form {
  .btn--round {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.btn--minus {
  margin: 0.6rem 0.9rem 0.6rem 0;
  &::before {
    width: 0.8rem;
  }
}

.btn--plus {
  margin: 0.6rem 0.9rem 0.6rem 0.9rem;
  &::before {
    width: 0.8rem;
  }

  &::after {
    width: 0.8rem;
  }
}

//
