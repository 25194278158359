.slot4-smallBig-component {
  padding: 30px 0;
}
.slot4-wrapper {
  max-width: 1352px;
  margin: 0 auto;
}

.slot4-wrapper {
  flex-direction: row;
  padding: 0 20px;
}

.smallBig__item {
  padding: 0 20px;
  max-width: calc(27% + 40px);
  width: 100%;
  // align-self: baseline;
  &:first-child,
  &:last-child {
    max-width: calc(17.3% + 40px);
    align-self: center;
  }

  .pd-video__container,
  .pd-wrapper-image-layer {
    padding: 0;
  }

  + .smallBig__item {
    margin-top: 0;
  }
}
