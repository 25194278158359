#product-show {
  .footer {
    &.z-index--1 {
      z-index: -1 !important;
    }
  }
}

.product-detail {
  &.product-wrapper {
    border: none;
    .recommendations {
      margin-top: 60px;

      .einstein-carousel {
        .swiper-button-next {
          &:after {
            visibility: hidden;
          }
        }
        .swiper-button-prev {
          &:after {
            visibility: hidden;
          }
        }
      }
    }
  }
  .product-description {
    margin-bottom: 24px;
    .description-content {
      color: $black;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      width: 316px;
    }
  }

  .product-name {
    h1 {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $black;
      text-transform: uppercase;
    }
  }

  .product-number-rating {
    height: 21.6px;
    margin-top: 8px;
    margin-bottom: 15.6px;
    .product-number {
      color: $dark-grey;
      font-size: 12px;
      padding: 0;
      display: flex;
      .editorial-composition-description {
        margin-left: 5px;
      }
    }
  }

  .product-price {
    text-align: right;

    .prices {
      color: $black;
      font-size: 16px;
      font-stretch: normal;
      letter-spacing: 1px;
      line-height: 1.6;
      font-style: normal;
      font-weight: 500;
      text-align: right;
      width: 100%;
      &.previous-price {
        text-decoration: line-through;
      }
    }

    .single-element-content-detail-price {
      .price {
        span {
          display: inline-flex;
          flex-direction: column;
        }
      }
    }
  }

  .product-see-also {
    margin-bottom: 24px;
    width: 100%;
    text-transform: uppercase;
    .col {
      display: flex;
      flex-wrap: wrap;
      span {
        height: 22px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.3px;
        color: $black;
        min-inline-size: max-content;
      }

      .see-also-value {
        text-decoration: underline;
        margin: 0 8px;
      }
    }
  }

  .variation-attribute {
    margin-bottom: 24px;
    position: relative;
    width: fit-content;

    .error-message {
      display: none;
      color: $status_error_light;
      font-size: 12px;
      font-style: normal;
      font-weight: normal;
      left: 0;
      letter-spacing: 0.03em;
      line-height: 19px;
      position: absolute;
      top: 32px;
    }

    .color-label {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      color: $black;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .size-label-section {
      align-items: center;
      display: flex;
      justify-content: space-between;
      height: 22px;

      .size-guidelines {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        cursor: pointer;
        height: 14px;
      }
      .size-label {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.3px;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 0;
        display: none;
      }
    }

    .attribute-label-value {
      font-size: 12px;
    }

    select {
      appearance: none;
      height: 24px;
      margin-left: 0;
      margin-top: 0;
      padding-left: 0;
      padding: 0;
      border: none !important;
      border-bottom: 1px solid $black !important;
      border-radius: 0;
      width: 184px;
      text-transform: uppercase;
      option {
        text-transform: uppercase;
        &:disabled {
          pointer-events: unset;
        }
      }

      .select-icon {
        position: absolute;
        bottom: 6px;
        left: 165px;
      }

      &.select-color {
        width: fit-content;
        margin-right: 0;
        padding-right: 20px;
        min-width: 160px;
      }
      &.select-size {
        width: 98px;
      }
    }

    .single-val {
      border-bottom: 1px solid $black;
      text-transform: uppercase;
      &-color {
        width: 160px;
      }
      &-size {
        width: 98px;
      }
    }

    &.attribute-size {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      width: 100%;
      .custom-select-wrap {
        position: relative;
        .select-icon {
          left: auto;
          right: 0;
        }
      }
      select {
        &.mobile-version {
          width: auto;
          padding-right: 15px;
        }
      }
      .select-icon {
        bottom: 36px;
        pointer-events: none;
        position: absolute;
        right: 0;
        transform: translateY(28px);
      }
      &.invalid {
        label {
          color: $status_error_light !important;
        }
        select {
          color: $status_error_light;
          border-bottom: 1px solid $status_error_light !important;
        }
      }
    }

    &.attribute-color {
      select {
        &.mobile-version {
          min-width: 160px;
        }
      }
      .select-icon {
        bottom: 36px;
        pointer-events: none;
        position: absolute;
        right: 0;
        transform: translateY(28px);
      }
      &.invalid {
        label {
          color: $status_error_light !important;
        }
        select {
          color: $status_error_light;
          border-bottom: 1px solid $status_error_light !important;
        }
      }
    }

    .custom-select-wrap {
      &.invalid {
        margin-bottom: 16px;
        .fsb-button {
          color: $status_error_light !important;
          border-bottom: 1px solid $status_error_light !important;
        }
        .fsb-button::after {
          color: $black !important;
        }
        .fsb-select {
          border-bottom: 1px solid $status_error_light !important;
        }
        .custom-select {
          color: $status_error_light !important;
          border-bottom: 1px solid $status_error_light !important;
        }
        .error-message {
          display: block;
          top: 30px;
          width: 215px;
        }
      }
    }
  }

  .add-to-cart-add-to-wishlist,
  .notify-me-add-to-wishlist {
    height: 48px;
    justify-content: space-between;
    .add-to-cart-actions,
    .notfy-me-actions {
      border-radius: 25px;
      max-width: 281px;
      .cart-and-ipay {
        > .col {
          padding: 0;
          .add-to-cart {
            padding: 16px 8px;
          }
        }
      }
    }

    .add-to-whishlist {
      button {
        border: 1px solid $medium-grey;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        .add-to-wish-list-icon {
          height: 20px;
          .white-icon {
            display: none;
          }
        }
        &.active,
        &:hover {
          border-radius: 50%;
          border: none;
          background-color: $black;
          -webkit-transition: background-color 1000ms linear;
          -ms-transition: background-color 1000ms linear;
          transition: background-color 1000ms linear;
          .add-to-wish-list-icon {
            .black-icon {
              display: none;
            }
            .white-icon {
              display: block;
            }
          }
        }
        &.disabled-click {
          -webkit-user-focus: none;
          -ms-user-focus: none;
          -moz-user-focus: none;
          -moz-user-modify: read-only;
          -webkit-user-modify: read-only;
          -ms-user-modify: read-only;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          pointer-events: none;
        }
      }
    }

    &.default {
      margin-right: 0;
    }
  }
  .add-to-cart-error-messaging {
    color: $status_error;
    margin-bottom: 16px;
  }

  .free-shipping {
    color: $dark-grey;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    height: 22px;
    letter-spacing: 0.3px;
    line-height: 1.8;
    margin: 24px 0;
  }

  .pickup-in-store-option {
    height: 12px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    color: $black;
    text-decoration: underline;
    text-transform: uppercase;
    margin-bottom: 32px;
    margin-top: 24px;
  }

  .product-collapsible-sections {
    .product-info-accordion {
      .accordion {
        .js-accordion-trigger {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
          &-title {
            height: 48px;
            padding: 18px 0;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.5px;
            color: $black;
            text-transform: uppercase;
          }
        }
      }
      .accordion-content {
        .acordion-subtitle {
          font-size: 12px;
          font-stretch: normal;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 0.5px;
          line-height: 1;
          margin-bottom: 8px;
          text-transform: uppercase;
        }
      }
    }

    h2 {
      align-items: center;
      display: flex;
      height: 48px;
      justify-content: space-between;

      .title {
        color: $black;
        height: 12px;
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 1;
        text-transform: uppercase;
      }
    }

    .pdp-product-details-review {
      .editorial-composition {
        margin-top: 16px;
      }
      .care-instructions-review {
        margin-top: 16px;
      }
    }
  }

  .product-breadcrumb {
    .breadcrumb-component {
      max-width: 100%;
      a {
        .last-element-text {
          display: inline-block;
          text-transform: lowercase;
        }
        .last-element-text::first-letter {
          text-transform: uppercase;
        }
      }
      .breadcrumb-separator {
        display: none;
      }
    }
  }

  .preorder-release-date {
    margin-bottom: 10px;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: $white;
    border-width: 1px;
    border-style: solid;
    border-color: $black;
    color: $black;
    text-align: center;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .product-photo-retouch {
    margin-top: 20px;
    color: #a5a5a1;
  }
}

.notify-me-container {
  .notify-me {
    &-info {
      align-items: center;
      display: flex;
      margin-bottom: 16px;
      .info-icon {
        height: 12px;
        margin-right: 8px;
        width: 12px;
      }
      .info-text {
        color: $status_error;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
      }
    }

    &-insert-email {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      .form-input {
        padding-bottom: 0;

        .email-field {
          margin-top: 16px;
          &-label {
            font-weight: 500;
            line-height: 1;
            letter-spacing: 0.5px;
          }
          &-input {
            margin: 0;
          }
        }
      }

      .add-to-cart-add-to-wishlist {
        margin: 24px 0;
        .add-to-cart-action {
          font-size: 14px;
        }
        .add-to-whishlist {
          max-width: min-content;
        }
      }
      .newsletter-checkbox {
        margin-top: 20px;
        margin-left: 12px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }

      .success-message {
        color: #096a0e;
        margin-top: 24px;
        display: none;
        &.success {
          display: block;
        }
      }

      .privacy-newsletter {
        color: $dark-grey;
        font-size: 12px;
        text-transform: lowercase;
        text-decoration: underline;
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    .notify-me-add-to-wishlist {
      margin: 24px 0;
    }
  }
}

.product-slides {
  list-style: none;
}
.product-slide {
  list-style: none;
}

.product-fullscreen-images-overlay {
  &.active {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}

.product-fullscreen-close-button {
  visibility: hidden;
  position: fixed;
  z-index: 103;
  cursor: pointer;
  background-repeat: no-repeat;
  top: 30px;
  right: 35px;
  @include fixedSprite('close-bold');
}

.product-slide {
  .swiper-zoom-container {
    align-items: flex-start;
  }
}
.product-coming-soon {
  .info-text {
    font-size: 16px;
    color: #111111;
  }
  .coming-soon-text {
    color: #757575;
  }

  .coming-soon-icon-info {
    width: 16px;
    height: 15px;
    background: url(../images/icon-info.svg);
    display: inline-block;
    margin-right: 4px;
  }
}
