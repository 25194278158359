.pd-masonry-grid {
  padding: 0 16px;

  &-wrapper {
    &.pd-page__content.experience-main {
      overflow: visible;
      z-index: auto;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: white;
    z-index: 1;
    flex-direction: column;
    padding: 0;
    transition: top 0.3s ease-in-out;

    &-mobile {
      .pd-masonry-grid__header-title {
        display: block;

        .display-heading {
          padding-bottom: 0;
        }
      }
    }

    &-title {
      display: none;

      .display-heading {
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.01em;
        padding: 12px 0 16px;
      }
    }

    .filter-action {
      flex: 100%;
    }

    .show-filter {
      display: flex;
      align-items: center;
      gap: 4px;
      border: 1px solid #E0E0E0;
      border-radius: 100px;
      padding: 9px 20px;
      font-size: 14px;
      line-height: 16px;
      color: #262626;
      flex: 1 1 100%;
      justify-content: center;

      &:hover {
        border: 1px solid #888;
      }

      .icon--equalizer-icon {
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.16667 0V2H0.5V3.33333H3.16667V5.33333H4.5V0H3.16667ZM5.83333 3.33333H12.5V2H5.83333V3.33333ZM9.83333 6.66667V8.66667H12.5V10H9.83333V12H8.5V6.66667H9.83333ZM7.16667 10H0.5V8.66667H7.16667V10Z' fill='%23262626'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  &__container {
    padding-top: 16px;
  }

  .pd-wrapper-image-layer {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;

    &.d-none {
      display: none;
    }
  }

  &__description {
    padding: 0 8px 24px;

    &-date {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.015em;
      padding-top: 8px;
    }
  
    &-title {
      font-size: 24px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.01em;
      padding-top: 4px;
    }
  
    &-text {
      display: none;
    }
  
    &-tag {
      .single-tag {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.015em;
        padding: 4px 8px;
        margin-top: 16px;
        color: #7D7D7D;
        background-color: #F4F4F4;
        border-radius: 4px;
        display: inline-flex;
      }
    }
  }

  .layer-filter {
    position: unset;
    display: flex;
    padding: 16px 0 12px;

    .modal-close {
      &:after {
        content: '';
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg transform='translate(-1288 -249)' fill='black'%3e%3cg transform='translate(1288 249)'%3e%3cpath d='M22.29.29L11.998 10.583 1.704.29.29 1.705l10.294 10.292L.29 22.291l1.414 1.413 10.294-10.293L22.29 23.704l1.415-1.413-10.294-10.294L23.705 1.705z'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e") center no-repeat;
        height: 16px;
        width: 16px;
        display: inline-block;
        
      }
    }

    .filter-overlay {
      position: fixed;
    }

    #layer-filter {
      height: 100%;
      position: fixed;
      display: flex;
      flex-direction: column;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.01em;
      padding: 16px;
    }

    .filter-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      padding: 24px 16px;

      .filter-item {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.03em;
        padding: 10px 24px 10px 8px;
        border: 1px solid #E5E5E5;
        cursor: pointer;
        position: relative;

        &:hover {
          border: 1px solid #ccc;
        }

        &.active {
          border: 1px solid #000;

          .icon--close {
            display: block;
          }
        }

        .icon--close {
          display: none;
          width: 12px;
          height: 12px;
          position: absolute;
          right: 8px;
          top: 14px;
        }
      }

      &-footer {
        margin-top: auto;
        padding: 16px;

        .buttons {
          display: flex;
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #000;
          color: #fff;
          flex: 100%;
          height: 48px;
          border-radius: 24px;
          font-family: "futura-pt", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: .56px;

          &:hover {
            background: #3d3d3d;
          }

          &[disabled] {
            background: #e5e5e5;
            color: #999;
            cursor: default;
          }
        }
      }
    }
  }
}

.layer-filter-open {
  &#page-show.no-zindex .header {
    position: sticky !important;
    z-index: 1;
  }

  main.main--layout-page {
    margin-top: 0;
  }
}

#page-show.no-zindex {
  .header,
  .header-search {
    z-index: 0;
  }

  #maincontent {
    position: unset !important;
  }
}
