.store-locator-canvas-container {
    position: relative;
    height: calc(100vh - 200px);
    overflow: hidden;

    .store-locator-fields-layer {
        position: absolute;
        top: 0;
        left: 0;
        margin-bottom: auto;
        width: 100%;
        height: 100%;
        pointer-events: none;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        z-index: 1;
        .results-panel {
          margin: 0;
          width: calc(100% - 10px);
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
        }
    }
    .fields-body {
        margin: 0 auto;
        height: 100%;
    }
    .fields-box {
        padding: 20px;
        background: #fff;
        /* max-width: 80rem; */
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        background-color: #fff;
        pointer-events: auto;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        height: 100%;
    }

    .fields-header-group {
      display: block;
    }

    .fields-header {
      margin-right: 20px;
      margin-bottom: 0;
      width: 100%;
    }

    .store-locator-fields-row {
      margin-top: 15px;
    }
    
    &:not(.layout-split) .fields-body {
        padding-top: 30px;
        
        .fields-box {
            max-width: 425px;
            -webkit-box-shadow: 0 2px 184px rgba(0, 0, 0, .5);
            box-shadow: 0 2px 184px rgba(0, 0, 0, .5);
            height: auto;
        }
    }
}