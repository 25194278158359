.mobile-filters {
  display: none;
}
.desktop-filters {
  display: flex;
  .filter-list {
    display: flex;
    list-style: none;
    margin-left: 0;
    &-element {
      margin-right: 23px;
      .name {
        padding-right: 26px;
      }

      .dropdown-single-filter {
        &:not(.is-open),
        &:not(.animate) {
          .dropdown-content {
            display: none;
          }
        }
        .is-open,
        .animate {
          .dropdown-content {
            display: block;
          }
        }
        .dropdown-content {
          width: max-content;
          padding: 6px 11px 0 11px;
          display: flex;
          margin-top: 5px;
          min-width: unset;
          margin-left: -16px;
          .categories-filter {
            width: auto;
            .values {
              .value {
                .selection-box {
                  &.selected-box {
                    position: relative;
                    &:after {
                      color: $black;
                      position: absolute;
                      display: block;
                      height: 0.4rem;
                      width: 0.8rem;
                      border-left: 1px solid;
                      border-bottom: 1px solid;
                      -webkit-transform: rotate(-45deg);
                      transform: rotate(-45deg);
                      left: 5px;
                      top: 6px;
                      -webkit-box-sizing: border-box;
                      box-sizing: border-box;
                      content: '';
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
