.footer {
  .open-chat-container {
    width: fit-content;
    text-align: left;
    margin-top: 0;
  }

  &-newsletter {
    padding-top: 80px;
    padding-bottom: 80px;

    &-container:last-child {
      padding-left: 12px;
    }
  }

  &-country {
    margin-top: auto;
  }

  &-country-wrapper {
    flex-wrap: nowrap;

    .footer-country-store {
      padding-right: 12px;
    }
  }

  &-accordion {
    margin-top: 0;

    &-content {
      .button-icon {
        display: none;
      }
    }

    .editorial-footer {
      display: flex;
      justify-content: space-around;

      .section-footer-box {
        width: 100%;
        border: 0;
        padding-left: 12px;
        padding-right: 12px;

        &.footer-accordeon-condensed,
        &.footer-accordeon-expanded {
          .accordion-content {
            display: block !important;
            max-height: none !important;
            opacity: 1 !important;
          }

          .icon--minus-icon-white {
            display: none !important;
          }

          .icon--plus-icon-white {
            display: none !important;
          }
        }
      }
    }
  }

  .single-footer-section {
    &> button.button-open-footer {
      display: none;
    }

    &>.title-section-footer {
      display: block;
    }
  }

  .single-footer-section-container.accordion-content {
    max-height: none !important;
    display: block !important;
    opacity: 1 !important;
  }
  
  &-bottom.container {
    margin-top: 80px;
    border-top: 1px solid #373838;
    padding: 0 15px;
    
    &>.container {
      display: flex;
      justify-content: space-between;
      padding: 0 44px;
      align-items: center;
    }

    .second-section-footer {
      padding: 0;
    }
  }

  &-social {
    display: flex;
    width: auto;

    .section-icon-social {
      max-height: 66px;
      margin-top: 0;
      border-top: 0;
      padding: 0;

      a {
          background-color: #B4B4B4;
      } 
    }
  }
}
