.access-details-container {
  &:not(reset-password) {
    padding: 0;
    .change-password {
      margin: 0;
    }
    .page-title {
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1.5px;
      color: $black;
      text-transform: uppercase;
    }

    .detail-description {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: 0.5px;
      margin-bottom: 24px;
      margin-top: 8px;
    }

    .card-body {
      input {
        width: 100%;
      }
      .change-password {
        .form-input {
          padding-bottom: 16px;
        }
        .save-button {
          margin-top: 16px;
        }
      }
    }
  }

  .dynamic-validation-message {
    position: relative;
    margin-left: 15px;
    
    &.error-length {
      margin-top: 8px;
    }
  
    &:not(.green):before {
      content: "";
      display: block;
      width: 10px;
      height: 1px;
      background: #666;
      left: -15px;
      top: 42%;
      position: absolute;
    }
  
    &.green {
      color: green;
  
      &:before {
        content: "L";
        position: absolute;
        left: -15px;
        font-family: arial;
        -ms-transform: scaleX(-1) rotate(-35deg);
        -webkit-transform: scaleX(-1) rotate(-35deg);
        transform: scaleX(-1) rotate(-35deg);
      }
    }
  }
  
  #newPassword + .error-message {
    display: none;
  }
  
  .form-group.error {
    .dynamic-validation-message:not(.green) {
      color: #ba2015;
      &::before {
        background: #ba2015;
      }
    }
  }
  
}
