.pd-carousel__title-region {
  padding-top: 24px;
  margin-bottom: 24px;
}

.pd-carousel__products-region {
  position: relative;
}

.product-carousel {
  .swiper-slide {
    width: 259px;
    display: flex;
  }
}

.experience-assets-product {
  .product {
    height: auto;
    width: 100%;
  }

  .second-image {
    display: none;
  }

  .single-element {
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 32px;
      &-detail {
        text-align: center;
      }
      &-image {
        position: relative;
        margin-bottom: 16px;
        width: 100%;
        .default-image {
          height: 100%;
          img {
            height: 100%;
            width: 100%;
          }
        }
        &-tag {
          background-color: red;
          height: 20px;
          padding: 4px 8px;
          border-radius: 10px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          text-align: center;
          position: absolute;
          bottom: 8px;
          left: 8px;
          color: $white;
        }
        .img {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
      &-detail {
        &-price {
          .previous-price {
            color: $dark-grey;
            text-decoration: line-through;
            margin-right: 16px;
          }
        }
      }
    }
  }
}

.product-carousel-container {
  position: relative;
  margin-bottom: 60px;
  .product-carousel-title {
    @extend %heading-medium;
    text-transform: uppercase;
    padding: 24px 0;
    text-align: center;
  }

  .single-element {
    &-content {
      margin-bottom: 47px;
      &-image {
        margin-bottom: 16px;
      }
      &-detail {
        @extend %body--small--medium;
        text-align: center;
        &-description {
          margin-bottom: 9px;
        }
      }
    }
  }
  .swiper-pagination {
    display: none;
    align-items: center;
    justify-content: center;
    bottom: 0;
    position: absolute;
    width: 100%;
  }
  .swiper-pagination-bullet {
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: black;
    opacity: 1;
    background: transparent;
    border: 1px solid black;

    width: 0.9rem;
    height: 1.2rem;
    margin: 1rem;
    transform: rotate(-270deg);
    border-radius: 7.5px;

    cursor: pointer;

    &.swiper-pagination-bullet-active {
      color: #fff;
      background: black;
      cursor: default;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
    top: auto;
    bottom: 8px;
    width: 14px;
    height: 14px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 20px;
      background-color: black;
      transform: rotate(-45deg) translate(0, 0);
      left: 0;
      top: 0;
    }
  }
  .swiper-button-next {
    transform: rotate(135deg);
  }
  .swiper-button-prev {
    transform: rotate(-45deg);
  }
}

.swiper-heading-wrapper {
  text-align: center;
  padding-bottom: 2rem;
  .swiper-header {
    @extend %heading-medium;
    text-transform: uppercase;
  }
  .swiper-subheader {
    @extend %title--small;
  }
}
.einstein-carousel {
  .tile-body {
    font-weight: 500;
  }

  .product-carousel-section {
    padding-top: 0;

    .swiper-wrapper {
      padding-bottom: 3rem;
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: 95% !important;
      display: none;
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-next.icon--arrow-back-black-default {
      transform: rotate(180deg);
    }
    .swiper-pagination {
      position: absolute;
      left: 0px;
      right: 0px;
      margin: auto;
      bottom: 1rem;
      &-bullet {
        cursor: pointer;
        border: 1px solid #000;
        width: 9px;
        height: 12px;
        margin: 0 39px 0 2px;
        -webkit-transform: rotate(-270deg);
        transform: rotate(-270deg);
        border-radius: 7.5px;
        background-color: #fff;
        &-active {
          background-color: #000;
        }
      }
    }
  }
}
.experience-layouts-cosmosCarousel,
.experience-layouts-productCarousel,
.experience-layouts-sliderGallery {
  .is-new-carousel-true {
    .product-carousel-container {
      .swiper-heading-wrapper {
        padding: 16px;
        display: flex;
        justify-content: space-between;

        .swiper-header-link {
          align-self: center;
          display: inline-table;

          .icon--chevron-right-new {
            width: 0.5rem;
            height: 0.8rem;
            margin-left: 8px;
          }
        }

        .icon--chevron-right {
          vertical-align: sub;
          width: 1.8rem;
          height: 1.8rem;
        }
        .swiper-header {
          align-self: center;
          font-family: $futura-book;
          font-size: 24px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0.015em;
          text-align: left;
          padding: 0;
        }
      }
      // .pd-carousel__products-region {
      //   // padding: 0 0 0 16px;
      // }

      .swiper-pagination {
        display: none;
      }
      .right-side-content {
        display: flex;
      }
      .new-carousel-arrows {
        display: flex;
        padding: 32px 16px;
        justify-content: end;
        .swiper-button-prev,
        .swiper-button-next {
          position: relative;
          display: block;
          height: 36px;
          width: 36px;
          background: #f5f5f5;
          border-radius: 20px;
          border: none;
          transform: none;
          margin: 0 16px 0 0;
          top: 0 !important;
          left: 0;
          right: 0;

          &:after {
            content: none;
            transform: none;
            background-color: unset;
          }

          &:hover {
            background: #e5e5e5;
          }

          .icon--chevron-right {
            width: 35px;
            height: 35px;
          }
        }
        .swiper-button-next,
        .swiper-button-prev {
          text-align: center;
          align-content: center;
          .icon--chevron-right-new,
          .icon--chevron-left-new {
            vertical-align: sub;
            width: 1.17rem;
            height: 1.9rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1.3rem;
            height: 2rem;
          }
        }

        .swiper-button-prev {
          .icon--chevron-left-new {
            left: calc(50% - 1px);
          }
        }

        .swiper-button-next {
          margin-right: 0;
          .icon--chevron-right-new {
            left: calc(50% + 1px);
          }
        }
      }

      .js-swiper-wrapper.swiper-wrapper {
        transform: translate3d(16px, 0, 0);
      }

      .experience-assets-product,
      .experience-component,
      .pd-wrapper-image-layer {
        margin-right: 16px;
      }

      img {
        min-width: 82vw;
      }

      .new-carousel-arrows-desktop {
        display: none;
      }
      .new-carousel-arrows-mobile {
        display: flex;
      }
    }

    // .product-carousel-container .swiper-button-next,
    // .product-carousel-container .swiper-button-prev {
    //   display: flex;
    // }
    &.hide-arrows-true {
      .new-carousel-arrows {
        display: none !important;
      }
    }
  }
}

.experience-layouts-sliderGallery {
  .experience-component {
    margin-right: 0 !important;
  }
  
  .pd-wrapper-image-layer {
    margin-right: 0 !important;
  }

  .is-new-carousel-true {
    .product-carousel-container img {
      min-width: auto;
    }
  }
}

// hide second images in recommendations carousel of PDP mobile
.product-carousel-container {
  .single-element-content {
    .second-image {
      display: none;
    }
    &:hover {
      .second-image {
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.experience-layouts-cosmosCarousel,
.experience-layouts-productCarousel,
.experience-layouts-sliderGallery {
  &.experience-component {
    margin: 0;
  }

  .product-carousel-container {
    margin: 0;
    .pd-carousel__title-region {
      padding: 0;
      margin: 0;
    }
    .single-element {
      &-content {
        margin-bottom: 0;

        .add-to-wish-list.js_add-to-wish-list {
          display: none;
        }

        .tile-image-link {
          background-color: #f5f5f5;
        }

        &-image {
          margin-bottom: 0;

          .image-container {
            .mm-product-card__hero__hover.second-image {
              display: none;
            }
          }
        }

        .product-badge-top {
          position: absolute;
          top: 8px;
          bottom: 8px;
          left: 8px;
          right: 8px;
        }

        &-detail {
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          padding-left: 8px;
          padding-right: 12px;
          padding-top: 12px;

          &-description {
            margin-bottom: 8px;
            text-transform: none;
          }
        }
      }
    }

    .swiper-heading-wrapper {
      padding-top: 16px;
      padding-bottom: 16px;

      .swiper-header {
        text-align: left;
        margin: 0;
        text-transform: none;
        font-size: 18px;
        line-height: 24px;
        font-family: $futura-book;
        font-weight: 400;
        padding-left: 16px;
        padding-bottom: 0;
      }
    }

    .swiper-button-prev {
      top: -23px !important;
      left: unset;
      right: 71px;
    }

    .swiper-button-next {
      top: -23px !important;
      right: 24px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 13px;
      height: 13px;
      border-top: 2px solid #000;
      border-left: 2px solid #000;

      &:after {
        width: 2px;
        height: 16px;
      }
    }

    .swiper-pagination {
      display: block;
      &.swiper-pagination-clickable.swiper-pagination-bullets {
        position: relative;
        padding-top: 8px;
        padding-bottom: 24px;
      }

      .swiper-pagination-bullet {
        width: 5px;
        height: 5px;
        margin: 0 12px;
        transform: rotate(-270deg);
        border-radius: 7.5px;
        border: 1px solid #ccc;
        border-color: transparent;
        background-color: #ccc;

        &-active {
          border: 1px solid #000;
          background-color: #000;
        }
      }
    }
  }

  .badge-wrapper {
    display: none;
  }

  .badge-wrapper-carousel {
    display: block;

    .product-badge.collection-badge {
      position: absolute;
      background-color: unset;
      color: #111;
      padding: 8px;

      .product-badge-middle-rotate {
        font-family: $futura-book;
        font-size: 12px;
      }
    }
  }

  .badge-wrapper-carousel-details {
    display: block;
    padding-bottom: 24px;

    .badge-container {
      padding-left: 8px;

      .product-badge {
        padding-top: 8px;
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.1px;
        letter-spacing: 0.03em;
        color: #0D0D0D;
      }
    }
  }
}

.experience-layouts-sliderGallery {
  .swiper-heading-wrapper {
    padding: 0 !important;
  }
  .pd-carousel__title-region {
    padding: 40px 24px 24px !important;
  }

  .display-heading {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
}

.experience-layouts-cosmosCarousel {
  .image-container-selector {
    aspect-ratio: 4 / 5;

    .visualImage {
      height: 100%;
      display: block;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .swiper-header-link,
  .swiper-header {
    color: $white;
  }

  .is-new-carousel-true .product-carousel-container .new-carousel-arrows.new-carousel-arrows-mobile {
    display: none;
  }

  .is-new-carousel-true .product-carousel-container .new-carousel-arrows {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
      background: #f5f5f533;

      &:hover {
        background: #f5f5f545;
      }
    }
  }

  // .swiper-container {
  //   padding-bottom: 48px !important;
  // }

  .swiper-scrollbar.swiper-scrollbar-horizontal {
    width: 50vw;
    background: #ffffff33;
    margin: 0 25%;
    bottom: 24px;

    .swiper-scrollbar-drag {
      background: white;
    }
  }

  .icon--chevron-left-new,
  .icon--chevron-right-new {
    filter: invert(100%);
  }

  .pd-carousel__products-region {
    padding: 0 !important;
  }

  .text-layer-notsticky-container.bottom {
    .text-layer {
      text-align: center;
      position: absolute;
      z-index: 1;
      bottom: 55px;
      margin: 0 auto;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      top: unset;
    }
  }

  .swiper-slide-active {
    .text-layer {
      animation: slideTopText 0.5s ease-in;
    }
  }

  .product-carousel-container {
    .swiper-pagination {
      display: flex !important;
      width: 50%;
      position: static;
      margin: 24px auto;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 0;

      &.swiper-pagination-clickable.swiper-pagination-bullets {
        padding: 0;
        bottom: 0;
        top: 0;
      }

      .swiper-pagination-bullet {
        width: auto;
        height: 2px;
        margin: 0 !important;
        border-radius: 0;
        -webkit-box-flex: 1;
        max-width: none;
        -ms-flex-positive: 1;
        opacity: 1;
        background: #ffffff33;
        flex-grow: 1;
        transform: none;

        &-active {
          border: none;
          background: white !important;
        }
      }
    }
  }
}

.experience-layouts-sliderGallery {
  .image-container-selector {
    .visualImage {
      height: 100%;
      display: block;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .swiper-header-link,
  .swiper-header {
    color: $white;
  }

  .is-new-carousel-true .product-carousel-container .new-carousel-arrows.new-carousel-arrows-mobile {
    display: none;
  }

  .is-new-carousel-true .product-carousel-container .new-carousel-arrows {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
      background: #f5f5f533;

      &:hover {
        background: #f5f5f545;
      }
    }
  }

  .icon--chevron-left-new,
  .icon--chevron-right-new {
    filter: invert(100%);
  }

  .pd-carousel__products-region {
    padding: 0 !important;
  }

  .text-layer-notsticky-container.bottom {
    .text-layer {
      max-width: 33%;
      text-align: center;
      position: absolute;
      z-index: 1;
      bottom: 55px;
      margin: 0 auto;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      top: unset;
    }
  }

  .swiper-slide-active {
    .text-layer {
      animation: slideTopText 0.5s ease-in;
    }
  }

  .product-carousel-container {
    .swiper-pagination {
      display: flex !important;
      width: 20%;
      position: static;
      margin: 24px auto;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 0;

      &.swiper-pagination-clickable.swiper-pagination-bullets {
        padding: 0;
      }

      .swiper-pagination-bullet {
        width: auto;
        height: 2px;
        margin: 0 !important;
        border-radius: 0;
        -webkit-box-flex: 1;
        max-width: none;
        -ms-flex-positive: 1;
        opacity: 1;
        background: #E0E0E0;
        flex-grow: 1;
        transform: none;

        &-active {
          border: none;
          background: #000 !important;
        }
      }
    }
  }
}

@keyframes slideTopText {
  from {
    bottom: 45px;
  }
  to {
    bottom: 55px;
  }
}

.badge-wrapper-carousel {
  display: none;
}

.badge-wrapper-carousel-details {
  display: none;
}

.pd-carousel__products-region {
  .experience-component.experience-assets-product {
    // max-width: calc(50vw - 2px);
  }
}

.pd-carousel__products-region.not-two-view-mobile {
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    display: none;
  }

  .experience-component.experience-assets-product {
    max-width: calc((100vw / 2.25) - 2px);
  }
}
