.desktop-steps-container {
  height: 112px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  .circle-col {
    width: 48px;
    max-width: 48px;
    height: 48px;
    padding: 0;
  }
  .description-col {
    height: 48px;
    max-width: 146px;
    padding-right: 0;
    width: 90px;
    display: flex;
    align-items: center;
  }

  .review-col {
    .description-col {
      width: auto;
    }
  }
  .stepper-step {
    &.active {
      .status-circle {
        background: black;
        p {
          color: $white;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          text-align: center;
        }
      }
    }
    &:not(.active),
    &:not(.completed) {
      .status-circle {
        border: 1px solid $dark-grey;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        text-align: center;
      }
    }
    &.completed {
      .status-circle {
        border: 1px solid green;
        p {
          color: green;
          position: relative;
          display: block;
          height: 0.4rem;
          width: 0.8rem;
          border-left: 1px solid;
          border-bottom: 1px solid;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          content: none;
          &.status-circle-number {
            display: none;
          }
        }
      }
    }
  }
  .status {
    &-circle {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        text-align: center;
      }
    }
    &-description {
      // height: 48px;
      display: flex;
      justify-content: start;
      align-items: center;
      padding-left: 0;
      font-size: 12px;
    }
  }

  .middle-element {
    .col-2 {
      padding: 0;
    }
  }
}
