.dropdown {
  &.layer-product-packaging,
  &.layer-product-services,
  &.layer-variation-swatch,
  &.layer-variation-size,
  &.layer-product-help,
  &.layer-product-details,
  &.layer-minicart,
  &.layer-filter,
  &.layer-tooltip-prior-best-price {
    .dropdown-content {
      transition: all ease-in 0.3s;
      position: absolute;
      top: -100vh;
      right: -527px;
      z-index: 1;
      min-width: 25rem;
      background-color: $white;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }

    &.is-open {
      .dropdown-content {
        animation-name: slideIn;
        animation-duration: 1s;
        animation-fill-mode: forwards;
      }
    }

    &.animate-minicart-closure {
      .dropdown-content {
        animation-name: slideOut;
        animation-duration: 1s;
        animation-fill-mode: forwards;
      }
    }
  }

  &.layer-product-coming-soon,
  &.layer-product-notify,
  &.layer-product-notify-size {
    .dropdown-coming-soon-content .dropdown-notify-content,
    .dropdown-coming-soon-content .dropdown-notify-size-content {
      transition: all ease-in 0.3s;
      position: absolute;
      top: -100vh;
      right: -527px;
      z-index: 1;
      min-width: 25rem;
      background-color: $white;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }

    &.is-open {
      .dropdown-coming-soon-content,
      .dropdown-notify-content,
      .dropdown-notify-size-content {
        animation-name: slideIn;
        animation-duration: 1s;
        animation-fill-mode: forwards;
      }
    }

    &.animate-minicart-closure {
      .dropdown-coming-soon-content,
      .dropdown-notify-content,
      .dropdown-notify-size-content {
        animation-name: slideOut;
        animation-duration: 1s;
        animation-fill-mode: forwards;
      }
    }
  }
}

.layer-tooltip-prior-best-price.is-open {
  .dropdown-content {
    display: block;
  }
}

.tooltip-prior-best-price-overlay {
  position: fixed;
  z-index: 99;
}

#layer-tooltip-prior-best-price {
  left: unset;
  position: fixed;
  top: 0;
  right: -527px;
  height: 100vh;
  min-width: 527px;
  max-width: 527px;

  .main-overlay {
    z-index: 21;
    top: 0;
  }

}

.layer-minicart {
  position: unset;
}


.tooltip-prior-best-price-container {
  
  .modal-header.tooltip-prior-best-price-header {
    margin: 4.8rem 7.2rem 2.4rem 7.2rem;
  }
  height: 100vh;

  .modal-content {
    padding: 2.4rem 7.2rem;
  }

  .modal {
    .modal-header.minicart-header {
      margin: 24px;
    }
  }
}


:root {
  --slide-open: 0;
  --slide-close: -527px;
}

@keyframes slideIn {
  0% {
    right: var(--slide-close);
  }
  100% {
    right: var(--slide-open);
  }
}
@keyframes slideOut {
  0% {
    right: var(--slide-open);
  }
  100% {
    right: var(--slide-close);
  }
}

