.discount-label {
  color: #0EA80C;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  letter-spacing: 0.015em;

  &.new-line {
    display: block;
    width: 100%;
    margin-top: 8px;
    text-align: left;
  }

  &.in-line {
    font-size: 14px;
    margin-left: 8px;
  }
}