#delete-address-modal {
  .modal-close {
    display: none;
  }
  .delete-address-modal-container {
    .modal-body {
      margin-top: 54px;
      text-align: center;
      .delete-modal {
        &-title {
          font-size: 24px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1.5px;
          color: $black;
          margin-bottom: 24px;
          margin-top: 8px;
        }
        &-text {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: 0.3px;
        }
      }
      .horizontal-row {
        border-top: 1px solid $light-grey;
        &.modal-title-top-row {
          margin-left: -24px;
          width: calc(100% + 48px);
          margin-top: 0;
        }
      }

      .delete-modal-buttons-wrapper {
        margin-top: 16px;
        button {
          margin: 16px 0;
        }
      }
      .modal-close {
        display: block;
        cursor: pointer;
        top: 15px;
        right: 15px;
      }
    }
  }
}
