.page-search-result-container.new-page-search-result {
  .search-no-result-decorator-container {
    .plp-banner-container {
      display: flex;
      justify-content: center;
      .text-content {
        .page {
          &-title {
            height: 38.4px;
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1.5px;
            color: $black;
            text-transform: uppercase;
            margin-bottom: 16px;
          }
          &-description {
            width: 616px;
          }
        }
      }
    }

    .category-menu-top-title-mobile {
      display: none;
    }

    .category-menu-top-container {
      position: sticky;
      background: $white;
      top: 0;
      display: block;
      flex-direction: column;
      z-index: 2;
      border-top: 1px solid #e5e5e5;
      border-bottom: none;

      .category-menu-top-container-menu {
        display: flex;
        gap: 24px;
      }

      .filters-bar {
        padding: 8px 24px;
        .mobile-filters {
          .button-icons {
            .filter-button {
              margin: 0;
            }

            button {
              width: unset;
              height: 40px;
            }
          }
        }
      }

      .category-menu-top-title {
        display: block;
        h1 {
          font-family: $futura-book;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.02em;
          text-align: left;
          padding-block: 12px;
          padding-left: 24px;
        }
      }
      .category-menu-top-categories {
        display: flex;
        flex-direction: row;
        gap: 24px;
        margin-left: 0;

        .category-menu-top-categories-category {
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.04em;
          text-align: center;
          color: #757575;
          cursor: pointer;
          padding-block: 16px;

          &.selected {
            color: $black;
          }
        }
      }
    }

    .skeleton-loader {
      width: 100vw;
      gap: 8px;
      .product-placement {
        width: calc(25vw - 6px);
      }
    }
    .breadcrumbs-hero-internal {
      display: flex;
    }
    .delivery-banner-mobile {
      border-bottom: 1px solid $light-grey;
    }
    .delivery-banner-mobile {
      display: none;
    }
    .delivery-banner-desktop-spacer {
      display: flex;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .delivery-banner-desktop {
      display: flex;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      justify-content: center;
      align-items: center;
      .delivery-banner-info {
        display: flex;
        align-items: center;
        margin: 8px 0;
        justify-content: center;
        &-icon {
          margin-right: 8px;
        }
        &-text {
          width: 183px;
          height: 12px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          color: #096a0e;
        }
      }
    }

    .no-result-page {
      .no-result-carousel {
        margin: 0 auto;
        margin-left: 0;
        max-width: 120rem;
        padding: 0 28px;
        position: relative;
        width: 100%;
        .image-carousel-global-container {
          .swiper-slide {
            width: 216px;
            margin-right: 20px;
          }
        }
        .img {
          /* width: 216px;
          height: 288px; */
          aspect-ratio: 3 / 4;
        }
      }
    }

    .back-to-top {
      bottom: 100px;
    }

    .search-result {
      .js-grid-header {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        height: 30px;
        align-items: center;
      }

      .refinement-bar {
        display: flex;
      }

      .grid-product {
        z-index: 0;
        padding: 0;
        .mobile-version {
          display: none;
        }
        .version {
          .product-grid-container {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 8px;
            max-width: 100vw;
            margin: 0;
            padding: 0;
            width: 100vw;
            .single-element {
              margin-bottom: 0;
              // 6px = ( 3 * 8px ) / 4
              width: calc(25vw - 6px);
              max-width: calc(25vw - 6px);
              flex-basis: calc(25vw - 6px);
              padding: 0;

              .swiper-container {
                z-index: -1;
                .swiper-pagination.swiper-pagination-bullets {
                  opacity: 0;
                }
              }

              &:hover {
                .swiper-container {
                  z-index: 0;
                  .swiper-pagination.swiper-pagination-bullets {
                    opacity: 1;
                  }
                  .swiper-button-next,
                  .swiper-button-prev {
                    opacity: 1;
                  }

                  .swiper-button-disabled {
                    opacity: 0.7;
                  }
                }
              }

              .login-to-buy-image-banner {
                display: none;
              }
              .tile-image {
                display: block;
              }
              &.login-to-buy {
                &:hover {
                  .login-to-buy-image-banner {
                    display: block;
                    &-overlay {
                      background: $black;
                      height: 100%;
                      opacity: 0.5;
                      width: 100%;
                      position: absolute;
                      top: 0;
                      z-index: 0;
                    }
                    &-text {
                      background: $white;
                      color: $black;
                      font-size: 12px;
                      font-stretch: normal;
                      font-style: normal;
                      font-weight: 500;
                      height: 60px;
                      letter-spacing: 0.5px;
                      line-height: 1;
                      padding: 24px 32px;
                      position: absolute;
                      text-align: center;
                      text-transform: uppercase;
                      top: 0;
                      width: 100%;
                      z-index: 2;
                    }
                  }
                }
              }
              &.show-second-image:hover,
              .single-element-content-image:focus {
                .tile-image {
                  display: block;
                  position: unset;
                  height: auto;
                  width: 100%;
                }
              }
              &-content {
                .single-element-content-detail {
                  padding: 12px 24px 24px 12px;
                  .badge-container {
                    .product-badge {
                      margin-top: 12px;
                      font-family: $futura-book;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 16.1px;
                      letter-spacing: 0.03em;
                      text-align: left;
                      text-underline-position: from-font;
                      text-decoration-skip-ink: none;
                      color: #0D0D0D;
                    }
                  }
                }
                &-detail {
                  text-align: center;
                  &-price {
                    .previous-price {
                      color: $dark-grey;
                      text-decoration: line-through;
                      margin-right: 16px;
                    }
                  }
                }
                &-image {
                  height: 100%;
                  margin-bottom: 0;
                  position: relative;
                  width: 100%;
                  &-tag {
                    background-color: red;
                    height: 20px;
                    padding: 4px 8px;
                    border-radius: 10px;
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: 0.5px;
                    text-align: center;
                    position: absolute;
                    bottom: 8px;
                    left: 8px;
                    color: $white;
                  }
                  &-img {
                    max-width: 100%;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    display: block;
                    height: 100%;
                  }
                }
              }
            }


            .pageDesignerGridContent {
              .col-md-3 {
                width: calc(25vw - 6px);
                max-width: calc(25vw - 6px);
              }
            }
          }

          .products-seen {
            margin-bottom: 16px;
            text-align: center;
          }

          .show-more {
            margin: 0 auto;
            padding-bottom: 40px;
            width: 296px;
          }
        }

        .product {
          .swiper-slide-overlay {
            position: absolute;
            z-index: 0;
          }
          .product-badge.product-badge-top {
            z-index: 1;
            top: 12px;
            left: 12px;
          }

          .swiper-container {
            z-index: -1;
          }
          &:hover {
            .swiper-slide-overlay {
              display: none;
              z-index: -1;
            }

            .swiper-container {
              z-index: 0;
            }
          }
        }
      }

      .selected-filters {
        display: block;
        margin-bottom: 24px;
        margin-top: 23px;
        &-list {
          display: flex;
          list-style: none;
          .selected-filter {
            display: flex;
            align-items: center;
            margin-right: 31px;
            &-icon {
              margin-right: 20px;
              position: relative;
              > span {
                position: absolute;
                top: 3px;
              }
            }
            &-label {
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 0.5px;
              color: $black;
            }
          }
          .reset-filters {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.5px;
            color: $black;
            text-decoration: underline;
          }
        }
      }
    }

    .horizontal-row {
      &.grid-header-bottom-row {
        display: block;
        width: calc(100% + 56px);
        border-top: 1px solid $light-grey;
        margin-left: -28px;
      }
    }

    .refinement-bar {
      display: flex;
    }
  }

  .category-description-footer {
    padding: 40px 24px;
    border-top: 1px solid #e5e5e5;
  }
}

.page-search-result-container.new-page-search-result {
  .single-element-content {
    .product-image-badge {
      height: 32px;
      top: 16px;
      left: 16px;

      &.top-right {
        left: unset;
        right: 16px;
      }

      &.height-desktop-16 {
        height: 16px;
      }
      &.height-desktop-20 {
        height: 20px;
      }
      &.height-desktop-24 {
        height: 24px;
      }
      &.height-desktop-28 {
        height: 28px;
      }
      &.height-desktop-32 {
        height: 32px;
      }
      &.height-desktop-36 {
        height: 36px;
      }
      &.height-desktop-40 {
        height: 40px;
      }
    }

    .single-element-content-detail {
      padding: 12px 24px 24px 12px;
      .product-badge {
        margin-bottom: 8px !important;
      }
      .single-element-content-detail-description {
        margin-bottom: 8px !important;
        .product-link {
          font-size: 14px;
          font-weight: 400;
          line-height: 16.1px;
          letter-spacing: 0.015em;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .single-element-content-image {
    display: block;
    margin: 0 auto;

    .swiper-slide-overlay {
      display: block;
    }

    .image-container {
      position: relative;

      .product-badge {
        background-color: transparent;
        border-radius: 18px;
        color: $black;
        height: 24px;
        padding: 0;
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.1px;
        letter-spacing: 0.03em;
        text-align: left;
      }
    }
  }

  .show-more.js_show-more {
    width: fit-content;
    margin: 40px auto 64px !important;
    padding: 0 !important;

    .button-load-more.js_button-load-more {
      font-family: $futura-book;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: left;
      text-transform: none;
      border: 1px solid #b0b0b0;
      padding: 12px 56px;
    }
  }
  .search-no-result-decorator-container {
    .search-result {
      .grid-product {
        .version {
          .product-grid-container {
            .pageDesignerGridContent {
              &.col-md-3 {
                width: calc(25vw - 6px) !important;
                max-width: calc(25vw - 6px) !important;
                grid-column: span 1;
              }


              &.col-md-6 {
                width: calc(50vw - 6px) !important;
                max-width: calc(50vw - 6px) !important;
                grid-column: span 2;
              }

              &.col-md-12 {
                width: 100% !important;
                flex-basis: 100% !important;
                max-width: 100% !important;
                grid-column: span 4;
              }

              &.col-span-2 {
                grid-column: span 2;
                grid-row: span 2;
              }

              .pd-wrapper-image-layer,
              .image-no-tv,
              .image-container-selector,
              .visualImage {
                height: 100%;
                img {
                  height: 100%;
                }
              }

              .zoomImage-true {
                overflow: hidden;
                .single-element-content-image {
                  transition: transform 0.5s ease-in-out;

                  &:hover {
                    transform: scale(1.03);
                    transition: transform 0.5s ease-in-out;
                  }
                }
              }

              .experience-assets-product,
              .pd-page__content,
              .product-tile-editorial,
              .product-tile {
                .tile-body.single-element-content-detail {
                  padding: 24px 40px 24px 24px;

                  .product-badge {
                    font-size: 16px;
                  }
 
                  .product-link {
                    font-family: $futura-book;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 18.4px;
                    letter-spacing: 0.015em;
                    color: $white;
                  }
                }

                .price {
                  margin-top: 8px;
                  font-family: $futura-book;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 18.4px;
                  letter-spacing: 0.015em;
                  color: $white;
                }


                .single-element-content-image {
                  aspect-ratio: unset !important;
                }
              }

              .pd-page__content {
                .pd-text {
                  position: relative;
                  padding: 40px 24px;

                  span {
                    max-width: 50%;
                    width: 50%;
                    display: block;
                  }

                  &.pd-text-align-center {
                    padding: 40px 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.no-results-recommendations {
  margin-top: 96px;

  .recommendations .experience-assets-product {
    padding: 0;
  }

  .recommendations {
    .swiper-header {
      margin-left: 0;
    }
  }
}
