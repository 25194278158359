.no-zindex {
  .header {
    position: sticky !important;
    z-index: 2;
  }
}

.header {
  @include z(header);
  background-color: $white;
  position: sticky;
  z-index: 6;
  top: 0;

  .account-header-link {
    display: none;
    padding: .9rem 0;
    height: 4rem;
    width: 4rem;
    
    .icon--account-new.action {
      display: flex;
      flex-direction: row;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }

  .hidden-sm {
    display: none;
  }

  .visible-md {
    display: none;
  }

  .header-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    position: unset !important;
    padding: 0 16px;

    &.no-menu {
      border: 1px solid $light-grey;
      .minicart-action {
        margin-right: 5px;
      }
    //   // border-bottom: none;
    //   .logo {
    //     height: 48px;
    //     padding-left: calc(24px + 8.33333%);
    //     a {
    //       height: 48px;
    //       &:after {
    //         width: 154px;
    //         height: 15px;
    //         margin: 20px 0 18px;
    //       }
    //     }
    //   }
    //   .right-actions {
    //     a {
    //       margin: 12px 0 18px;
    //     }
    //   }
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    .action-icon {
      margin: 0 15px;
    }
    .cart-elements {
      margin-left: 2px;
    }
  }

  .left-actions {
    justify-content: flex-start;
    .action {
      margin-right: 24px;
    }
  }

  .right-actions {
    // flex: 1 0 auto;
    justify-content: flex-end;
    z-index: 2;
    .action {
      margin-left: 20px;
    }
  }

  ul {
    list-style: none;
  }

  .nav-services {
    display: flex;
  }

  .cart-amount {
    padding-left: 15px;
  }
}

.header {
  z-index: 50;
  .burger {
    position: relative;
    width: 2rem;
    height: 2rem;
    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: center center;
      transition: all ease-in-out 0.2s;
    }
    &::before {
      opacity: 1;
      transform: translate(-50%, -50%) rotate(0);
      @include fixedSprite('burger-new');
      width: 1.6rem;
      height: 1.6rem;
    }
    &::after {
      opacity: 0;
      transform: translate(-50%, -50%) rotate(-15deg);
      @include fixedSprite('close-bold');
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  .header-search,
  .right-actions .action {
    opacity: 1;
    transition: opacity ease-in-out 0.2s;
    position: relative;
    width: 1.8rem;
    height: 2.1rem;

    &.minicart-notification-action {
      margin: 0;
      width: 0;
      height: 0;
    }

    &.burger {
      height: 1.6rem;
    }
  
    .js-quantity {
      display: block;
      position: absolute;
      left: calc(100% + 2px);
      top: 9px;
      font-family: $futura-book;
      font-size: 10px;
      font-weight: 700;
      line-height: 9px;
      letter-spacing: 0.015em;
      
      text-align: center;
    }
  }

  .mobile-icon-minicart-container {
    margin-right: 5px;
  }

  &.animate {
    .header-search,
    .right-actions .action:not(.burger) {
      opacity: 0;
    }
    .burger {
      &::before {
        opacity: 0;
        transform: translate(-50%, -50%) rotate(15deg);
      }
      &::after {
        transform: translate(-50%, -50%) rotate(0);
        opacity: 1;
      }
    }
  }
}

.header,
.footer {
  .logo {
    text-align: left;
    line-height: 0;
    z-index: 2;
    a {
      display: inline-block;
    }
    a::after {
      content: '';
      @include fixedSprite('logo-mobile-red');
      width: 20rem;
      height: 1.5rem;
    }
  }
}
.blur-body-overlay,
.blur-overlay {
  background: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100vw;

  backdrop-filter: blur(20px);
  visibility: hidden;
  position: absolute;
  opacity: 0;
  z-index: 0;
  transition: opacity .6s cubic-bezier(.4,0,.6,1) 80ms, visibility .6s step-end 80ms;

  &.active {
    // backdrop-filter: blur(20px);
    // z-index: 0;
    opacity: 1;
    visibility: visible;
    // transition: opacity .5s cubic-bezier(0.05, 0.32, 0.58, 1) 80ms, visibility .5s step-start 80ms;
    transition: opacity .3s cubic-bezier(0.05, 0.32, 0.54, 0.84) 0ms, visibility .3s step-start 0ms;
  }
}

.blur-body-overlay {
  &.active {
    transition: all cubic-bezier(0.95, -0.23, 0.93, 0.72) 0.5s;
  }
}


.blur-body-overlay {
  top: 0;
  position: fixed;

  &.active {
    z-index: 15;
  }
}
.ae-price-description {
  position: absolute;
  left: -999em;
}


.button--loading::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid #0D0D0D;
  border-top-color: #E0E0E0;
  border-right-color: #E0E0E0;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}


@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
