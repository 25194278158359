.twoColumnContainer {
  .twoColumnContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    aspect-ratio: 4/5;
    height: auto;
    gap: 4px;
    min-height: inherit !important;

    .visualImage {
      position: relative;
    }

    .column1,
    .column2 {
      width: 100vw;
    }
  }
}

.twoColumnContainer {
  .twoColumnContent {
    .column1,
    .column2 {
      width: unset;
    }
  }
}