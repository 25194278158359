.negative-margin-container {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
}

.cart-container {
  display: flex;
  flex-direction: column;
}

.cart-head-section {
  .title {
    @extend %heading-medium;
    padding: 17px 0 16px;
  }
}

.cart-body-section {
  border-top: 1px solid $light-grey;
  // border-bottom: 1px solid $light-grey;
}

.list-products {
  .title-list {
    padding-top: 24px;
    @extend %title--large;
  }
}
.product-wrapper {
  border-top: 1px solid $light-grey;
  border-bottom: 1px solid $light-grey;
  margin-top: 16px;
}

.product-message {
  padding-bottom: 16px;
  @extend %label--small;
  &--green {
    color: $status_success;
  }
  [class*='icon--'] {
    margin-right: 8px;
  }
}

/** Actions on product line item -  WISHLIST - REMOVE - PICKUP-SHIP  -->START*/
.product-actions-wrapper {
  @extend %label--small;
}

.wish-delete-wrapper {
  display: flex;
  justify-content: center;
  border-top: 1px solid $lighter-grey;
  border-bottom: 1px solid $lighter-grey;
}

.option-ship-type {
  display: flex;
  justify-content: center;

  [class*='instead'] {
    display: none;
    text-align: center;
    flex: 1;
  }

  [class*='icon--'] {
    margin-right: 8px;
  }
  .ship-action {
    padding: 18px 0;
    &.pick-up {
      .ship-instead {
        display: inline-flex;
      }
    }

    &.ship {
      .pick-up-instead {
        display: inline-flex;
      }
    }
  }
}

.product-wishlist-wrapper,
.product-remove-wrapper {
  display: flex;
  justify-content: center;
  flex: 1;
  [class*='icon--'] {
    margin-right: 8px;
  }
}

.product-wishlist-wrapper {
  border-right: 1px solid $light-grey;
}

.btn-cart-remove {
  padding: 18px 0;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}
.btn-cart-wishlist {
  padding: 18px 0;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  .icon--wishlist-small {
    width: 1.2rem;
    height: 1.2rem;
  }
}

/** Actions on product line item -  WISHLIST - REMOVE - PICKUP-SHIP  -->END*/

/** CART ITEM GRID setup -->START*/
.cart-item-grid {
  display: grid;
  grid-template-areas:
    'message message'
    'product-image attributes'
    'product-image price'
    'quantity quantity'
    'product-actions product-actions';
  grid-template-columns: 128px 1fr;
}

.product-message {
  grid-area: message;
}

.product-image-wrapper {
  grid-area: product-image;
  grid-column: 1;
  grid-row: 2 / 4;
}

.product-attributes {
  grid-area: attributes;
}

.product-prices-wrapper {
  grid-area: price;
  justify-self: end;
}

.product-quantity-wrapper {
  grid-area: quantity;
}

.product-actions-wrapper {
  grid-area: product-actions;
}

/** CART ITEM GRID setup --> END*/

/** CART ITEM  style -->START*/
.cart-item-grid {
  padding-top: 16px;
}
.product-title {
  @extend %title--regular;
  text-transform: none;
}
.product-info-img {
  width: 120px;
  height: 160px;
  margin-right: 8px;
  flex-shrink: 0;
}

.product-info-details {
  width: 100%;
  flex: 1;

  .detail-attribute {
    display: flex;
    justify-content: space-between;
    @extend %body--small;
    margin-top: 16px;
    + .detail-attribute {
      margin-top: 8px;
    }
  }
  .value-field {
    text-align: right;
    display: flex;
  }
}

.cart-edit-link {
  @extend %body--small;
  margin-left: 20px;
  &.link-underline {
    &::before {
      bottom: 4px;
    }
  }
}

.product-prices-wrapper {
  .line-item-price-info {
    display: none;
  }

  .price {
    column-gap: 8px;
    display: inline-flex;
    flex-wrap: wrap;

    span {
      font-size: 14px;

      &.price-discount {
        color: #27ae61;
      }

      &.strike-through {
        color: #7d7d7d;
      }

      &.pricing {
        color: #0d0d0d;
      }
    }

    .cart-line-item-disclaimer {
      font-size: 12px;
      color: #b4b4b4;
      line-height: 1.2;
    }

    .prior-best-price-component {
      width: fit-content;
      flex-wrap: wrap;
      margin-left: 0;
      display: none;
    }

    .layer-tooltip-prior-best-price {
      width: fit-content;
      flex-wrap: wrap;
      margin-left: 0;
      display: inline-flex;
    }
  }
}

.product-quantity-wrapper {
  .name-field {
    display: none;
  }
  @extend %body--small;
  text-transform: uppercase;
}

/** CART ITEM  style -->END*/

.strike-through {
  color: $dark-grey;
  text-decoration: line-through;
}

.cart-body-section {
  .info-message-general {
    padding: 16px 0;
    text-align: center;
    color: $dark-grey;
    @extend %label--small;
    [class*='icon--'] {
      margin-right: 8px;
    }
  }
}

/** CART SIDEBAR  -->START*/
.cart-sidebar-section {
  display: grid;

  grid-template-areas:
    'heading'
    'totals'
    'buttons'
    'voucher'
    'accordions';

  .cart-head-section {
    grid-area: heading;
  }
  .wrapper-cart-totals {
    grid-area: totals;
  }
  .sticky-wrapper-totals {
    grid-area: buttons;
  }
  .voucher-wrapper {
    grid-area: voucher;
  }
  .wrapper-accordion-editorial {
    grid-area: accordions;
  }

  .accordion-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 13px 0;
  }
  .accordion-trigger {
    @extend %label--small;
  }

  .voucher-accordion-content {
    padding-bottom: 16px;
  }
  .cart-promo-accordion {
    .voucher-accordion-content {
      display: none;
    }
    &.is-active {
      .voucher-accordion-content {
        display: block;
      }
    }
  }

  .wrapper-cart-totals {
    @extend %body--small--medium;
    background: $light-grey;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .line-totals-item {
    display: flex;
    justify-content: space-between;

    + .line-totals-item {
      padding-top: 16px;
    }
    &.hide-order-discount {
      display: none;
    }
    &.cart-duties-tax {
      .duties-tax-label {
        display: flex;
      }
    }
    &.cart-sales-tax {
      .sales-tax-label {
        display: flex;
      }
    }
  }
}

.voucher-input-container {
  display: flex;
  align-items: baseline;
  .promo-code-submit {
    margin-left: 16px;
  }
}

.sticky-wrapper-totals {
  background: $light-grey;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid $dark-grey;

  @extend %body--heavy;
}

.cart-checkout-buttons-wrapper {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  flex: 1 0 50%;
  flex-direction: column;
  .btn-container {
    width: 100%;
    + .btn-container {
      margin-left: 0;
      margin-top: 16px;
      z-index: 4;
    }
    .checkout-btn {
      &.disabled {
        background-color: $dark-grey;
        border: 1px solid $dark-grey;
        cursor: not-allowed;
      }
    }
  }
  &.disabled {
    #cart-paypal-button-container {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.start-lines {
  justify-content: flex-start;
}

.end-lines {
  justify-content: flex-end;
  text-align: right;
}

.text-green {
  color: $status_sucess_green;
}

.coupon-price-adjustment {
  justify-content: space-between;
}
/** CART SIDEBAR  -->END*/

.cart-error {
  background: $light-grey;
  color: $status_error;
  margin-bottom: -17px;
  margin-left: -15px;
  margin-top: -1px;
  padding: 8px 15px;
  width: calc(100% + 31px);
}

#editProductModal {
  background: $white;
  display: none;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 6;

  .edit-modal-container {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &.show {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .quick-view-dialog {
    height: 100%;
    .modal-content {
      max-height: 100vh;
      padding: 0 18px;
      .horizontal-row {
        &.edit-product-modal-header-bottom {
          border-top: 1px solid $light-grey;
          margin-left: -18px;
          width: calc(100% + 36px);
        }
      }
      .modal-body {
        .product-detail {
          margin-top: 0;
          .carousel-item {
            height: 333px;
            margin: 0 auto;
            width: 250px;
          }
          .name-number-details {
            .product-name {
              font-size: 16px;
              font-stretch: normal;
              font-style: normal;
              font-weight: 500;
              letter-spacing: 1px;
              line-height: 1.6;
              margin-bottom: 8px;
              margin-top: 16px;
            }
            .product-description {
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.8;
              letter-spacing: 0.3px;
            }
            .size-selection-field {
              .size-label-section {
                align-items: center;
                display: flex;
                justify-content: space-between;
              }
              .size-values {
                padding: 0;
                .swatch-attribute-values {
                  // display: flex;
                  display: inline-block;
                  vertical-align: middle;
                  // height: 48px;
                  height: unset;
                  list-style: none;
                }
              }
              .swatch-attribute-value {
                align-items: center;
                display: flex;
                justify-content: center;
                width: 48px;
                float: left;
                padding: 4px 16px;

                &.selected {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
      .modal-header {
        padding: 0;
        height: 50px;
        .modal-close {
          top: 15px;
          right: 15px;
        }
      }
      .modal-footer {
        margin-top: 16px;
        border-top: none;
        padding: 0;
        padding-bottom: 85px;
        #add-to-cart-wishlist-wrapper-mobile {
          align-items: center;
          display: flex;
          height: 48px;
          justify-content: space-between;

          .add-to-wishlist {
            display: flex;
            justify-content: flex-end;
          }
          .add-to-wish-list {
            border: 1px solid $dark-grey;
            height: 48px;
            width: 48px;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            .add-to-wish-list-icon {
              height: 20px;
              .white-icon {
                display: none;
              }
            }
            &.active {
              background-color: $black;
              .add-to-wish-list-icon {
                .white-icon {
                  display: block;
                }
                .black-icon {
                  display: none;
                }
              }
            }
          }
        }
        .add-to-cart {
          padding: 16px 4px;
        }
      }
    }
  }

  .invalid-selected-product {
    display: block;
    color: $status_error;
    margin-bottom: 16px;
    &.hidden {
      display: none;
    }
  }
}

// .red-el {
//   border: 1.5px dashed red;
//   color: grey;
// }
