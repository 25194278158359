.experience-region.experience-imageVideoCtaContent {
  position: relative;

  video-js {
    padding-top: 125% !important;
  }

  .component-video-layer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  .videoBrightCove-container {
    &.mobilecrop {
      video {
        object-fit: cover;
      }
    }

    &.mobilefill {
      video {
        object-fit: fill;
      }
    }
  }

  .textLayer {
    background: #111;
  }

  .experience-component.experience-assets-headingGroup {
    .headingGroup-container {
      display: flex;
      justify-content: space-evenly;
      position: absolute;
      color: #fff;
      left: 50%;
      z-index: 2;
      width: 100%;
      padding-right: 16px;
      padding-left: 16px;

      &.text-bottom-centred {
        bottom: 56px;
        transform: translateX(-50%);
      }

      &.text-centered {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .headingGroup-inner {
      align-content: center;
      font-family: $futura-pt;
      text-align: center;
      font-weight: 400;

      .heading {
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: .01em;
      }

      .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: .03em;
        text-align: center;
        margin-top: 8px;
      }

      .link {
        display: flex;
        flex-direction: row;
        gap: 24px;
        margin-top: 16px;
        justify-content: center;
        text-underline-offset: 15%;

        a {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: .04em;
          width: fit-content;
          cursor: pointer;
        }
      }
    }
  }

  .vjs-control-bar {
    z-index: 2;
    margin-top: 16px;
    margin-bottom: 24px;
    pointer-events: all;

    .vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button {
      display: none;
    }

    .vjs-play-control.vjs-control.vjs-button.vjs-playing,
    .vjs-volume-panel.vjs-control.vjs-volume-panel-horizontal,
    .vjs-play-control.vjs-control.vjs-button.vjs-paused {
      opacity: 0.75;
      z-index: 2;
      margin-right: 24px;
      margin-bottom: 24px;
      width: 24px;
      margin-left: 24px;

      &:hover {
        opacity: 0.90;
      }
    }

    .vjs-volume-panel.vjs-control.vjs-volume-panel-horizontal {
      &:active {
        width: unset;
      }
    }

    .vjs-volume-control.vjs-control.vjs-volume-horizontal {
      display: none;

      .vjs-volume-level {
        width: unset;
        display: none;
      }
    }

    .vjs-volume-panel.vjs-control.vjs-volume-panel-horizontal.vjs-hover {
      width: 24px;
    }
  }
}
