.wheel-carousel-component {
  .swiper-pagination {
    display: none;
    align-items: center;
    height: 3rem;
    width: 100%;
    justify-content: center;
    padding: 0 6.9rem;
    margin-top: 2.3rem;
    .swiper-pagination-bullet {
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      color: black;
      opacity: 1;
      background: transparent;
      border: 1px solid black;

      width: 0.9rem;
      height: 1.2rem;
      margin: 1rem;
      transform: rotate(-270deg);
      border-radius: 7.5px;
    }

    .swiper-pagination-bullet-active {
      color: #fff;
      background: black;
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0;
  }
}

.wheel-carousel-component {
  padding: 2rem 1.6rem 2rem;
  .pd-video__container,
  .pd-wrapper-image-layer {
    margin: 0 auto;
    margin-bottom: 0;
  }

  .swiper-wrapper {
    align-items: center;
    flex-wrap: wrap;
    > * {
      padding: 0 4.9rem;
      width: 100%;
      height: 100%;
    }
  }

  .group-component {
    + .group-component {
      margin-top: 4rem;
    }
  }
}
