.twoColumnContainer {
  .twoColumnContent {
    display: flex;
    flex-direction: row;
    height: calc(50vw - 4px);
    aspect-ratio: 1/1;
    gap: 8px;

    .visualImage {
      height: calc(50vw - 4px);
      position: relative;

      img {
        height: calc(50vw - 4px);
        width: calc(50vw - 4px);
      }
    }

    .text-layer-notsticky-container.bottom {
      .text-layer {
        max-width: calc(100% - 48px);
      }
    }

    .text-layer-notsticky-container.center {
      .text-layer {
        max-width: calc(100% - 48px);
      }
    }
  }
}
