.editorial-product-grid {
  &__media-product-region {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .pd-wrapper-image-layer {
      flex: 100%;
    }

    .experience-assets-product {
      flex: 0 0 calc(50% - 2px);
      width: calc(50vw - 2px);
    }
  }

  &__product-region {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .experience-assets-product {
      flex: 0 0 calc(50% - 2px);
      width: calc(50% - 2px);
    }
  }

  &__wrapper {
    .single-element-content {
      .product-color {
        display: none;
      }

      &-content-detail {
        &-description {
          width: 100%;
        }
      }
    }
  }

  .experience-assets-product {
    .single-element-content {
      margin-bottom: 0;
    }
  }
}