//fonts
$base-font: 10rem;

$futura-pt: 'futura-pt', sans-serif;
$futura-pt-condensed: 'futura-pt-condensed', sans-serif;
$futura-pt-bold: 'futura-pt-bold', sans-serif;

$futura-book: $futura-pt;
$futura-medium: $futura-pt;
$futura-heavy: $futura-pt;

$primary-font-desktop: (
  xs: (
    $futura-medium,
    500,
    1.2rem,
    1.2rem,
    1px,
  ),
  xs-u: (
    $futura-medium,
    500,
    1.2rem,
    1.2rem,
    1px,
  ),
  sm: (
    $futura-medium,
    400,
    1.2rem,
    2.4rem,
    0.5px,
  ),
  sm-u: (
    $futura-medium,
    400,
    1.2rem,
    1.6rem,
    1px,
  ),
  m: (
    $futura-medium,
    500,
    1.4rem,
    2.4rem,
    0.2px,
  ),
  m-u: (
    $futura-medium,
    500,
    1.4rem,
    2.4rem,
    0.2px,
  ),
);

$heading-desktop: (
  sub: (
    $futura-medium,
    500,
    1.6rem,
    2.4rem,
    0px,
  ),
  sub-u: (
    $futura-medium,
    500,
    1.6rem,
    2.4rem,
    0px,
  ),
  h6: (
    $futura-medium,
    400,
    1.6rem,
    2.4rem,
    0.5px,
  ),
  h5: (
    $futura-medium,
    500,
    2rem,
    2.4rem,
    0px,
  ),
  h4: (
    $futura-medium,
    400,
    2.4rem,
    4rem,
    0px,
  ),
  h3: (
    $futura-medium,
    400,
    3.6rem,
    4rem,
    0px,
  ),
  h3-u: (
    $futura-medium,
    400,
    3.6rem,
    4rem,
    0px,
  ),
  h2: (
    $futura-medium,
    400,
    4rem,
    4.8rem,
    0px,
  ),
  h1: (
    $futura-medium,
    400,
    4.8rem,
    5.6rem,
    -0.2px,
  ),
  big: (
    $futura-medium,
    400,
    6.4rem,
    6.4rem,
    -0.2px,
  ),
  big-u: (
    $futura-medium,
    400,
    6.4rem,
    6.4rem,
    -0.2px,
  ),
);

$component-desktop: (
  cta: (
    $futura-book,
    500,
    1.2rem,
    1.6rem,
    2px,
  ),
  price: (
    $futura-book,
    500,
    1.2rem,
    2.4rem,
    1px,
  ),
  dida: (
    $futura-book,
    400,
    1.1rem,
    2.4rem,
    0.5px,
  ),
  paragraph: (
    $futura-book,
    400,
    1.4rem,
    2.4rem,
    0.2px,
  ),
);
