.order-list-container {
  width: 100%;
  padding-left: 12px;
  padding-right: 24px;

  .order-list-header {
    .row {
      margin: 0;
      .order-label-box {
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 1;
        margin-bottom: 40px;
      }
    }
  }

  .order-list-title {
    .history-title {
      font-size: 24px;
      letter-spacing: 1.5px;
    }
  }

  .order-row {
    > span {
      font-size: 12px;
      height: 21px;
      margin: 4px 0;
      &.order-link {
        text-align: right;
        margin-top: 0;
        margin-bottom: 0;
      }
      &.toggle-order-accordion {
        width: 12px;
        margin-right: 16px;
        cursor: pointer;
        &.active {
          transform: rotate(180deg);
          transition: 0.3s;
        }
      }
      &.green-text {
        color: green;
      }
    }
    .order-value {
      text-align: left;
      &.order-number {
        flex-basis: calc(16.66667% - 28px);
      }
    }
  }

  .order-accordion-content {
    display: none;
    opacity: 0;
    width: 100%;
    &.is-open {
      display: block;
      opacity: 1;
      transition: all ease-in 0.3s;
    }
    .order-details {
      margin: 16px 0;
    }
    .order-products {
      margin: 0;
      .order-product-details {
        min-height: unset;
        margin-bottom: 8px;
        display: flex;
        .product-image-wrapper {
          cursor: pointer;
          height: 155px;
          width: 116px;
          margin-right: 16px;
        }
        .product-info-details {
          .product-title {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            margin-bottom: 16px;
          }
          .product-infot-text {
            .product-single-info {
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.8;
              letter-spacing: 0.3px;
              margin: 16px 0;
              .info-label {
                text-transform: uppercase;
                padding: 0;
              }
              .info-value {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}
