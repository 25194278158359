#dynamic-modal {
  .modal-container {
    height: auto;
    bottom: 4.4rem;
    right: 4.4rem;
    left: auto;
    min-width: 40rem !important;
    max-width: 41.5rem !important;
  }
}
