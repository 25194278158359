#layer-notification-minicart,
#layer-minicart,
#layer-filter {
  top: 120vh;
  transition: top linear 0.5s;
  left: 0;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  z-index: 6;
  position: absolute;

  .paypal-powered-by {
    display: none !important;
  }
}

.layer-notification-minicart {
  width: 0;
}

#layer-minicart-component {
  position: unset;
}

.minicart-notification-container,
.minicart-modal,
.modal {
  .modal-header.minicart-header {
    margin: 1.6rem;
    .title {
      color: $black;
      font-family: $futura-book;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.64px;
    }
    align-items: center;
    display: flex;
    justify-content: space-between;
    .modal-close-container {
      height: 16px;
      cursor: pointer;
      .modal-close {
        height: 16px;
        width: 16px;
      }
    }
  }
  .minicart-item-grid {
    // padding: 16px;
    padding-bottom: 0;
    padding-top: 0;
    padding-right: 24px;
  }
}

.minicart-empty {
  padding-bottom: 1.6rem;
  .wrapper-login-actions {
    padding-top: 1.6rem;
  }

  .separator {
    padding-top: 2.6rem;
    padding-bottom: 2.6rem;
    display: flex;
    .line-separator {
      border-bottom: 1px solid $light-grey;
      margin: auto;
    }
    .separator-center {
      text-align: center;
      font-size: 14px;
    }
  }
}

.minicart-item-grid {
  display: grid;
  grid-template-areas:
    'product-image product-name product-name'
    'product-image attributes attributes'
    'product-image quantity quantity'
    'product-image price price'
    'product-image product-actions product-actions';
  grid-template-columns: 148px 1fr;

  .product-image-wrapper {
    grid-area: product-image;
  }

  .product-name-wrapper {
    grid-area: product-name;
  }

  .product-attributes {
    color: $dark-grey;
    grid-area: attributes;
  }

  .product-prices-wrapper {
    grid-area: price;

    .strike-trough {
      font-family: $futura-book;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.6px;
    }
  }

  .product-quantity-wrapper {
    color: $dark-grey;
    display: flex;
    grid-area: attributes;
    margin: 0;
    text-transform: none;

    .name-field {
      display: flex;
    }
  }

  .pricing {
    vertical-align: top;
  }

  .product-title,
  .pricing,
  .detail-attribute .name-field,
  .detail-attribute .value-field,
  .quantity .name-field,
  .quantity .value-field{
    color: #111;
    font-family: $futura-book;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.24px;
    text-transform: capitalize;
  }

  .icon--trash {
    display: none;
  }

  .product-actions-wrapper {
    grid-area: product-actions;
    margin-top: 10px;
    margin-bottom: 4px;
    .wish-delete-wrapper {
      border-bottom: none;
      border-top: none;
      .btn-cart-remove {
        justify-content: flex-start;
        padding-bottom: 0;
        padding-top: 0;
        .icon--trash {
          display: none;
        }

        span {
          color: #111;
          font-family: $futura-book;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: 0.21px;
        }
      }
    }
  }

  .minicart-line-item-disclaimer {
    font-size: 12px;
    font-weight: 400;
    color: #7d7d7d;
    width: 100%;
    line-height: 150%;
    letter-spacing: 0.24px;
  }
}

/** MINCART ITEM  style -->START*/
.minicart-item-grid {
  .product-title {
    @extend %title--small;
    text-transform: none;
    padding-right: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .product-info-img {
    width: 132px;
    min-height: 140px;
    height: 100%;
    margin-right: 16px;
  }

  .product-info-details {
    width: 100%;
    flex: 1;

    .detail-attribute {
      display: flex;
      justify-content: flex-start;
      @extend %body--small;
      margin-top: 0;
      + .detail-attribute {
        margin-top: 0;
      }
    }

    .name-field {
      width: fit-content !important;
      color: #757575;
      margin-right: 8px;
    }
    .value-field {
      text-align: left;
      display: flex;
    }
  }

  .name-field {
    color: #757575;
  }

  .cart-edit-link {
    display: none;
  }

  .product-prices-wrapper {
    text-align: left;
    justify-self: start;

    .line-item-price-info {
      display: none;
    }

    .line-item-total-price {
      margin-bottom: 8px;

      .price {
        column-gap: 8px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        span {
          font-size: 12px;
          line-height: 150%;
          letter-spacing: 0.24px;

          &.price-discount {
            color: #27ae61;
          }

          &.strike-through {
            color: #7d7d7d;
          }

          &.pricing {
            color: #0d0d0d;
          }
        }
      }
    }
  }

  .product-quantity-wrapper {
    @extend %body--small;
    .name-field {
      width: fit-content !important;
      margin-right: 8px;
      text-transform: none;
    }

    .value-field {
      color: #111;
    }
  }
}

.minicart-prior-best-price-disclaimer {
  padding: 16px 13px;
  background-color: #f9f9f9;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 13px;

  .icon,
  .text {
    display: flex;
  }

  .text {
    font-size: 14px;
    color: #0d0d0d;
  }
}

.minicart-message {
  margin-top: 16px;
  @extend %body--small;
  text-align: left;
}

#overlay-minicart::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#overlay-minicart {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// #overlay-minicart {
//   padding: 16px;
// }

.minicart-notification-container {
  .minicart-content {
    .minicart-recommendations {
      .minicart-item-grid {
        padding: 0;
      }
    }
  }
}

.minicart-recommendations {
  border-top: 1px solid #e5e5e5;

  .minicart-item-grid {
    padding: 0;
  }

  .product-wrapper {
    //padding: 16px;
    padding-top: 0;
  }
  .row {
    .modal-header.minicart-header {
      margin-left: 0;
      margin-bottom: 8px;
      margin-top: 16px;
    }
  }
}

.minicart-notification-container {
  .minicart-content {
    .minicart-item-grid {
      // padding: 16px;
      padding-top: 0;
    }
  }
}

.minicart-notification-container,
#minicart-modal,
.minicart-modal {
  .minicart-recommendations {
    .product-wrapper {
      // padding: 16px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.minicart-notification-container {
  .product-content {
    .product-wrapper {
      .minicart-item-grid {
        padding: 16px 16px 0;
      }
    }

    .minicart-recommendations {
      .product-wrapper {
        .minicart-item-grid {
          padding: 0;
        }
      }
    }
  }
}

.minicart-recommendations {
  .row {
    padding: 0 16px;
  }

  .row.cart-and-ipay {
    padding: 0;
  }
}

.minicart-notification-container,
#minicart-modal,
.minicart-modal {
  position: relative;
  height: 100%;

  br {
    display: none;
  }

  .product-wrapper {
    position: relative;
    border: none;
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
    overflow: hidden;
    + .product-wrapper {
      // border-top: 1px solid $light-grey;
      padding-top: 0;
    }

    .removed-overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #f5f5f5;
      text-align: center;
      display: none;
    }
    .overlay-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      font-family: $futura-book;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.05em;
      text-align: center;
      color: #757575;
    }

    .removed-overlay.animate {
      animation-name: slide-left;
      animation-duration: 1s;
      -webkit-animation-name: slide-left;
      -webkit-animation-duration: 1s;
      display: block;
      left: 0;
      .overlay-text {
        animation-name: appear;
        animation-duration: 1.4s;
        opacity: 1;
      }
    }

    @keyframes appear {
      0% {
        opacity: 0;
      }
      90% {
        opacity: 0.01;
      }
      98% {
        opacity: 0.01;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes slide-left {
      from {
        left: 100%;
      }
      to {
        left: 0%;
      }
    }
  }
  .modal-footer {
    bottom: 0;
    border-top: 1px solid #e5e5e5;
    background: $white;
    padding: 16px 0 0 0;
    width: 100%;

    .link-shopping-bag {
      text-transform: none;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.04em;
      padding: 13.5px 40px;
      height: 48px;
      align-items: center;
    }

    .minicart-continue-shopping {
      width: 100%;
      text-align: center;
      margin: 8px 0 0 0;

      &-link {
        color: $black;
        cursor: pointer;
        //styleName: button-regular;
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.04em;
      }
    }

    .minicart-delivery-info {
      // margin-top: 16px;
      padding: 8px 16px 8px;
      border-top: 1px solid #e5e5e5;
      &-title,
      &-content {
        font-family: $futura-book;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.6px;
      }
      &-title {
        color: $black;
      }
      &-content {
        color: $secondary-grey;
      }
    }

    .btn-container {
      margin-top: 8px;
      padding: 0 16px;
    }

    .paypal-content {
      height: 48px;

      .paypal-button > .paypal-button-label-container {
        height: 21px;
      }
    }

    .button-container {
      padding: 0 16px;
    }

    .minicart-totals {
      padding: 0 16px;
      .sub-total-label,
      .sub-total {
        color: $black;
        font-family: $futura-book;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.64px;
      }

      .tax-label {
        margin-left: 8px;
        color: #999999;
      }

      .link-shopping-bag {
        text-transform: none;
      }
    }
  }
  .modal-content {
    padding: 0;
    max-height: calc(100vh / 1.5);
    overflow-y: scroll;
    overflow-x: hidden;
    &.emtpy-minicart-modal-content {
      overflow: hidden;

      .modal-footer {
        padding: 0;
      }
    }
  }
  :root {
    --padding-recommendations: 75px;
  }
  .product-content {
    padding-bottom: var(--padding-recommendations);
    -webkit-overflow-scrolling: touch;
  }
  .minicart-recommendations {
    padding-bottom: 0;

    .row {
      margin: 0;
    }
  }
}
.minicart-notification-container {
  .product-wrapper {
    margin-bottom: 0;
  }

  .modal-footer {
    .minicart-continue-shopping {
      padding: 0 16px 16px;
      margin: 8px 0 0 0;

      &-link {
        padding: 13.5px 40px;
        font-size: 14px;
      }
    }
  }
}

.minicart-modal {
  .modal-footer {
    .minicart-continue-shopping {
      padding-bottom: 16px;
    }
  }
}
.minicart-totals {
  @extend %body--small;
  .line-totals-item {
    display: none;
    justify-content: space-between;
    padding-bottom: 16px;
    &.grand-total {
      display: flex;
    }
  }

  .grand-total {
    @extend %body--heavy;
  }
}

.main-overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #00000087;
  z-index: 5;
  top: unset;
  &.no-top {
    top: 0;
  }
  &.full-height {
    height: 100%;
  }
}

.js-dropdown-content {
  z-index: 6;
}

.minicart-recommendations {
  .minicart-item-grid {
    overflow-y: auto;
    grid-template-columns: 140px 1fr;
  }

  .product-prices-wrapper,
  .product-title {
    font-weight: 500;
    text-transform: none;
  }
  .minicart-item-grid .product-actions-wrapper {
    grid-area: attributes;
  }

  .product-actions-wrapper {
    display: block !important;
  }

  .btn--secondary {
    padding: 16px 20px;
  }

  .product-info-img {
    margin-right: 0;
  }

  .product-prices-wrapper {
    position: unset !important;
  }
}

.minicart-notification-container {
  .minicart-recommender-desktop {
    padding: 0;
    overflow: unset;

    .minicart-recommendations {
      .modal-header.minicart-header {
        padding: 0;
      }
      .minicart-item-grid {
        background: #f5f5f5;
      }
    }
  }

  .modal-content {
    max-height: unset;

    .added-product-name {
      font-family: $futura-book;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.05em;
      text-align: left;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .color-label,
    .size-label {
      font-family: $futura-book;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.05em;
      text-align: left;
      color: #757575;

      .added-color,
      .added-size {
        font-family: $futura-book;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.05em;
        text-align: left;
        color: #111;
        margin-left: 8px;
      }
    }

    .added-price {
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.04em;
      text-align: left;
    }

    .added-price-strike-through {
      font-size: 14px;
    }

    .added-discount-percentage {
      font-size: 14px;
      color: #27ae61;
    }
  }

  .minicart-content {
    position: relative;
    display: block;

    .minicart-item-grid {
      display: flex;
      flex-direction: row;
      position: relative;
      margin-bottom: 16px;

      .attributes-container {
        position: relative;
      }

      .product-prices-wrapper {
        position: absolute;
        bottom: 0;
      }
    }

    .minicart-recommender-desktop {
      bottom: unset;
      position: unset;
      width: 100%;

      .minicart-item-grid {
        display: flex;
      }

      .added-price {
        margin-bottom: 8px;
      }

      .product-info-container {
        padding: 16px;
        position: relative;
      }

      .col {
        padding: 0;
      }

      .col-12 {
        padding: 0;
      }

      .product-title {
        font-family: $futura-book;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.05em;
        text-align: left;
        text-decoration: none;
        padding-right: 8px;
      }

      .product-prices-wrapper {
        margin-top: 8px;

        .value {
          font-family: $futura-book;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.05em;
          text-align: left;
        }
      }

      .product-actions-wrapper {
        position: absolute;
        bottom: 16px;

        a {
          font-family: $futura-book;
          font-size: 12px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0.04em;
          text-align: left;

          text-decoration: underline;
        }
      }
    }
  }

  .minicart-continue-shopping-link {
    border: 1px solid #e5e5e5;
    width: 100%;
    justify-content: center;
    padding: 13px 40px;
    border-radius: 100px;

    &:before {
      display: none;
    }
  }
}

#minicart-modal,
.minicart-modal {
  .modal-content {
    padding: 16px 16px 0;

    .minicart-prior-best-price-disclaimer {
      margin-bottom: 24px;
    }
  }
}

.emtpy-minicart-modal-footer {
  padding: 0 !important;
}

.minicart-notification-overlay,
.minicart-overlay,
.filter-overlay {
  // display: none;
  width: 100vw;
  position: absolute;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  background: #000000a1;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: visibility ease-out 1s, opacity ease-out 1s;
}
.layer-notification-minicart.is-open .minicart-notification-overlay,
.layer-minicart-animation.is-open .minicart-overlay,
.layer-minicart-animation.is-open .filter-overlay {
  visibility: visible;
  transition: visibility ease-in 1s, opacity ease-in 1s;

  opacity: 1;
}

// Add to Mini Cart Recommender
#layer-notification-minicart {
  .minicart-notification-container {
    #overlay-minicart.modal-content.product-content {
      .minicart-item-grid {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr;

        & .product-image-wrapper {
          width: auto;
          height: auto;
          min-height: auto;
          margin-right: 0;
        }

        & .attributes-container {
          display: flex;
          flex-direction: column;
          height: inherit;
          margin-left: 16px;

          .product-prices-wrapper {
            justify-content: flex-end;
            height: 100%;
            flex-direction: column;
            display: flex;
            width: 100%;

            .line-item-total-price {
              display: flex;
              gap: 8px;
              align-items: center;
            }
          }
        }
      }
    }

    .minicart-recommendations {
      &_wrapper {
        padding: 16px 0 0 16px;

        .product-image-wrapper {
          width: 100%;
          height: 100%;

          & > a {
            width: 100%;
            height: 100%;
          }
        }
      }

      &_title {
        font-size: 16px;
        font-weight: 400;
        color: #111;
        padding: 16px 16px 0 16px;
      }

      .skeleton-loading {
        display: flex;
        width: fit-content;

        &_item {
          height: 169.72px;
          background: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #f5f5f5 100%);
          width: 127.29px;
          margin-right: 8px;
        }

        &_progression-bar {
          height: 50px;
          max-width: 176px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;

          .swiper-pagination-progressbar.swiper-pagination-horizontal {
            height: 2px;
            width: 100%;
            background-color: #e5e5e5;
            display: flex;
            position: relative;

            .swiper-pagination-progressbar-fill {
              height: 2px;
              width: 23%;
              background-color: #111;
              position: relative;
              transform: none;
            }
          }
        }
      }

      .swiper {
        visibility: hidden;

        &-slide {
          margin-right: 8px;
        }

        &-progress-bar {
          height: 50px;
        }

        .swiper-pagination {
          position: relative;
        }

        &_progression-bar {
          height: 50px;
          max-width: 176px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;

          .swiper-pagination-progressbar.swiper-pagination-horizontal {
            height: 2px;
            background-color: #e5e5e5;

            .swiper-pagination-progressbar-fill {
              height: 2px;
              background-color: #111111;
            }
          }
        }
      }
    }
  }
}
