.page-account-container {
  .account-menu-col {
    border-right: 1px solid $light-grey;
    padding-top: 24px;
    .page-account-title {
      .main-title {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1.5px;
        color: black;
        text-transform: uppercase;
        margin-top: 0;
      }
    }
    .page-account-search {
      margin-bottom: 16px;
      margin-top: 24px;
      border-bottom: none;
      padding-bottom: 0;
    }

    .page-account-menu {
      overflow-x: unset;
      margin: 0;
      > .col {
        padding: 0;
        .menu-list {
          padding: 0;
          width: 100%;
          list-style: none;
          display: block;
          overflow-x: unset;
          height: 100%;
          background: none;
          align-items: center;
          &-item {
            width: 100%;
            flex: unset;
            margin-left: 0;
            margin-top: 32px;
            display: flex;
            align-items: center;
            a {
              height: 14px;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 1px;
              text-transform: uppercase;
              margin-left: 8px;
            }
            &.active {
              a {
                text-decoration: none;
                font-weight: 900;
                &:before {
                  content: '';
                  position: absolute;
                  z-index: -1;
                  left: 0;
                  right: 0;
                  bottom: -3px;
                  height: 0;
                  border: 0.1rem solid $black;
                  border-radius: 10px;
                }
              }
            }
            &:first-child {
              margin-top: 24px;
            }
            .icon-link {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    .horizontal-row {
      &.title-bottom-row {
        border-top: 1px solid $light-grey;
        margin-left: 0;
        margin-top: 24px;
      }
    }
  }

  .account-content-col {
    padding-top: 24px;
    padding-left: 40px;
    .account-content-box {
      &-title {
        margin-top: 0;
      }
      .account-shipping-methods {
        margin: 0;
        .single-method {
          align-items: center;
          display: flex;
          border: solid 1px $light-grey;
          flex-direction: column;
          height: 135px;
          justify-content: center;
          width: 100%;
          margin: 8px 12px;
          text-align: center;
          &.col-md-3 {
            flex-basis: 30%;
            max-width: 31%;
          }
          p {
            margin-top: 0;
          }
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .account-content-box-section {
      .account-search-order {
        > .row {
          margin-left: 0;
          margin-right: 0;
        }
        .user-email {
          padding-left: 0;
        }
        .search-order {
          padding-right: 0;
          padding-left: 0;
        }
        .search-order-row {
          .search-order-label {
            padding-left: 0;
          }
        }
      }
    }

    .account-search-order-col {
      padding: 0;
    }
  }
}

.account-contacts {
  position: unset;
  height: 44px;
  margin: 0;
  border-bottom: 1px solid $light-grey;
  border-top: 1px solid $light-grey;
  display: flex;
  align-items: center;
  padding: 0;

  .col-6 {
    a {
      display: flex;
      justify-content: center;
      .account-contacts-icon {
        width: 10px;
        height: 12px;
        margin-right: 8px;
      }
      .account-contacts-text {
        height: 12px;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        text-decoration: underline;
      }
    }
  }
}

.live-chat-container {
  bottom: 32px;
  height: min-content;
  right: 28px;
}

.account-dashboard {
  .page-account-title {
    .main-title {
      margin-bottom: 8px;
      margin-top: 24px;
    }
    .main-subtitle {
      text-align: center;
    }
  }
}
