/** DOTS STYLE */
.custom-wheel-component {
  .dots {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    position: absolute;
    width: 100%;
  }
  .dot {
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: black;
    opacity: 1;
    background: transparent;
    border: 1px solid black;

    width: 0.9rem;
    height: 1.2rem;
    margin: 1rem;
    transform: rotate(-270deg);
    border-radius: 7.5px;

    cursor: pointer;

    &.dot-active {
      color: #fff;
      background: black;
      cursor: default;
    }
  }
}

/** iTEMS STYLE */
.custom-wheel-component {
  position: relative;
  min-height: 650px;
  width: 100%;
  max-width: 1352px;
  margin: 0 auto;
  overflow: hidden;
  padding: 0;
  opacity: 0;

  &[data-component-init='true'] {
    opacity: 1;
  }
}
//
.custom-wheel-component {
  .items {
    top: 0%;
    left: 0%;
    // transform: translate(-50%, -50%);
    user-select: none;
    width: 100%;
    height: 80vh;
  }

  .item-slide {
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(25% - 40px);
    overflow: hidden;
    transition: all cubic-bezier(0.6, 0, 0.25, 1) 700ms;
    z-index: -1;
    opacity: 0;

    .pd-video__container.video--tv {
      padding-bottom: 0;
      min-height: 210px;
    }

    .bc-player-default_default.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
    .bc-player-default_default.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
    .bc-player-default_default.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
    .bc-player-default_default.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
    .bc-player-default_default.vjs-has-started.vjs-paused.vjs-ad-playing.vjs-user-inactive .vjs-control-bar,
    .bc-player-default_default.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible) {
      opacity: 0;
    }

    .pd-video__container {
      .video-js:not(.vjs-fullscreen) {
        .vjs-control-bar {
          opacity: 0;
          transition: opacity cubic-bezier(0.6, 0, 0.25, 1) 700ms;
        }
      }
    }

    > .experience-region > [class*='pd-text'],
    > .experience-region > .experience-component {
      opacity: 0;
      transition: opacity cubic-bezier(0.6, 0, 0.25, 1) 700ms;
    }

    a {
      pointer-events: none;
    }

    &.active {
      z-index: 3;
      opacity: 1;
      width: calc(50% - 40px);
      margin: 0 2rem 0 0;
      a {
        pointer-events: visible;
      }

      .pd-video__container.video--tv:not(.video--no-controls) {
        padding-bottom: 80px;
      }

      .bc-player-default_default.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
      .bc-player-default_default.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
      .bc-player-default_default.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
      .bc-player-default_default.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
      .bc-player-default_default.vjs-has-started.vjs-paused.vjs-ad-playing.vjs-user-inactive .vjs-control-bar,
      .bc-player-default_default.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible) {
        opacity: 1;
      }

      .pd-video__container {
        .video-js:not(.vjs-fullscreen) {
          .vjs-control-bar {
            opacity: 1;
          }
        }
      }
      > .experience-region > [class*='pd-text'],
      > .experience-region > .experience-component {
        opacity: 1;
      }
    }
  }

  .item-slide.prev {
    z-index: 2;
    opacity: 1;
    transform: translate(calc(-200% - 60px), -50%);
    margin: 0 2rem 0 0;
  }

  .item-slide.next {
    z-index: 2;
    opacity: 1;
    transform: translate(calc(100% + 40px), -50%);
    margin: 0 2rem;
  }
}
//
