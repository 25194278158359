.image-text-component {
  padding: 30px 15px;
}

.image-text-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  .media-position-right & {
    flex-direction: column-reverse;
  }
}

.image-text__text-region,
.image-text__media-region {
  width: 100%;
}

.image-text__text-region {
  padding: 32px 45px 0;

  .media-position-right & {
    padding: 0 45px 32px;
  }
}
