.editorial-image-grid {
  padding: 0 24px 40px;

  &__gallery {
    gap: 8px;
    padding-bottom: 8px;

    .gallery-item {
      flex: 0 0 calc(25% - 6px);
    }
  }

  &__show-button {
    padding: 40px 40px 0;
  }

  &.hover-effect {
    &-zoom-in {
      .gallery-item {
        picture {
        overflow: hidden;
    
          img {
            transition: transform 0.3s ease;
      
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }

    &-zoom-out {
      .gallery-item {
        picture {
        overflow: hidden;
  
          img {
            transition: transform 0.3s ease;
      
            &:hover {
              transform: scale(0.9);
            }
          }
        }
      }
    }
  }
}