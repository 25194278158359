.product-display-component {
  .product-display-content {
    width: 100%;
  }
  .product-content {
    width: 100%;
  }
  .product-display-title {
    font-family: $futura-book;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    padding: 16px;
  }

  .productdisplay-container {
    position: relative;
    &::before {
      content: '';
      width: 100%;
      border-top: 1px solid #e5e5e5;
      display: block;
      top: -1px;
      position: absolute;
      z-index: -1;
    }
  }

  .productdisplay-content {
    gap: 4px;
    display: flex;
    flex-flow: wrap;
    margin-top: 16px;
    justify-content: space-between;

    .experience-component {
      width: calc(50% - 4px);
    }
  }

  .product-display-options {
    display: flex;
    overflow-x: scroll;
    gap: 24px;

    &::-webkit-scrollbar {
      display: none;
    }

    .product-display-option {
      position: relative;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.04em;
      text-align: center;
      padding: 16px 0;
      cursor: pointer;
      min-inline-size: max-content;
      color: #757575;

      &.-active {
        color: $black;

        &::after {
          content: '';
          display: block;
          border-bottom: 2px solid #000;
          position: absolute;
          width: 100%;
          bottom: 0;
        }
      }
    }
  }

  .single-element-content-image {
    margin: 0;
  }

  .add-to-wish-list {
    display: none;
  }

  .single-element-content {
    margin: 0;
  }

  .explore-range {
    display: none;
  }

  .hidden {
    display: none;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 100% !important;
  }

  .explore-range-container-mobile {
    .explore-title {
      font-family: $futura-book;
      font-size: 14px;
      margin-top: 32px;
      text-align: center;
      text-decoration: underline;
      color: #666666;
    }
  }
}

.product-display-component {
  .product-display-title.no-options {
    padding: 24px 16px;
    text-align: left;
  }
  .no-border {
    .productdisplay-container {
      position: relative;
      &::before {
        content: none;
        width: 100%;
        border-top: none;
        display: block;
        top: -1px;
        position: absolute;
        z-index: -1;
      }
    }

    .productdisplay-content {
      margin-top: 0;
    }
  }
}

.newTile-true {
  overflow: hidden;
  .login-to-buy-image-banner {
    display: none;
  }

  .tile-image {
    display: block;
    object-fit: cover;
    height: auto;
  }
  .swiper-container {
    display: block;
    z-index: 0;

    .swiper-button-next,
    .swiper-button-prev {
      opacity: 0;
      color: black;

      &:after {
        font-size: 15px;
      }
    }

    .swiper-pagination.swiper-pagination-bullets {
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 2px;

      .swiper-pagination-bullet {
        background: #e5e5e5;
        width: inherit;
        border-radius: 0;
      }

      .swiper-pagination-bullet-active {
        background: $black;
      }
    }

    .swiper-slide {
      height: auto;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .single-element {
    padding: 0;
    max-width: 50%;
    flex: 0 0 calc(50% - 2px);
    width: calc(50% - 2px);
    margin: 0;
    margin-bottom: 0;
    &-image {
      height: auto;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    &-detail {
      text-align: center;
    }

    .single-element-content {
      margin-bottom: 0;
      .single-element-image {
        position: relative;
        margin-bottom: 0;
        .default-image {
          height: 100%;
          img {
            height: 100%;
            width: 100%;
          }
        }
        &-tag {
          background-color: red;
          height: 20px;
          padding: 4px 8px;
          border-radius: 10px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          text-align: center;
          position: absolute;
          bottom: 8px;
          left: 8px;
          color: $white;
        }
        .img {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
      .single-element-content-detail {
        &-price {
          .previous-price {
            color: $dark-grey;
            text-decoration: line-through;
            margin-right: 16px;
          }
        }
      }
    }
  }

  .single-element-content {
    position: relative;

    .add-to-wish-list {
      display: none;
      position: absolute;
      top: 6px;
      right: 10px;

      .white-icon {
        display: none;
      }
    }

    .wishlist-filled {
      display: none;
    }

    .active {
      .black-icon {
        display: none;
      }

      .wishlist-filled {
        display: block;
      }
    }

    .single-element-content-detail {
      padding: 12px 16px 24px 8px;
      .single-element-content-detail-description {
        text-transform: none;
        text-align: left;
        .product-link {
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.1px;
          letter-spacing: 0.015em;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .price-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;

        .sales {
          margin-right: 8px;
        }
      }

      .price {
        text-align: left;
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.03em;
        margin-top: 8px;
      }

      .badge-container {
        .product-badge {
          margin-top: 8px;
          text-align: left;
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.1px;
          letter-spacing: 0.03em;
          color: #0D0D0D;   
        }
      }
    }
  }
  .single-element-content-image {
    display: block;
    margin: 0 auto;

    .swiper-slide-overlay {
      display: none;
    }

    .image-container {
      position: relative;

      .product-badge {
        background-color: transparent;
        border-radius: 18px;
        color: $black;
        height: 24px;
        padding: 0;
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.1px;
        letter-spacing: 0.03em;
        text-align: left;

        width: max-content;
        z-index: 1;
        &.product-badge-top {
          position: absolute;
          top: 8px;
          left: 8px;
        }
        &.product-badge-middle {
          width: 100%;
          position: absolute;
          text-align: center;
          top: calc(50% - 14px);
        }
        &.product-badge-bottom {
          position: absolute;
          bottom: 8px;
          left: 8px;
        }

        &.product-badge--red {
          background-color: $badge-red;
        }
        &.product-badge--green {
          background-color: $badge-green;
        }
        &.product-badge--orange {
          background-color: $badge-orange;
        }
        &.product-badge--white {
          background-color: $white;
          color: $black;
        }
      }
    }
  }
}

.newTile-true,
.newTile-false {
  .product-image-badge {
    height: 24px;
    position: absolute;
    top: 8px;
    left: 8px;

    img {
      height: 100%;
      width: auto;
    }

    &.top-right {
      left: unset;
      right: 8px;
    }

    &.height-mobile-16 {
      height: 16px;
    }
    &.height-mobile-20 {
      height: 20px;
    }
    &.height-mobile-24 {
      height: 24px;
    }
    &.height-mobile-28 {
      height: 28px;
    }
    &.height-mobile-32 {
      height: 32px;
    }
    &.height-mobile-36 {
      height: 36px;
    }
    &.height-mobile-40 {
      height: 40px;
    }
  }
}
