#survey-start {
  overflow: hidden;
}

#survey {
  .surveylanding-container {
    width: 100%;

    .actions-container {
      order: 2;

      .actions-container-box {
        padding: 0;
      }
    }

    .img-container {
      order: 1;
      max-height: calc(100vh - 48px);
    }
  }

  #questions-container {
    .question {
      max-width: 504px;
    }
  }
}
