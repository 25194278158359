.container-benefit-bar {
  border-bottom: 1px solid #eeedeb;

  .content-benefit-bar {
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 39px;
    .icon-section {
      margin-right: 8px;
    }
    > ul {
      padding: 0;
      list-style: none;
      width: 100%;
      > li {
        @extend %label--small;
        .button-country-selector {
          text-transform: unset;
          line-height: 22px;
        }
        .button-chat-benefit {
          .icon-section {
            @include fixedSprite('customer-care-small');
            width: 12px;
            height: 12px;
          }
          line-height: 22px !important;
        }
        .button-phone-benefit {
          display: flex;
          align-items: center;
          line-height: 22px !important;
          .icon--phone {
            margin-right: 8px;
          }
        }
        + li {
          margin-left: 24px;
        }
      }
    }
    .button-store-modal {
      display: flex;
      align-items: center;
      @extend %label--small;
      line-height: 22px !important;
      text-decoration: none;
      .icon--map-pin {
        width: 12px;
        height: 12px;
        margin-right: 10px;
      }
    }
  }
  .content-central-benefit-bar {
    z-index: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    display: none;
    &.swiper-container-initialized {
      display: block;
    }
    @extend %body--small;
    .notifications-container {
      padding: 0;
      .notifications-slide {
        text-align: center;
        cursor: pointer;
      }

      .trigger-description-notification {
        [class*='icon--'] {
          margin-left: 10px;
        }
        &.opened {
          [class*='icon--'] {
            transform: rotate(180deg);
          }
          & + .description-notification {
            display: block;
          }
        }
      }
      .description-notification {
        display: none;
        padding: 10px 0;
      }
    }
  }
}

.benefit-bar-right,
.benefit-bar-left {
  display: none;

  .dropdown-trigger {
    @extend %label--small;
    line-height: 22px;
  }
}
