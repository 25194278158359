.order-list-container {
  .horizontal-row {
    &.history-card-bottom {
      border-top: 1px solid $light-grey;
      margin: 16px 0;
    }
  }
  .order-list-title {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .history-title {
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 1.6;
      text-transform: uppercase;
    }
    .history-count {
      text-transform: lowercase;
    }
  }

  .order-accordion-content {
    display: none;
  }

  .order-row {
    margin: 0;
    > span {
      height: 21px;
      margin: 4px 0;
      &.order-link {
        text-align: right;
        margin-top: 24px;
        margin-bottom: 10px;
      }
    }
    .order-value {
      text-align: right;
    }
  }

  .pagination {
    margin: 30px 0;
    .pages {
      display: flex;
      justify-content: space-between;
      .arrow-button {
        width: 20px;
      }
      .icon--back {
        height: 20px;
        width: 20px;
        &.next-page-icon {
          transform: rotate(180deg);
        }
      }
      .page-list {
        display: flex;
        list-style: none;
        justify-content: center;
        .page-number {
          margin: 0 4px;
          color: $dark-grey;
          &.current-page {
            color: $black;
          }
        }
      }
    }
  }
}
