.modal-container {
  max-width: 589px;
  width: 589px;
}

.modal-size-guidelines {
  &.container-size-guidelines {
    .modal-header {
      padding: 0;
      height: 50px;
    }

    .modal-content {
      .modal-content-gudielines {
        .fitguide__container--desktop {
          .fitguide__container {
            // display: flex;
            border-top: solid 1px $light-grey;
            // justify-content: space-between;
            // justify-content: center;
            .tab-button {
              align-items: center;
              display: none;
              height: 54px;
              justify-content: center;
              &:first-child {
                padding-right: 0;
              }
              &:last-child {
                padding-left: 0;
              }
              button {
                border: solid 1px $light-grey;
                border-top: none;
                height: 100%;
                width: 100%;
                color: $dark-grey;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                letter-spacing: 1px;
              }
            }
            .tab-button #fitguide-content-01 {
              order: 1;
            }
            .tab-button #fitguide-content-02 {
              order: 2;
            }
          }
          #fitguide-content-01 {
            .fitguide__interactive {
              .measuring-guide__radio-input {
                .form-group {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  padding-bottom: 20px;
                  padding-top: 28px;
                  align-items: center;
                  .radio {
                    margin-bottom: 0;
                  }
                }
              }
              .choose-size-and-unit-measure {
                .choose-size-label {
                  height: 12px;
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: 0.5px;
                  color: $black;
                }
                #choose-your-size {
                  width: 100%;
                }
              }

              .horizontal-row {
                border-top: 1px solid $light-grey;
                margin: 16px 0;
                &.choose-your-size-bottom {
                  margin-bottom: 0;
                  width: calc(100% + 48px);
                  margin-left: -24px;
                }
                &.measuring-size-table-bottom {
                  width: calc(100% + 48px);
                  margin-left: -24px;
                }
                &.measuring-img-bottom {
                  width: calc(100% + 48px);
                  margin-left: -24px;
                  margin-top: 0;
                }
              }

              .measuring-guide__size-table {
                .table-details {
                  &-label {
                    height: 22px;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.8;
                    letter-spacing: 0.3px;
                    color: $black;
                    text-transform: uppercase;
                  }
                  &-value {
                    height: 22px;
                    margin: 0 0 0 16px;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.8;
                    letter-spacing: 0.3px;
                    color: $dark-grey;
                  }
                  &:first-child {
                    margin-top: 16px;
                  }
                }
              }

              .measuring-img__sketches {
                img {
                  width: auto;
                  max-height: 390px;
                }
              }

              .click-to-convert-sizes {
                &-text {
                  height: 12px;
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: 0.5px;
                  color: $black;

                  a {
                    text-decoration: underline;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          #fitguide-content-02 {
            display: none;
            overflow-x: hidden;
            .measuring-guide {
              .measuring-guide-content {
                overflow-x: auto;
                display: flex;
                justify-content: center;
                .table {
                  thead {
                    tr {
                      th {
                        text-align: center;
                        height: 26px;
                        font-size: 16px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.6;
                        letter-spacing: 1px;
                        color: $black;
                        text-transform: uppercase;
                      }
                    }
                  }
                  tbody {
                    tr:nth-child(odd) {
                      background-color: $light-grey;
                    }
                    tr {
                      td {
                        padding: 0px;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: 1px;
                        color: $dark-grey;
                        text-transform: uppercase;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .modal-footer {
      border-top: none;
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
      .button-phone-modal {
        &-icon {
          width: 10px;
          height: 12px;
          margin-right: 9px;
        }
        &-text {
          height: 12px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          color: $black;
        }
      }
    }
  }
}

.measure-taken-by-product {
  &-icon {
    height: 12px;
    width: 12px;
    margin-right: 8px;
  }
  &-text {
    height: 12px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    color: $dark-grey;
  }
}

#size-guidelines-modal {
  .modal-close {
    top: 15px;
    right: 15px;
  }
}
