.negative-margin-container {
  margin-left: -28px;
  margin-right: inherit;
  padding-left: 28px;
  padding-right: inherit;
}

.cart-container {
  flex-direction: row;
}

.product-wrapper {
  padding: 16px;
  border: 1px solid $light-grey;
}

.product-actions-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.wish-delete-wrapper {
  border: none;
  margin-left: -15px;
}

.product-wishlist-wrapper,
.product-remove-wrapper {
  flex: 0 0 auto;
  padding: 0 15px;
}

.btn-cart-remove,
.btn-cart-wishlist {
  padding: 5px 0;
}

.option-ship-type {
  .ship-action {
    padding: 5px 0;
    display: flex;
    align-items: center;
    position: relative;
    .icon--shipping-medium {
      // margin-top: -11px;
      position: absolute;
      left: -24px;
      bottom: 3px;
    }
    .link-underline {
      margin-bottom: -2px;
    }
  }
}

.js-pickup-container {
  .store-item {
    .option-ship-type {
      justify-content: flex-start;
    }
  }
}

/** CART ITEM GRID setup -->START*/
.cart-item-grid {
  grid-template-areas:
    'product-image attributes price'
    'product-image quantity price'
    'product-image message message'
    'product-image product-actions product-actions';
  grid-template-columns: 120px 3fr 2fr;
  grid-template-rows: 0fr 0fr 0fr 0fr;

  .product-prices-wrapper {
    text-align: right;
    .line-item-total-price {
      .price {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 8px;
        justify-content: flex-end;

        .prior-best-price-component {
          margin-left: 0;
          display: inline-flex;
        }
        .layer-tooltip-prior-best-price {
          display: none;
        }
      }
    }
  }
}

.product-image-wrapper {
  grid-row: 1 / 4;
}

/** CART ITEM GRID setup --> END*/

/** CART ITEM  style -->START*/
.cart-item-grid {
  padding-top: 0;
}

.product-info-img {
  width: 103px;
  height: 138px;
}

.product-quantity-wrapper {
  .name-field {
    display: inline-flex;
    width: 80px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: fit-content;
}

.product-prices-wrapper {
  height: fit-content;
}

.product-info-details {
  width: fit-content;
  .detail-attribute {
    margin-top: 15px;
    justify-content: flex-start;
    + .detail-attribute {
      margin-top: 10px;
    }

    .name-field {
      width: 80px;
    }
    .value-field {
      text-align: left;
    }
  }
}

.product-message {
  place-self: end;
  margin-top: -15px;
}

/** CART ITEM  style -->END*/

.cart-body-section {
  .info-message-general {
    text-align: left;
  }
}

/** CART SIDEBAR  -->START*/
.cart-sidebar-section {
  grid-template-areas:
    'heading'
    'totals'
    'voucher'
    'buttons'
    'accordions';

  .wrapper-cart-totals {
    margin-left: -28px;
    margin-right: inherit;
    padding-left: 28px;
    padding-right: inherit;
    background: none;
  }

  .voucher-wrapper {
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
  }
}

.sticky-wrapper-totals {
  margin-left: 0;
  margin-right: inherit;
  padding-left: 0;
  padding-right: inherit;
  background: none;
  border: none;
}

.cart-checkout-buttons-wrapper {
  margin-top: 24px;
  flex-direction: column;
  flex: 1 0 100%;
  .btn-container {
    + .btn-container {
      margin-left: 0;
      margin-top: 16px;
    }
  }
}

/** CART SIDEBAR  -->END*/

.cart-error {
  margin-left: 0;
  margin-top: 0;
  padding: 8px 15px;
  width: 100%;
  margin-bottom: -16px;
}

#editProductModal {
  // display: none;
  background: transparent;
  height: 100%;
  position: fixed;
  max-width: 100%;
  width: 100%;
  &.show {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
  }
  .edit-modal-container {
    background: $white;
    position: relative;
    height: auto;
    // max-width: 672px;
    width: 672px;
  }

  .quick-view-dialog {
    height: 100%;
    .modal-content {
      position: relative;
      // max-height: 299px;
      padding: 0;
      .horizontal-row {
        &.edit-product-modal-header-bottom {
          display: none;
        }
      }
      .modal-body {
        padding: 16px;
        .product-detail {
          padding: 0;
          .carousel-item {
            width: 200px;
            height: 267px;
            margin: 0;
            .product-image {
              width: 200px;
              height: 267px;
              margin-bottom: 0;
            }
          }
          .name-number-details {
            .product-name {
              margin-top: 0;
            }
            .detail-panel {
              margin-left: -12px;
              margin-right: -12px;
              .attributes {
                padding-right: 0;
                > .col-12 {
                  padding-right: 0;
                }
                #add-to-cart-wishlist-wrapper-desktop {
                  margin-top: 16px;
                  .row {
                    justify-content: space-between;
                    margin-left: 0;
                    width: 100%;
                    .add-to-cart-actions {
                      height: 48px;
                      margin-left: -12px;
                      padding-left: 0;
                      padding-right: 0;
                      max-width: 360px;
                      .cart-and-ipay {
                        .col-sm-12 {
                          padding-left: 0;
                          padding-right: 0;
                        }
                        .row {
                          padding-left: 0;
                        }
                      }
                    }
                    .add-to-wishlist {
                      padding-right: 0;
                      padding-left: 17px;
                      display: flex;
                      justify-content: flex-end;
                      .add-to-wish-list {
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        border: 1px solid $dark-grey;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .add-to-wish-list-icon {
                          height: 20px;
                          .white-icon {
                            display: none;
                          }
                        }
                        &.active,
                        &:hover {
                          background-color: $black;
                          .add-to-wish-list-icon {
                            .white-icon {
                              display: block;
                            }
                            .black-icon {
                              display: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .size-selection-field {
              .size-label-section {
                align-items: center;
                display: flex;
                justify-content: space-between;
                padding-right: 0;
                .size-label {
                  text-transform: uppercase;
                }
                .size-guidelines-button {
                  text-transform: lowercase;
                }
              }
              .size-values {
                max-width: 200px;
                justify-content: center;
              }
              .swatch-attribute-value {
                width: 24px;
                cursor: pointer;
              }
            }
          }
          > .row {
            margin: 0;
          }
        }
      }
      .modal-header {
        height: 0;
      }
      .modal-footer {
        display: none;
      }
    }
  }

  .invalid-selected-product {
    padding-left: 12px;
    &.hidden {
      display: none;
    }
  }
}
