// requested specif alignment
.checkout-login-box {
  + .checkout-login-box--guest {
    padding-left: 18px;
  }
}

.checkout-login-box:not(.checkout-login-box--guest) {
  padding-right: 18px;

  // .btn-login {
  //   margin-top: 55px;
  // }
}
