.accordion {
  &.is-enabled {
    .accordion-content {
      transition: all ease-in 0.3s;
      max-height: 0;
      display: none;
      opacity: 0;
    }

    .is-active {
      .accordion-content {
        display: block;
        max-height: 20000px;
      }

      &.is-visible {
        .accordion-content {
          opacity: 1;
        }
      }
    }
  }
  &-title {
    width: 100%;
    justify-content: space-between;
    &-icon {
      margin-top: 0.8rem;
    }
  }
}

.accordion-trigger {
  &[aria-expanded='true'] {
    .button-icon {
      transform: rotate(180deg);
      transition: 0.3s;
    }
  }
}
