.editorial-swiper {
  .pd-swiper-slide {
    width: 259px;

    padding-left: 0 !important;
    padding-right: 0 !important;

    .pd-wrapper-image-layer {
      height: 340px;
      + div {
        margin-top: 12px;
      }
    }
  }

  .col-12 {
    flex-basis: unset;
  }

  .pd-swiper-wrapper {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  .swiper-pagination-bullet {
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: black;
    opacity: 1;
    background: transparent;
    border: 1px solid black;

    width: 9px;
    height: 12px;
    margin: 0 39px 0 2px;
    transform: rotate(-270deg);
    border-radius: 7.5px;
  }
  .swiper-pagination-bullet-active {
    color: #fff;
    background: black;
  }

  .carousel-control {
    display: none;
  }
}
