.page-account-container {
  .page-account-title {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 1.5px;
    color: black;
  }
  .page-account-menu {
    .menu-list {
      list-style: none;
      &-item {
        height: 14px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1px;
      }
    }
  }

  .account-content-col {
    padding-left: 40px;
    padding-right: 0;
    padding-top: 24px;
    .account-content-box {
      &-title {
        margin-top: 0;
      }
      .account-shipping-methods {
        margin: 0;
      }
    }
    .account-content-box-section {
      .account-search-order {
        > .row {
          margin-left: 0;
          margin-right: 0;
        }
        .search-order {
          padding-right: 0;
        }
      }
    }
  }
}

.live-chat-container {
  right: 44px;
}
