#product-show {
  .footer {
    &.z-index--1 {
      z-index: -1 !important;
    }
  }
}

#inside_tabs {
  z-index: 14 !important;
  #inside_liveChatTab {
    bottom: 65px;
    z-index: 14 !important;
  }
}

main {
  &.main--layout-page {
    border-top: none !important;
    position: relative;
    overflow-x: clip;
  }
}

.product-detail.pdp-new {
  padding: 0;
  margin: 0;

  button,
  a,
  div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .wrapper-pdp {
    max-width: 100vw;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }

  .product-price {
    .prices {
      .price {
        font-size: 1.4rem;

        span {
          display: flex;
          flex-direction: row;
          text-wrap: nowrap;
          align-items: center;
          line-height: 1.4;
        }

        .strike-through {
          margin-left: 8px;
        }
      }

      .prior-best-price-disclaimer {
        color: #b4b4b4;
        font-size: 1.4rem;
      }
    }
  }

  .col-images {
    padding: 0;
  }

  .page-designer-container {
    .experience-region.experience-imageVideoCtaContent video-js {
      padding-top: 125% !important;
    }
  }

  .image-carousel-col {
    position: relative;

    .add-to-cart-add-to-wishlist {
      position: absolute;
      top: 20px;
      right: 20px;

      .add-to-wish-list {
        display: block;

        .add-to-wish-list-icon {
          height: 2rem;
        }

        .icon--heart-filled {
          display: none;
        }

        &.active {
          .icon--heart-filled {
            display: block;
          }
          .icon--heart-round {
            display: none;
          }
        }
      }
    }
  }

  .product-slider-container {
    .swiper-zoom-container img {
      background: #cccccc;
    }
  }

  .button--loading .button-add-to-cart-text,
  .button--loading .add-to-cart-selected-size,
  .button--loading .button__text {
    visibility: hidden;
    opacity: 0;
  }

  .add-to-cart-selected-size {
    margin-left: 4px;
  }

  .button--loading::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid #d9d9d980;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }

  &.product-wrapper {
    border: none;

    .image-carousel-global-container {
      margin: 0;

      .swiper-pagination {
        justify-content: center;
        .swiper-pagination-bullet {
          width: 5px;
          height: 5px;
          background-color: #999999;
          border-color: #999999;
          margin: 0 8px;
        }
        .swiper-pagination-bullet-active {
          background-color: $black;
          border-color: $black;
        }
      }
    }

    .recommendations {
      margin-top: 0 !important;
      width: 100vw;
      min-height: 1px;

      .product-carousel-section {
        padding: 0;
        min-height: 1px;
      }

      .product-carousel,
      .swiper-wrapper,
      .swiper-container {
        min-height: 1px;
      }

      .experience-assets-product .single-element-content-image,
      .experience-assets-product .single-element-content {
        margin-bottom: 0;
      }

      .swiper-heading-wrapper {
        padding: 24px 16px;
        .swiper-header {
          font-family: $futura-book;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.035em;
          text-align: left;
          text-transform: none;
        }
      }

      .add-to-wish-list {
        display: none !important;
      }

      .single-element-content-detail {
        padding: 12px 16px 24px 8px;
        .single-element-content-detail-description {
          text-transform: none;
          text-align: left;
          .product-link {
            font-family: $futura-book;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.1px;
            letter-spacing: 0.015em;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .price {
          text-align: left;
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.04em;
          margin-top: 8px;

          .price-container {
            display: flex;
            flex-direction: row-reverse;
            justify-content: start;

            .strike-through {
              margin-left: 4px;
            }

            .discount-label {
              margin-left: 0;
            }

            .prior-best-price-component {
              display: none;
            }
          }
        }
      }

      .product-carousel-section .product-carousel {
        max-width: 100vw;
      }

      .swiper-slide {
        // width: 43%;
        // min-width: 43%;
        // max-width: 43%;

        .product {
          max-width: 100%;
          width: 100%;
        }
      }
      .swiper-button-next {
        display: none;
        &:after {
          @include fixedSprite('arrow-new');
        }
      }
      .swiper-button-prev {
        display: none;
        &:after {
          @include fixedSprite('arrow-new');
        }
      }
    }
  }

  .attributes {
    display: inline;

    .requirement-container {
      margin: 24px 16px;
    }

    .no-top-border-paddding {
      margin-top: 0 !important;
      padding-top: 0 !important;

      .add-to-cart-actions {
        padding-top: 0 !important;
      }
    }
  }

  .col-details {
    padding: 24px 0 40px;

    .product-name-and-price {
      padding: 0 16px;
    }

    .add-to-cart-add-to-wishlist,
    .pdp-apple-pay-wrapper {
      position: relative;
      background: $white;
      padding: 12px 16px;
      width: 100%;
      margin-top: 16px;
      margin-right: 0;
      margin-left: 0;
      height: 82px;

      .add-to-cart-actions {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        background: $white;
        padding: 12px 16px;
        z-index: 1;
        border-radius: 0;
        -webkit-box-shadow: 0 -3px 3px 0 #0000000d;
        -moz-box-shadow: 0 -3px 3px 0 #0000000d;
        box-shadow: 0 -3px 3px 0 #0000000d;

        .cart-and-ipay {
          margin: 0;
        }
      }

      &.hide-shadow {
        .add-to-cart-actions {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }

    .pdp-apple-pay-wrapper {
      margin-top: 0;
      padding-top: 0;
      height: auto;
    }

    .product-notify.product-notify-feature {
      padding: 0;
    }

    .product-notify.product-notify-feature,
    .product-coming-soon.coming-soon.coming-soon-feature {
      .notify-me-container {
        position: relative;
        height: 82px;
        margin-top: 16px;

        .layer-product-notify#layer-product-notify-component,
        .layer-product-coming-soon {
          position: fixed;
          bottom: 0;
          z-index: 20;
          padding: 16px;
          background: $white;
          width: 100vw;
          -webkit-box-shadow: 0px -3px 3px 0px #0000000d;
          -moz-box-shadow: 0px -3px 3px 0px #0000000d;
          box-shadow: 0px -3px 3px 0px #0000000d;

          &.hide-shadow {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
          }
        }

        .layer-product-notify#layer-product-notify-component.is-open,
        .layer-product-coming-soon.is-open {
          position: unset !important;
        }
      }
    }
    .variation-attribute,
    .product-options,
    .product-notify,
    .add-to-cart-error-messaging,
    .bopis-wrraper,
    .product-collapsible-sections {
      padding: 0 16px;
    }

    .product-collapsible-sections-list {
      padding-top: 24px;
    }
  }

  .product-description {
    margin-bottom: 0;
    .description-content {
      color: $black;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.03em;
      text-align: left;

      width: 316px;
    }
  }

  .details-column-content {
    max-width: 100vw;
  }

  .product-badge {
    margin-bottom: 8px;
    font-family: $futura-book;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-align: left;
    color: #999999;
    text-transform: uppercase;
  }

  .product-image-badge {
    height: 24px;
    margin-bottom: 8px;

    img {
      height: 100%;
      width: auto;
    }

    &.height-mobile-16 {
      height: 16px;
    }
    &.height-mobile-20 {
      height: 20px;
    }
    &.height-mobile-24 {
      height: 24px;
    }
    &.height-mobile-28 {
      height: 28px;
    }
    &.height-mobile-32 {
      height: 32px;
    }
    &.height-mobile-36 {
      height: 36px;
    }
    &.height-mobile-40 {
      height: 40px;
    }
  }

  .product-name {
    padding: 0;
    margin-bottom: 8px;
    border-bottom: none;
    max-width: 100%;
    h1 {
      font-family: $futura-book;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.04em;
      text-align: left;
      color: $black;
      width: 100%;
      text-transform: none;
    }

    .add-to-wish-list {
      display: none;

      .icon--heart-filled {
        display: none;
      }

      &.active {
        .icon--heart-filled {
          display: block;
        }
        .icon--heart-round {
          display: none;
        }
      }
    }
  }

  .product-number-rating {
    height: unset;
    margin: 0;
    .product-number {
      color: $dark-grey;
      font-size: 12px;
      padding: 0;
      display: block;
      .editorial-composition-description {
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.03em;
        text-align: left;
        margin-left: 0;
      }
    }

    .editorial-composition-description {
      color: $black;
    }
  }

  .product-price {
    text-align: left;
    padding: 0;
    margin-bottom: 24px;

    .prices {
      color: $black;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.04em;
      text-align: left;
      width: 100%;
      &.previous-price {
        text-decoration: line-through;
      }
    }
  }

  .product-see-also {
    margin-bottom: 24px;
    width: 100%;
    text-transform: uppercase;
    .col {
      display: flex;
      flex-wrap: wrap;
      span {
        height: 22px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.3px;
        color: $black;
        min-inline-size: max-content;
      }

      .see-also-value {
        text-decoration: underline;
        margin: 0 8px;
      }
    }
  }

  .product-options {
    margin-top: 32px;
  }

  .selector-selected-size,
  .selector-selected-color {
    font-family: $futura-book;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #757575;
    margin-left: 8px;
  }

  .attribute-group-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .variation-attribute {
    margin-bottom: 0;
    position: relative;
    width: 100%;

    .error-message {
      display: none;
      color: $status_error_light;
      font-size: 12px;
      font-style: normal;
      font-weight: normal;
      left: 0;
      letter-spacing: 0.03em;
      line-height: 19px;
      position: absolute;
      top: 32px;
    }

    &.single-value-attribute {
      .attribute-label-value {
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.04em;
        text-align: left;
        width: 100%;
        span {
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.04em;
          text-align: left;
          color: #757575;
          margin-left: 8px;
        }
      }
    }

    .color-label {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      color: $black;
      text-transform: none;
      margin-bottom: 0;
    }

    .attribute-label-value {
      font-size: 12px;
    }

    select {
      appearance: none;
      //height: 24px;
      margin-left: 0;
      margin-top: 0;
      padding-left: 0;
      padding: 0;
      border: none !important;
      border-bottom: 1px solid #e5e5e5 !important;
      border-radius: 0;
      width: 100%;
      text-transform: none;
      height: 75px;
      option {
        text-transform: none;
        &:disabled {
          pointer-events: unset;
        }
      }

      .select-icon {
        position: absolute;
        bottom: 6px;
        left: 165px;
      }

      &.select-color {
        width: 100%;
        margin-right: 0;
        padding-right: 20px;
        min-width: 100%;
        border-top: 1px solid #e5e5e5 !important;
      }
      &.select-size {
        width: 100%;
      }
    }

    .single-val {
      border-bottom: none !important;
      text-transform: none;
      &-color {
        width: 100%;
      }
      &-size {
        width: 100%;
      }
    }

    &.attribute-size {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      width: 100%;
      flex-wrap: wrap-reverse;
      .custom-select-wrap {
        position: relative;
        width: 100%;
        .select-icon {
          left: auto;
          right: 0;
        }
      }

      &.single-value-attribute {
        display: none;
      }
      select {
        &.mobile-version {
          width: 100%;
          padding-right: 15px;
        }
      }
      .select-icon {
        bottom: 50%;
        pointer-events: none;
        position: absolute;
        right: 0;
        transform: rotate(270deg);
      }
      &.invalid {
        label {
          color: $status_error_light !important;
        }
        select {
          color: $status_error_light;
          border-bottom: 1px solid $status_error_light !important;
        }
      }
    }

    &.attribute-color {
      select {
        &.mobile-version {
          min-width: 160px;
        }
      }
      .select-icon {
        bottom: 50%;
        pointer-events: none;
        position: absolute;
        right: 0;
        transform: rotate(270deg);
      }
      &.invalid {
        label {
          color: $status_error_light !important;
        }
        select {
          color: $status_error_light;
          border-bottom: 1px solid $status_error_light !important;
        }
      }
    }

    .custom-select-wrap {
      &.invalid {
        margin-bottom: 16px;
        .fsb-button {
          color: $status_error_light !important;
          border-bottom: 1px solid $status_error_light !important;
        }
        .fsb-button::after {
          color: $black !important;
        }
        .fsb-select {
          border-bottom: 1px solid $status_error_light !important;
        }
        .custom-select {
          color: $status_error_light !important;
          border-bottom: 1px solid $status_error_light !important;
        }
        .error-message {
          display: block;
          top: 30px;
          width: 215px;
        }
      }
    }
  }

  .size-label-section {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 22px;
    margin-top: 16px;
    padding: 0 16px;

    .size-guidelines {
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0.04em;
      text-align: left;
      height: unset;

      color: $black;
      cursor: pointer;
    }
    .size-label {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      color: $black;
      text-transform: none;
      margin-bottom: 0;
      display: none;
    }

    .marni-size-message {
      font-family: $futura-book;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 1.5%;
      color: #7d7d7d;
    }
  }

  .add-to-cart-add-to-wishlist,
  .notify-me-add-to-wishlist {
    justify-content: space-between;
    // position: sticky;
    // bottom: 0;
    // background: white;
    border: none;
    height: fit-content;

    .add-to-cart-actions {
      position: relative;
    }

    .add-to-cart-actions,
    .notfy-me-actions {
      border-radius: 25px;
      max-width: 100%;
      padding: 0;
      // margin: 16px;
      .cart-and-ipay {
        > .col {
          padding: 0;
          .add-to-cart {
            padding: 16px 8px;
          }
        }
      }

      .btn--primary {
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.04em;
        text-transform: none;
      }
    }

    .add-to-whishlist {
      button {
        border: none;
        height: 17px;
        width: 48px;
        justify-content: center;
        align-items: center;
        .add-to-wish-list-icon {
          height: 20px;
          .white-icon {
            display: none;
          }
        }
        &.active,
        &:hover {
          border-radius: 50%;
          border: none;
          background-color: $black;
          -webkit-transition: background-color 1000ms linear;
          -ms-transition: background-color 1000ms linear;
          transition: background-color 1000ms linear;
          .add-to-wish-list-icon {
            .black-icon {
              display: none;
            }
            .white-icon {
              display: block;
            }
          }
        }
        &.disabled-click {
          -moz-user-focus: none;
          -webkit-user-focus: none;
          -ms-user-focus: none;
          -moz-user-focus: none;
          -moz-user-modify: read-only;
          -webkit-user-modify: read-only;
          -ms-user-modify: read-only;
          -moz-user-modify: read-only;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          pointer-events: none;
        }
      }
    }

    &.default {
      margin-right: 0;
    }
  }

  .bopis-wrraper {
    .initialized {
      text-align: center;

      .bopis-btn {
        margin: 0 0 16px 0;
        text-transform: none;
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.04em;
        text-align: left;
      }
    }
  }

  .add-to-cart-error-messaging {
    color: $status_error;
    margin-bottom: 16px;
  }

  .free-shipping {
    color: $dark-grey;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    height: 22px;
    letter-spacing: 0.3px;
    line-height: 1.8;
    margin: 24px 0;
  }

  .pickup-in-store-option {
    height: 12px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    color: $black;
    text-decoration: underline;
    text-transform: uppercase;
    margin-bottom: 32px;
    margin-top: 24px;
  }

  .product-collapsible-sections {
    .product-info-accordion {
      .accordion {
        .js-accordion-trigger {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
          &-title {
            height: 48px;
            padding: 18px 0;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.5px;
            color: $black;
            text-transform: uppercase;
          }
        }
      }
      .accordion-content {
        .acordion-subtitle {
          font-size: 12px;
          font-stretch: normal;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 0.5px;
          line-height: 1;
          margin-bottom: 8px;
          text-transform: uppercase;
        }
      }
    }

    h2 {
      align-items: center;
      display: flex;
      height: fit-content;
      justify-content: space-between;

      .title {
        color: $black;
        height: 12px;
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 1;
        text-transform: uppercase;
      }
    }

    .pdp-product-details-review {
      .editorial-composition {
        margin-top: 16px;
      }
      .care-instructions-review {
        margin-top: 16px;
      }
    }

    .product-collapsible-trigger {
      display: block !important;
      width: fit-content;
      margin: 16px 0;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.04em;
      text-align: left;
      cursor: pointer;
    }

    .layer-product-help {
      .product-collapsible-trigger {
        margin-top: 24px;
      }
    }
  }

  .row-breadcrumbs {
    width: 100vw;
    border-top: 1px solid #e5e5e5;

    .product-breadcrumb {
      .breadcrumb-component {
        max-width: 100%;
        .breadcrumb {
          justify-content: center;
          margin: 16px 0;
          border-top: none;
          flex-flow: wrap;
          height: unset;
        }
        a {
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.04em;
          color: $black;

          .last-element-text {
            display: inline-block;
            text-transform: lowercase;
          }
          .last-element-text::first-letter {
            text-transform: uppercase;
            display: block;
          }
        }
        .breadcrumb-item.last-element {
          display: none;
        }
        .breadcrumb-separator {
          display: none;
        }
      }
    }
  }

  span.private-sales,
  .preorder-release-date {
    // margin-bottom: 10px;
    text-align: center;
    font-family: $futura-book;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.04em;
    color: #757575;
    width: 100%;
    display: block;
    padding: 0 16px;
  }

  .preorder-release-date {
    margin-bottom: 16px;
  }

  span.private-sales {
    &.message-private-sales {
      margin-bottom: 16px;
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip-container .tooltip-trigger:hover .tooltip-box {
    left: 0;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: $white;
    border-width: 1px;
    border-style: solid;
    border-color: $black;
    color: $black;
    text-align: center;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .product-photo-retouch {
    margin-top: 20px;
    font-family: $futura-book;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #999999;
  }

  #size-guidelines-modal.new-pdp-size-guidelines,
  #findinstore-modal {
    display: block;
    visibility: hidden;

    .modal-size-guidelines.container-size-guidelines {
      height: 100vh;
      display: flex;
      flex-direction: column;
    }
    .modal-content-gudielines {
      padding: 16px;
      height: 100%;
      position: relative;
    }

    .modal-content {
      max-height: calc(100svh - 57px);
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      .modal-content-gudielines {
        padding-bottom: 50px;
      }
    }

    .modal-container {
      position: absolute;
      top: -100%;
      z-index: 1;
      min-width: 25rem;
      background-color: $white;
      top: 100vh;
      transition: top linear 1s;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &.is-open {
      visibility: visible;
      .modal-container {
        top: 0 !important;
        transition: none;
        visibility: visible;
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
      }
    }
  }

  #findinstore-modal {
    .modal-overlay {
      height: 100svh;
    }

    .modal-close {
      top: 2rem;
      right: 1.6rem;

      &:after {
        height: 16px;
        width: 16px;
      }
    }

    .modal-header {
      font-family: $futura-book;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.035em;
      text-align: left;
      text-transform: none;
    }

    .modal-content {
      height: 100vh;
      max-height: calc(100vh - 57px);
      overflow: hidden;

      .bopis-product-info-container {
        display: flex;
        flex-direction: row;
        padding: 16px;

        .bopis-product-info-container-image {
          height: 128px;
          max-width: 98px;

          img {
            height: 128px;
          }
        }

        .bopis-product-info-container-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          margin-left: 16px;
          position: relative;
          width: calc(100% - 112px);

          .product-name {
            margin: 0;
          }

          .product-size-container,
          .product-color-container {
            font-family: $futura-book;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.05em;
            text-align: left;
            color: #757575;
          }

          .product-color,
          .product-size {
            font-family: $futura-book;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.05em;
            text-align: left;
            color: $black;
            margin-left: 8px;
          }

          .product-price {
            text-align: left;
            margin: 0;
            bottom: 0;
          }
        }
      }

      .store-name {
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.04em;
        text-align: left;
        text-transform: none;

        input {
          width: 20px;
          position: absolute;
          height: 20px;
          display: block;
          top: 19px;
          left: 0;
          padding: 11px 11px;
          border: none;

          &:before {
            content: '';
            color: $black;
            width: 20px;
            height: 20px;
            display: block;
            border: 1px solid $black;
            border-radius: 100%;
            background: $white;
            position: absolute;
            top: 0;
            left: 0;
          }

          &:after {
            content: '';
            color: $black;
            width: 8px;
            position: absolute;
            height: 8px;
            display: block;
            top: 7px;
            left: 7px;
            border: none;
            border-radius: 100%;
            background: $white;
          }
        }
      }

      .checked {
        .store-name input {
          &:before {
            content: '';
            background: $black;
          }
          &:after {
            background: $white;
            content: '';
          }
        }
      }

      .box-actions-search-store {
        position: initial;
        padding: 16px 16px 24px 16px;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        flex-direction: column;
        text-align: left;

        .store-search-filter-header {
          width: 100%;
          font-family: $futura-book;
          text-align: left;
          font-size: 14px;
          line-height: 16.8px;
          letter-spacing: 0.01em;
          color: #262626;
        }

        .search-form-group {
          position: relative;
          margin: 0;
          width: 100%;

          .icon--zoom_in {
            position: absolute;
            top: 19px;
            right: 13px;
          }

          .btn-geolocator {
            display: none;
          }
        }

        .item-error-message-container-sticky {
          display: none;
        }

        .btn-filter-store {
          display: none;
        }
      }

      .store-search-result {
        margin-top: 0;

        .store-search-result-header {
          padding: 16px;
          font-family: $futura-book;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.04em;
          text-align: left;
        }

        .stores {
          padding: 0 16px;
          overflow-y: scroll;
          -ms-overflow-style: none; /* Hide scroll bar in IE and Edge */
          scrollbar-width: none; /* Hide scroll bar in Firefox */

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .store-item.form-check {
          border-bottom: 1px solid #eeedeb;
          border-top: none;
          padding: 1.6rem 0;

          .store-main-info {
            margin-left: 40px;
            text-align: left;

            .store-avail-message {
              padding: 0;
              font-family: $futura-book;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0.05em;
              text-align: left;
              color: $black;

              .icon--info {
                display: none;
              }

              .avail-indicator {
              }
            }

            .store-map {
              text-align: left;
              button {
                color: black;
                font-family: $futura-book;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.05em;
              }
            }
          }

          .selector-store-pickup {
            width: 20px;
            position: absolute;
            height: 20px;
            display: block;
            top: 20px;
            border: 1px solid black;
            border-radius: 100%;
            background: white;
          }

          .store-address-short {
            padding: 0;
            margin-top: 24px;
            font-family: $futura-book;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.05em;
            text-align: left;
            color: #757575;
          }

          .store-phone {
            font-family: $futura-book;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.05em;
            text-align: left;
            color: #757575;
            .icon--phone {
              display: none;
            }
          }
          .store-hours {
            padding: 0;
            margin-top: 24px;
            text-align: left;
            .store-extended-openinghours {
              display: none;
            }
            .store-extended-hours {
              color: $black;
              font-family: $futura-book;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0.05em;
              text-align: left;
            }
          }
        }
      }

      .add-to-cart-actions.btn-add-to-cart {
        display: none;
      }
    }

    .btn-confirm-address {
      text-transform: none;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.04em;
      border: none;
    }

    .store-search-result {
      height: calc(100svh - 331px);
      position: relative;

      .stores-list {
        overflow-y: scroll;
        height: calc(100svh - 465px);
        padding: 0;
        background: white;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .btn-confirm-address {
        position: fixed;
        margin: 16px 16px 0 16px;
        width: calc(100vw - 32px);

        &.disabled {
          background: #e5e5e5;
          color: #999999 !important;
          cursor: not-allowed;
          border: none;
        }
      }
    }

    .no-results-label {
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.04em;
      text-align: left;
      color: #757575;
    }

    .show-all-stores {
      cursor: pointer;
      margin-top: 18px;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.04em;
      text-align: left;
      float: left;
    }
  }

  .product-fullscreen-images-overlay {
    &.active {
      width: 100vw;
      height: 100svh;
      overflow: hidden;

      .product-fullscreen-close-button {
        visibility: visible;
      }
    }
  }

  .product-fullscreen-close-button {
    visibility: hidden;
    position: fixed;
    z-index: 103;
    cursor: pointer;
    height: 1.4rem;
    width: 1.4rem;
    background-repeat: no-repeat;
    top: 21px;
    right: 21px;
    @include fixedSprite('close-bold');
  }

  .product-notify-feature {
    .notify-button {
      justify-content: center;
      text-transform: none;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.04em;
    }
  }
}

.no-zindex {
  .product-detail.product-wrapper.pdp-new.product-set-pdp {
    .recommendations {
      .experience-assets-product {
        z-index: -1;
      }
    }
  }
}

.product-detail.product-wrapper.pdp-new.product-set-pdp {
  .image-carousel-col {
    top: 0;
    .add-to-cart-add-to-wishlist {
      display: block !important;
      top: 0;
      right: 0;

      .add-to-wish-list.js_add-to-wish-list {
        padding: 24px;
        height: auto;
      }
    }

    .icon--chevron-left-new {
      width: 0.8rem;
      height: 1.3rem;
      display: block;
    }

    .go-back-button {
      position: absolute;
      top: 0;
      left: 0;
      padding: 24px;
    }
  }

  .col-details {
    padding: 0;
    .details-column-content {
      padding: 24px 16px;
      width: 100%;
      max-width: 100%;
      .product-name-and-price {
        padding: 0;

        .product-name {
          display: flex;
          flex-direction: column;
          gap: 4px;
          margin-bottom: 0;

          h1 {
            font-family: $futura-book;
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0.01em;
            text-align: left;
            color: #0d0d0d;
          }

          p {
            font-family: $futura-book;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.015em;
            text-align: left;
            color: #7d7d7d;
            text-transform: lowercase;
          }
        }
      }

      .attributes {
        display: block;
        padding-bottom: 24px;
        .product-set-item {
          position: relative;
          display: grid;
          gap: 16px;
          grid-template: 'image image info info info';
          grid-template-columns: repeat(5, minmax(0, 1fr));
          cursor: pointer;
          margin: 24px 0;
          height: inherit;

          a {
            height: 100%;
          }

          &:hover {
            -webkit-box-shadow: inset 0 0 0 1px #e0e0e0;
            -moz-box-shadow: inset 0 0 0 1px #e0e0e0;
            box-shadow: inset 0 0 0 1px #e0e0e0;
          }

          &-image {
            aspect-ratio: 4 / 5;
            grid-area: image;
            object-fit: cover;
            max-height: min-content;

            img {
              object-fit: cover;
              height: 100%;
            }
          }

          &-info {
            padding: 12px 16px 12px 0;
            display: flex;
            flex-direction: column;
            gap: 8px;
            grid-area: info;
            height: 100%;

            &-link,
            &-price,
            &-name {
              font-family: $futura-book;
              font-size: 14px;
              font-weight: 400;
              line-height: 16.1px;
              letter-spacing: 0.02em;
              text-align: left;
              color: #0d0d0d;
            }

            &-name {
              margin-bottom: 8px;
            }

            &-price.product-price .prices .price {
              span {
                flex-flow: wrap;

                .discount-label {
                  width: 100%;
                  margin: 8px 0 0;
                }

                .prior-best-price-component {
                  display: none;
                }
              }

              .prior-best-price-disclaimer {
                display: none;
              }
            }

            &-link {
              position: absolute;
              bottom: 12px;
            }
          }
        }
      }

      .product-set-pdp-help {
        padding: 40px 16px 0;

        .contact-title {
          font-size: 24px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0.01em;
          text-align: center;
          margin-bottom: 16px;
        }

        .contact-description {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.015em;
          text-align: center;
          margin-bottom: 24px;
        }

        .product-help-action {
          padding: 10px 16px;
          border-radius: 100px;
          border: 1px solid #e0e0e0;
          margin: 0 auto;

          &:hover {
            background: #f9f9f9;
          }

          .service-trigger {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.015em;
            text-align: left;
          }
        }

        #layer-product-help .product-help-content .pdp-contacts-container .pdp-contacts-mobile {
          .phone {
            background: $white;
            color: $black;
            border: 1px solid #e5e5e5;
            padding: 14px;
            line-height: 16px;

            .icon--phone-pdp {
              filter: none;
            }
          }

          .email {
            padding: 14px;
            line-height: 16px;
          }
        }
      }
    }
  }

  .recommendations {
    .swiper-heading-wrapper {
      padding: 24px 16px;
    }
    .product-set-recomendation-container-inner-products {
      display: flex;
      flex: 0 1 auto;
      flex-wrap: wrap;
      gap: 4px;
      margin: 0;
      padding: 0;
      width: 100vw;

      .experience-assets-product {
        padding: 0;
        max-width: calc(50vw - 2px);
        flex-basis: calc(50vw - 2px);
        width: calc(50vw - 2px);
      }
    }
  }
}

#size-guidelines-modal.size-guidelines-modal-new {
  &.new-pdp-size-guidelines.is-open {
    visibility: visible;
  }

  &.animate-minicart-closure {
    visibility: visible !important;
  }

  .modal-size-guidelines.container-size-guidelines {
    height: 100%;
  }

  .modal-container.js-modal-container {
    width: 100%;
  }

  .measuring-guide__radio-input {
    align-self: flex-end;
    width: fit-content;

    .form-group {
      gap: 16px;

      .radio input[type='radio']::after {
        width: 1.7rem;
        height: 1.7rem;
      }
    }
  }

  .modal-header {
    height: unset;
    display: flex;
    width: 100%;
    padding: 16px;
    justify-content: space-between;

    .title-size {
      //styleName: headline-regular;
      font-family: $futura-book;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.035em;
      text-align: left;
    }

    .back-button {
      display: none;
      cursor: pointer;
      padding: 0;

      &:before {
        content: none;
      }
    }
  }

  .modal-content {
    height: 100%;

    .choose-size-label {
      font-family: $futura-book;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.02em;
      text-align: left;
    }

    .modal-content-gudielines .fitguide__container--desktop #fitguide-content-01 {
      padding: 1.6rem;

      .fitguide__interactive .choose-size-and-unit-measure {
        #choose-your-size {
          border: 1px solid #999999;
          color: #111111;
        }

        span.dropdown-arrow:after {
          top: 71%;
          width: 9px;
          height: 9px;
        }
      }
    }

    .fitguide-content-01 {
      padding: 2.4rem 1.6rem;
    }
    .fitguide__container {
      border-top: none !important;
    }

    .tab-button.measurement-tab,
    .tab-button.sizes-tab {
      display: none !important;
    }

    .measuring-guide__size-table {
      .measuring-guide__size-table-content {
        width: 50%;

        .table-details {
          position: relative;

          .table-details-label {
            text-decoration: none !important;
            font-family: $futura-book !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 21px !important;
            letter-spacing: 0.04em !important;
            text-transform: none !important;
          }

          .table-details-value {
            position: absolute;
            right: 0;
            font-family: $futura-book !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 20px !important;
            letter-spacing: 0.03em !important;
            text-align: left;
            color: $black;
          }
        }
      }
    }

    .horizontal-row.measuring-size-table-bottom {
      display: none;
    }
  }

  .modal-overlay {
    .pdp-popup-close {
      height: 14px;
      width: 14px;
      place-self: center;
      cursor: pointer;
    }
  }

  &.modal[aria-hidden='false'] .modal-overlay {
    transition: background 1s;
  }

  &.is-open .modal-overlay.pdp-popup-close {
    background: #000000a1;
  }

  .modal-overlay.pdp-popup-close {
    background: rgb(255 255 255 / 0%);
  }
}

#size-guidelines-modal.new-pdp-size-guidelines {
  display: block;
  visibility: hidden;

  .sizeguide-old-pdp {
    display: none;
  }

  .sizeguide-new-pdp {
    display: block;
  }

  .col,
  .row {
    margin: 0 !important;
    padding: 0;
  }

  #fitguide-content-02 {
    .title-table {
      font-family: $futura-book;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.02em;
      text-align: left;
      padding: 8px 0 16px 0;
    }
  }

  .fitguide__content.size-conversion-accordion {
    padding: 0;
  }

  .fitguide__content.fitguige-sizes {
    padding: 16px;

    .measuring-guide-container {
      .table.table-striped {
        margin-top: 0 !important;
        margin: 0 !important;
        table-layout: fixed;

        tr {
          th,
          td {
            font-family: $futura-book !important;
            font-size: 12px !important;
            font-weight: 400 !important;
            line-height: 18px !important;
            letter-spacing: 0.04em !important;
            text-align: left !important;
            color: $black !important;
            background: $white;
            border: 1px solid #e5e5e5;
            padding: 8px !important;
          }

          th {
            width: 80px;
          }

          th:nth-child(even) {
            background-color: #f5f5f5;
          }

          td:nth-child(even) {
            background-color: #f5f5f5;
          }
        }
      }
    }
  }

  .modal-content .modal-content-gudielines .fitguide__container--desktop #fitguide-content-01 .fitguide__interactive .choose-size-and-unit-measure span.dropdown-arrow:after {
    right: 20px;
  }

  .radio input[type='radio'] {
    width: 2rem;
    height: 2rem;
    padding: 11px 11px;
  }

  .choose-size-radio-input-row {
    display: flex;
    flex-direction: column-reverse;
  }

  .modal-content {
    max-height: calc(100vh - 57px);
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }



    .modal-content-gudielines {
      padding-bottom: 50px;
    }

    .switch-to-table {
      margin: 24px 0 0 0;
      width: fit-content;
      font-family: $futura-book;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: left;
      cursor: pointer;
    }

    .measuring-guide__size-table-container {
      margin-bottom: 24px !important;
    }

    #fitguide-spacer-tab {
      display: none;
    }

    .fitguide__interactive {
      width: 100%;
    }

    .choose-size-radio-input-row {
      padding: 0;
      .choose-size-and-unit-measure {
        max-width: 100%;
        padding: 0;

        .choose-size-label {
          font-family: $futura-book !important;
          font-size: 16px !important;
          font-weight: 400 !important;
          line-height: 22px !important;
          letter-spacing: 0.02em !important;
          text-align: left;
          margin-bottom: 8px !important;
        }
      }

      .measuring-guide__size-table,
      .measuring-guide__size-table-content {
        max-width: unset;
        flex-basis: unset;
        padding: 0;
      }
    }

    .measuring-guide__size-table-container {
      display: flex;
      flex-direction: column;
    }

    .modal-content-gudielines .fitguide__container--desktop #fitguide-content-01 .fitguide__interactive .measuring-guide__size-table .table-details-value {
      color: $black;
    }
  }

  .modal-content .modal-content-gudielines .fitguide__container--desktop #fitguide-content-01 .fitguide__interactive .measuring-guide__radio-input .form-group {
    padding: 24px 0;
  }

  .pdp-contacts-container i {
    margin-right: 8px;
  }

  .product-help-content {
    .pdp-contacts-container {
      margin: 0;

      .pdp-contacts-mobile {
        display: none;

        .phone,
        .email {
          text-align: center;
          padding: 16px;
          border-radius: 30px;
          margin-top: 8px;
        }

        .phone {
          background: $black;
          color: $white;
          .icon--phone-pdp {
            filter: brightness(20);
          }
        }

        .email {
          margin-top: 16px;
          border: 1px solid #e5e5e5;
        }
      }
    }

    .pdp-contacts-desktop {
      display: flex;
      flex-direction: column;

      .pdp-contacts-desktop-title {
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.03em;
        text-align: left;

        color: #757575;
      }

      .phone,
      .email {
        margin-top: 8px;
      }
    }
  }

  .modal-container {
    position: absolute;
    top: -100%;
    z-index: 1;
    min-width: 25rem;
    background-color: $white;
    top: 100vh;
    transition: none;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.is-open {
    visibility: visible;
    .modal-container {
      top: 0 !important;
      transition: none;
      visibility: visible;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }
  }
}

.notify-me-container {
  .notify-me {
    &-info {
      align-items: center;
      display: flex;
      margin-bottom: 16px;
      .info-icon {
        height: 12px;
        margin-right: 8px;
        width: 12px;
      }
      .info-text {
        color: $status_error;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
      }
    }

    &-insert-email {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      .form-input {
        padding-bottom: 0;

        .email-field {
          margin-top: 16px;
          &-label {
            font-weight: 500;
            line-height: 1;
            letter-spacing: 0.5px;
          }
          &-input {
            margin: 0;
          }
        }
      }

      .add-to-cart-add-to-wishlist {
        margin: 24px 0;
        .add-to-cart-action {
          font-size: 14px;
          max-width: 100%;
          width: 100%;
          padding: 0;
        }
        .add-to-whishlist {
          max-width: min-content;
        }
      }
      .newsletter-checkbox {
        margin-top: 16px;
        margin-left: 0;
        font-weight: 500;
        letter-spacing: 0.5px;

        input {
          width: 2rem;
          height: 2rem;
          padding: 10px 10px;
        }
      }

      .success-message {
        color: #096a0e;
        margin-top: 24px;
        display: none;
        &.success {
          display: block;
        }
      }

      .privacy-newsletter {
        color: $dark-grey;
        font-size: 12px;
        text-transform: lowercase;
        text-decoration: underline;
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    .notify-me-add-to-wishlist {
      margin: 24px 0;
    }
  }
}

.product-slides {
  list-style: none;
}
.product-slide {
  list-style: none;
}

.product-fullscreen-images-wrapper {
  display: none;

  &.active {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 102;
    background-color: $white;
    overflow: auto hidden;

    .product-fullscreen-close-button {
      visibility: visible;
      color: transparent;
    }
    .product-fullscreen-image-first {
      display: block;
      width: 100%;
    }
    .product-video-container {
      display: block;
      .product-video-fullscreen {
        width: 100%;
      }
    }
    .product-fullscreen-close-button {
      visibility: visible;
      color: transparent;
    }
  }

  .product-fullscreen-images {
    height: 100vh;
    width: 100vw;
    img {
      object-fit: cover;
      height: 100%;
    }
  }
}

.product-slide {
  .swiper-zoom-container {
    align-items: flex-start;
  }
}

html.product-main-overlay,
body.product-main-overlay {
  overflow: hidden;
}

.main-overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #00000087;
  top: 0;

  &.show-overlay {
    display: block;
    z-index: 50;
  }
}

.product-coming-soon {
  .info-text {
    font-size: 16px;
    color: #111111;
  }
  .coming-soon-text {
    color: #757575;
  }

  .coming-soon-icon-info {
    width: 16px;
    height: 15px;
    background: url(../images/icon-info.svg);
    display: inline-block;
    margin-right: 4px;
  }
}

/* Fade-in keyframe animation */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-80px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-80px);
  }
}

.wishlist-alert {
  &-container {
    display: none;
    opacity: 0;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 0;
    left: 0;
    top: -48px;
    z-index: 50;
    background-color: #000;
    color: #fff;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 400;

    &.fade-in {
      display: flex;
      animation: fadeInDown 0.3s ease forwards;
    }

    &.fade-out {
      display: flex;
      animation: fadeOutUp 0.3s ease forwards;
    }
  }

  &-content {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 20px;
    letter-spacing: 0.015em;
  }

  &-link {
    line-height: 16.8px;
    letter-spacing: 0.01em;

    .link-underline {
      &::before {
        border-top: 1px solid #fff;
      }
    }
  }
}
