.checkoutpage {
  .checkout-shipping__address {
    .checkout-shipping__options {
      fieldset {
        .form-group {
          .horizontal-row {
            &.checkout-option-bottom {
              display: block;
              margin-top: 5px;
              margin-left: -39px;
              width: calc(100% + 84px);
            }
          }
        }
      }
    }
    .horizontal-row {
      &.upper-title-row {
        margin-left: -38px;
        width: calc(100% + 82px);
      }
      &.shipping-data {
        margin-left: -39px;
        width: calc(100% + 84px);
      }
      &.pickup-checkout-address-bottom {
        margin-left: -39px;
        width: calc(100% + 84px);
      }
      &.checkout-option-bottom {
        margin-left: -39px;
        width: calc(100% + 84px);
      }
      &.checkout-option-top {
        margin-left: -39px;
        width: calc(100% + 84px);
      }
      &.checkout-navigations-bottom {
        display: block;
        margin-left: -38px;
        width: calc(100% + 82px);
      }
    }
  }
  .order-summary-box {
    .horizontal-row {
      &.order-summary-elements-top {
        border-top: 1px solid $light-grey;
        margin-left: -39px;
        width: calc(100% + 36px);
        margin-bottom: 16px;
        margin-top: 24px;
      }
    }
  }

  &.payment {
    .checkout-payment {
      form {
        > .form-group {
          margin-top: 24px;
        }
        .billingAddressForm-checkbox {
          padding-left: 12px;
          &.no-addresses {
            padding-left: 0;
          }
        }
      }
      #addresses {
        margin: 16px 0 0 0;
      }
      .addresses-label {
        height: 23px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        color: $black;
        text-transform: uppercase;
      }
      .horizontal-row {
        width: calc(100% + 30px);
        border-top: 1px solid $light-grey;
        margin-left: -15px;
        margin-bottom: 16px;

        &.upper-title-row {
          margin-top: 40px;
          margin-left: -38px;
          width: calc(100% + 82px);
        }
      }
      &-title {
        display: block;
        height: 39px;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1.5px;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 24px;
      }
      &-subtitle {
        height: 23px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 16px;
        padding-left: 12px;
        &.no-addresses {
          padding-left: 0;
        }
      }
    }
    .payment-method {
      .radio__icon {
        margin-right: 0;
        &.credit-card {
          .single-payment-box {
            margin-left: 18px;
            padding-right: 17px;
            .col-3 {
              padding-left: 8px;
            }
            &-card-type {
              margin-right: 16px;
            }
          }
        }
        &:not(.credit-card) {
          .single-payment-box {
            margin-left: 0;
            .col-3 {
              padding-left: 16px;
            }
            &-card-type {
              margin-right: -4px;
            }
          }
        }
      }

      .container-radio-payment {
        .radio__icon {
          &.credit-card {
            .single-payment-box {
              &-card-type {
                margin-right: 18px;
              }
            }
          }
          &:not(.credit-card) {
            .single-payment-box {
              &-card-type {
                margin-right: -1px;
              }
            }
          }
        }
      }
    }

    .billingAddressForm {
      .checkout-billing__address__address-book__new {
        .address_form {
          .accordion-title {
            &-text {
              margin-top: 0;
            }
            &-icon {
              margin-top: 0;
            }
          }
          .checkout-navigations {
            .checkbox {
              margin-bottom: 0;
            }
          }
        }
      }
      .form-title {
        height: 12px;
        margin: 0 16px 0 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: black;
        text-transform: uppercase;
      }
      &.no-addresses {
        .checkout-billing__address__address-book__new {
          .address_form {
            #billing-address-accordion-content {
              > .row {
                &.add-to-address {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .paymentmethods {
      .checkout-shipping__address__address-book {
        &-title {
          height: 23px;
          margin: 16px 0 18px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          color: $black;
          text-transform: uppercase;
        }
        > .form-group {
          .form-group__label {
            height: 23px;
            margin: 24px 46px 16px 0;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            color: $black;
            text-transform: uppercase;
          }
        }
      }
      .billingAddressForm-checkbox {
        .checkbox {
          margin-bottom: 26.8px;
        }
      }
      .horizontal-row {
        width: calc(100% + 30px);
        border-top: 1px solid $light-grey;
        margin-left: -15px;
        margin-bottom: 16px;
        &.payment-form-bottom {
          margin-top: 24px;
          width: calc(100% + 44px);
          margin-left: 0;
        }
        &.upper-title-row {
          margin-top: 16px;
          margin-left: -38px;
          width: calc(100% + 82px);
        }
      }
    }
    .form-select {
      select {
        width: 100%;
        margin: 16px 0 0;
      }
      .col-1 {
        top: 3rem;
      }
    }
    .horizontal-row {
      width: calc(100% + 30px);
      border-top: 1px solid $light-grey;
      margin-left: -15px;
      margin-bottom: 16px;
      &.billing-address-form-top {
        width: calc(100% + 20px);
        margin-left: 0;
        margin-bottom: 0;
        margin-top: 24px;
      }
      &.upper-billing-form {
        margin-left: 0;
        margin-top: 24px;
        margin-bottom: 18px;
        width: calc(100% + 44px);
      }
      &.sub-radio-element-bottom {
        width: 100%;
        margin: 17px 0;
        margin-left: 0;
      }
    }
  }

  #addressbookid {
    > div {
      &:not(:last-child) {
        height: 80px;
        padding: 24px 0;
        padding-left: 24px;
        width: calc(100% + 84px);
        margin-left: -20px;
        &.radio-item-checked {
          background: $light-grey;
        }
        &:not(.radio-item-checked) {
          padding: 24px 0;
          padding-left: 24px;
          width: calc(100% + 84px);
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .details-box {
    padding-right: 44px;
  }
}

.review-summary-box {
  &.box-inside-form {
    display: none;
  }
  &.outer {
    .accordion {
      .order-summary-box-review {
        .js-accordion-trigger {
          padding: 0;
        }
        .order-summary-box-info {
          padding: 0;
        }
      }
      .js-accordion-trigger-title {
        padding: 0;
        margin-top: 22px;
        margin-bottom: 0;
      }
    }
    .order-summary-box-info {
      margin-top: 24px;
    }
  }
  .order-summary-box-container {
    .horizontal-row {
      &.estimated-total-row-bottom {
        display: block;
        width: calc(100% + 19px);
      }
      &.details-box-upper-row {
        width: calc(100% + 19px);
      }
      &.order-summary-elements-top {
        width: calc(100% + 19px);
      }
    }
  }
}
