.maintenance-container {
  .container {
    max-width: 888px;
  }

  .maintenance-description {
    margin-bottom: 32px;
  }

  .maintenance-footer {
    margin-left: 0;
  }

  .maintenance-social-links {
    .container-social {
      text-align: left;
    }
    .follow-us {
      font-size: 24px;
      margin-bottom: 0;
    }
  }

  .maintenance-title {
    font-size: 24px;
    margin-top: 24px;
  }
}
