.page-search-result-container {
  .search-no-result-decorator-container {
    .plp-banner-container {
      display: flex;
      justify-content: center;
      .text-content {
        .page {
          &-title {
            height: 38.4px;
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1.5px;
            color: $black;
            text-transform: uppercase;
            margin-bottom: 16px;
          }
          &-description {
            width: 616px;
          }
        }
      }
    }
    .breadcrumbs-hero-internal {
      display: flex;
    }
    .delivery-banner-mobile {
      border-bottom: 1px solid $light-grey;
    }
    .delivery-banner-mobile {
      display: none;
    }
    .delivery-banner-desktop-spacer {
      display: flex;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .delivery-banner-desktop {
      display: flex;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      justify-content: center;
      align-items: center;
      .delivery-banner-info {
        display: flex;
        align-items: center;
        margin: 8px 0;
        justify-content: center;
        &-icon {
          margin-right: 8px;
        }
        &-text {
          width: 183px;
          height: 12px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          color: #096a0e;
        }
      }
    }

    .no-result-page {
      .no-result-carousel {
        margin: 0 auto;
        margin-left: 0;
        max-width: 120rem;
        padding: 0 28px;
        position: relative;
        width: 100%;
        .image-carousel-global-container {
          .swiper-slide {
            // width: 308px;
            width: 216px;
            margin-right: 20px;
          }
        }
        .img {
          // width: 308px;
          // height: 411px;
          width: 216px;
          height: 288px;
          // margin-right: 20px;
        }
      }
    }

    .back-to-top {
      bottom: 100px; /* Place the button at the bottom of the page */
    }

    .search-result {
      .js-grid-header {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        height: 30px;
        align-items: center;
      }

      .refinement-bar {
        display: flex;
      }

      .grid-product {
        z-index: 0;
        padding: 0;
        .mobile-version {
          display: none;
        }
        .version {
          .product-grid-container {
            .single-element {
              margin-bottom: 32px;
              .login-to-buy-image-banner {
                display: none;
              }
              .second-image {
                display: none;
              }
              &.login-to-buy {
                &:hover {
                  .login-to-buy-image-banner {
                    display: block;
                    &-overlay {
                      background: $black;
                      height: 100%;
                      opacity: 0.5;
                      width: 100%;
                      position: absolute;
                      top: 0;
                      z-index: 0;
                    }
                    &-text {
                      background: $white;
                      color: $black;
                      font-size: 12px;
                      font-stretch: normal;
                      font-style: normal;
                      font-weight: 500;
                      height: 60px;
                      letter-spacing: 0.5px;
                      line-height: 1;
                      padding: 24px 32px;
                      position: absolute;
                      text-align: center;
                      text-transform: uppercase;
                      top: 0;
                      width: 100%;
                      z-index: 2;
                    }
                  }
                }
              }
              &.show-second-image:hover,
              .single-element-content-image:focus {
                .second-image {
                  display: block;
                  position: absolute;
                  top: 0;
                  height: 100%;
                  width: 100%;
                }
              }
              &-content {
                &-detail {
                  text-align: center;
                  &-price {
                    .previous-price {
                      color: $dark-grey;
                      text-decoration: line-through;
                      margin-right: 16px;
                    }
                  }
                }
                &-image {
                  height: 288px;
                  // margin: 0 auto;
                  margin-bottom: 16px;
                  // margin-right: 0;
                  position: relative;
                  // width: 216px;
                  width: 216px;
                  &-tag {
                    background-color: red;
                    height: 20px;
                    padding: 4px 8px;
                    border-radius: 10px;
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: 0.5px;
                    text-align: center;
                    position: absolute;
                    bottom: 8px;
                    left: 8px;
                    color: $white;
                  }
                  &-img {
                    max-width: 100%;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    display: block;
                    height: 100%;
                  }
                }
              }
            }
          }

          .products-seen {
            margin-bottom: 16px;
            text-align: center;
          }

          .show-more {
            // height: 48px;
            margin: 0 auto;
            // margin-bottom: 40px;
            padding-bottom: 40px;
            width: 296px;
          }
        }
      }

      .selected-filters {
        display: block;
        margin-bottom: 24px;
        margin-top: 23px;
        &-list {
          display: flex;
          list-style: none;
          .selected-filter {
            display: flex;
            align-items: center;
            margin-right: 31px;
            &-icon {
              margin-right: 20px;
              position: relative;
              > span {
                position: absolute;
                top: 3px;
              }
            }
            &-label {
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 0.5px;
              color: $black;
            }
          }
          .reset-filters {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.5px;
            color: $black;
            text-decoration: underline;
          }
        }
      }
    }

    .horizontal-row {
      &.grid-header-bottom-row {
        display: block;
        width: calc(100% + 56px);
        border-top: 1px solid $light-grey;
        margin-left: -28px;
      }
    }

    .refinement-bar {
      display: flex;
    }
  }
}

.no-results-recommendations {
  margin-top: 96px;

  .recommendations .experience-assets-product {
    padding: 0;
  }

  .recommendations {
    .swiper-header {
      margin-left: 0;
    }
  }
}
