.full-width-carousel-component {
  position: relative;

  .swiper-slide {
    .image-no-tv {
      height: 100%;
    }
  }

  .swiper-pagination {
    display: none;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: block;
    top: 50%;
    color: $black;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 20px !important;
  }
}
