.full-width-carousel-component {
  .swiper-slide {
    margin: 0 !important;

    .component-image {
      height: 100%;
    }
  }

  .swiper-pagination {
    width: 100%;
    display: flex;
    position: static;
    margin: 0;
    justify-content: center;

    .swiper-pagination-bullet {
      background: $black;
      max-width: none;
      width: auto;
      height: 2px;
      margin: 0 !important;
      border-radius: 0;
      flex-grow: 1;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}
