.tooltip-container.notify {
  display: none;
}

.tooltip-container.not-notify {
  display: block;
}

.product-detail.pdp-new {
  #layer-variation-size,
  #layer-variation-swatch,
  #layer-product-help,
  #layer-product-notify,
  #layer-product-notify-size,
  #layer-product-coming-soon,
  #layer-product-services,
  #layer-product-packaging,
  #layer-product-details {
    top: 100vh;
    transition: top linear 0.5s;
    left: 0;
    width: 0;
    max-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    z-index: 100;
    position: fixed;

    .pdp-help-client-pop-up {
      margin-top: 16px;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400 !important;
      line-height: 21px;
      letter-spacing: 0.04em;
      text-align: center;
      color: #757575;

      a {
        display: none;
      }
    }

    #openChatButton {
      margin-top: 8px;
    }

    .open-chat-container {
      width: 100%;
      text-align: center;
    }

    .editorial-composition-title {
      margin-top: 24px;
    }

    .editorial-composition-title,
    .product-services-content-container h5 {
      //styleName: body-regular;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400 !important;
      line-height: 21px;
      letter-spacing: 0.04em;
      text-align: left;
      color: #757575;
      margin-bottom: 8px;
    }

    .product-help-content {
      .pdp-contacts-container {
        margin: 0;

        .pdp-contacts-mobile {
          display: flex;
          flex-direction: column;

          .phone,
          .email {
            text-align: center;
            padding: 16px;
            border-radius: 30px;
          }

          .phone {
            background: $black;
            color: $white;
            .icon--phone-pdp {
              filter: brightness(20);
            }
          }

          .email {
            margin-top: 16px;
            border: 1px solid #e5e5e5;
          }
        }
      }

      .pdp-contacts-desktop {
        display: none;
      }
    }
  }

  .layer-product-coming-soon,
  .layer-product-notify,
  .layer-product-notify-size {
    .product-notify-content .notify-me-info,
    .notify-me-container .notify-me-info {
      display: none;
    }

    .notfy-me-actions {
      max-width: unset !important;
    }

    .add-to-whishlist {
      display: none;
    }

    .d-none {
      display: none;
    }

    .notify-me-container {
      .coming-soon-text {
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.03em;
        text-align: left;
        color: $black;
      }
      .email-field-label {
        display: none;
      }

      .tooltip-container.notify {
        display: block;
      }

      .tooltip-container.not-notify {
        display: none;
      }

      .newsletter-checkbox {
        .custom-control-label.form-group__label {
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.03em;
          text-align: left;
        }
      }

      .checkbox input[type='checkbox'] + label {
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.03em;
        text-align: left;
      }

      .tooltip-container {
        font-family: $futura-book;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.04em;
        text-align: left;
        color: $black;

        .tooltip-trigger {
          font-weight: normal;
        }
      }

      .newsletter-checkbox {
        margin-top: 16px;
        margin-left: 0;
      }
    }

    .product-notify-content {
      .text {
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.03em;
        text-align: left;
      }

      .email-field-label {
        display: none;
      }

      .tooltip-container.notify {
        display: block;
      }

      .tooltip-container.not-notify {
        display: none;
      }

      .newsletter-checkbox {
        .custom-control-label.form-group__label {
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.03em;
          text-align: left;
        }
      }

      .newsletter-checkbox {
        margin-top: 16px;
        margin-left: 0;
      }

      .tooltip-container {
        font-family: $futura-book;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.04em;
        text-align: left;
        color: $black;

        .tooltip-trigger {
          font-weight: normal;
        }
      }

      .checkbox input[type='checkbox'] + label {
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.03em;
        text-align: left;
      }

      .add-to-cart-add-to-wishlist {
        border: none;
        margin: 16px 0 32px;
        padding: 0;
        height: unset;
      }
    }

    .product-coming-soon-content {
      height: 100%;

      .add-to-cart-add-to-wishlist {
        padding: 0;
        height: unset;
      }
    }

    .product-coming-soon-content-success,
    .product-notify-content-success,
    .product-notify-size-content-success {
      display: none;

      &.success {
        display: block;
      }

      .notify-message-success {
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.04em;
      }

      .notify-success-continue-shopping {
        margin-top: 16px;
        padding: 16px;
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.04em;
        text-align: center;
        width: 100%;
        border: 1px solid #e5e5e5;
        border-radius: 30px;
        cursor: pointer;
      }
    }
  }

  #layer-variation-swatch,
  #layer-product-coming-soon,
  #layer-product-notify,
  #layer-product-notify-size,
  #layer-product-services,
  #layer-product-packaging,
  #layer-product-details {
    height: 100svh;
  }

  #layer-variation-size-component,
  #layer-variation-swatch-component,
  #layer-product-coming-soon-component,
  #layer-product-notify-component,
  #layer-product-notify-size-component,
  #layer-product-help-component,
  #layer-product-services-component,
  #layer-product-packaging-component,
  #layer-product-details-component {
    position: unset;

    .coming-soon-button {
      justify-content: center;
      text-transform: none;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.04em;
    }
  }

  .variation-size-container,
  .variation-swatch-container,
  .product-coming-soon-container,
  .product-notify-container,
  .product-notify-size-container,
  .product-help-container,
  .product-services-container,
  .product-packaging-container,
  .product-details-container {
    .modal-header.variation-size-header,
    .modal-header.variation-swatch-header,
    .modal-header.product-coming-soon-header,
    .modal-header.product-notify-header,
    .modal-header.product-notify-size-header,
    .modal-header.product-help-header,
    .modal-header.product-services-header,
    .modal-header.product-packaging-header,
    .modal-header.product-details-header {
      margin: 1.6rem;
      .title {
        color: $black;
        font-family: $futura-book;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.64px;
      }
      align-items: center;
      display: flex;
      justify-content: space-between;
      .modal-close-container {
        height: 16px;
        cursor: pointer;
        .modal-close {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  #overlay-minicart::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  #overlay-minicart {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .variation-swatch-container {
    .modal-content {
      max-height: calc(100vh - 54px);
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: row;
    }
  }

  .variation-size-container,
  .variation-swatch-container,
  .product-help-container,
  .product-services-container,
  .product-coming-soon-container,
  .product-notify-container,
  .product-notify-size-container,
  .product-packaging-container,
  .product-details-container {
    position: relative;
    height: 100%;

    br {
      display: none;
    }

    .modal-footer {
      bottom: 0;
      border-top: 1px solid #e5e5e5;
      background: $white;
      padding: 16px 0 0 0;
      width: 100%;

      .link-shopping-bag {
        text-transform: none;
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.04em;
        padding: 13.5px 40px;
        height: 48px;
        align-items: center;
      }

      .minicart-continue-shopping {
        width: 100%;
        text-align: center;
        margin: 8px 0 0 0;

        &-link {
          color: $black;
          cursor: pointer;
          //styleName: button-regular;
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.04em;
        }
      }

      .minicart-delivery-info {
        // margin-top: 16px;
        padding: 8px 16px 8px;
        border-top: 1px solid #e5e5e5;
        &-title,
        &-content {
          font-family: $futura-book;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.6px;
        }
        &-title {
          color: $black;
        }
        &-content {
          color: $secondary-grey;
        }
      }

      .btn-container {
        margin-top: 8px;
        padding: 0 16px;
      }

      .paypal-content {
        height: 48px;

        .paypal-button > .paypal-button-label-container {
          height: 21px;
        }
      }

      .button-container {
        padding: 0 16px;
      }

      .minicart-totals {
        padding: 0 16px;
        .sub-total-label,
        .sub-total {
          color: $black;
          font-family: $futura-book;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.64px;
        }

        .tax-label {
          margin-left: 8px;
          color: #999999;
        }

        .link-shopping-bag {
          text-transform: none;
        }
      }
    }
    .modal-content {
      padding: 1.6rem 1.6rem;
      max-height: calc(100svh - 54px);
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

  .product-details-container {
    br {
      display: block;
    }
  }

  .variation-size-container {
    .modal-content {
      padding: 1.6rem 1.6rem 2.4rem 1.6rem;
    }
  }

  .main-overlay {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #00000087;
    z-index: 5;
    top: unset;
    &.no-top {
      top: 0;
    }
    &.full-height {
      height: 100%;
    }
  }

  .js-dropdown-content {
    z-index: 6;
  }

  .variation-size-overlay,
  .variation-swatch-overlay,
  .product-help-overlay,
  .product-coming-soon-overlay,
  .product-notify-overlay,
  .product-notify-size-overlay,
  .product-services-overlay,
  .product-packaging-overlay,
  .product-details-overlay {
    // display: none;
    width: 100vw;
    position: fixed;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    background: #000000a1;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: visibility ease-out 1s, opacity ease-out 1s;

    .dropdown-content {
      z-index: 100;
    }
  }

  .layer-variation-size.is-open .variation-size-overlay,
  .layer-variation-swatch.is-open .variation-swatch-overlay,
  .layer-product-help.is-open .product-help-overlay,
  .layer-product-coming-soon.is-open .product-coming-soon-overlay,
  .layer-product-notify.is-open .product-notify-overlay,
  .layer-product-notify-size.is-open .product-notify-size-overlay,
  .layer-product-services.is-open .product-services-overlay,
  .layer-product-packaging.is-open .product-packaging-overlay,
  .layer-product-details.is-open .product-details-overlay {
    visibility: visible;
    transition: visibility ease-in 1s, opacity ease-in 1s;

    opacity: 1;
  }

  .variation-swatch-action.single-value-attribute {
    border-top: none;
    border-bottom: none;
    .attribute-label-value {
      border-top: 1px solid #e5e5e5 !important;
      border-bottom: 1px solid #e5e5e5 !important;
    }

    .attribute-label-value {
      padding: 24px 0;
    }

    &:after {
      display: none;
      background: none !important;
    }
  }

  .variation-size-action,
  .variation-swatch-action {
    width: 100%;
    padding: 24px 0;
    font-family: $futura-book;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.04em;
    text-align: left;

    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;

    .single-value-color {
      border-bottom: none;
    }

    &::after {
      content: '';
      @include fixedSprite('chevron-right');
      // transform: rotate(-90deg);
      width: 22px;
      height: 22px;
    }
  }

  .variation-size-action {
    border-top: none;
  }

  .variation-size-content {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: scroll;

    .notify-action-button {
      .notify-button {
        display: none;
      }
    }

    .notify-action-button.disabled {
      .notify-button {
        display: block;
      }
    }

    .attribute-selector {
      margin-bottom: 16px;
      cursor: pointer;
      width: fit-content;
      flex-basis: fit-content;
      align-content: flex-start;
      height: 32px;
      text-align: -webkit-left;
      text-align: left;

      span {
        padding: 5px 5px;
        border: 1px solid $white;
      }

      &:hover {
        span {
          border: solid 1px #cccccc;
        }
      }

      &.selected {
        span {
          width: fit-content;
          border: 1px solid $black;
        }
      }

      &.disabled {
        display: flex;
        justify-content: space-between;
        color: #cccccc;
        width: fit-content;
        span {
          border: none !important;
        }
      }

      .notify-button {
        color: #000000;
      }
    }

    .size-guidelines-button {
      margin-left: 8px;

      .size-guidelines {
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.04em;
        text-align: left;
      }
    }
  }

  .variation-swatch-content {
    display: flex;
    overflow: scroll;

    .variation-container {
      width: 46%;
      padding: 8px;
      border: solid 1px #e5e5e5;
      cursor: pointer;
      margin-bottom: 16px;

      &:hover {
        border: solid 1px #cccccc;
      }
    }

    .selected {
      border: solid 1px $black;

      &:hover {
        border: solid 1px $black;
      }
    }

    .variation-not-available,
    .variation-name {
      font-family: $futura-book;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.05em;
      text-align: left;
    }

    .variation-name {
      margin: 8px 4px 0 4px;
    }

    .variation-not-available {
      color: #999999;
      display: none;
      margin: 0;
      margin-left: 4px;
    }

    .disabled {
      .variation-name {
        color: #999999;
      }

      .variation-not-available {
        color: #999999;
        display: block;
      }
    }
  }

  .pdp-contacts-container {
    margin-top: 40px;
    i {
      margin-right: 8px;
    }

    .pdp-contacts-desktop {
      display: flex;
      flex-direction: column;

      &-title {
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.04em;
        text-align: left;
        color: #757575;
      }

      .phone,
      .email {
        width: fit-content;
        margin-top: 8px;
      }

      .phone {
        span:after {
          @include fixedSprite('phone-pdp');
        }
      }

      .email {
        span:after {
          @include fixedSprite('email-pdp');
        }
      }
    }

    .pdp-contacts-mobile {
      display: none;
    }
  }
}
/** MINICART ITEM  style -->END*/
