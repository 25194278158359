.pd-image-component {
  .twoColumnContainer {
    .twoColumnContent {
      height: auto;
      aspect-ratio:auto;
      gap: 0;

      .pd-wrapper-image-layer {
        flex: 0 0 100%;
      }
      
      .visualImage {
        height: auto;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &__position {
    &-left {
      .pd-image-component__column-region {
        display: flex;
        justify-content: left;
        
      }
    }

    &-center {
      .pd-image-component__column-region {
        display: flex;
        justify-content: center;
      }
    }

    &-right {
      .pd-image-component__column-region {
        display: flex;
        justify-content: right;
      }
    }
  }

  .image-container-selector {
    overflow: hidden;
  }
  img.component-image {
    display: block;
    object-fit: cover;
    width: 100%;
  }
}
