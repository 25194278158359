#layer-customer-care {
  right: 0;
}
.modal-customer-care {
  min-width: 375px;

  border: 1px solid $light-grey;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 375px;
  padding: 0 16px;
}
