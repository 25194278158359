.payment-data-container {
  .payment-wrapper {
    flex-direction: row;
    border-bottom: 1px solid $light-grey;
  }

  .card-wrapper {
    min-width: 33%;
    + .card-wrapper {
      border: none;
    }
  }
}

.account-customer-care {
  display: block;
  margin: 2rem 0;
  padding-top: 0;
  border-top: 1px solid $light-grey;
  border-bottom: 1px solid $light-grey;

  .title-section {
    padding-top: 1.7rem;
  }
  .content-asset {
    flex-direction: row;
    justify-content: space-between;
    > div {
      width: calc(50% - 20px);
    }

    > div:not(.section-footer-contact) {
      border-right: 1px solid $light-grey;
      padding-right: 2rem;
    }
  }

  .section-footer-contact {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
  }
}
