.reset-password {
  &.access-details-container {
    .form-group.required .form-control-label::before {
      content: '';
    }
  }

  .content {
    background-color: $light-grey;
    padding: 16px;
    width: 100%;

    &#new-password-confirmation {
      background-color: $white;
    }
  }

  #new-password-confirm {
    display: none;
    .card-header {
      .main-title {
        margin-bottom: 16px;
        text-align: left;
      }
    }

    .card-body {
      .password-confirmation-buttons {
        margin-top: 16px;
        .buttons {
          .cart-show-button {
            margin: 16px 0;
          }
        }
        .go-to-home-button {
          text-align: center;
        }
      }
    }
  }

  .card-body {
    .form-input {
      padding-bottom: 0.5rem;
      input {
        background: $white;
      }
    }
  }

  .card-header {
    .main-title {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .save-new-pass-container {
    width: 100%;
    .save-new-pass {
      margin-top: 16px;
    }
  }
}

.reset-password-help-boxes {
  margin-top: 8px;
  .content-asset-box {
    .single-box-element {
      background-color: $light-grey;
      margin-bottom: 16px;
      padding: 16px;
      .single-box-element-content {
        h2 {
          font-weight: bold;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
  }
}


.dynamic-validation-message {
  position: relative;
  margin-left: 15px;
  
  &.error-length {
    margin-top: 8px;
  }

  &:not(.green):before {
    content: "";
    display: block;
    width: 10px;
    height: 1px;
    background: #666;
    left: -15px;
    top: 42%;
    position: absolute;
  }

  &.green {
    color: green;

    &:before {
      content: "L";
      position: absolute;
      left: -15px;
      font-family: arial;
      -ms-transform: scaleX(-1) rotate(-35deg);
      -webkit-transform: scaleX(-1) rotate(-35deg);
      transform: scaleX(-1) rotate(-35deg);
    }
  }
}

#newPassword+ .error-message {
  display: none;
}

.form-group.error {
  .dynamic-validation-message:not(.green) {
    color: #ba2015;
    &::before {
      background: #ba2015;
    }
  }
}
