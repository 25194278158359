// container
.radio {
  display: flex;
  margin-bottom: ($space-unit * 6);
  break-inside: avoid-column;

  input[type='radio'] {
    color: $black;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 100%;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: $space-line solid $black;
    cursor: pointer;
    flex-shrink: 0;

    &:focus {
      outline-offset: 0.3rem;
    }

    &:disabled {
      cursor: not-allowed;
      border: $space-line solid $medium-grey;
      & + label {
        color: $medium-grey;
        cursor: not-allowed;
      }
    }
  }

  input[type='radio'] + label {
    @extend %body--small;
    display: flex;
    padding-left: ($space-unit * 3);
    cursor: pointer;
    position: relative;
    width: 100%;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  input[type='radio']::after {
    position: relative;
    display: block;
    height: 1.2rem;
    width: 1.2rem;
    background-color: $black;
    border-radius: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: none;
  }

  input[type='radio']:checked::after {
    content: '';
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @extend %body--small;

    [class^='icon'] {
      margin-left: $space-unit;
    }
  }

  &--outline {
    border: $space-line solid $black;
    padding: ($space-unit * 4) 1rem;
    margin-bottom: $space-unit;
    min-height: 5rem;
    align-items: baseline;
  }
}

.is-invalid {
  .radio {
    color: $status_error;
  }
}
