.header {
  .header-top {
    @include z(header);
    justify-content: space-between;
    // &.no-menu {
    //   .logo {
    //     padding-left: 0;
    //   }
    // }
  }

  .left-actions {
    display: none;
  }

  .right-actions {
    padding: 0;

    .cart-elements {
      margin-left: 2px;
    }
  }

  .logo {
    text-align: left;
    padding: 0;
    line-height: 0;
  }
}
