.pd-video__container {
  &.video--embed-controls:not(.video--tv):not(.video--no-controls) {
    .vjs-control-bar {
      margin-bottom: 2.4rem;

      .vjs-play-control .vjs-icon-placeholder,
      .vjs-icon-play {
        &:before {
          @include fixedSprite('embed-player-play');
        }
      }

      .vjs-play-control.vjs-playing .vjs-icon-placeholder,
      .vjs-icon-pause {
        &:before {
          @include fixedSprite('embed-player-pause');
        }
      }

      .vjs-mute-control .vjs-icon-placeholder,
      .vjs-icon-volume-high {
        &:before {
          @include fixedSprite('embed-player-volume-on');
        }
      }

      .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder,
      .vjs-icon-volume-mute {
        &:before {
          @include fixedSprite('embed-player-volume-off');
        }
      }

      .vjs-play-control {
        margin-left: 2.5rem;
      }

      .vjs-volume-panel {
        margin-right: 2.5rem;
      }
    }
  }
}
