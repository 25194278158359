:root {
  --navContainer-maxHeight: 2000px;
}

.login-dropdown,
.mobile-icon-search-container,
.mobile-icon-minicart-container {
  padding: 0.9rem 0;
  height: 4rem;
  width: 4rem;
}

.page-template-homepage  {
  .header .right-actions {
    .mobile-icon-search-container {
      display: none;
    }

    .login-dropdown {
      display: block;
    }

  }
}

.header .right-actions .mobile-icon-burger-container {
  padding: 1.2rem 0;
  height: 4rem;
  width: 4rem;
  position: relative;

  .action.burger {
    top: 1.2rem;
    position: absolute;
  }
}

.navigation {
  // display: none;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  @include fullViewportHeight;
  width: 100%;

  padding: 0;
  z-index: -1;

  transition: all linear 0.5s;
  opacity: 1;
  overflow: hidden;
  max-height: 0;

  background-color: white;
}

.first-level-link span {
  transition: all ease-in 0.2s;
  opacity: 0;
  transform: translateX(-10px);
}

.menu-in {
  .navigation {
    z-index: 54;
    display: flex;
    // opacity: 0;
    // transform: translateX(0);
  }

  &.animate {
    .navigation {
      opacity: 1;
      transition: all linear 0.5s;
      max-height: var(--navContainer-maxHeight);

      .first-level-link span {
        transition: all ease-in 0.2s;
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  .close-button {
    width: 2.4rem;
    height: 2.4rem;
    &::after {
      content: '';
      transition: all ease-in-out 0.2s;
      opacity: 1;
      @include fixedSprite('close-bold');
      width: 1.2rem;
      height: 1.2rem;
      display: block;
      margin: 0 auto;
    }
  }
}

.is-sticky {
  .navigation {
    height: calc(100vh);
  }
}

.wrapper-nav {
  display: flex;
  height: calc(100svh - 48px);
  flex-direction: column;
  overflow: auto;
}
.category-nav {
  height: fit-content;
}
.services-nav {
  // flex: 0 0 auto;
  margin-top: 24px;
  .primary-menu {
    border-top: none;
  }
}

.navigation-head {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding: 1.2rem 1.6rem;
  z-index: 3;
}

.primary-menu {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow-y: scroll;
  padding: 0;
  border-top: 1px solid #eeedeb;
}

.mobile-actions {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  ul {
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
  }
}

.mobile-action {
  display: flex;
  flex-direction: row;

  border-top: none;

  span {
    margin-right: 1rem;
  }
  button,
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 16px;
    flex: 1;
    font-family: $futura-book;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.015em;
    text-align: center;
  }
}

.country-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $light-grey;
  padding: 1.3rem 1.5rem 1.4rem;
  width: 100%;

  strong {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.main-nav-link-image {
  width: 27%;
  margin: 0 16px 0 0;
  height: auto;
}

.primary-link {
  padding: 0.8rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &.has-submenu::after {
    content: '';
    @include fixedSprite('chevron-right-new');
    height: 1.4rem;
    position: absolute;
    right: 7px;
  }
}

.first-level {
  &.is-open {
    .sub-menu {
      display: flex;
      z-index: 11;
    }

    &.animate {
      .sub-menu {
        opacity: 1;
        transform: translateX(-100%);
        transition: transform ease-in-out 0.4s, opacity ease-in-out 0.5s;
      }
    }
  }
}

.sub-menu-third-level,
.sub-menu {
  position: absolute;
  width: 100%;
  height: 100svh;
  top: 0;
  left: 100%;
  transform: translateX(0);
  background-color: $white;
  display: none;
  flex-direction: column;
  opacity: 0;
  transition: transform ease-in-out 0.4s, opacity ease-in-out 0.5s;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;

  .menu-second-level-container,
  .menu-third-level-container {
    overflow: scroll;
    width: 100%;
  }
}

.sub-menu-third-level {
  .sub-menu-item .primary-link {
    font-family: $futura-book;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.015em;
    text-align: left;
  }
}

.navigation-head-sub-menu {
  background-color: $white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $black;
  padding: 12px 16px;

  .close-button {
    width: 2.4rem;
    height: 2.4rem;
    font: icon;
    &::after {
      content: '';
      @include fixedSprite('close-bold');
      width: 1.2rem;
      height: 1.2rem;
      display: block;
      margin: 0 auto;
    }
  }
}

.back-button {
  position: relative;
  text-align: center;
  width: 1.5rem;
  height: 1.5rem;

  &::before {
    content: '';
    @include fixedSprite('arrow-left-new');
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
  }
}

.sub-menu-item {
  a:not(.mobile-back) {
    padding: 0 0 16px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: none;

    &.has-submenu::after {
      content: '';
      @include fixedSprite('chevron-right-new');
      height: 1.4rem;
      position: absolute;
      right: 7px;
    }
  }
}

.mobile-back {
  border-bottom: none;
  // padding: 2rem 2rem 1.9rem;
  flex: 1;
}

.menu-banner {
  text-align: center;
  .image--tv {
    min-height: 118px;
    margin-bottom: 1.6rem;
  }
}

.sub-menu-content {
  padding: 24px 16px 24px 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: unset;

  .sub-menu-third-level {
    position: absolute;
    top: 0;
  }

  ul {
    padding: 0;
  }
}

.editorial-banner-area {
  padding: 1.6rem 1.5rem;
  flex: unset;
  order: 2;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}
.menu-banner-wrapper {
  width: calc(50% - 15px);
}

.menu-links-area {
  padding: 0;
}

.sub-menu-item {
  &.is-open {
    .sub-menu-third-level {
      display: flex;
      height: 100vh;
      // margin-top: -4.8rem;
    }

    &.animate {
      .sub-menu-third-level {
        opacity: 1;
        transform: translateX(-100%);
        transition: transform ease-in-out 0.4s, opacity ease-in-out 0.5s;
      }
    }
  }
}
