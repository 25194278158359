.header-search {
  .search-input {
    // margin: 0 auto;
    // width: 100%;
    // max-width: 144rem;

    .icon--close-mini-icon {
      right: 24px;
    }
  }
}
