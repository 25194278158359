.edit-add-address-container {
  .edit-add-address {
    .edit-address-form {
      .form-input {
        .select-icon {
          right: 33px;
        }
      }
    }
  }
}
