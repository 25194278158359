.quantity-form {
  display: flex;

  .btn--round {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    border: 1px solid $black;
    position: relative;
  }
}

%content-quantity-btn {
  content: '';
  width: 1.6rem;
  height: 0.1rem;
  background: $black;
  position: absolute;
  transform: translate(-50%, -50%);
}

.btn--minus {
  margin: 1.2rem 1.2rem;
  &::before {
    @extend %content-quantity-btn;
  }
}

.btn--plus {
  margin: 1.2rem 1.2rem;
  &::before {
    @extend %content-quantity-btn;
  }

  &::after {
    @extend %content-quantity-btn;
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

.quantity-input {
  border: none;
  background: transparent;
  width: 24px;
  text-align: center;
  margin: 0;
  @extend %body--small;
  padding: 0;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
}
