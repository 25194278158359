.payment-data-container {
  .payment-header {
    @extend %heading-medium;
    text-transform: uppercase;
  }

  .payment-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border-bottom: 1px solid $light-grey;
  }

  .card-wrapper {
    @extend %body--small;
    padding: 1.5rem 0;
    + .card-wrapper {
      border-top: 1px solid $light-grey;
    }
  }

  .card-action-link {
    cursor: pointer;
  }
}

.account-customer-care {
  display: none;
  > .content-asset {
    display: flex;
    flex-direction: column;
  }
  padding: 17px 0 0;
  margin-bottom: 0;
  @extend %body--small;
  .title-section {
    @extend %title--regular;
    margin-bottom: 15px;
  }
  .customer-care-divider {
    border-top: 1px solid $light-grey;
    padding-bottom: 20px;
  }

  .section-footer-contact {
    margin-top: 15px;
    padding-top: 15px;
  }

  .contact-us-item-wrapper {
    padding: 15px 0;
  }

  .contact-us-item {
    display: flex;
    align-items: center;
    text-decoration: none;
    & + p {
      margin-top: 15px;
    }
  }

  [class*='icon--'] {
    margin-right: 15px;
  }
}

#payment-delete {
  .modal-container {
    height: auto;
    text-align: center;
  }
  .modal-close {
    top: 1.6em;
  }
  .modal-header {
    padding: 1.6rem 2.4rem;
  }
}

.deletemodal-product {
  @extend %nav__title--hover;
  padding: 1.6rem 0;
}

.modal-payment-delete {
  .wrapper-actions {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
  }
  .modal-delete-btn {
    width: calc(50% - 10px);
  }
}
//
