.breadcrumb-component {
  text-align: center;
  .breadcrumb {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 16px 0;
    height: 22px;

    &-item {
      position: relative;
      a {
        margin-left: 0;
        margin-right: 6px;
        height: 22px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.3px;
        color: $black;
      }
      &:first-child {
        a {
          margin-left: 0;
        }
      }
      &:last-child {
        a {
          margin-right: 0;
        }
      }
      &:not(:first-child) {
        a {
          padding-left: 21px;
        }
      }
      &.last-element {
        display: none;
        a {
          height: 22px;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: 0.3px;

          color: $dark-grey;
        }
      }
      .breadcrumb-item-arrow {
        @include fixedSprite('chevron-mini-right');
        width: 8px;
        height: 8px;
        position: absolute;
        left: 0;
        top: 4px;
        transform: translate(50%, 50%);
      }
    }
  }
}

.pd-breadcrumbs-hero-internal {
  width: 100vw;
  border-top: 1px solid #e5e5e5;
  justify-content: center;

  .breadcrumb-component {
    max-width: 100%;
    display: block;
    .breadcrumb {
      justify-content: center;
      margin: 16px 0;
      border-top: none;
      flex-flow: wrap;
      height: unset;
    }
    a {
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.04em;
      color: $black;

      .last-element-text {
        display: inline-block;
        text-transform: lowercase;
      }
      .last-element-text::first-letter {
        text-transform: uppercase;
        display: block;
      }
    }
    .breadcrumb-item.last-element {
      display: none;
    }
    .breadcrumb-separator {
      display: none;
    }
  }
}
