// @import 'bootstrap/scss/variables';
@import '../../components/applepay/applepay';
.checkoutpage {
  border: 1px solid $light-grey;
  border-bottom: none;
  border-top: none;
  padding-bottom: 40px;

  #shipping-data-form,
  #payment-data-form {
    .form-control {
      .error-message {
        display: none;
      }
    }
  }

  .gift-option-container  {
    .form-control:not(.mm-form-textarea) .error-message {
      display: none;
    }
  }

  &.shipping {
    .order-summary-box-container {
      margin-top: 24px;
    }
  }
  .checkout-shipping__address {
    font-family: $futura-book;
    .checkout-shipping__address__address-book {
      &.no-addresses {
        #shipping-data-accordion-content {
          > .form-group {
            margin-top: 16px;
          }
        }
      }
      > .form-group {
        margin-top: 16px;
        .form-group__label {
          width: 125px;
          height: 23px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          color: $black;
          text-transform: uppercase;
        }
        .form-select {
          .col-1 {
            top: 2.1rem;
            .select-icon {
              right: 15px;
            }
          }
        }
      }
      .accordion {
        .address_form {
          margin-top: 0;
          &.is-active {
            margin-bottom: 16px;
          }
          #shipping-data-accordion-content {
            > .form-group {
              margin-top: 16px;
            }
          }
        }
        &-title {
          padding-bottom: 18px;
          text-transform: uppercase;
        }
        .accordion-title-icon {
          height: 1rem;
          width: 1rem;
        }
      }
      .address_form {
        .form-group {
          margin-bottom: 16px;
          .form-group__label {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            color: $black;
          }
          .form-control {
            input {
              margin: 4px 0 0;
            }
          }
        }
        .form-adress-phone-number {
          .form-group {
            margin-bottom: 0;
          }
        }
      }
      &-title {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        display: none;
      }
      #addresses {
        height: 48px;
        margin-top: 8px;
        background: transparent;
        margin-bottom: 16px;
      }
      .option-address__address-book-list {
        cursor: default;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.3px;
        color: darkgrey;
      }
    }
    .checkout-shipping__address__address-book__new {
      .address_form {
        &.is-active {
          margin-bottom: 16px;
        }
      }
      .country-wrapper {
        &:not(.select-not-disabled) .icon--chevron-mini-down {
          display: none;
        }
        &:not(.select-disabled) .country {
          .row {
            .col-1 {
              top: 1.8rem;
            }
          }
        }
        select {
          &:disabled {
            opacity: 1;
            color: $black;
          }
        }
      }
    }
    .checkout-shipping__options {
      fieldset {
        .form-group {
          .radio {
            padding-top: 16px;
            padding-bottom: 16px;
            position: relative;
            margin-bottom: 0;
            .checkout-shipping__options__option {
              &-name,
              &-cost {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.8;
                letter-spacing: 0.3px;
                color: $black;
                margin-top: 13px;
                margin-right: 5px;
                text-transform: uppercase;
              }
              &-description {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.8;
                letter-spacing: 0.3px;
                color: $dark-grey;
                margin-top: 13px;
                margin-left: -4px;
              }
            }
            &:last-child {
              .checkout-option-middle {
                display: none;
              }
            }
          }
        }
      }
    }
    .checkout-navigations {
      justify-content: space-between;
      .checkbox {
        padding-left: 12px;
        padding-top: 4px;
      }
      .proceed-to-payment {
        margin-top: 7px;
        button {
          width: 100%;
          height: 48px;
          border-radius: 25px;
          color: $white;
          background: $black;
          text-transform: uppercase;
        }
      }
    }
    .horizontal-row {
      width: calc(100% + 30px);
      border-top: 1px solid $light-grey;
      margin-left: -15px;
      margin-bottom: 16px;
      &.shipping-data {
        margin-bottom: 18px;
      }
      &.checkout-option-bottom {
        margin-top: 0;
      }
      &.checkout-option-top {
        margin-bottom: 1px;
      }
      &.checkout-option-middle {
        border-top: 1px solid $light-grey;
        right: 0;
        position: absolute;
        margin-top: 14px;
      }
      &.upper-title-row {
        margin-top: 6px;
      }
      &.checkout-navigations-bottom {
        display: none;
      }
    }
  }

  &.payment {
    .order-summary-box-container {
      margin-top: 0;
    }
    .checkout-payment {
      form {
        > .form-group {
          margin-top: 36px;
        }

        .payment-methods-title {
          height: 23px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          color: $black;
          text-transform: uppercase;
          margin-bottom: 16px;
        }

        .billingAddressForm-checkbox {
          padding-left: 12px;
          .checkbox {
            margin-bottom: 0;
          }
        }
      }
      #addresses {
        margin: 16px 0 5px 0;
      }
      .addresses-label {
        height: 23px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        color: $black;
        text-transform: uppercase;
      }

      .form-select {
        &.addresses {
          .row {
            .icon--chevron-mini-down {
              position: absolute;
              top: 15px;
            }
          }
        }
      }
      &-subtitle {
        height: 23px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 18px;
      }
      &-title {
        display: none;
      }
    }
    .form-select {
      .col-1 {
        top: 2rem;
      }
    }
    .billingAddressForm {
      .checkout-billing__address__address-book__new {
        .address_form {
          .accordion-title {
            &-text {
              height: 12px;
              margin: 0 16px 0 0;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 0.5px;
              color: $black;
              text-transform: uppercase;
            }
            &-icon {
              width: 1rem;
              height: 1rem;
              margin-top: 0;
            }
          }
          #billing-address-accordion-content {
            .col-12 {
              .form-group {
                margin-top: 18px;
                .form-adress-phone-number {
                  .col-12 {
                    margin-top: 0;
                  }
                }
              }
            }
            .checkout-navigations {
              margin-top: 18px;
            }
          }
        }
      }
      &.no-addresses {
        .checkout-billing__address__address-book__new {
          .address_form {
            #billing-address-accordion-content {
              > .row {
                .col-12 {
                  margin-top: 24px;
                  &.form-adress-phone-number {
                    .col-12 {
                      margin-top: 0;
                    }
                  }
                }
                &:first-child {
                  .col-12 {
                    &:first-child {
                      margin-top: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .form-control {
        input {
          width: 100%;
        }
      }
      .form-title {
        height: 12px;
        margin: 0 16px 0 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: black;
        text-transform: uppercase;
      }
      .phone-row-checkout {
        margin-top: 18px;
      }
    }

    .paymentmethods {
      .adyen-checkout__label__text {
        height: 12px;
        margin-bottom: 8px;
      }
      .adyen-checkout__input {
        color: $dark-grey;
        display: block;
        border-radius: 0;
        height: 48px;
        border: solid 1px $black;
      }
      .adyen-checkout__field {
        // adyen-checkout__field adyen-checkout__field--50 adyen-checkout__field--storedCard
        margin-bottom: 0;
      }
      .adyen-checkout__field--expiryDate,
      .adyen-checkout__field--securityCode,
      .adyen-checkout__label.adyen-checkout__label--disabled,
      .adyen-checkout__card__holderName {
        margin-top: 24px;
      }
      // input icon (cvv and card circuit icon)
      .adyen-checkout__card__cvc__hint__wrapper,
      .adyen-checkout__card__cardNumber__brandIcon {
        display: none;
      }

      .adyen-checkout__card__dual-branding__buttons .adyen-checkout__card__cardNumber__brandIcon {
        display: block;
      }
      //  .adyen-checkout__card__cardNumber__input,
      //  .adyen-checkout__card__exp-date__input,
      //  .adyen-checkout__card__cvc__input,
      //  .adyen-checkout__card__holderName__input {
      //    color: $dark-grey;
      //    font-size: 1em;
      //    font-family: inherit;
      //    display: block;
      //    background: #fff;
      //    border-radius: 0;
      //    height: 48px;
      //    border: solid 1px $black;
      //  }
      .checkout-shipping__address__address-book {
        &-title {
          height: 23px;
          margin: 16px 0 18px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          color: $black;
          text-transform: uppercase;
        }
        > .form-group {
          .form-group__label {
            height: 23px;
            margin: 24px 46px 16px 0;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            color: $black;
            text-transform: uppercase;
          }
        }
        .form-select {
          select {
            width: 100%;
            margin: 16px 0 0;
          }
        }
      }
      .billingAddressForm-checkbox {
        .checkbox {
          margin-bottom: 26.8px;
        }
      }
      .form-group {
        div {
          &:last-child {
            &.radio-bottom-separator {
              display: none;
            }
          }
        }
      }
    }

    .radio-container {
      width: 100%;
      .radio__icon {
        width: calc(100% - 2.4rem - 11px);
      }
    }

    .payment-method {
      margin-bottom: 0;
      position: relative;

      .payment-method-bottom {
        margin-left: 0;
      }
      &:last-child {
        .payment-method-bottom {
          margin-left: -15px;
          width: calc(100% + 30px);
        }
      }
      &:not(:first-child) {
        display: flex;
        align-items: center;
      }

      > input {
        position: absolute;
      }
      > label {
        padding-left: 0;
      }
      .radio-section-container {
        width: 100%;
      }
      .container-radio-payment {
        width: 100%;
        .radio__icon {
          display: block;
          &:not(.credit-card) {
            width: calc(100% - 2.4rem - 11px);
            .single-payment-box {
              > .row {
                align-items: center;
                .col-9 {
                  flex-basis: 25%;
                  max-width: 25%;
                  .single-payment-box-card-type {
                    div {
                      margin-bottom: 0;
                      padding: 0;
                    }
                  }
                }
                .col-3 {
                  flex-basis: 75%;
                  max-width: 75%;
                }
              }
            }
          }
          &:not(.credit-card) {
            margin-left: calc(2.4rem + 11px);
            &:parent(.parent) {
              border: 3px solid red;
            }
          }
          &.credit-card {
            .single-payment-box {
              padding-left: calc(2.4rem + 11px);
              &-name {
                margin-top: 10px;
              }

              .col-3,
              .col-9 {
                flex-basis: 50%;
                max-width: 50%;
              }
            }

            .single-payment-box-card-type-icons {
              .card-type-icon {
                height: 85% !important;
              }
            }
          }
          &.devmode {
            background-color: none;
          }
        }
      }

      .single-payment-box {
        position: relative;
        &-name {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: 0.3px;
          color: $black;
        }
        &-card-type {
          display: flex;
          flex-direction: row-reverse;
          margin-left: 40px;
          margin: 0;

          div {
            // width: calc(63px - 0.4rem);
            height: 40px;
            flex-basis: unset;
            max-width: calc(63px - 0.4rem);
            // display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            display: block;
            width: 16%;
            padding: 0;

            i {
              display: flex;
              align-items: center;
              height: 100%;
              // width: 110px;
              width: 100%;
              margin: 0;
            }
          }

          .card-type-icon {
            // margin: 0 16px;
            height: 100%;
            // width: 110px;
            width: 100%;
            margin: 0;
          }
        }
      }

      .single-payment-box-card-type-icons {
        position: absolute;
        width: 100%;
        left: 0;
        justify-content: center;
        margin-top: 8px;
      }

      .icon--payment-card-creditcardicon {
        margin: 0;
      }

      .credit-card-fields-box {
        margin-top: 65px;
        .save-payment {
          margin-top: 24px;
          margin-bottom: 12px;
        }

        &.sub-radio-group {
          .sub-radio__element {
            align-items: center;
            padding-left: 24px;
            &-img {
              width: 44px;
              max-width: 44px;
              height: 32px;
            }
            &-card-details {
              padding-left: 8px;
              width: 157px;
              max-width: 157px;
              height: 34px;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 0.5px;
              color: $black;
            }
            &-cvv {
              width: 57px;
              max-width: 57px;
              height: 32px;
              margin: 8px 0 8px 16px;
              padding: 10px 16px;
              border: solid 1px $black;
              background-color: $white;
              p {
                width: 25px;
                height: 12px;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: 0.5px;
                color: $dark-grey;
              }
            }
          }

          .payment-method {
            padding-left: 16px;
            display: flex;
            align-items: center;
            .radio-container {
              padding-left: 36px;
            }
          }
        }
      }
      .payment-option-buttons {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 48px;
        padding-right: 0;
        &:before {
          display: none;
        }
        button {
          text-decoration: underline;
          height: 12px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          margin-top: 15px;
          color: $black;
        }
      }
    }

    .horizontal-row {
      width: calc(100% + 30px);
      border-top: 1px solid $light-grey;
      margin-left: -15px;
      margin-bottom: 16px;
      &.billing-address-form-top {
        margin-top: 18.8px;
        margin-bottom: 0;
        width: 100%;
        margin-left: 0;
      }
      &.estimated-total-row-bottom {
        margin-top: 16px;
        margin-bottom: 0;
        width: calc(100% + 32px);
        margin-left: -16px;
      }
      &.order-summary-elements-top {
        width: 100%;
        margin-left: 0;
      }

      &.payment-form-bottom {
        margin-top: 16px;
      }

      &.payment-method-bottom {
        margin-top: 16px;
        width: 100%;
        margin-left: 0;
      }

      &.payment-method-group {
        margin-top: 16px;
      }

      &.radio-bottom-separator {
        margin-top: 16px;
        width: 100%;
        margin-left: 0;
      }

      &.shipping-restriction-box-bottom {
        width: 100%;
        margin-left: 0;
      }

      &.sub-radio-element-bottom {
        display: none;
        width: 100%;
        margin: 17px 0;
      }

      &.upper-billing-form {
        width: 100%;
        margin-top: 16px;
        margin-left: 0;
      }

      &.upper-title-row {
        margin-top: 16px;
        width: calc(100% + 30px);
      }
    }
  }

  &.review {
    .estimated-total-row-bottom {
      display: none;
    }
    .accordion__group {
      .step__header__title {
        display: none;
      }
    }
    .checkout-review {
      &-title {
        display: none;
      }
      .billing-review {
        margin-top: 16px;
        &-title {
          height: 22.4px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          color: $black;
          text-transform: uppercase;
        }
        .details {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: 0.3px;
          color: $black;
        }
      }
      .gift-summary-container {
        margin-top: 0;
      }
      .shipping-review {
        &-title {
          height: 22.4px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          color: $black;
          text-transform: uppercase;
        }
        .details {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: 0.3px;
          color: $black;
        }
      }
      .shipping-method-review {
        &-title {
          height: 22.4px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          color: $black;
          text-transform: uppercase;
        }
        .details {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: 0.3px;
          color: $black;
        }
      }
      .condition-summary-review {
        &-title {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          color: $black;
          text-transform: uppercase;
          margin-bottom: 16px;
        }
        &-description {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: 0.3px;
          color: $dark-grey;
        }
        .details {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: 0.3px;
          color: $black;
        }
        .tc-read-more-trigger {
          margin-bottom: 18px;
          margin-top: 26px;
          height: 12px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          text-decoration: underline;
        }
      }
      .payment-review {
        &-title {
          height: 22.4px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          color: $black;
          text-transform: uppercase;
        }
        .details {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: 0.3px;
          color: $black;
        }
        .card-expiration {
          &-label {
            margin-right: 8px;
          }
        }
        .payment-amount {
          &-label {
            margin-right: 8px;
          }
        }
      }
      .horizontal-row {
        width: calc(100% + 30px);
        border-top: 1px solid $light-grey;
        margin-left: -15px;
        margin-bottom: 16px;
        &.shipping-method-top-row {
          width: 100%;
          margin-left: 0;
          margin-top: 16px;
        }
        &.payment-method-top-row {
          width: 100%;
          margin-left: 0;
          margin-top: 16px;
        }
        &.rules-bottom-row {
          width: calc(100% + 30px);
          margin-left: -15px;
          margin-top: 16px;
          margin-bottom: 0;
        }
        &.rules-top-row {
          width: 100%;
          margin-left: 0;
          margin-top: 16px;
        }
        &.upper-title-row {
          width: calc(100% + 30px);
          margin-left: -15px;
          margin-top: 12px;
        }
      }
    }
  }

  .adyen-checkout__checkbox__input.checkbox-disabled-input {
    + span {
      cursor: default;
      color: $dark-grey;

      &::after {
        background-color: $dark-grey;
        border-color: $dark-grey;
      }
    }
  }

  .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 0;
    background-color: #fff;
    border: 1px solid $black;
    z-index: 0;
  }

  .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    color: $black;
  }

  .proceed-to-review {
    margin-bottom: 24px;
    button {
      width: 100%;
      height: 48px;
      border-radius: 25px;
      color: $white;
      background: $black;
      text-transform: uppercase;
    }
  }

  .gift-option-container {
    padding-bottom: 15px;
    .gift-option-header {
      display: flex;
      align-items: center;
      .gift-option-icon {
        margin-right: 8px;
        span {
          width: 24px;
          height: 24px;
        }
      }
      .gift-option-title {
        font-size: 14px;
        text-transform: uppercase;
      }
    }
    .gift-option-subheader {
      margin-top: 8px;
      .gift-option-radios {
        .form-group {
          display: flex;
          margin-top: 28px;
          .radio {
            &:first-child {
              margin-right: 52px;
            }
          }
        }
      }
    }
    .gift-option-fields {
      display: none;
      .gift-input-field {
        position: relative;
        label {
          font-size: 12px;

          &.giftTo-label + .form-control {
            padding-right: 4rem;
          }
          &.labelforGifto {
            position: absolute;
            top: 4.5rem;
            right: 1.2rem;
          }
        }
        input,
        textarea {
          width: 100%;
        }
        textarea {
          width: 100% !important;
          height: 100% !important;
        }
        &.textarea-field {
          margin-top: 8px;
          > .form-group {
            &.is-invalid {
              textarea {
                border-color: $status_error;
              }
              .form-input {
                padding-bottom: 0;
              }
              
              #bouncer-error_giftMessage {
                display: none;
              }
            }
          }
        }
      }

      input,
      textarea {
        &.error {
          border-color: $status_error;
        }
      }
      .error-message {
        color: $status_error;
        margin-top: 8px;
        font-family: 'futura-pt', sans-serif;
        font-size: 1.2rem;
        line-height: 2.2rem;
      }
    }
  }

  .voucher-wrapper {
    padding-top: 2px;
    padding-bottom: 10px;
    .voucher-accordion {
      padding-right: 20px;
      .accordion-mini__header {
        font-size: 16px;
        text-transform: uppercase;
        text-align: left;
      }
      .coupon-code {
        padding-top: 2px;
      }
    }
  }
}

.shipping-accordion {
  border: 1px $light-grey;
}

#addressbookid {
  > div {
    &:not(:last-child) {
      height: 80px;
      width: calc(100% + 32px);
      padding: 24px 0;
      margin-left: -16px;
      margin-left: -16px;
      &.radio-item-checked {
        background: $light-grey;
      }
      &:not(.radio-item-checked) {
        width: calc(100% + 32px);
        padding: 24px 0;
        display: flex;
        align-items: center;
      }
    }
  }
}

.payment-method {
  > input {
    top: 8px;
  }
}

.devmode {
  background-color: transparent;
}

.order-summary-box-container {
  margin-top: 24px;
  .accordion {
    .js-accordion-trigger {
      display: flex;
      justify-content: space-between;
      width: 100%;
      &-title {
        height: 48px;
        padding: 18px 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        text-transform: uppercase;
      }
    }
  }
  .order-summary-box-info-text {
    .js-accordion-trigger {
      height: 48px;
      padding: 18px 0;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.5px;
      color: $black;
      text-transform: uppercase;
    }
  }
  .order-summary-box {
    &-title {
      justify-content: space-between;
      display: flex;
      width: 100%;
      &-text {
        height: 48px;
        padding: 18px 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        text-transform: uppercase;
      }
      &-icon {
        margin-top: 1.8rem;
      }
    }
    &-info {
      &-icon {
        margin-top: 1px;
        max-width: 20px;
        width: 20px;
      }
    }
  }

  .horizontal-row {
    width: calc(100% + 30px);
    border-top: 1px solid $light-grey;
    margin-left: -15px;
    margin-bottom: 16px;
    &.estimated-total-row-bottom {
      margin-top: 16px;
    }
    &.order-summary-elements-top {
      margin-bottom: 16px;
      margin-top: 16px;
    }
  }

  .shipping-restriction {
    margin-bottom: 16px;
    &-title {
      justify-content: space-between;
      display: flex;
      width: 100%;
      &-text {
        height: 48px;
        padding: 18px 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        text-transform: uppercase;
      }
      &-icon {
        margin-top: 1.8rem;
      }
    }
  }

  .customer-care {
    &-title {
      justify-content: space-between;
      display: flex;
      width: 100%;
      &-text {
        height: 48px;
        padding: 18px 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        text-transform: uppercase;
      }
      &-icon {
        margin-top: 1.8rem;
      }
    }
  }

  .return-and-services {
    &-title {
      justify-content: space-between;
      display: flex;
      width: 100%;
      &-text {
        height: 48px;
        padding: 18px 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        text-transform: uppercase;
      }
      &-icon {
        margin-top: 1.8rem;
      }
    }
  }

  .bag-review {
    &-title {
      height: 26px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $black;
      text-transform: uppercase;
      margin-bottom: 18px;
    }
    .bag-product {
      .product-info {
        height: 160px;
        &-img {
          width: 120px;
          max-width: 120px;
          margin-right: 8px;
          padding: 0;
          > div {
            width: 120px;
          }
        }
        .bag-product__content {
          max-width: 100%;
          .bag-product__name {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            color: $black;
          }
          .bag-product__details {
            > .row {
              margin-top: 8px;
              &:first-child {
                margin-top: 16px;
              }
              .bag-product__details-name {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.8;
                letter-spacing: 0.3px;
                color: $black;
                text-transform: uppercase;
              }
              .col-6 {
                &:last-child {
                  display: flex;
                  justify-content: flex-end;
                  .bag-product__details-value {
                    height: 22px;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.8;
                    letter-spacing: 0.3px;
                    color: $black;
                  }
                }
              }
            }
            .bag-product__details-quantity {
              margin-top: 8px;
              > .row {
                .col-6 {
                  &:last-child {
                    display: flex;
                    justify-content: flex-end;
                    .bag-product__details-quantity__value {
                      height: 21.6px;
                      font-size: 12px;
                      font-weight: normal;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.8;
                      letter-spacing: 0.3px;
                      color: $black;
                    }
                  }
                }
              }
              .bag-product__details-quantity__label {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.8;
                letter-spacing: 0.3px;
                color: $black;
                text-transform: uppercase;
              }
            }
          }
        }
      }
      .shipping-and-price {
        margin-top: 16px;
        .bag-product__shipping {
          &-text {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: 0.3px;
            color: #096a0e;
            text-transform: uppercase;
            .available-days-additional-text {
              color: $black;
              display: block;
              text-transform: lowercase;
              &:first-letter {
                text-transform: uppercase;
              }
            }
          }
        }
        .bag-product__price {
          display: flex;
          justify-content: flex-end;
          &-text {
            height: 22.4px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            color: $black;
            text-align: right;
            del {
              display: block;
              color: $dark-grey;
            }
            &.previous-price {
              color: $dark-grey;
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }

  .pickup-in-store {
    &-title {
      height: 26px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $black;
      text-transform: uppercase;
    }
    &-location {
      margin-bottom: 24px;
      margin-top: 16px;
      .row {
        .col {
          height: 12px;
          display: flex;
          align-items: center;
          .icon--pin {
            height: 12px;
            width: 8px;
            margin-right: 10px;
          }
          .shop {
            height: 12px;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.5px;
            color: $black;
          }
        }
      }
    }
    .bag-review {
      width: 100%;
      .bag-product {
        .shipping-and-price {
          .bag-product__shipping {
            &-text {
              display: none;
            }
          }
        }
      }
    }
  }

  .details-box-upper-row {
    display: none;
  }

  .details-box {
    background: $light-grey;
    margin-bottom: 16px;
    margin-left: -15px;
    margin-top: 16px;
    padding: 8px 15px;
    width: calc(100% + 30px);

    .section-title {
      display: none;
    }

    .items__subtotal {
      margin: 8px 0;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      color: $black;
      &-cost {
        display: flex;
        justify-content: flex-end;
        .spedizione {
          color: #096a0e;
          text-transform: uppercase;
        }
        .gratuita {
          color: #096a0e;
          text-transform: uppercase;
        }
      }
      &-description {
        .items__label {
          display: flex;
          align-items: center;
          .tooltip-global-container {
            .tooltip-toggler {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  .estimated-total-row {
    &-title {
      p {
        height: 22.4px;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
    &-price {
      p {
        height: 22.4px;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        text-transform: uppercase;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.cvv-expiration-fields {
  #component-container {
    margin: 7rem 0 2rem 0;
    .adyen-checkout__field--storedCard {
      position: absolute;
      top: -65px;
      width: 57px;
      height: 32px;
      background-color: $white;
      .adyen-checkout__label__text {
        display: none;
      }
      .adyen-checkout__input-wrapper {
        .adyen-checkout__card__cvc__input {
          height: 32px;
          padding: 7px 10px;
        }
        .adyen-checkout-input__inline-validation {
          position: absolute;
          right: 2px;
          top: 14px;
        }
      }
      &:not(.adyen-checkout__field--securityCode) {
        display: none;
      }
    }
  }
  &.shared-component-adyen {
    .adyen-checkout__field--storedCard {
      position: static !important;
      width: 90px !important;
    }
  }
}

.button-and-terms-conditions {
  margin-top: 16px;
  .component-container-button {
    button {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 2px;
      width: 100%;
      height: 48px;
      border-radius: 25px;
      color: $white;
      background: $black;
      text-transform: uppercase;
    }
  }

  #terms-and-conditions {
    margin-bottom: 16px;
    margin-top: 14px;
    p {
      height: 22px;
      margin: 0 0 4px;
      opacity: 0.8;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      color: $dark-grey;
    }
  }
}

.condition-summary-review-description {
  .read-more-target {
    display: none;
  }
  &.expanded {
    .read-more-target {
      display: block;
    }
  }
}

.stepper-container {
  .default-steps-container {
    display: flex;
  }
  .desktop-steps-container {
    display: none;
  }
}

.method-deliveryTimeslot {
  width: 100%;
  .timeslot-selector {
    margin-left: -39px;
    margin-top: 16px;
    width: calc(100% + 39px);
  }
}

.timeslot-field {
  .form-group {
    margin-bottom: 24px;
  }
  .form-select {
    width: 100%;
    height: 48px;
    appearance: none;
    + .select-icon {
      position: absolute;
      transform: translateY(28px);
      pointer-events: none;
    }
  }
}

.checkoutpage {
  .review-summary-box {
    width: 100%;
  }

  .summary-row {
    .place-order-bottom {
      width: 100%;
    }
  }
}

.top-margin-expanded {
  margin-top: 60px !important;
}

.globalerrors.invalid-feedback {
  margin-top: 50px;
}

.apple-pay-enabled {
  .applepay-tab-wrapper,
  .payment-options-container .payment-option-item[data-method-id='DW_APPLE_PAY'] {
    display: block;
  }
}
