//spacings
$space-unit: 0.4rem;
$space-line: 1px;
$header-height-desktop: 5.6rem;
$header-height-mobile: 6rem;
$footer-clientservice-height-desktop: 31.6rem; // arbitrary height, could vary depending on content
$footer-clientservice-height-mobile: 57rem; // arbitrary height, could vary depending on content
$ticker-bar: 3.2rem;
$gradient-height: 12rem;
$spacing-multiplier: (1, 2, 3, 4, 5, 6, 7, 8, 12, 16, 20, 30);

//marni
$spacing-xs: 8px;
$spacing-s: 16px;
$spacing-m: 24px;
$spacing-l: 32px;
$spacing-xl: 40px;

$v-spacing-xs: 8px;
$v-spacing-s: 16px;
$v-spacing-m: 24px;
$v-spacing-l: 32px;
$v-spacing-xl: 40px;
$v-spacing-xxl: 80px;
