.modal-size-guidelines {
  &.container-size-guidelines {
    .modal-header {
      padding: 0;
      height: 0;
    }

    .modal-content {
      overflow-x: hidden;
      .modal-content-gudielines {
        .fitguide__container--desktop {
          .fitguide__container {
            // display: flex;
            border-top: solid 1px $light-grey;
            // justify-content: space-between;
            // justify-content: center;
            .tab-button {
              align-items: center;
              display: none;
              height: 54px;
              justify-content: center;
              flex: none;
              button {
                border: solid 1px $light-grey;
                border-top: none;
                height: 100%;
                width: 100%;
                color: $dark-grey;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                letter-spacing: 1px;
              }
              &:first-child {
                padding-right: 0;
              }
              &:nth-child(2) {
                padding-left: 0;
              }
              &:not(:last-child) &:not(:first-child) {
                padding-left: 0;
                button {
                  border-right: 1px solid $light-grey;
                  border-bottom: 0;
                }
              }
            }
          }
          #fitguide-content-01 {
            .fitguide__interactive {
              .choose-size-radio-input-row {
                display: flex;
                align-items: flex-end;
                flex-direction: row-reverse;
                max-width: 500px;
                padding-top: 15px;
                .measuring-guide__radio-input {
                  padding-bottom: 10px;
                  .form-group {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 0;
                    padding-top: 0;
                    align-items: center;
                    .radio {
                      margin-bottom: 0;
                    }
                  }
                }
                .choose-size-and-unit-measure {
                  .choose-size-label {
                    height: 12px;
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: 0.5px;
                    color: $black;
                  }
                  #choose-your-size {
                    width: 100%;
                  }
                }
              }

              .horizontal-row {
                border-top: 1px solid $light-grey;
                margin: 16px 0;
                &.choose-your-size-bottom {
                  margin-bottom: 0;
                  width: calc(100% + 48px);
                  margin-left: -24px;
                }
                &.measuring-size-table-bottom {
                  width: calc(100% + 48px);
                  margin-left: -24px;
                }
                &.measuring-img-bottom {
                  width: calc(100% + 48px);
                  margin-left: -24px;
                  margin-top: 0;
                }
              }

              .measuring-guide__size-table {
                align-items: center;
                display: flex;
                padding-left: 16px;
                .table-details {
                  &-label {
                    height: 22px;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.8;
                    letter-spacing: 0.3px;
                    color: $black;
                    text-transform: uppercase;
                  }
                  &-value {
                    height: 22px;
                    margin: 0 0 0 16px;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.8;
                    letter-spacing: 0.3px;
                    color: $dark-grey;
                  }
                  &:first-child {
                    margin-top: 16px;
                  }
                }
              }

              .measuring-img__sketches {
                align-items: center;
                display: flex;
              }

              .click-to-convert-sizes {
                &-text {
                  height: 12px;
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: 0.5px;
                  color: $black;

                  a {
                    text-decoration: underline;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          #fitguide-content-02 {
            display: none;
            overflow-x: hidden;
            .measuring-guide {
              .measuring-guide-content {
                overflow-x: auto;
                .table {
                  margin: 60px 0 0;
                  thead {
                    tr {
                      th {
                        text-align: center;
                        height: 26px;
                        font-size: 16px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.6;
                        letter-spacing: 1px;
                        color: $black;
                        text-transform: uppercase;
                      }
                    }
                  }
                  tbody {
                    tr:nth-child(odd) {
                      background-color: #eeedeb;
                    }
                    tr {
                      td {
                        padding: 0px;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: 1px;
                        color: $dark-grey;
                        text-transform: uppercase;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .modal-footer-fixed {
      position: absolute;
      bottom: 15px;
    }

    .modal-footer {
      border-top: none;
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
      width: 100%;
      .button-phone-modal {
        margin-top: 0;
        &-icon {
          width: 10px;
          height: 12px;
          margin-right: 9px;
        }
        &-text {
          height: 12px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          color: $black;
        }
      }

      .phone-measure-row {
        display: flex;
      }
    }
  }
}

.measure-taken-by-product {
  &-icon {
    height: 12px;
    width: 12px;
    margin-right: 8px;
  }
  &-text {
    height: 12px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    color: $dark-grey;
  }
}

#size-guidelines-modal {
  .modal-container {
    max-width: 800px;
    max-height: 600px;
    overflow: hidden;
    overflow-y: scroll;
  }
  .modal-close {
    top: 15px;
    right: 15px;
  }
}
