.mobile-order {
  display: none;
}
.desktop-order {
  display: flex;
  .js-dropdown-content {
    padding: 8px;
    position: absolute;
    margin-top: 5px;
    right: 0;
    .order-by-radios {
      .radio {
        height: 22px;
        margin-bottom: 0;
        margin: 8px;
      }
    }
  }
}
