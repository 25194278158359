.error-page-404 {
  .container {
    max-width: 77vw;
  }
  .desktop-customer-care {
    margin: 0;
  }

  .error-404-buttons {
    .go-to-home {
      margin-bottom: 0;
      margin-right: 24px;
      padding-right: 0;
      max-width: 293px;
      .btn {
        max-width: 100%;
        padding: 16px 0;
        width: 100%;
      }
    }
    .client-service {
      padding: 0;
      max-width: 293px;
      .btn {
        max-width: 100%;
        padding: 16px 0;
        width: 100%;
      }
    }
    .error-button-action {
      max-width: 185px;
    }
  }

  .error-404-header {
    img {
      height: 200px;
    }
  }

  .error-404-title {
    margin: 0 auto;
    .col {
      padding: 0;
    }
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.48px;
  }

  .recommendations {
    margin-top: 0;
    width: 70%;
    margin-left: 30%;
    padding: 40px 0 10px 44px;
    border-left: 1px solid $light-grey;
    z-index: 23;
    position: relative;
    border-top: none;

    .einstein-carousel {
      padding: 0;
    }

    .product-carousel-section {
      padding: 0;
    }

    .swiper-header {
      text-align: left;
      margin: 0;
      text-transform: uppercase;
      font-size: 16px;
      font-family: $futura-book;
      font-weight: 450;
    }

    .swiper-button-prev {
      top: -35px !important;
      left: unset;
      right: 49px;
      background: none;
      width: fit-content;
    }

    .swiper-button-next {
      top: -35px !important;
      background: none;
      width: fit-content;
    }

    .swiper-button-prev:after {
      color: $black;
      font-size: 20px;
    }
    .swiper-button-next:after {
      color: $black;
      font-size: 20px;
      transform: rotate(180deg);
    }

    .experience-assets-product {
      // width: 25% !important;

      .product.swiper-slide {
        width: 100%;
      }
    }
  }
}

.error-page-recommendations {
  .product-carousel-section {
    padding: 0 !important;
  }

  .einstein-carousel {
    .product-carousel-section {
      .swiper-wrapper {
        padding-bottom: 3rem;
      }
      .swiper-button-prev {
        top: -25px !important;
        display: block;
        left: unset;
        right: 49px;
        background: none;
        width: fit-content;
      }

      .swiper-button-next {
        top: -25px !important;
        display: block;
        background: none;
        width: fit-content;
        transform: none;
      }

      .swiper-button-prev:after {
        color: $black;
        font-size: 20px;
      }
      .swiper-button-next:after {
        color: $black;
        font-size: 20px;
      }
    }
  }
}

.main--layout-page {
  &.error-404 {
    padding-bottom: 0;
  }
}
