.checkoutpage {
  padding-left: 0;
  .checkout-shipping__address {
    .checkout-shipping__options {
      fieldset {
        .form-group {
          .radio {
            padding-left: 0;
          }
          .horizontal-row {
            &.checkout-option-bottom {
              display: block;
              margin-top: 5px;
              margin-left: -50px;
              width: calc(100% + 94px);
            }
          }
        }
      }
      .gift-option-fields {
        .gift-input-field {
          background-color: #096a0e;
          label {
            &.giftTo-label + .form-control {
              padding-right: 0;
            }
            &.labelforGifto {
              right: 0;
            }
          }
        }
      }
    }
    .horizontal-row {
      &.upper-title-row {
        margin-left: -50px;
        width: calc(100% + 96px);
      }
      &.shipping-data {
        margin-left: -50px;
        width: calc(100% + 94px);
      }
      &.pickup-checkout-address-bottom {
        margin-left: -50px;
        width: calc(100% + 94px);
      }
      &.checkout-option-bottom {
        margin-left: -50px;
        width: calc(100% + 94px);
      }
      &.checkout-option-top {
        margin-left: -50px;
        width: calc(100% + 94px);
      }
      &.checkout-navigations-bottom {
        display: block;
        margin-left: -50px;
        width: calc(100% + 94px);
      }
    }
  }
  .order-summary-box {
    padding-left: 39px;
    padding-right: 16px;
    &-title {
      margin-top: 10px;
    }
    &-info {
      &-text {
        p {
          width: 100%;
        }
      }
    }
  }

  &.payment {
    .checkout-payment {
      form {
        > .form-group {
          margin-top: 24px;
        }
        .billingAddressForm-checkbox {
          padding-left: 12px;
          &.no-addresses {
            padding-left: 0;
          }
        }
      }
      #addresses {
        margin: 16px 0 0 0;
      }
      .addresses-label {
        height: 23px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        color: $black;
        text-transform: uppercase;
      }
      .horizontal-row {
        width: calc(100% + 30px);
        border-top: 1px solid $light-grey;
        margin-left: -15px;
        margin-bottom: 16px;

        &.upper-title-row {
          margin-top: 40px;
          margin-left: -50px;
          width: calc(100% + 96px);
        }
      }
      &-title {
        display: block;
        height: 39px;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1.5px;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 24px;
      }
      &-subtitle {
        height: 23px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 16px;
        padding-left: 12px;
        &.no-addresses {
          padding-left: 0;
        }
      }
    }
    .payment-method {
      .container-radio-payment {
        width: 100%;
      }
      .radio__icon {
        margin-right: 0;
        &.credit-card {
          .single-payment-box {
            margin-left: 0;
            padding-right: 17px;
            .col-3 {
              padding-left: 16px;
            }
            &-card-type {
              margin-right: -6px;
            }
          }
        }
        &:not(.credit-card) {
          width: 100%;
          .single-payment-box {
            margin-left: 0;
            .col-3 {
              padding-left: 16px;
            }
            &-card-type {
              margin-right: -8px;
            }
          }
        }
      }
      &:not(:last-child) {
        .payment-method-bottom {
          margin-left: 0;
          width: calc(100% + 44px);
        }
      }
      &:last-child {
        .payment-method-bottom {
          margin-left: 0;
          width: calc(100% + 44px);
        }
      }
      .credit-card-fields-box {
        &.sub-radio-group {
          .radiobox {
            .radiobox__option {
              button {
                margin-top: 10px;
                margin-right: -12px;
              }
            }
          }
        }
      }
    }

    .billingAddressForm {
      .checkout-billing__address__address-book__new {
        .address_form {
          .accordion-title {
            &-text {
              margin-top: 0;
            }
            &-icon {
              margin-top: 0;
            }
          }
          .checkout-navigations {
            .checkbox {
              margin-bottom: 0;
            }
          }
        }
      }
      .form-title {
        height: 12px;
        margin: 0 16px 0 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: black;
        text-transform: uppercase;
      }
      &.no-addresses {
        .checkout-billing__address__address-book__new {
          .address_form {
            #billing-address-accordion-content {
              > .row {
                &.add-to-address {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .paymentmethods {
      .checkout-shipping__address__address-book {
        &-title {
          height: 23px;
          margin: 16px 0 18px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          color: $black;
          text-transform: uppercase;
        }
        > .form-group {
          .form-group__label {
            height: 23px;
            margin: 24px 46px 16px 0;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            color: $black;
            text-transform: uppercase;
          }
        }
      }
      .billingAddressForm-checkbox {
        .checkbox {
          margin-bottom: 26.8px;
        }
      }
      .horizontal-row {
        width: calc(100% + 30px);
        border-top: 1px solid $light-grey;
        margin-left: -15px;
        margin-bottom: 16px;
        &.payment-form-bottom {
          margin-top: 24px;
          width: calc(100% + 44px);
          margin-left: 0;
        }
        &.upper-title-row {
          margin-top: 16px;
          margin-left: -50px;
          width: calc(100% + 96px);
        }
      }
    }
    .form-select {
      select {
        width: 100%;
        margin: 16px 0 0;
      }
      .col-1 {
        top: 3rem;
      }
    }
    .horizontal-row {
      width: calc(100% + 30px);
      border-top: 1px solid $light-grey;
      margin-left: -15px;
      margin-bottom: 16px;
      &.billing-address-form-top {
        width: calc(100% + 20px);
        margin-left: 0;
        margin-bottom: 0;
        margin-top: 24px;
      }
      &.details-box-upper-row {
        width: calc(100% + 27px);
        margin-left: -39px;
      }
      &.estimated-total-row-bottom {
        width: calc(100% + 27px);
        margin-left: -39px;
      }
      &.order-summary-elements-top {
        width: calc(100% + 27px);
        margin-left: -39px;
        margin-bottom: 24px;
        margin-top: 24px;
      }
      &.shipping-restriction-box-bottom {
        width: calc(100% + 20px);
        margin-left: -20px;
      }
      &.upper-billing-form {
        margin-left: 0;
        margin-top: 24px;
        margin-bottom: 18px;
        width: calc(100% + 44px);
      }
      &.sub-radio-element-bottom {
        width: 100%;
        margin: 17px 0;
        margin-left: 0;
      }
    }
  }

  &.review {
    .checkout-review {
      .horizontal-row {
        &.shipping-method-top-row {
          margin-left: 0;
          width: calc(100% + 44px);
        }
        &.payment-method-top-row {
          margin-left: 0;
          width: calc(100% + 44px);
        }
        &.rules-top-row {
          margin-left: 0;
          width: calc(100% + 44px);
        }
        &.rules-bottom-row {
          margin-left: 0;
          width: calc(100% + 44px);
        }
        &.upper-title-row {
          width: calc(100% + 44px);
        }
      }
    }
  }

  .shipping-form-data {
    padding-right: 44px;
    padding-left: 58px;
  }

  #addressbookid {
    > div {
      &:not(:last-child) {
        height: 80px;
        width: calc(100% + 68px);
        padding: 24px 0;
        margin-left: -12px;
        padding-left: 24px;
        &.radio-item-checked {
          background: $light-grey;
        }
        &:not(.radio-item-checked) {
          width: calc(100% + 68px);
          padding: 24px 0;
          padding-left: 24px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.sub-radio__element-card-details {
  display: flex;
  flex-basis: 58.33333%;
  max-width: 24.33333%;
  align-items: center;
}

.order-summary-box-container {
  padding-left: 39px;
  padding-right: 44px;
  .order-summary-box {
    padding: 0;
    &-title {
      justify-content: space-between;
      display: flex;
      width: 100%;
      &-text {
        height: 38.4px;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1.5px;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 24px;
      }
      &-icon {
        margin-top: 1.8rem;
        display: none;
      }
    }
    .order-summary-box-content {
      .order-summary-box-info {
        margin-right: 0;
        &icon {
          margin-top: 1px;
          max-width: 20px;
          width: 20px;
        }
      }
    }
  }

  .order-summary-box-info-text {
    padding-right: 0;
    > .row {
      margin-right: 0;
      .section-footer-box {
        padding-left: 0;
      }
    }
  }

  .accordion {
    margin-left: 0;
    .order-summary-box-review {
      padding-left: 0;
    }
  }

  .bag-review-container {
    padding-right: 0;
    .bag-review-title {
      display: block;
      padding: 0;
      margin-bottom: 24px;
      margin-top: 16px;
    }

    .shipping-restriction {
      > .row {
        margin-right: 0;
      }
      margin-bottom: 16px;
      padding: 0;
      &-title {
        display: none;
      }
    }
  }

  .customer-care {
    padding: 0;
    margin-bottom: 58px;
    &-title {
      justify-content: space-between;
      display: flex;
      width: 100%;
      &-text {
        height: 48px;
        padding: 18px 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        text-transform: uppercase;
      }
      &-icon {
        margin-top: 1.8rem;
      }
    }
  }

  .return-and-services {
    padding: 0;
    &-title {
      justify-content: space-between;
      display: flex;
      width: 100%;
      &-text {
        height: 48px;
        padding: 18px 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        text-transform: uppercase;
      }
      &-icon {
        margin-top: 1.8rem;
      }
    }
  }

  .bag-review {
    &-title {
      height: 26px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $black;
      text-transform: uppercase;
    }

    .bag-product {
      .product-info {
        height: 160px;
        margin-left: 0;
        &-img {
          height: 100%;
          > div {
            width: 143px;
            height: 191px;
          }
        }
        .bag-product__content {
          .bag-product__name {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            color: $black;
          }
          .bag-product__details {
            > .row {
              margin-top: 8px;
              &:first-child {
                margin-top: 16px;
              }
              .bag-product__details-name {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.8;
                letter-spacing: 0.3px;
                color: $black;
                text-transform: uppercase;
              }
              .col-6 {
                &:last-child {
                  display: flex;
                  justify-content: flex-start;
                  .bag-product__details-value {
                    height: 22px;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.8;
                    letter-spacing: 0.3px;
                    color: $black;
                  }
                }
              }
            }
            .bag-product__details-quantity {
              margin-top: 8px;
              > .row {
                .col-6 {
                  &:last-child {
                    display: flex;
                    justify-content: flex-start;
                    .bag-product__details-quantity__value {
                      height: 21.6px;
                      font-size: 12px;
                      font-weight: normal;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.8;
                      letter-spacing: 0.3px;
                      color: $black;
                    }
                  }
                }
              }
              .bag-product__details-quantity__label {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.8;
                letter-spacing: 0.3px;
                color: $black;
                text-transform: uppercase;
              }
            }
          }
        }
      }
      .shipping-and-price {
        margin-top: 16px;
        .bag-product__shipping {
          &-text {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: 0.3px;
            color: #096a0e;
            text-transform: uppercase;
          }
        }
        .bag-product__price {
          display: flex;
          justify-content: flex-end;
          &-text {
            height: 22.4px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            text-align: right;
            color: $black;
          }
        }
      }
    }
  }

  .horizontal-row {
    &.details-box-upper-row {
      width: calc(100% + 24px);
      margin-left: -39px;
      margin-bottom: 8px;
    }
    &.estimated-total-row-bottom {
      width: calc(100% + 71px);
      margin-left: -39px;
      margin-top: 24px;
    }
    &.order-summary-elements-top {
      width: calc(100% + 24px);
      margin-left: -39px;
      margin-bottom: 16px;
      margin-top: 16px;
    }
  }

  .pickup-in-store {
    padding: 0;
    &-title {
      height: 26px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $black;
      text-transform: uppercase;
    }
    &-location {
      margin-bottom: 24px;
      margin-top: 16px;
      .row {
        .col {
          height: 12px;
          display: flex;
          align-items: center;
          .icon--pin {
            height: 12px;
            width: 8px;
            margin-right: 10px;
          }
          .shop {
            height: 12px;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.5px;
            color: $black;
          }
        }
      }
    }
    .bag-review {
      width: 100%;
      .bag-product {
        .shipping-and-price {
          .bag-product__shipping {
            &-text {
              display: none;
            }
          }
        }
      }
    }
  }

  .details-box {
    margin-bottom: 16px;
    margin-left: -39px;
    margin-top: 16px;
    padding-top: 8px;
    padding-left: 39px;
    padding-right: 35px;
    width: calc(100% + 63px);
    > .row {
      padding-right: 0;
    }
  }

  .estimated-total-row {
    padding: 0;
    margin-right: 0;
    &-title {
      p {
        height: 22.4px;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
    &-price {
      p {
        height: 22.4px;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        text-transform: uppercase;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.cvv-expiration-fields {
  #component-container {
    .adyen-checkout__field--storedCard {
      top: -56px;
      left: -8px;
    }
  }
  &.shared-component-adyen {
    .adyen-checkout__field--storedCard {
      position: static !important;
      width: 90px !important;
    }
  }
}

.review-summary-box {
  &.box-inside-form {
    display: none;
  }
  &.outer {
    .accordion {
      .order-summary-box-review {
        .js-accordion-trigger {
          padding: 0;
        }
        .order-summary-box-info {
          padding: 0;
        }
      }
      .js-accordion-trigger-title {
        padding: 0;
        margin-top: 22px;
        margin-bottom: 0;
      }
    }
    .order-summary-box-info {
      margin-top: 24px;
    }
  }
  .order-summary-box-container {
    .accordion {
      .order-summary-box-review {
        .js-accordion-trigger {
          padding: 0;
        }
        .order-summary-box-info {
          padding: 0;
        }
      }
      .js-accordion-trigger-title {
        padding: 0;
        margin-top: 22px;
        margin-bottom: 0;
      }
    }
    .bag-review-container {
      .bag-review-title {
        padding: 0;
      }

      .shipping-restriction {
        margin-bottom: 16px;
        padding: 0;
      }
    }

    .horizontal-row {
      &.estimated-total-row-bottom {
        display: block;
        width: calc(100% + 27px);
      }
      &.details-box-upper-row {
        width: calc(100% + 27px);
      }
      &.order-summary-elements-top {
        width: calc(100% + 27px);
      }
    }

    .details-box {
      margin-bottom: 0;
      margin-left: -24px;
      margin-top: 0;
      padding-left: 24px;
      padding-right: 36px;
      padding-top: 8px;
      width: calc(100% + 40px);
    }
    .estimated-total-row {
      padding: 0;
    }
  }
}

.button-and-terms-conditions {
  margin-top: 24px;
  .place-order-top & {
    #terms-and-conditions {
      margin-bottom: -6px;
    }
  }
  .place-order-bottom & {
    margin-bottom: -6px;
    margin-top: 6px;
  }
}
