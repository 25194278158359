$base_margin: 4px;

.pd-desktop-spacing-01 {
  height: $base_margin;
}
.pd-desktop-spacing-02 {
  height: $base_margin * 2;
}
.pd-desktop-spacing-03 {
  height: $base_margin * 3;
}
.pd-desktop-spacing-04 {
  height: $base_margin * 4;
}
.pd-desktop-spacing-05 {
  height: $base_margin * 6;
}
.pd-desktop-spacing-06 {
  height: $base_margin * 8;
}
.pd-desktop-spacing-07 {
  height: $base_margin * 10;
}
.pd-desktop-spacing-08 {
  height: $base_margin * 12;
}
.pd-desktop-spacing-09 {
  height: $base_margin * 16;
}
.pd-desktop-spacing-10 {
  height: $base_margin * 20;
}
.pd-desktop-spacing-11 {
  height: $base_margin * 24;
}
.pd-desktop-spacing-12 {
  height: $base_margin * 40;
}