.pd-page__content {
  z-index: 0;
  overflow: hidden;
  & > * {
    // margin: $base_margin 0;
  }
}
.pd-debug__preview {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  overflow: scroll;
}
.pd-debug__link {
  display: inline-block;
  width: auto !important;
  height: auto !important;
  min-width: 500px;
}

.experience-main {
  z-index: 0;
  overflow: clip;
}

.bottom-default-recommendations {
  .icon--arrow-back-black-default {
    background: none;
  }

  .swiper-heading-wrapper .swiper-header {
    font-family: $futura-book;
    text-align: left;
    font-size: 16px;
    font-weight: 450;
    margin-left: 10px;
  }

  .einstein-carousel .product-carousel-section .swiper-button-next {
    top: -32px !important;
  }
  .einstein-carousel .product-carousel-section .swiper-button-prev {
    top: -34px !important;
  }

  .swiper-button-prev {
    left: 95% !important;
    top: -40px !important;
  }

  .swiper-button-next:after {
    transform: rotate(180deg);
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 21px;
    color: black;
  }

  .product-link {
    font-family: $futura-book;
    font-size: 14px;
    font-weight: 400;
  }

  .price {
    display: none;
  }
}
