.tooltip-global-container {
  align-items: center;
  display: flex;
  left: 8px;
  position: relative;
  width: min-content;

  .tooltip-toggler {
    // display: block;
    margin-top: 2px;
    height: 12px;
    width: 12px;
    position: relative;
    z-index: 1;

    i {
      position: absolute;
    }
  }

  .tooltip-box {
    bottom: 27px;
    display: none;
    left: -16px;
    position: absolute;
    &.visible {
      display: block;
    }
    .tooltip {
      background-color: $white;
      border: 1px solid $light-grey;
      border-radius: 4px;
      box-shadow: 0 0 8px -1px $light-grey;
      height: auto;
      width: 200px;
      -moz-box-shadow: 0 0 8px -1px $light-grey;
      -webkit-box-shadow: 0 0 3px -1px $light-grey;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;

      .tooltip-content {
        color: $dark-grey;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        font-weight: normal;
        letter-spacing: 0.3px;
        line-height: 1.5;
        padding: 16px;
      }

      .tooltip-tail {
        border: 10px solid;
        border-color: transparent $white transparent transparent;
        height: 0;
        left: 12px;
        position: absolute;
        top: calc(100% - 5px);
        transform: rotate(270deg);
        width: 0;
      }
    }
  }
}

.tooltip-container {
  color: $dark-grey;
  font-size: 12px;
  > .tooltip-box {
    &:not(.inner-tooltip-box) {
      display: none;
      &.visible {
        display: block;
      }
    }
  }
  .tooltip-trigger {
    position: relative;
    cursor: pointer;
    font-weight: bold;
    &:hover {
      .tooltip-box {
        display: block;
      }
    }
    .tooltip-box {
      background: $white;
      border: 1px solid $light-grey;
      border-radius: 4px;
      bottom: 24px;
      display: none;
      font-weight: normal;
      left: unset;
      right: unset;
      padding: 8px;
      position: absolute;
      width: 250px;
      z-index: 1;
      &.visible {
        display: block;
      }

      &.center-positioned {
        right: unset;
        left: unset;
      }
      &.left-positioned {
        right: unset;
        left: 0;
      }

      &.right-positioned {
        right: 0;
        left: unset;
      }

      .tooltip-tail {
        border: 10px solid;
        border-color: transparent #fff transparent transparent;
        height: 0;
        left: unset;
        right: 16px;
        position: absolute;
        top: calc(100% - 1px);
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
        width: 0;
      }
      .tooltip-tail-2 {
        border: 10px solid;
        border-color: transparent $light-grey transparent transparent;
        height: 0;
        left: unset;
        right: 16px;
        position: absolute;
        top: 100%;
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
        width: 0;
      }

      &.tail-centered {
        .tooltip-tail {
          left: calc(50% - 5px);
          right: unset;
        }
        .tooltip-tail-2 {
          left: calc(50% - 5px);
          right: unset;
        }
      }
      &.tail-left {
        .tooltip-tail {
          left: 12px;
          right: unset;
        }
        .tooltip-tail-2 {
          left: 12px;
          right: unset;
        }
      }
      &.tail-right {
        .tooltip-tail {
          left: unset;
          right: 12px;
        }
        .tooltip-tail-2 {
          left: unset;
          right: 12px;
        }
      }
    }
  }
  .link-underline {
    display: inline-flex;
  }
}
