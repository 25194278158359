.product-detail {
  padding: 0 84px;
  .primary-images {
    padding-left: 10px;
    padding-right: 10px;
  }
  .image-title-price {
    margin-left: 0;
    margin-right: 0;
  }

  .product-description {
    margin-bottom: 24px;
    .description-content {
      color: $black;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      width: 100%;
    }
  }

  .product-image {
    width: 345px;
    height: 460px;
    margin: 0;
    // border: solid 1px $white;
    margin-bottom: 16px;
  }

  .product-name {
    border-bottom: none;
    h1 {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $black;
      text-transform: uppercase;
    }
  }

  .product-number-rating {
    height: 21.6px;
    margin-top: 8px;
    margin-bottom: 15.6px;
    border-bottom: none;
    border-top: none;

    .product-number {
      color: $dark-grey;
      font-size: 12px;
      padding: 0;
    }
  }

  .product-price {
    text-align: right;

    .prices {
      color: $black;
      font-size: 16px;
      font-stretch: normal;
      letter-spacing: 1px;
      line-height: 1.6;
      font-style: normal;
      font-weight: 500;
      text-align: right;
      width: 100%;
      &.previous-price {
        text-decoration: line-through;
      }
    }
  }

  .product-see-also {
    margin-bottom: 24px;
    width: 100vw;
    text-transform: uppercase;
    .col {
      display: flex;
      span {
        // width: 78px;
        height: 22px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.3px;
        color: $black;
      }

      .see-also-value {
        text-decoration: underline;
        margin: 0 8px;
      }
    }
  }

  .variation-attribute {
    margin-bottom: 24px;

    .color-label {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      color: $black;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    .size-label-section {
      align-items: center;
      display: flex;
      justify-content: space-between;
      height: 22px;

      .size-guidelines {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
      }
      .size-label {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.3px;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }

    select {
      width: 58.33333%;
      // max-width: 58.33333%;
      border: none;
      padding-left: 0;
      margin-top: 0;
      padding: 0;
      option {
        text-transform: uppercase;
      }
    }
  }

  .add-to-cart-add-to-wishlist {
    flex-direction: row-reverse;
    margin-left: 0;
    margin-right: 0;
    .add-to-cart-actions,
    .notfy-me-actions {
      max-width: 538px;
      width: 538px;
      .cart-and-ipay {
        margin-left: 0;
      }
    }

    .add-to-whishlist {
      max-width: 48px;
      padding-left: 0;
      width: 48px;

      button {
        border: 1px solid $medium-grey;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        .add-to-wish-list-icon {
          height: 20px;
        }
      }
    }
  }

  .free-shipping {
    color: $dark-grey;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    height: 22px;
    letter-spacing: 0.3px;
    line-height: 1.8;
    margin: 24px 0;
  }

  .pickup-in-store-option {
    height: 12px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    color: $black;
    text-decoration: underline;
    text-transform: uppercase;
    margin: 24px 0 24px 0;
  }

  .product-collapsible-sections {
    .col-12 {
      &.col-md-5 {
        padding: 0;
      }
    }

    h2 {
      align-items: center;
      display: flex;
      height: 48px;
      justify-content: space-between;

      .title {
        color: $black;
        height: 12px;
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 1;
        text-transform: uppercase;
      }
    }
  }
}
