.pd-masonry-grid {
  padding: 0 24px;

  &__header {
    &-title {
      .display-heading {
        font-size: 18px;
        line-height: 24px;
        padding: 18px 0;
      }
    }
  }

  &__content {
    .grid-sizer,
    .pd-wrapper-image-layer {
      width: 32.666%;
    }

    .pd-wrapper-image-layer {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      a.editoral-link {
        overflow: hidden;

        img {
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  &__description {
    &-title {
      font-size: 18px;
      line-height: 24px;
    }
  
    &-text {
      font-size: 14px;
      line-height: 20px;
      padding-top: 16px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }
}