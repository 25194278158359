.slot4-smallBig-component {
  padding: 30px 15px;
}

.slot4-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.smallBig__item {
  width: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;

  .pd-video__container,
  .pd-wrapper-image-layer {
    padding: 0 35px;
  }
  + .smallBig__item {
    margin-top: 4rem;
  }
}
//
