.twoColumnContainer {
  .twoColumnContent {
    .column1,
    .column2 {
      width: calc(50vw - 4px);
    }
  }
}

.menu-item {
  .twoColumnContainer {
    .twoColumnContent {
      .column1,
      .column2 {
        width: unset;
      }
    }
  }
}