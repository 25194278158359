.page-search-result-container.new-page-search-result {
  .search-no-result-decorator-container {
    .plp-banner-container {
      display: flex;
      justify-content: center;
      .text-content {
        .page {
          &-title {
            height: 38.4px;
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1.5px;
            color: $black;
            text-transform: uppercase;
            margin-bottom: 16px;
          }
          &-description {
            width: 616px;
          }
        }
      }
    }
    .breadcrumbs-hero-internal {
      display: flex;
    }
    .delivery-banner-mobile {
      border-bottom: 1px solid $light-grey;
    }
    .delivery-banner-mobile {
      display: none;
    }
    .delivery-banner-desktop-spacer {
      display: flex;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .delivery-banner-desktop {
      display: flex;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      .delivery-banner-info {
        display: flex;
        align-items: center;
        margin: 8px 0;
        justify-content: center;
        &-text {
          width: 183px;
          height: 12px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          color: #096a0e;
        }
      }
    }
    .search-result {
      margin-top: 0;

      .grid-product {
        .version {
          .product-grid-container {
            .single-element {
              margin-bottom: 0;
              &-content {
                &-detail {
                  text-align: center;
                  &-price {
                    .previous-price {
                      color: $dark-grey;
                      text-decoration: line-through;
                      margin-right: 16px;
                    }
                  }
                }
                &-image {
                  width: 100%;
                  height: 100%;
                  position: relative;
                  margin-bottom: 0;
                  &-tag {
                    background-color: red;
                    height: 20px;
                    padding: 4px 8px;
                    border-radius: 10px;
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: 0.5px;
                    text-align: center;
                    position: absolute;
                    bottom: 8px;
                    left: 8px;
                    color: $white;
                  }
                }
              }
            }
          }

          .products-seen {
            margin-bottom: 16px;
            text-align: center;
          }

          .show-more {
            // height: 48px;
            margin: 0 auto;
            // margin-bottom: 40px;
            width: 424px;
          }
        }
      }
    }

    .no-result-page {
      .no-result-carousel {
        padding: 0 28px;
        max-width: 144rem;
      }
    }

    .horizontal-row {
      &.grid-header-bottom-row {
        display: block;
        width: 100%;
        border-top: 1px solid $light-grey;
        margin-left: 0;
        margin-bottom: 24px;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .page-search-result-container {
    .search-no-result-decorator-container {
      .search-result {
        .grid-product {
          .version {
            .product-grid-container {
              .single-element-content-image {
                width: 100%;
                height: 90%;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}
