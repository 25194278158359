.address-book-container {
  padding: 0;
  .card-address-header {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 1.5px;
    color: $black;
    text-transform: uppercase;
  }
  .address-book-wrapper {
    h3 {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 24px 0;
    }
    .card-wrapper {
      margin-bottom: 8px;
      &:not(.preferred-address) {
        .card {
          .edit-address-button {
            z-index: 9999;
          }
        }
      }
      .card-actions {
        margin-top: 24px;
        margin-bottom: 16px;
        .single-card-action {
          cursor: pointer;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          text-decoration: underline;
          margin: 0 8px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  .horizontal-row {
    border-top: 1px solid $light-grey;
    &.upper-button-row {
      margin: 24px 0;
    }
  }
  .account-bottom-section {
    margin-bottom: 24px;
  }
}

.other-addresses-box {
  .section-title {
    .card-other-addresses {
      &:not(:first-child) {
        display: none;
      }
    }
  }
}
