%button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 40px;
  border-radius: 25px;
  text-transform: uppercase;
  width: 100%;
  border: 1px solid $black;
  text-align: center;
  position: relative;
}

.btn--primary {
  @extend %button;
  @extend %cta;
  color: $white !important;
  background-color: $black;
}

.btn--secondary {
  @extend %button;
  @extend %cta;
  color: $black !important;
  background-color: $white;
}

.btn--tertiary {
  @extend %button;
  @extend %cta;
  color: $black !important;
  background-color: transparent;
}

.btn--paypal {
  @extend %button;
  @include fixedSprite('pay-paypal-big');
  background-position: center;
  background-size: 100px 20px;
  border: none;
  background-color: #0070b9;
  min-height: 48px;
  min-width: 173px;
}

.btn--arrow-right {
  @extend %button;
  @extend %label--small;
  justify-content: flex-start;
  padding-left: 15px;

  &::after {
    content: '';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: ($space-unit * 6);
    height: ($space-unit * 6);
    @include fixedSprite('chevron-mini-right');
  }
}

.icon-link {
  &::after {
    content: '';
    @include fixedSprite('arrow-cta');
  }
}

.block-link {
  display: block;
}

button {
  color: $black;
}
