:root {
  --filters-maxHeight: 1000px;
}

#openChatButton {
  white-space: nowrap;
  cursor: pointer;
}

.footer {
  background-color: #111111;
  color: $white;

  .link-underline {
    color: $white;
    text-decoration: underline;
    border-top: 0;

    &:before {
      border: 0;
    }
  }

  .open-chat-container {
    margin-top: 24px;
    width: 100%;
    text-align: center;
  }

  &-newsletter {
    padding-top: 40px;

    .title-section-newsletter {
      text-transform: none;
      font-family: $futura-book;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 1.5;
      padding-right: 0;
      
      & ~ p {
        font-family: $futura-book;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1.5;
        margin-top: 8px;
        margin-bottom: 24px;
      }
    }

    & input:active ~ button span.icon--arrow-new-grey,
    & input:focus ~ button span.icon--arrow-new-grey,
    & input:focus-within ~ button span.icon--arrow-new-grey {
      filter: brightness(0) invert(1);
    }

    button {
      display: flex;
    }

    .container-newsletter .form-input {
      background-color: #2F2F2F;
      margin-bottom: 8px;

      input {
        color: $white;
        padding-left: 16px;
      }

      &::placeholder {
        color: #B4B4B4;
      }

      .newsletter-message {
        padding-left: 10px;
        margin-top: 1px;
      }
    }

    .tooltip-container {
      color: $white;
      font-family: $futura-book;
      font-size: 11px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 4;
    }
  }

  &-country {
    display: flex;
    width: 100%;
    margin-top: 64px;
    align-items: center;
  }

  &-country-wrapper {
    margin: 0;

    .col-12,
    .col-md-3,
    .col-md-4 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-country-flag {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    background-color: $white;
    border-radius: 100%;
    overflow: hidden;

    & ~ p {
      color: #B4B4B4;
      line-height: 20px;
      letter-spacing: 1.5;
    }

    .btn-country-selector p span {
      color: $white;
    }
  }

  &-icon-flag {
    min-width: 20px;
  }

  &-store-selector {
    display: flex;
    width: 100%;
    margin-top: 27px;
    align-items: center;

    a {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 1.5;
    }

    .button-icon {
      margin-right: 8px;
    }
  }

  &-accordion {
    width: 100%;
    margin-top: 80px;

    &-content {
      .section-footer-box {
        border-bottom: 1px solid #373838;

        .accordion-content {
          display: flex !important;
          -webkit-transition: max-height .2s ease-out;
          transition: max-height .2s ease-out;
          max-height: 0 !important;
          opacity: 0 !important;
          overflow: hidden;
        }

        &:first-child {
          
          .accordion-content:not(.footer-accordeon-condensed) {
            opacity: 1;
            max-height: none;
          }

          .icon--minus-icon-white {
            display: block;
          }

          .icon--plus-icon-white {
            display: none;
          }
        }

        &:not(:first-child) {
          .accordion-content {
            padding-bottom: 0;
          }
        }
  
        .accordion.is-enabled .accordion-content {
          display: flex !important;
        }

        @-webkit-keyframes acc-hide {
          0% {
              max-height: var(--filters-maxHeight)
          }
        
          100% {
              max-height: 0
          }
        }
        
        @keyframes acc-hide {
          0% {
              max-height: var(--filters-maxHeight)
          }
        
          100% {
              max-height: 0
          }
        }
        
        @-webkit-keyframes acc-show {
          0% {
              max-height: 0
          }
        
          50% {
              max-height: calc(var(--filters-maxHeight/2))
          }
        
          100% {
              max-height: var(--filters-maxHeight)
          }
        }
        
        @keyframes acc-show {
          0% {
              max-height: 0
          }
        
          50% {
              max-height: calc(var(--filters-maxHeight/2))
          }
        
          100% {
              max-height: var(--filters-maxHeight)
          }
        }

        &.footer-accordeon-condensed {
          .accordion-content {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-transition: max-height 0.2s ease-out, opacity 0.1s ease-out;
            transition: max-height 0.2s ease-out, opacity 0.1s ease-out;
            padding: 0;
            max-height: 0 !important;
            opacity: 0 !important;
            overflow: hidden;
          }

          .icon--minus-icon-white {
            display: none !important;
          }

          .icon--plus-icon-white {
            display: block !important;
          }
        }

        &.footer-accordeon-expanded {
          .accordion-content {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-transition: max-height 0.2s ease-in, opacity 0.1s ease-in;
            transition: max-height 0.2s ease-in, opacity 0.1s ease-in;
            max-height: var(--filters-maxHeight) !important;
            opacity: 1 !important;
            padding-bottom: 16px;
          }

          .icon--minus-icon-white {
            display: block !important;
          }

          .icon--plus-icon-white {
            display: none !important;
          }
        }
      }

      .section-footer-box:not(.footer-accordeon-condensed) {
        &:first-child {
          .accordion-content {
            opacity: 1 !important;
            max-height: none !important;
          }
        }
      }

      .single-footer-section {
        &-container {
          padding-bottom: 16px;
        }

        .button-open-footer {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          margin: 12px 0;
          height: 45px;

          .accordion-button-label {
            padding: 12px 0;
            color: #B4B4B4;
            font-family: $futura-book;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 1.5;

            .grey-span {
              color: #999999;
            }
          }
        }

        .button-open-footer ~ .title-section-footer {
            color: #B4B4B4;
            font-family: $futura-book;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 1.5;
            margin-bottom: 8px;
        }

        &>.title-section-footer {
          display: none;
        }

        .icon--minus-icon-white {
          display: none;
        }

        .accordion-trigger[aria-expanded='false'] {
          .icon--minus-icon-white {
            display: none;
          }
        }

        .accordion-trigger[aria-expanded='true'] {
          .icon--plus-icon-white {
            display: none;
          }

          .icon--minus-icon-white {
            display: block;
          }
        }

        .footer-list-item {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 1.5;

          &:not(:first-child) {
            margin-top: 12px;
          }
        }
      }
    }
  }

  &-logo {
    margin-top: 64px;
    margin-bottom: 24px;

    a {
      width: 100%;
      display: inline-block;
    }
    a::after {
      content: '';
      @include fixedSprite('logo-mobile-red');
      width: 100%;
      padding-top: 8.44%;
      height: auto;
      background-size: contain;
      background-repeat: no-repeat;
      
    }
  }

  &-bottom.container {
    max-width: none;
    padding: 0;

    &>.container {
      padding: 0;
    }

    .second-section-footer {
      padding: 0 15px;

      p {
        font-size: 12px;
        color: #CCCCCC;
      }
    }
  }

  &-social {
    width: 100%;

    .section-icon-social {
      flex-wrap: nowrap;
      display: flex;
      justify-content: center;
      max-height: 64px;
      border-top: 1px solid #373838;
      padding-top: 24px;
      padding-bottom: 24px;
      margin-top: 40px;

      a {
          width: 16px;
          height: 16px;
          background-color: #CCCCCC;
          background-size: contain;
          display: inline-flex;
          padding-right: 0;

          &:not(:last-child) {
            margin-right: 32px;
          }

          &:last-child {
            margin-right: 0;
          }
      }

      .icon-facebook-footer {
        mask: url(map-get($sprites, 'facebook-footer'));
        mask-repeat: no-repeat;
        mask-size: contain;
      }

      .icon-instagram-footer {
        mask: url(map-get($sprites, 'instagram-footer'));
        mask-repeat: no-repeat;
        mask-size: contain;
      }

      .icon-twitter-footer {
        mask: url(map-get($sprites, 'twitter-footer'));
        mask-repeat: no-repeat;
        mask-size: contain;
      }

      .icon-youtube-footer {
        mask: url(map-get($sprites, 'youtube-footer'));
        mask-repeat: no-repeat;
        mask-size: contain;
      }

      .icon-tiktok-footer {
        mask: url(map-get($sprites, 'tiktok-footer'));
        mask-repeat: no-repeat;
        mask-size: contain;
      }

      .icon-line-footer {
        mask: url(map-get($sprites, 'line-footer'));
        mask-repeat: no-repeat;
        mask-size: contain;
      }
    }
  }
}


