.pd-wrapper-image-layer {
  + .experience-component {
    // margin-top: 2rem;
  }
}

.image--tv {
  position: relative;
  overflow: hidden;
  border-radius: 42% / 14%;

  picture {
    img {
      width: 100%;
      height: 100%;
      position: relative;
      left: 0;
      top: 0;
    }
  }
}
