.reset-password {
  &.access-details-container {
    padding: 16px 88px;
    .content {
      display: flex;
      justify-content: center;
      padding: 16px 16px 32px;
    }
  }

  .content {
    &#new-password-confirmation {
      .body {
        &.col-md-6 {
          width: 800px;
          .new-password-confirmation-content {
            .password-confirmation-buttons {
              .buttons {
                display: flex;
                align-items: center;
                > div {
                  width: 208px;
                  &.login-button {
                    margin-right: 24px;
                  }
                  a {
                    padding: 16px 13px;
                  }
                }
              }
              .go-to-home-button {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }

  .card-header {
    .main-title {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .save-new-pass {
    margin-top: 16px;
  }

  .body {
    &.col-md-6 {
      flex-basis: 100%;
      max-width: 100%;
      width: 400px;
    }
  }

  .save-new-pass-container {
    padding: 0 24px;
  }

  // .new-password-form {
  //   margin-left: 0;
  //   margin-right: 0;
  // }
}

.reset-password-help-boxes {
  .content-asset-box {
    margin-left: 0;
    margin-right: 0;
    .single-box-element {
      background-color: $light-grey;
      display: flex;
      justify-content: center;
      .single-box-element-content {
        text-align: center;
      }
      &:first-child {
        padding-left: 104px;
      }
      &:last-child {
        padding-right: 104px;
      }
    }
  }
}
