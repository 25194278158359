.pd-masonry-grid {
  padding: 0 16px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    flex-direction: row;
    padding: 0;

    &-mobile {
      display: none;
    }

    &-title {
      display: block;
    }

    .filter-action {
      flex: auto;
    }

    .show-filter {
      justify-content: flex-start;
    }

    .layer-filter {
      padding: 12px 0;

      #layer-filter {
        margin-top: 0;
        height: 100vh;
      }

      &-header {
        padding: 48px 72px 24px;
      }

      .filter-list {
        padding: 24px 72px;
      }
    }
  }

  &__container {
    padding-top: 0;
  }
}

.layer-filter-open {
  &#page-show.no-zindex .header {
    position: sticky !important;
  }
}