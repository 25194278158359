.page-search-result-container {
  .search-no-result-decorator-container {
    .plp-banner-container {
      display: flex;
      justify-content: center;
      .text-content {
        .page {
          &-title {
            height: 32px;
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            color: $black;
            text-transform: uppercase;
          }
          &-description {
            width: 616px;
          }
        }
      }
    }

    .search-result {
      margin-top: 38px;
      .grid-product {
        .version {
          .product-grid-container {
            .single-element {
              display: flex;
              justify-content: center;
              &-content {
                margin-bottom: 32px;
                &-image {
                  aspect-ratio: 3 / 4;
                  /* width: 352px;
                  height: 469px; */
                  position: relative;
                  margin-bottom: 16px;
                  &-tag {
                    background-color: red;
                    height: 20px;
                    padding: 4px 8px;
                    border-radius: 10px;
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: 0.5px;
                    text-align: center;
                    position: absolute;
                    bottom: 8px;
                    left: 8px;
                    color: $white;
                  }
                }
                &-detail {
                  font-size: 14px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.54;
                  letter-spacing: 0.5px;
                  text-align: center;
                  color: $black;
                  padding: 0 16px;
                  &-price {
                    margin-top: 8px;
                    .previous-price {
                      color: $dark-grey;
                      text-decoration: line-through;
                      margin-right: 16px;
                    }
                  }
                }
              }
            }

            &.product-x-1 {
              .show-more {
                width: 100%;
                margin-inline: 7px;
                margin-bottom: 40px;
                .col {
                  padding: 0;
                }

                button {
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: 2px;
                  color: $white;
                  text-transform: uppercase;
                }
              }
              .single-element {
                padding: 0;
                display: block;
                .single-element-content {
                  margin-bottom: 32px;
                  &-image {
                    margin: 0 auto 16px;
                    aspect-ratio: 3 / 4;
                    /* height: 459px;
                    width: 345px; */
                  }
                  &-detail {
                    text-align: center;
                    margin: 0 auto;

                    &-description {
                      margin-bottom: 8px;
                    }
                  }
                }
              }
            }

            &.product-x-2 {
              .show-more {
                width: 100%;
                margin-inline: 7px;
                margin-bottom: 40px;
                .col {
                  padding: 0;
                }
                button {
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: 2px;
                  color: $white;
                  text-transform: uppercase;
                }
              }

              .single-element {
                padding: 0;
                .single-element-content {
                  margin-bottom: 32px;
                  &-image {
                    aspect-ratio: 3 / 4;
                    /* height: 469px;
                    width: 352px; */
                  }
                  &-detail {
                    text-align: center;
                  }
                }
              }
            }

            .products-seen {
              display: block;
              width: 100%;
              margin-inline: 7px;
              margin-bottom: 16px;
              .col {
                display: flex;
                justify-content: center;
                p {
                  height: 21.6px;
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.8;
                  letter-spacing: 0.3px;
                  text-align: center;
                  color: $black;
                }
              }
            }
          }
        }
      }

      .show-more {
        margin: 0 -10px;
        padding-bottom: 16px;
      }
    }
  }
}
