:root {
  font-size: 10px;
}

html {
  // scroll-behavior: smooth;
  /* evita gli spostamenti quando appare il menu che fa sparire la scrollbar */
  // disattivo temporaneamente per testare il fix sull'ovcerflow hidden sul body
  // overflow-y: scroll;
  font-size: 1.4rem;
  &.initial-scroll {
    scroll-behavior: initial;
  }
}

.header {
  transition: transform 0.25s ease-in-out;
  position: fixed;
  width: 100%;
}

.header-search {
  transition: transform 0.25s ease-in-out;
}
.header-unpin {
  /* display: none; */
  transform: translateY(-100%);
}
.header-pin {
  /* display: block; */
  transform: translateY(0);
}

.page-template-homepage {
  .header-unpin {
    /* display: none; */
    transform: translateY(-100%);
  }
  .header-pin {
    /* display: block; */
    transform: translateY(0);
  }
}

#product-show {
  .header {
    transition: none;
  }
  .header-unpin {
    /* display: none; */
    transform: none;
  }
  .header-pin {
    /* display: block; */
    transform: none;
  }
}

body {
  // @extend %body-regular;
  font-family: $futura-book;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.3px;

  color: $black;
  position: relative;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $overlay;
    opacity: 0;
    pointer-events: none;
    @include z(overlay);
    @include animate(0.15);
  }

  &.overlay {
    &::before {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &.no-overflow,
  &.ReactModal__Body--open {
    overflow: hidden;
  }

  &.hide-scroll {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

#top-page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
}

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &.no-padding {
    padding-top: 0;
  }
  &.main--layout-page {
    border-top: none;
    margin-top: var(--navContainer-height);

    .accessibility-statement-link {
      position: absolute;
      display: block;
      overflow: hidden;
      left: -10000px;
    }
  }
  &.maintenance-page {
    min-height: 100%;
  }
  &.main--checkout {
    margin-top: var(--navContainer-height);
    position: relative;
  }
}

.page-template-homepage {
  main {
    &.main--layout-page {
      padding-top: 74px;
    }
  }
}

img {
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  height: auto;
}

ul,
ol {
  li {
    padding: 0;
  }
}

a,
a:visited {
  color: inherit;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  font: inherit;
}

input[type='search']::-webkit-search-cancel-button {
  /* Remove default cancel button */
  -webkit-appearance: none;
}

.list-no-style {
  list-style-type: none;
}

.editorial-swiper {
  display: none;
  &.swiper-container-initialized {
    display: block;
  }
}

.description {
  strong {
    font-weight: 500;
    display: inline;
  }
}

.prior-best-price-component {
  position: relative;
  align-content: center;
  margin-left: 0.8rem;

  .prior-best-price-info-btn {
    border: none;
    background: none;
    padding: 0;
    color: #7d7d7d;
    display: flex;
  }

  .price-listing {
    display: flex;
    flex-wrap: wrap;
    margin-left: 1.5rem;
    gap: 16px;
    text-align: left;

    .price-listing-item {
      div.price {
        display: inline-flex;
        color: #7d7d7d;
      }
    }
  }

  .modal-view {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: 55;
    height: 196px;
    padding: 1.6rem;

    .modal-header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 24px;

      .modal-title {
        font-size: 16px;
      }

      .close-modal-price-tooltip {
        display: flex;
      }
    }

    .price-listing {
      gap: 24px;
    }
  }
}

.layer-tooltip-prior-best-price {
  margin-left: 0.8rem;

  .prior-best-price-info-btn {
    color: #7d7d7d;
  }

  .price-listing {
    display: flex;
    flex-wrap: wrap;
    margin-left: 1.5rem;
    gap: 16px;
    text-align: left;

    .price-listing-item {
      div.price {
        display: inline-flex;
        color: #7d7d7d;
      }
    }
  }

}