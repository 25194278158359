.tooltip-global-container {
  position: relative;
  width: min-content;

  .tooltip-box {
    bottom: -8px;
    left: 26px;
    position: absolute;

    .tooltip {
      .tooltip-tail {
        border: 10px solid;
        border-color: transparent $white transparent transparent;
        height: 0;
        left: 12px;
        position: absolute;
        width: 0;
        transform: unset;
        bottom: 10px;
        left: -18px;
        top: unset;
      }

      .tooltip-tail-2 {
        border: 10px solid;
        border-color: transparent $light-grey transparent transparent;
        height: 0;
        left: 12px;
        position: absolute;
        width: 0;
        transform: unset;
        bottom: 10px;
        left: -19px;
        top: unset;
      }
    }
  }
}

.tooltip-container {
  > .tooltip-box {
    &:not(.inner-tooltip-box) {
      display: none;
      &.visible {
        display: block;
      }
    }
  }
  .tooltip-trigger {
    .tooltip-box {
      display: none;
      font-weight: normal;
      left: unset;
      padding: 16px;
      right: 0;

      .tooltip-tail {
        left: unset;
        right: 20px;
      }
      .tooltip-tail-2 {
        left: unset;
        right: 20px;
      }
      &.right-positioned {
        left: 0;
        .tooltip-tail {
          right: unset;
        }
        .tooltip-tail-2 {
          right: unset;
        }
      }
      &.left-positioned {
        right: 0;
        left: unset;
      }
    }
  }
  .link-underline {
    display: inline-flex;
  }
}
