.pd-container--no-padding {
  padding-right: 0;
}

.experience-component {
  .container {
    overflow: hidden;
  }
}

.experience-layouts-editorialContainerRow {
  .col-6 {
    max-width: calc(50% - 0.8rem);
    &:nth-child(even) {
      margin-left: 1.6rem;
    }
  }
}
