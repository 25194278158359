.modal-container {
  max-width: 589px;
  width: 589px;
}

.modal-size-guidelines {
  .sizeguide-old-pdp {
    display: block;
  }

  .sizeguide-new-pdp {
    display: none;
  }
  &.container-size-guidelines {
    .modal-header {
      padding: 0;
      height: 50px;
    }

    .modal-content {
      padding: 0;
      .modal-content-gudielines {
        .fitguide__container--desktop {
          .fitguide__container {
            // display: flex;
            border-top: solid 1px $light-grey;
            // justify-content: space-between;
            margin: 0;
            padding: 0;
            // justify-content: center;
            .tab-button {
              align-items: center;
              display: none;
              height: 54px;
              justify-content: center;
              flex: 1;
              button {
                border: solid 1px $light-grey;
                border-top: none;
                height: 100%;
                width: 100%;
                color: $dark-grey;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                letter-spacing: 1px;
                padding: 0 16px;
                &.fitguide__tab-button--active {
                  color: $black;
                  border-right: 1px solid $light-grey;
                  border-left: 1px solid $light-grey;
                  border-bottom: 1px solid $white;
                  z-index: 1;
                }
                &:not(.fitguide__tab-button--active) {
                  border: unset;
                  border-bottom: 0;
                }
              }
            }
            .tab-button #fitguide-content-01 {
              order: 1;
            }
            .tab-button #fitguide-content-02 {
              order: 2;
            }
          }
          #fitguide-content-01 {
            padding: 0 2.4rem;
            .fitguide__interactive {
              .measuring-guide__radio-input {
                .form-group {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  padding-bottom: 20px;
                  padding-top: 28px;
                  align-items: center;
                  .radio {
                    margin-bottom: 0;
                    position: relative;
                    &:after {
                      position: absolute;
                    }
                  }
                }
              }
              .choose-size-and-unit-measure {
                position: relative;
                .choose-size-label {
                  height: 12px;
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: 0.5px;
                  color: $black;
                }
                #choose-your-size {
                  width: 100%;
                  border-radius: 0;
                  border: 1px solid $black;
                }
                span.dropdown-arrow:after {
                  content: '';
                  display: block;
                  position: absolute;
                  width: var(--fsb-arrow-size);
                  height: var(--fsb-arrow-size);
                  right: 33px;
                  top: 66%;
                  transform: translateY(-65%) rotateZ(45deg);
                  border: solid var(--fsb-arrow-color);
                  border-width: 0 1.5px 1.5px 0;
                  box-sizing: border-box;
                  transition: transform 0.3s ease-in-out;
                  pointer-events: none;
                }
              }

              .horizontal-row {
                border-top: 1px solid $light-grey;
                margin: 16px 0;
                &.choose-your-size-bottom {
                  margin-bottom: 0;
                  width: calc(100% + 48px);
                  margin-left: -24px;
                }
                &.measuring-size-table-bottom {
                  width: calc(100% + 48px);
                  margin-left: -24px;
                  margin-top: 0;
                }
                &.measuring-img-bottom {
                  width: calc(100% + 48px);
                  margin-left: -24px;
                  margin-top: 0;
                }
              }

              .measuring-guide__size-table {
                .table-details {
                  margin: 16px 0;
                  &-label {
                    height: 22px;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.8;
                    letter-spacing: 0.3px;
                    color: $black;
                    text-transform: uppercase;
                  }
                  &-value {
                    height: 22px;
                    margin: 0 0 0 16px;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.8;
                    letter-spacing: 0.3px;
                    color: $dark-grey;
                  }
                  &:first-child {
                    margin-top: 16px;
                  }
                }
              }

              .measuring-img__sketches {
                display: flex;
                justify-content: center;
              }

              .click-to-convert-sizes {
                display: flex;
                margin: 10px 0;
                &-text {
                  height: 12px;
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: 0.5px;
                  color: $black;

                  a {
                    text-decoration: underline;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          #fitguide-content-02 {
            display: none;
            overflow-x: hidden;
            .measuring-guide {
              .measuring-guide-content {
                overflow-x: auto;
                padding: 0;
                .table {
                  margin: 50px 0 0;
                  margin-top: 48px;
                  padding-bottom: 8px;
                  border-collapse: collapse;
                  width: 100%;
                  thead {
                    tr {
                      th {
                        text-align: center;
                        height: 26px;
                        font-size: 16px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.6;
                        letter-spacing: 1px;
                        color: $black;
                        text-transform: uppercase;
                        padding: 0 16px;
                      }
                    }
                  }
                  tbody {
                    tr:nth-child(odd) {
                      background-color: #eeedeb;
                    }
                    tr {
                      td {
                        padding: 0px;
                        text-align: center;
                        height: 31px;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 2.1;
                        letter-spacing: 1px;
                        color: $dark-grey;
                        text-transform: uppercase;
                      }
                    }
                  }
                }
              }
              .horizontal-row {
                &.table-bottom-row {
                  border-top: 1px solid $light-grey;
                  margin-top: 0;
                  margin-bottom: 16px;
                  width: 100%;
                }
              }
              .modal-footer {
                padding: 0 2.4rem 10px 2.4rem;
              }
            }
          }
          &.size-uni-active {
            #fitguide-content-01 {
              .fitguide__interactive {
                .choose-size-radio-input-row {
                  flex-direction: row;
                  .choose-size-and-unit-measure {
                    display: none;
                  }
                }
              }
            }
            .measure-taken-by-product {
              display: none;
            }
          }
        }
        .fitguide__interactive {
          .radio {
            #unit-measure_0 {
              position: relative;
              &:after {
                position: absolute;
              }
            }
            #unit-measure_1 {
              position: relative;
              &:after {
                position: absolute;
              }
            }
          }
        }
        .fitguide__container {
          .fitguide__content {
            display: none;
          }
        }
      }
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .modal-footer {
      border-top: none;
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
      .button-phone-modal {
        display: flex;
        margin-top: 10px;
        &-icon {
          width: 10px;
          height: 12px;
          margin-right: 9px;
        }
        &-text {
          height: 12px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          color: $black;
        }
      }
    }
  }
}

.bordered-col {
  padding: 0;
  &.empty-col {
    border-bottom: 1px solid $light-grey;
    position: relative;
    top: -1px;
  }
}

.measure-taken-by-product {
  display: flex;
  &-icon {
    height: 12px;
    width: 12px;
    margin-right: 8px;
  }
  &-text {
    height: 12px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    color: $dark-grey;
  }
}

.client-service {
  display: flex;
}

#size-guidelines-modal {
  .modal-close {
    top: 15px;
    right: 15px;
  }
}
