#dynamic-modal {
  .modal-header {
    padding: 2.3rem 3.3rem 1.6rem;
  }

  .popup-body {
    font-size: 14px;
    margin-bottom: 35px;
  }

  .btn--primary {
    padding: 16px 40px;
  }
}
