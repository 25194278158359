.container-social {
  padding: 5px 0 0 0;
  text-align: center;
  .section-icon-social {
    padding: 0;
    a {
      padding-right: 40px;
    }
  }
}
