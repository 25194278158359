.checkoutpage {
  padding: 0;
  &.shipping {
    padding-bottom: 40px;
  }
  .checkout-shipping__address {
    .checkout-shipping__address__address-book {
      .accordion {
        .address_form {
          #shipping-data-accordion-content {
            > .form-group {
              margin-top: 0;
            }
          }
        }
        &-title {
          padding-bottom: 18px;
          text-transform: uppercase;
        }
        .accordion-title-icon {
          height: 1rem;
          width: 1rem;
        }
      }
      .address_form {
        .form-group {
          .form-control {
            input {
              margin: 4px 0 0;
            }
          }
        }
      }
      &.no-addresses {
        .address_form {
          margin-bottom: 29px;
        }
      }
    }
    .checkout-shipping__options {
      fieldset {
        margin: 0 -22px;
        padding: 0 22px;
        .form-group {
          display: flex;
          flex-wrap: wrap;
          margin-top: 13px;
          margin-bottom: 13px;
          .radio {
            border-bottom: none;
            margin-top: 0;
            margin-bottom: 0;
            width: 50%;
            padding-left: 24px;
            padding-top: 13px;
            padding-bottom: 0;

            &:nth-child(odd) {
              border-right: 1px solid $light-grey;
            }

            .checkout-shipping__options__option {
              &-name {
                margin-right: 5px;
              }
              &-description {
                padding-left: 13px;
              }
            }
          }
          .horizontal-row {
            display: none;
          }
        }
      }
    }
    .checkout-navigations {
      margin-top: 0;
      .checkbox {
        padding-left: 0;
        padding-top: 0;
        margin-bottom: 0;
      }

      .navigations-buttons {
        padding-bottom: 24px;
      }
      .proceed-to-payment {
        button {
          width: 145px;
          height: 48px;
          border-radius: 25px;
          color: $white;
          background: $black;
          text-transform: uppercase;
        }
      }
    }
    .horizontal-row {
      width: calc(100% + 30px);
      border-top: 1px solid $light-grey;
      margin-left: 0;
      margin-bottom: 16px;

      &.shipping-data {
        margin-bottom: 18px;
        width: calc(100% + 170px);
        margin-left: -96px;
      }
      &.pickup-checkout-address-bottom {
        margin-bottom: 18px;
        width: calc(100% + 170px);
        margin-left: -96px;
      }
      &.checkout-option-bottom {
        display: block;
        margin-top: 5px;
        width: calc(100% + 170px);
        margin-left: -96px;
      }
      &.checkout-option-top {
        margin-bottom: 1px;
        width: calc(100% + 170px);
        margin-left: -96px;
      }
      &.checkout-navigations-bottom {
        display: none;
      }
      &.upper-title-row {
        width: calc(100% + 170px);
        margin-left: -96px;
        margin-top: 30px;
      }
    }
  }

  .order-summary-box {
    padding-left: 24px;
    &-title {
      height: 39px;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1.5px;
      color: $black;
      margin-top: 24px;
    }
    &-info {
      margin-top: 24px;
      height: 100%;
      &-text {
        padding-left: 0;
        p {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: 0.3px;
          color: $black;
        }
      }
    }
    .horizontal-row {
      &.order-summary-elements-top {
        width: calc(100% + 15px);
      }
    }
  }

  &.payment {
    .checkout-payment {
      form {
        > .form-group {
          margin-top: 36px;
        }

        .billingAddressForm-checkbox {
          padding-left: 12px;
          .checkbox {
            margin-bottom: 0;
          }
        }
        .payment-methods-title {
          height: 23px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          color: $black;
          text-transform: uppercase;
          margin-bottom: 16px;
        }
      }
      #addresses {
        margin: 8px 0 0;
      }
      .addresses-label {
        height: 23px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        color: $black;
        text-transform: uppercase;
      }

      &-title {
        height: 23px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 28px;
      }
    }
    .form-select {
      .col-1 {
        top: 2.1rem;
      }
    }
    .billingAddressForm {
      .checkout-billing__address__address-book__new {
        .address_form {
          .accordion-title {
            &-text {
              height: 12px;
              margin: 0 16px 0 0;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 0.5px;
              color: $black;
              text-transform: uppercase;
              margin-top: 18px;
            }
            &-icon {
              width: 1rem;
              height: 1rem;
              margin-top: 19px;
            }
          }
          #billing-address-accordion-content {
            > .row {
              margin-top: 18px;
              &:first-child {
                margin-top: 19px;
              }
              .col-12 {
                margin-top: 0;
                &:first-child {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
      &.no-addresses {
        .checkout-billing__address__address-book__new {
          .address_form {
            #billing-address-accordion-content {
              > .row {
                .col-12 {
                  margin-top: 0;
                  &.form-adress-phone-number {
                    .col-12 {
                      margin-top: 0;
                    }
                  }
                }
                &:first-child {
                  .col-12 {
                    &:first-child {
                      margin-top: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .form-control {
        input {
          width: 100%;
          margin: 3px 0 0;
        }
      }
      .form-title {
        height: 12px;
        margin: 0 16px 0 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: black;
        text-transform: uppercase;
      }
    }

    .paymentmethods {
      .checkout-shipping__address {
        .horizontal-row {
          &.header-bottom-row {
            display: none;
          }
        }
      }
      .checkout-shipping__address__address-book {
        &-title {
          height: 23px;
          margin: 16px 0 18px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          color: $black;
          text-transform: uppercase;
        }
        > .form-group {
          .form-group__label {
            height: 23px;
            margin: 24px 46px 16px 0;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            color: $black;
            text-transform: uppercase;
          }
        }
        .form-select {
          select {
            width: 100%;
            margin: 16px 0 0;
          }
        }
      }
      .billingAddressForm-checkbox {
        .checkbox {
          margin-bottom: 26.8px;
        }
      }
      .form-group {
        div {
          &:last-child {
            &.radio-bottom-separator {
              display: none;
            }
          }
        }
      }
    }

    .radio-container {
      width: 100%;
      .radio__icon {
        width: calc(100% - 2.4rem - 11px);
      }
    }

    .payment-method {
      margin-bottom: 0;
      position: relative;
      padding-left: 12px;
      &:not(:last-child) {
        .payment-method-bottom {
          margin-left: 0;
          width: 100%;
        }
      }
      &:last-child {
        .payment-method-bottom {
          margin-left: 0;
          width: 100%;
        }
      }
      .container-radio-payment {
        width: 100%;
        .radio__icon {
          &.credit-card {
            .single-payment-box {
              padding-left: calc(2.4rem + 22px);
              padding-right: 16px;
            }
          }
          &:not(.credit-card) {
            .single-payment-box {
              padding-right: 18px;
            }
          }
        }
      }
      > input {
        position: absolute;
      }
      > label {
        padding-left: 0;
      }
      .radio__icon {
        display: block;
        .adyen-checkout__field {
          margin-bottom: 0;
        }
        .adyen-checkout__field--expiryDate,
        .adyen-checkout__field--securityCode,
        .adyen-checkout__card__holderName {
          margin-top: 24px;
        }
        .adyen-checkout__card__cardNumber__input,
        .adyen-checkout__card__exp-date__input,
        .adyen-checkout__card__cvc__input,
        .adyen-checkout__card__holderName__input {
          color: $dark-grey;
          font-size: 1em;
          font-family: inherit;
          display: block;
          background: #fff;
          border-radius: 0;
          height: 48px;
          border: solid 1px $black;
        }
        .adyen-checkout__label__text {
          height: 12px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          color: $black;
          margin-bottom: 8px;
        }
      }

      .single-payment-box {
        &-name {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: 0.3px;
          color: $black;
        }
        &-card-type {
          display: flex;
          flex-direction: row-reverse;
        }
      }

      .credit-card-fields-box {
        .save-payment {
          margin-top: 24px;
          margin-bottom: 12px;
        }

        &.sub-radio-group {
          .sub-radio__element {
            align-items: center;
            padding-left: 15px;
            &-img {
              width: 44px;
              max-width: 44px;
              height: 32px;
            }
            &-card-details {
              padding-left: 8px;
              width: 157px;
              height: 34px;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 0.5px;
              color: $black;
              display: flex;
              align-items: center;
              .col {
                width: auto;
              }
            }
            &-cvv {
              width: 57px;
              max-width: 57px;
              height: 32px;
              margin: 8px 0 8px 16px;
              padding: 10px 16px;
              border: solid 1px $black;
              background-color: $white;
              p {
                width: 25px;
                height: 12px;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: 0.5px;
                color: $dark-grey;
              }
            }
          }
          .payment-method {
            padding-left: 16px;

            .radio-container {
              padding-left: 36px;
            }
          }
          .radiobox {
            .radiobox__option {
              button {
                margin-right: -10px;
              }
            }
          }
        }
      }
      .payment-option-buttons {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 48px;
        align-items: center;
        padding-left: 10px;
        flex-basis: 100%;
        max-width: 100%;
        &:before {
          display: none;
        }
        button {
          margin-top: -6px;
          left: -20px;
          text-decoration: underline;
          height: 12px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          color: $black;
        }
      }
      // input icon (cvv and card circuit icon)
      .adyen-checkout__card__cvc__hint__wrapper,
      .adyen-checkout__card__cardNumber__brandIcon {
        display: none;
      }

      .adyen-checkout__card__dual-branding__buttons .adyen-checkout__card__cardNumber__brandIcon {
        display: block;
      }
    }

    .horizontal-row {
      width: calc(100% + 30px);
      border-top: 1px solid $light-grey;
      margin-left: -15px;
      margin-bottom: 16px;

      &.shipping-restriction-box-bottom {
        width: 100%;
      }
      &.header-bottom-row {
        width: calc(100% + 15px);
      }
      &.payment-form-bottom {
        margin-top: 12px;
        width: calc(100% + 170px);
        margin-left: -96px;
      }
      &.radio-bottom-separator {
        margin-top: 16px;
        width: 100%;
        margin-left: 0;
      }
      &.payment-method-group {
        margin-top: 16px;
      }
      &.upper-title-row {
        margin-top: 30px;
        width: calc(100% + 170px);
        margin-left: -96px;
      }
      &.upper-billing-form {
        margin-top: 16px;
      }
    }
  }

  &.review {
    .checkout-review {
      .billing-review {
        margin-top: 0;
      }
      .horizontal-row {
        &.upper-title-row {
          width: calc(100% + 148px);
          margin-left: -74px;
          margin-top: 30px;
        }
      }
    }
  }

  .proceed-to-review {
    button {
      width: 145px;
    }
  }

  .radio-item-checked {
    background-color: $light-grey;
    width: calc(100% + 181px);
    padding: 24px 0;
    margin-left: -96px;
    padding-left: 72px;
  }

  .shipping-form-data {
    padding: 0 84px;
  }
}

.order-summary-box-container {
  .order-summary-box {
    padding: 0 84px;
    &-title {
      justify-content: space-between;
      display: flex;
      width: 100%;
      &-text {
        height: 48px;
        padding: 18px 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        text-transform: uppercase;
      }
      &-icon {
        margin-top: 1.8rem;
      }
    }
    &-info {
      &-icon {
        margin-top: 0;
        max-width: 30px;
      }
    }
  }

  .accordion {
    .order-summary-box-review {
      .js-accordion-trigger {
        padding: 0 84px;
      }
      .order-summary-box-info {
        padding: 0 84px;
      }
    }
  }
  .order-summary-box-info-text {
    .js-accordion-trigger {
      padding: 0 84px;
      align-items: center;
    }
    .accordion-content {
      padding: 0 84px;
    }
  }

  .bag-review-container {
    .bag-review-title {
      padding: 0 84px;
      height: 26px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $black;
      text-transform: uppercase;
    }

    .shipping-restriction {
      margin-bottom: 16px;
      padding: 0 84px;
      &-title {
        justify-content: space-between;
        display: flex;
        width: 100%;
        &-text {
          height: 48px;
          padding: 18px 0;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          color: $black;
          text-transform: uppercase;
        }
        &-icon {
          margin-top: 1.8rem;
        }
      }
    }
  }

  .horizontal-row {
    &.order-summary-elements-top {
      margin-bottom: 16px;
      margin-top: 16px;
    }
  }

  .customer-care {
    padding: 0 84px;
    margin-bottom: 58px;
    &-title {
      justify-content: space-between;
      display: flex;
      width: 100%;
      &-text {
        height: 48px;
        padding: 18px 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        text-transform: uppercase;
      }
      &-icon {
        margin-top: 1.8rem;
      }
    }
  }
  .return-and-services {
    padding: 0 84px;
    &-title {
      justify-content: space-between;
      display: flex;
      width: 100%;
      &-text {
        height: 48px;
        padding: 18px 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        text-transform: uppercase;
      }
      &-icon {
        margin-top: 1.8rem;
      }
    }
  }

  .bag-review {
    &-title {
      height: 26px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $black;
      text-transform: uppercase;
    }
    .bag-product {
      .product-info {
        height: 160px;
        &-img {
          height: 100%;
          > div {
            width: 120px;
            height: 160px;
          }
        }
        .bag-product__content {
          .bag-product__name {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            color: $black;
          }
          .bag-product__details {
            > .row {
              margin-top: 8px;
              &:first-child {
                margin-top: 16px;
              }
              .bag-product__details-name {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.8;
                letter-spacing: 0.3px;
                color: $black;
                text-transform: uppercase;
              }
              .col-6 {
                &:last-child {
                  display: flex;
                  justify-content: flex-end;
                  .bag-product__details-value {
                    height: 22px;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.8;
                    letter-spacing: 0.3px;
                    color: $black;
                  }
                }
              }
            }
            .bag-product__details-quantity {
              margin-top: 8px;
              > .row {
                .col-6 {
                  &:last-child {
                    display: flex;
                    justify-content: flex-end;
                    .bag-product__details-quantity__value {
                      height: 21.6px;
                      font-size: 12px;
                      font-weight: normal;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.8;
                      letter-spacing: 0.3px;
                      color: $black;
                    }
                  }
                }
              }
              .bag-product__details-quantity__label {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.8;
                letter-spacing: 0.3px;
                color: $black;
                text-transform: uppercase;
              }
            }
          }
        }
      }
      .shipping-and-price {
        margin-top: 16px;
        .bag-product__shipping {
          &-text {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: 0.3px;
            color: #096a0e;
            text-transform: uppercase;
          }
        }
        .bag-product__price {
          display: flex;
          justify-content: flex-end;
          &-text {
            height: 22.4px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            text-align: right;
            color: $black;
          }
        }
      }
    }
  }

  .pickup-in-store {
    padding: 0 84px;
    &-title {
      height: 26px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $black;
      text-transform: uppercase;
    }
    &-location {
      margin-bottom: 24px;
      margin-top: 16px;
      .row {
        .col {
          height: 12px;
          display: flex;
          align-items: center;
          .icon--pin {
            height: 12px;
            width: 8px;
            margin-right: 10px;
          }
          .shop {
            height: 12px;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.5px;
            color: $black;
          }
        }
      }
    }
    .bag-review {
      width: 100%;
      .bag-product {
        .shipping-and-price {
          .bag-product__shipping {
            &-text {
              display: none;
            }
          }
        }
      }
    }
  }

  .details-box {
    background: $light-grey;
    margin-bottom: 16px;
    margin-left: 0;
    margin-top: 16px;
    padding-top: 8px;
    padding-left: 84px;
    padding-right: 84px;
    width: 100%;
    > .row {
      padding-right: 68px;
    }
  }
  .estimated-total-row {
    padding: 0 84px;
    &-title {
      p {
        height: 22.4px;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
    &-price {
      p {
        height: 22.4px;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        text-transform: uppercase;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.review-summary-box {
  .order-summary-box-info-text {
    .js-accordion-trigger {
      padding: 0;
    }
  }
  .order-summary-box-container {
    .accordion {
      .order-summary-box-review {
        .js-accordion-trigger {
          padding: 0;
        }
        .order-summary-box-info {
          padding: 0;
        }
      }
    }
    .bag-review-container {
      .bag-review-title {
        padding: 0;
      }

      .shipping-restriction {
        margin-bottom: 16px;
        padding: 0;
      }
    }

    .horizontal-row {
      &.estimated-total-row-bottom {
        display: none;
      }
    }

    .details-box {
      margin-left: -74px;
      padding-top: 8px;
      padding-left: 74px;
      padding-right: 83px;
      width: calc(100% + 158px);
    }
    .estimated-total-row {
      padding: 0;
    }
  }
}

.button-and-terms-conditions {
  margin-top: 24px;
  .component-container-button {
    padding: 0;
  }
}

.checkout-navigations__steps {
  &-current {
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    font-weight: 500;
    color: $black;
  }
  &-total {
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    font-weight: 500;
    color: $dark-grey;
  }
}
