.product-highlight {
  &__content {
    display: flex;
    flex-direction: row;
  }

  &__image,
  &__product {
    width: 50%;
  }

  &__product {
    display: flex;
    align-items: center;
    padding: 0;

    & > .product {
      flex: 0 0 50%;
      padding: 0;
    }

    .experience-assets-product {
      width: 50vw;
      display: flex;
      justify-content: center;

      .product {
        flex: 0 0 50%;
        padding: 0;
      }

      .newTile-true {
        .single-element-content-image {
          .swiper-slide-overlay {
            display: block;
          }
        }
      }
    }
  }
}