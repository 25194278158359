.product-tile-editorial {
  .single-element-content.product-tile {
    display: block;
    .tile-body.single-element-content-detail {
      padding: 16px 64px 32px 24px;

      .product-link {
        font-family: $futura-book;
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.015em;
        text-align: left;
      }
    }
  }
}
