.login-register-page {
  .register-benefit {
    .title {
      font-size: 20px;
    }
    p {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2.2rem;
      letter-spacing: 0.5px;
    }
  }
  &.login-page {
    .register-benefit {
      display: block;
      padding-top: 20px;
      padding-bottom: 43px;
    }
  }
  .registration {
    .btn-register {
      width: 100%;
    }
  }

  .select-icon {
    position: absolute;
    top: 36px;
    left: unset;
    right: 12px;
    bottom: auto;
  }
.dynamic-validation-message {
  position: relative;
  margin-left: 15px;
  
  &.error-length {
    margin-top: 8px;
  }

  &:not(.green):before {
    content: "";
    display: block;
    width: 10px;
    height: 1px;
    background: #666;
    left: -15px;
    top: 42%;
    position: absolute;
  }

  &.green {
    color: green;

    &:before {
      content: "L";
      position: absolute;
      left: -15px;
      font-family: arial;
      -ms-transform: scaleX(-1) rotate(-35deg);
      -webkit-transform: scaleX(-1) rotate(-35deg);
      transform: scaleX(-1) rotate(-35deg);
    }
  }
}

#registration-form-password + .error-message {
  display: none;
}

.form-group.error {
  .dynamic-validation-message:not(.green) {
    color: #e74c3c;
    margin-top: 4px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .015em;
    font-weight: normal;

    &::before {
      background: #e74c3c;
    }
  }
}

}

.register-benefit {
  display: none;
  @extend %label--small;
  .title {
    @extend %body--medium;
    margin-bottom: 16px;
  }

  li {
    display: flex;
    align-items: center;
    padding: 8px 0;
    img {
      width: 15px;
      height: 15px;
      padding-right: 10px;
    }
  }
}

.login-register-page {
  padding-top: 17px;
  padding-bottom: 25px;
  .main-title {
    @extend %heading-medium;
    text-transform: uppercase;
  }
}

//////////
.login-page {
  .login-container {
    display: flex;
  }

  .login-box {
    padding: 16px 0;
    & + .login-box {
      border-top: 1px solid $light-grey;
    }

    .login-account {
      width: 100%;
    }

    p {
      @extend %body--medium;
    }

    .box-body {
      padding-top: 16px;
    }

    .form-input {
      padding-bottom: 0;
      & + .form-input {
        padding-top: 2.5rem;
      }
    }
    .checkbox {
      margin-bottom: 0;
      input[type='checkbox'] {
        margin: 12px;
      }
    }

    .container-input-password {
      padding-bottom: 8px;
    }

    .btn-login {
      margin-top: 24px;
    }

    .guest-newsletter-wrapper {
      display: inline-flex;
      align-items: center;
    }

    .guest-login-form-disclaimer {
      @extend %body--small;
      padding-left: 48px;
      padding-bottom: 24px;
      color: $dark-grey;
      a {
        display: inline-flex;
      }
      .link-underline {
        &:before {
          border-color: inherit;
          bottom: 4px;
        }
      }
    }

    .forgot-password {
      margin-top: 16px;
    }
  }
}
