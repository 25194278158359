.checkbox {
  margin-bottom: ($space-unit * 3);
  break-inside: avoid-column;

  input[type='checkbox'] {
    // NB evitiamo di nascondere le checkbox e ricrearle con elementi grafici, megli stilare gli effettivi campi
    color: $black;
    // box-shadow: 0 0 0 .1rem $black;
    width: 2.4rem;
    height: 2.4rem;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: $space-line solid $black;
    cursor: pointer;
    flex-shrink: 0;
    border-radius: 0;
    padding: 11px 10px;
    &:disabled {
      cursor: not-allowed;
      border: $space-line solid $medium-grey;
      & + label {
        color: $medium-grey;
        cursor: not-allowed;
      }
    }

    &:focus {
      outline-offset: 0.3rem;
    }
    & + label {
      @extend %body--small--medium;
      display: inline-block;
      padding-left: ($space-unit * 3);
      cursor: pointer;
      position: relative;
    }

    &::after {
      position: relative;
      display: block;
      height: 0.4rem;
      width: 0.8rem;
      border-left: $space-line solid;
      border-bottom: $space-line solid;
      transform: rotate(-45deg);
      left: -0.3rem;
      top: -0.2rem;
      box-sizing: border-box;
      content: none;
    }

    &:checked::after {
      content: '';
    }
  }

  // container
  .checkbox__field {
    display: flex;
    justify-content: flex-start;
  }
}

.is-invalid {
  .checkbox {
    color: $status_error;
  }
}
