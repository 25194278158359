.page-cart-mobile {
  overflow-x: hidden;
  .cart-edit-link.link-underline::before {
    border-top: 1px solid $black;
  }
  .sticky-wrapper-totals {
    background: $white;
    margin: 0;

    .cart-checkout-buttons-wrapper {
      .btn--primary {
        text-transform: none;
        font-weight: 400;
        letter-spacing: 0.56px;
      }
    }
  }

  .cart-sidebar-section .wrapper-cart-totals {
    margin: 0;
  }

  .cart-top-section {
    .sticky-wrapper-totals {
      border-top: none;
    }

    .cart-head-section {
      .title {
        text-align: center;
        padding: 24px 0 24px;
        color: #111;
        font-family: $futura-book;
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.02em;
      }
    }

    .line-totals-item {
      position: relative;
      padding-bottom: 16px;
      border-bottom: 1px solid #e5e5e5;

      span {
        color: #111;
        font-family: $futura-book;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .mid-lines-start {
        letter-spacing: -2px;
      }

      .mid-lines {
        color: #999;
      }

      .grand-total {
        position: absolute;
        right: 0;
      }
    }

    .cart-top-info {
      padding-top: 16px;
      color: #999;
      text-align: center;
      font-family: $futura-book;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.36px;
      text-transform: none;
    }

    .cart-checkout-buttons-wrapper {
      margin-top: 24px;
    }
  }

  .cart-body-section {
    background: #f5f5f5;
    border-top: none;

    .title-list {
      text-align: center;
      font-family: $futura-book;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.035em;
      padding: 24px 0;
    }

    .product-wrapper {
      background: $white;
      padding: 16px 16px 0 16px;
      border-top: none;
      border-bottom: none;
      margin: 0 0 16px 0;

      .icon--shipping-medium {
        display: none;
      }

      .cart-item-grid {
        padding: 0;
      }

      .product-info-details {
        padding-left: 8px;

        .detail-attribute {
          justify-content: unset;
        }

        .cart-edit-link,
        .product-title,
        .value-field,
        .name-field {
          font-family: $futura-book;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.48px;
        }
        .product-title {
          font-weight: 400;
          color: #111;
        }

        .detail-attribute {
          margin-top: 8px;
        }

        .name-field {
          color: #999;
        }

        .cart-edit-link {
          color: $black;
          margin-left: 16px;

          .link-underline::before {
            bottom: 3px;
            border-top: 1px solid $black;
          }
        }

        .value-field {
          margin-left: 8px;
          color: #111;
          width: 100%;
          justify-content: space-between;
        }
      }

      .product-prices-wrapper {
        padding-left: 8px;
        justify-self: start;
        align-self: end;

        span {
          font-family: $futura-book;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.48px;
        }
      }

      .quantity-form {
        .btn--round {
          border: 1px solid #757575;

          &:after {
            background: #757575;
          }
        }
        .btn--plus {
          margin: 1.2rem 0 1.6rem 2.4rem;
        }

        .btn--minus {
          margin: 1.2rem 2.4rem 1.6rem 0;
        }

        .quantity-input {
          font-family: $futura-book;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.56px;
        }
      }

      .product-actions-wrapper {
        .option-ship-type .ship-action {
          padding: 16px 0;

          .icon--pick-up-small {
            display: none;
          }

          span {
            font-family: $futura-book;
            font-size: 14px;
            color: $black;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.56px;
          }
        }

        .bopis-disabled-label {
          padding: 16px 0;
          font-family: $futura-book;
          font-size: 14px;
          color: #757575;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.56px;
          width: 100%;
          display: block;
          text-align: center;
        }

        .product-remove-wrapper {
          .btn-cart-remove {
            padding: 16px 0;
          }
          .icon--trash {
            display: none;
          }

          span {
            font-family: $futura-book;
            font-size: 14px;
            color: $black;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.56px;
          }
        }

        .link-underline:before {
          border-top: 1px solid #757575;
          bottom: 3px;
        }
      }
    }

    .info-message-general {
      font-family: $futura-book;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.48px;
      padding: 0 0 16px 0;
    }
  }

  .cart-sidebar-section {
    grid-template-areas:
      'heading'
      'totals'
      'voucher'
      'buttons'
      'accordions';

    .wrapper-cart-totals {
      background: $white;
      padding-bottom: 24px;

      span {
        color: #111;
        font-family: $futura-book;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.3px;
      }

      .mid-lines-start {
        letter-spacing: -2px;
      }

      .mid-lines {
        color: #999;
      }
    }

    .voucher-wrapper {
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;

      .title-section {
        color: #111;
        font-family: $futura-book;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.3px;
      }

      .input-d-none {
        .coupon-code-field,
        .promo-code-btn {
          display: none;
        }
      }

      .button__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.56px;
      }

      .coupons-and-promos {
        width: fit-content;
        background: #eeedeb;
        margin-top: 8px;

        .coupon-price-adjustment {
          padding: 8px;
          .coupon-code {
            margin-right: 8px;
            color: #757575;
          }
        }
        .close-wrapper {
          background: #bcbcbc4d;
          border-radius: 99px;
          height: 14px;
          width: 14px;
          position: relative;
          align-self: center;
          .icon--close {
            width: 8px;
            height: 8px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            filter: invert(45%) sepia(0%) saturate(0%) hue-rotate(72deg) brightness(103%) contrast(98%);
          }
        }
      }

      .accordion-trigger {
        padding: 16px 0;
      }

      .form-input {
        padding: 0;
        input {
          margin: 0;
          border: 1px solid #ccc;
          color: #111;
          font-family: $futura-book;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }

        .coupon-code-field-active {
          border: 1px solid #000;
        }

        .coupon-code-field-error {
          border: 1px solid #e32001;
        }

        .error-message {
          margin: 0;
          color: #e32001;
          font-family: $futura-book;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.3px;
        }
      }

      .button--loading .button__text {
        visibility: hidden;
        opacity: 0;
      }

      .button--loading::after {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid #d9d9d980;
        border-top-color: #ffffff;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease infinite;
      }

      @keyframes button-loading-spinner {
        from {
          transform: rotate(0turn);
        }

        to {
          transform: rotate(1turn);
        }
      }

      .promo-code-btn {
        text-transform: none;
        padding: 16px 24px;
      }

      .ab-disabled {
        background-color: #e5e5e5;
        color: #999 !important;
        font-family: $futura-book;
        font-size: 16px;
        font-weight: 400;
        border: 1px solid #e5e5e5;
        pointer-events: none;
      }

      p {
        display: none;
      }
    }

    .accordion {
      &.is-enabled {
        .cart-promo-accordion {
          .accordion-content {
            max-height: unset;
          }
        }
      }
    }

    .cart-promo-accordion {
      height: 54px;
      overflow: hidden;
      transition: 0.4s;

      .voucher-accordion-content {
        // animation-name: slideOutDrop;
        // animation-duration: 1s;
        display: block;
      }
      &.is-active {
        height: 118px;

        .voucher-accordion-content {
          // animation-name: slideInDrop;
          // animation-duration: 1s;
          overflow: hidden;
          display: block;
          transition: max-height 0.2s ease-out;
        }
      }
    }

    .sticky-wrapper-totals {
      border-top: none;

      span {
        color: #111;
        font-family: $futura-book;
        font-style: normal;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.04em;
      }

      .cart-checkout-buttons-wrapper {
        margin: 24px 0 0;

        .btn--primary {
          text-transform: none;
          font-weight: 400;
          letter-spacing: 0.56px;
        }
      }
    }

    .cart-head-section {
      .title {
        text-align: center;
        color: #111;
        font-family: $futura-book;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.035em;
        padding: 20px 0 8px;
      }
    }
  }
}
