.cookies-modal-popup {
  display: none;
  height: 100svh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  &-overlay {
    background-color: #00000087;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 20;
  }
  &-content {
    background-color: $white;
    bottom: 0;
    color: $black;
    display: flex;
    height: 298px;
    flex-flow: row wrap;
    flex: 1 0 auto;
    overflow: auto;
    padding: 16px;
    position: absolute;
    width: 100%;
    &.show {
      display: flex;
    }
    .cookies {
      &-accept-button {
        height: 48px;
      }
      &-description {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.3px;
      }
      &-management {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-align: center;
        width: 100%;
      }
      &-title {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
  }

  .cookies-titlt {
    text-transform: uppercase;
    font-weight: 450;
  }

  .popup-close {
    position: absolute;
    right: 30px;
    color: #757575;
    text-decoration: underline;
    font-size: 11px;
    cursor: pointer;
  }
}

.cookies-management-modal-popup {
  background-color: $white;

  color: $black;
  display: none;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;

  .cookieManagement-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 24px;
    text-transform: uppercase;
  }

  .cookie-modal-footer {
    background-color: $white;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    a {
      font-size: 12px;
      line-height: 2;
      color: $black;
    }
    flex-grow: 0;
    padding-bottom: 0;
    .wrapper-actions {
      margin-bottom: 16px;
      display: flex;
      flex: 1 1 auto;
      justify-content: space-between;
      flex-direction: column;
      // @include mq(lg) {
      //   flex-direction: row;
      // }
    }
    button {
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
      & + button {
        margin-top: 30px;
      }
      // @include mq(md) {
      //   width: calc(50% - 20px);
      //   & + button {
      //     margin-top: 0px;
      //   }
      // }
    }
  }

  .cookie-paragraph {
    font-size: 12px;
    letter-spacing: 0.48px;
    line-height: 2;
    margin-right: 0;
    margin-left: 39px;
    margin-top: 10px;
  }

  .custom-checkbox {
    margin-bottom: 30px;
  }

  .custom-control-label {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
  }

  .modal-content {
    height: 100vh;
    position: relative;
  }
  .modal-close-container {
    height: 32px;
    display: flex;
    flex-direction: row-reverse;
  }
  .modal-close {
    position: relative;
    z-index: 12;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .modal-body {
    padding: 16px;
    &:not(.cookie-modal-footer) {
      height: calc(100% - 198px);
    }
  }

  .modal-body:nth-child(1) {
    // @include mq(lg) {
    //   max-height: 575px;
    // }
    overflow-y: scroll;
    padding-bottom: 0;
  }

  .modal-dialog {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    height: 100%;
  }

  .form-control,
  .button,
  input[type='checkbox'] ~ label::before {
    box-sizing: border-box;
  }

  .view-privavy {
    a {
      font-weight: 500;
    }
  }
}
