.store-locator-breadcrumb {
  border-bottom: 1px solid $light-grey;
}

.store-locator-canvas-container {
  .gm-style,
    .infowindow-layer.open {
      .actions {
        justify-content: space-between;
        flex-direction: row;

        .btn {
          font-size: 12px;
          padding: 18px 30px;
        }

        .btn.details {
          margin-right: 5%;
          margin-bottom: 0;
        }
      }
    }

    .map-canvas {
      min-height: 100%;
    }

    &.layout-split {
      .map-canvas {
        width: 60%;
        margin: 0 0 0 auto; 
      }
    }
} 

.store-detail-breadcrumb {
  border-bottom: none;
}


.store-detail-container {
  border-bottom: 1px solid $light-grey;
  .map-canvas {
    min-height: 520px;
  }

  .store-details {
    padding: 24px;
    border-right: 1px solid $light-grey;
  }

  .store-map {
    padding: 40px;
  }

  .store-detail-info {
    display: flex;
    padding: 0 15px;
  }
  .store-name {
    padding: 40px 15px;
    border-bottom: 1px solid $light-grey;
    border-top: 1px solid $light-grey;

    h1 {
      text-align: center;
      text-transform: uppercase;
      font-size: 28px;
      font-weight: 500;
    }
  }

  .store-box {
    .store-services,
    .store-hours {
      padding: 15px 0;
    }
    .store-services {
      border: none;
    }
  }

  &.store-direction-wrapper {
    border: none;

    .store-box .actions .btn {
      margin: 0 auto;
      max-width: 480px;
    }
  }
}