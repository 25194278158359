.pd-richtext {
  p {
    font-size: inherit;
    color: inherit;
  }
}
.pd-text-align-left {
  text-align: left;
}
.pd-text-align-center {
  text-align: center;
}
.pd-text-align-right {
  text-align: right;
}

.pd-text-style-bold {
  font-weight: bold;
}
.pd-text-style-italic {
  font-style: italic;
}
.pd-text-style-underline {
  text-decoration: underline;
}
.pd-text-style-strike {
  text-decoration: line-through;
}
.pd-anchor-text--uppercase,
.pd-text--font-uppercase {
  text-transform: uppercase;
}

.pd-h1-style,
.pd-h2-style,
.pd-h3-style,
.pd-h4-style,
.pd-h5-style,
.pd-h6-style {
  margin: 0;
  line-height: 1;
}

/** MARNI PAGE DESIGNER **/
.pd-text--font {
  &-16-20 {
    font-size: 1.6rem;
    line-height: 2.55rem;
  }
  &-20-38 {
    font-size: 2rem;
    line-height: 3rem;
  }
  &-14-14 {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  &-12-12 {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  &-Book {
    font-weight: 400;
  }
  &-Medium {
    font-weight: 500;
  }
  &-Heavy {
    font-weight: 700;
  }
}

//
