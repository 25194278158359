.hero-store-locator {
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
}

.store-locator-breadcrumb {
  .store-detail-breadcrumb {
    border: none;
  }
}

.store-locator-canvas-container {
  padding-left: 0;

  .store-locator-fields-layer {
    margin-bottom: 20px;

    .results-panel {
      margin: 10px 20px 20px;
    }
  }

  .map-canvas {
    width: 100%;
    min-height: 50vh;
  }

  &:not(.layout-split) .fields-body {
    .fields-box {
      background-color: #fff;
      pointer-events: auto;
    }
    .results-length {
      display: none;
    }
  }

  .results-length {
    display: block;
    padding: 20px 0 0;
  }

  .fields-header {
    margin-bottom: 20px;
  }
  .title-fields {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 1.5px;
    color: $black;
    text-transform: uppercase;
  }
  .store-locator-fields-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .store-detect-location-wrapper {
    padding: 10px;
    border: 1px solid;
    line-height: 1;
  }
  .form-group {
    position: relative;
    width: 100%;
    margin-right: 10px;
    input {
      color: $dark-grey;
      width: 100%;
      margin: 0;
    }
    .icon--search {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  .store-box-info {
    padding: 18px 0;
    border-bottom: 1px solid $light-grey;
  }

  .store-box-header {
    display: flex;
    justify-content: space-between;

    .store-name {
      text-transform: uppercase;
      padding-bottom: 5px;
    }
  }

  .more-info-wrap {
    min-width: fit-content;
  }

  .more-info,
  .less-info {
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }

  .live-open {
    &.closed {
      color: $status_error;
    }
    &.open {
      color: $status_success;
    }
  }

  .gm-style .gm-style-iw-c,
  .gm-style-iw-d,
  .gm-style-iw .gm-style-iw-c {
    width: 100%;
    border-radius: 0;
    font-family: $futura-medium;
  }
  .gm-style-iw-d {
    padding: 10px;
    line-height: 1.6;
  }
  .gm-ui-hover-effect {
    top: 17px !important;
    right: 15px !important;
    width: 23px !important;
    height: 23px !important;
    opacity: 1;
    background: url(../images/storelocator/close.svg) no-repeat center !important;
    img {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    span {
      display: none !important;
    }
  }

  .gm-style,
  .infowindow-layer.open {
    .store-name {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .live-open {
      margin-bottom: 15px;
    }

    .address {
      font-size: 12px;
    }

    .label {
      font-size: 12px;
      font-weight: normal;
      color: $dark-grey;
    }

    .info-box {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $light-grey;

      .info-row {
        display: block;
        position: relative;
        .icon {
          position: absolute;
          top: 5px;
        }

        .cta {
          padding-left: 20px;
        }
      }
    }

    .more-info {
      margin-bottom: 24px;
      display: block;
    }

    .less-info {
      margin-bottom: 24px;
      display: block;
    }

    .services {
      display: none;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $light-grey;
    }

    .less-info-text {
      display: none;
    }

    .more-info-content {
      &.is-active {
        .more-info-text {
          display: none;
        }

        .less-info-text {
          display: block;
        }
        .services {
          display: block;
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none;
        }
      }
      .store-box {
        .store-services,
        .store-hours {
          padding: 0 0 15px 0;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;

      .btn.details {
        margin-bottom: 20px;
      }
    }
  }

  .infowindow-layer {
    display: none;
    &.open {
      display: block;
      position: fixed;
      width: 100%;
      padding: 20px;
      bottom: 0;
      background: white;
      min-height: 345px;
      z-index: 9;
    }
    .hero-infowindow-close {
      position: absolute;
      top: 17px;
      right: 15px;
      width: 23px;
      height: 23px;
      background: url(../images/storelocator/close.svg) no-repeat center;
      cursor: pointer;
    }
  }
}

.store-detail-breadcrumb {
  border-bottom: 1px solid $light-grey;

  .breadcrumb-component .breadcrumb {
    padding: 0 15px;
    justify-content: flex-start;
  }

  .breadcrumb-item.last a {
    color: $dark-grey;
  }
}

.store-detail-container {
  .store-detail-info {
    padding: 0;
  }
  .store-name,
  .store-details,
  .actions,
  .store-services,
  .store-hours {
    padding: 0 15px;
  }

  .store-name {
    padding: 25px 15px;
    h1 {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 500;
    }
  }

  > .store-box {
    padding: 0;
  }

  .store-details {
    .actions {
      padding: 0;
    }
  }

  .store-box {
    display: flex;
    flex-direction: column;
    .info-row {
      margin-bottom: 10px;

      .label {
        font-weight: normal;
      }
    }
    .store-address {
      font-weight: 500;
    }

    .actions {
      margin: 20px 0;
      .btn {
        margin-bottom: 15px;
      }

      .btn.whatsapp {
        border-color: $mountain-meadow;
        background-color: $mountain-meadow;
      }
    }

    .store-box-title {
      display: block;
      font-size: 14px;
      font-weight: 500;
    }

    .store-service {
      .label {
        font-size: 12px;
        font-weight: normal;
      }
    }

    .store-services {
      padding: 15px;
      border-bottom: 1px solid $light-grey;
    }

    .store-box-title {
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .store-hour-box {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  .map-canvas {
    width: 100%;
    min-height: 50vh;
  }

  &.store-direction-wrapper {
    border-bottom: 1px solid $light-grey;
  }

  &.store-hours-wrapper {
    margin: 15px 0;
    border-bottom: none;
  }

  .holiday-hours {
    margin-bottom: 15px;

    .store-hour-box {
      text-transform: uppercase;
    }
  }
}
