.order-detail-data-container {
  .is-sticky {
    width: 100%;
    .order-actions-mobile {
      position: fixed;
      bottom: 1.6rem;
      left: 1.6rem;
      right: 1.6rem;
    }
  }

  .order-actions-mobile {
    display: flex;
    padding: 2.4rem 0 1.6rem 0;
    // width: calc(100% - 3.2rem);
    align-items: center;
  }
}
