.account-dashboard {
  .account-dashboard-title {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 1.5px;
    color: $black;
    text-transform: uppercase;
    margin-top: 24px;
  }
  .account-dashboard-items {
    padding-bottom: 16px;
    .account-item {
      align-items: center;
      border: 1px solid $light-grey;
      display: flex;
      justify-content: space-between;
      margin: 8px 0;
      padding: 16px 19px;
      &-content {
        align-items: center;
        display: flex;
        &-icon {
          width: 34px;
          height: 40px;
          margin-right: 16px;
        }
        &-text {
          &-title {
            color: $black;
            font-size: 12px;
            font-stretch: normal;
            font-style: normal;
            font-weight: 500;
            line-height: 1.6;
            letter-spacing: 0.5px;
            text-transform: uppercase;
          }
          &-description {
            display: none;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.3px;
            line-height: 1.8;
            text-align: center;
          }
        }
      }
      &-arrow {
        height: 12px;
        transform: rotate(-90deg);
        width: 6px;
      }
    }
  }

  &.contacts {
    padding: 0;
    .account-account-contacts {
      background-color: $light-grey;
      // margin: 0 -15px;
      &-element {
        padding: 0 15px;
        &-icon {
          display: flex;
          justify-content: center;
          span {
            width: 40px;
            height: 40px;
            margin-bottom: 24px;
          }
        }
        &-title {
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 0.5px;
          margin-bottom: 8px;
          text-align: center;
          text-transform: uppercase;
        }
        &-text {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: 0.3px;
          text-align: center;
        }
        .horizontal-row {
          &.contact-separator {
            border-top: 1px solid $medium-grey;
            margin: 24px 0;
          }
        }

        &:first-child {
          padding-top: 24px;
        }
        &:last-child {
          padding-bottom: 24px;
          .contact-separator {
            display: none;
          }
        }
      }
    }
  }

  .horizontal-row {
    .boxes-bottom-row {
      border-top: 1px solid $light-grey;
    }
  }

  .promo-box {
    margin: 16px 0;
    &-title {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    &-description {
      margin-top: 8px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
    }
  }
}

.image-centered{
  position: relative;
  width: 100%;
  overflow: hidden;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    width: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}

#account-show {
  .main--layout-page {
    min-height: 100%;
  }
}
