@use "sass:math";

@each $multiply in $spacing-multiplier {
  $space: $space-unit * $multiply;
  $space-nounit: math.div($space, 0.1rem);
  // margin bottom
  .mb-#{$space-nounit} {
    margin-bottom: $space;
  }
  // margin top
  .mt-#{$space-nounit} {
    margin-top: $space;
  }
  // padding bottom
  .pb-#{$space-nounit} {
    padding-bottom: $space;
  }
  // padding top
  .pt-#{$space-nounit} {
    padding-top: $space;
  }
}

// class used by js to generate mqObj
.custom-mq {
  $mqObj: '';
  @each $name, $value in $grid-breakpoints {
    @if $mqObj != '' {
      $mqObj: $mqObj + '&';
    }
    $mqObj: $mqObj + $name + '=' + $value;
  }
  font-family: $mqObj;
}

.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
@each $name, $br in $grid-breakpoints {
  @include breakpoint($name, min) {
    .d-#{$name}-none {
      display: none !important;
    }
    .d-#{$name}-block {
      display: block !important;
    }
    .d-#{$name}-flex {
      display: flex !important;
    }
  }
}

.hidden {
  // note that this is hidden to screen reader
  visibility: hidden;
}

.link {
  text-decoration: underline;
}

.block {
  display: block;
}

img[data-sizes='auto'] {
  display: block;
  width: 100%;
}

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

.unstyled-list {
  list-style: none;
}

.d-none-checkout {
  display: none !important;
}

.toggle-password {
  position: absolute;
  top: 35px;
  right: 12px;
  .visible-password {
    display: none;
  }
  &.show-password {
    .visible-password {
      display: flex;
    }
    .hidden-password {
      display: none;
    }
  }
}

.mm-form--needs-validation {
  .form-input {
    .no-background {
      background-image: none !important;
    }
    .toggle-password {
      display: none;
      &.visible {
        display: flex;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
}
