.experience-layouts-cosmosCarousel,
.experience-layouts-productCarousel,
.experience-layouts-sliderGallery {
  .pd-carousel__products-region {
    .experience-component.experience-assets-product {
      max-width: unset;
    }
  }
  
  .pd-carousel__products-region.not-two-view-mobile {
    .experience-component.experience-assets-product {
      max-width: unset;
    }
  }
}