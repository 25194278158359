.address_form,
.gift-form {
  .form-control {
    input,
    textarea {
      width: 100%;
    }
  }
}
.phone-row-checkout {
  .col-4:nth-child(1) {
    padding-right: 8px;
  }
  .col-4:nth-child(2) {
    padding-left: 8px;
    padding-right: 8px;
  }
  .col-4:nth-child(3) {
    padding-left: 8px;
  }
  .phone-margin-checkout {
    position: relative;
    // padding-top: 22px;
    .phone-dash {
      display: block;
      top: 37px;
      left: -2px;
      position: absolute;
    }
  }
}
