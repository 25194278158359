.account-dashboard {
  .account-dashboard-title {
    text-align: center;
  }
  .account-dashboard-items {
    padding-bottom: 24px;
    margin-right: -12px;
    margin-left: -12px;

    .account-item {
      align-items: flex-start;
      border: 1px solid $light-grey;
      flex-basis: 30%;
      height: 250px;
      margin: 12px;
      max-width: 31%;
      width: 359px;
      &-arrow {
        display: none;
      }
      &-content {
        // display: block;
        flex-direction: column;
        width: 100%;
        &-icon {
          margin-right: 0;
          margin-bottom: 16px;
        }

        &-text {
          &-description {
            display: block;
            margin-top: 8px;
          }
          &-title {
            text-align: center;
          }
        }
      }
    }
  }

  .horizontal-row {
    padding: 0 28px;
    .boxes-upper-row {
      border-top: 1px solid $light-grey;
      margin-bottom: 8px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .promo-box {
    margin: 0 auto;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 16px;
      margin-bottom: 16px;
      text-align: center;
      .col {
        width: 886px;
        max-width: 886px;
      }
    }
  }

  // .account-dashboard-search {
  //   margin-right: 0;
  //   margin-left: 0;
  //   padding-right: 0;
  //   padding-left: 0;
  //   > .col {
  //     padding-left: 0;
  //   }
  // }

  // &.contacts {
  //   padding: 0;
  //   .account-dashboard-contacts {
  //     margin-left: 0;
  //     margin-right: 0;
  //     padding: 16px 0;
  //     &-element {
  //       padding: 16px 58px;
  //       border-left: 1px solid $medium-grey;
  //       &:first-child {
  //         padding: 16px 58px;
  //         border-left: 0;
  //       }
  //       &:last-child {
  //         padding-bottom: 16px;
  //       }
  //     }

  //     .horizontal-row {
  //       &.contact-separator {
  //         display: none;
  //       }
  //     }
  //   }
  // }
}
