.page-help-menu {
  overflow-x: hidden;
  margin: 0 -16px;
  > .col {
    .menu-list {
      padding: 0 4px;
      width: 100%;
      list-style: none;
      display: flex;
      overflow-x: auto;
      height: 48px;
      background: $light-grey;
      align-items: center;
      &-item {
        width: auto;
        flex: 1 0 auto;
        margin: 0 12px;
        a {
          text-transform: uppercase;
          height: 14px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 1px;
        }
        &.active {
          a {
            text-decoration: underline;
            font-weight: 900;
          }
        }
      }
    }
  }
}
.page-help-container {
  .page-help-menu .menu-list.sticky {
    position: fixed;
    z-index: 1;
  }

  .help-menu-col {
    position: sticky;
    z-index: 1;
    top: -55px;

    .page-help-title {
      height: 38.4px;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1.5px;
      color: black;
      text-transform: uppercase;
      margin-top: 16px;
    }

    .page-help-menu {
      overflow-x: hidden;
      margin: 0 -16px;
      > .col {
        .menu-list {
          padding: 0 4px;
          width: 100%;
          list-style: none;
          display: flex;
          overflow-x: auto;
          height: 48px;
          background: $light-grey;
          align-items: center;
          &-item {
            width: auto;
            flex: 1 0 auto;
            margin: 0 12px;
            a {
              text-transform: uppercase;
              height: 14px;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 1px;
            }
            &.active {
              a {
                text-decoration: underline;
                font-weight: 900;
              }
            }
          }
        }
      }
    }
  }

  .help-content-col {
    .help-content-box {
      margin-bottom: 48px;

      &-title {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1.5px;
        color: $black;
        text-transform: uppercase;
        margin-top: 24px;
      }

      &-subtitle {
        margin-top: 24px;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1.3px;
        color: $black;
        text-transform: uppercase;
      }
      &-subtitle-no-bold {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1px;
        color: $black;
        text-transform: uppercase;
        margin-top: 24px;
        margin-bottom: 16px;
      }

      &-section {
        &-paragraph {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 0.3px;
          color: $black;
          margin-top: 16px;
          &.uppercased {
            text-transform: uppercase;
          }
        }
        &-unordered-list {
          margin-top: 24px;
          padding-left: 16px;
        }
        .help-search-order {
          > .row {
            margin-bottom: 16px;
            margin-left: 0;
            margin-right: 0;
            margin-top: 8px;
            .search-order-label {
              padding-left: 0;
            }
          }
          .user-email {
            height: 48px;
            display: flex;
            align-items: flex-end;
            width: 100%;
            padding-bottom: 8px;
            justify-content: space-between;
            padding-left: 0;
            flex-direction: column;
            position: relative;
            padding-right: 0;

            &-icon {
              width: 30px;
              height: 18px;
              transform: rotate(180deg);
            }
            &-number-input {
              margin: 0;
              padding: 0;
              padding-left: 4px;
              border: none;
              width: 100%;
              border-bottom: 1px solid $black;
              padding-bottom: 4px;

              &.error {
                border-bottom: 1px solid $status_error;
              }
            }

            .error-message {
              color: $status_error;
              width: 100%;
              text-align: left;
            }
          }
          .search-order {
            height: 48px;
            display: flex;
            align-items: flex-end;
            width: 100%;
            padding-bottom: 8px;
            justify-content: space-between;
            padding-right: 0;
            padding-left: 0;
            flex-direction: column;
            position: relative;
            &-icon {
              width: 30px;
              height: 18px;
              transform: rotate(180deg);
              position: absolute;
              top: 6px;
            }
            &-number-input {
              border: none;
              border-bottom: 1px solid $black;
              margin: 0;
              padding: 0;
              padding-left: 4px;
              width: 100%;
              padding-bottom: 7px;
              margin-top: 4px;
              &.error {
                border-color: $status_error;
              }
            }
            .error-message {
              text-align: left;
              width: 100%;
              color: $status_error;
            }
          }
        }
      }

      .help-content-box-section-accordion-group {
        margin-top: 16px;
        .accordion {
          .single-accordion {
            margin: 8px 0;

            button {
              width: 100%;
              display: flex;
              justify-content: space-between;
              height: 14px;
              font-size: 14px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 1.3px;
              color: $black;
              text-transform: uppercase;
            }

            .accordion-content {
              &-title {
                height: 23px;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                letter-spacing: 0.3px;
                color: black;
                margin-top: 8px;
                text-transform: capitalize;
              }
              &-table {
                overflow-x: auto;
                .table {
                  width: 100%;
                  margin-top: 8px;
                  padding-bottom: 8px;
                  thead {
                    tr {
                      th {
                        text-align: center;
                        height: 26px;
                        font-size: 16px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.6;
                        letter-spacing: 1px;
                        color: $black;
                        text-transform: uppercase;

                        &:not(:first-child) {
                          padding: 0 16px;
                        }
                        &:first-child {
                          padding-right: 16px;
                        }
                      }
                    }
                  }
                  tbody {
                    tr {
                      td {
                        padding: 0 16px;
                        text-align: center;
                        height: 23px;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.6;
                        letter-spacing: 1px;
                        color: $dark-grey;
                        text-transform: uppercase;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .help-shipping-methods {
        .single-method {
          align-items: center;
          display: flex;
          border: solid 1px $light-grey;
          flex-direction: column;
          height: 135px;
          justify-content: center;
          width: 100%;
          margin: 8px 0;
          p {
            margin-top: 0;
          }
        }
      }

      .help-contact-form {
        margin-top: 8px;
        .fsb-select {
          border-radius: 2px !important;
        }
        .form-control {
          width: 100%;
        }
        .select-arrow {
          position: absolute;
          top: 36px;
          left: unset;
          right: 12px;
          bottom: auto;
          background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3e%3cg clip-path='url(%23clip0_289_349)'%3e%3cpath d='M12 13.171l4.95-4.95 1.414 1.415L12 16 5.636 9.636 7.05 8.222l4.95 4.95z' fill='%23404040'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_289_349'%3e%3cpath fill='white' d='M0 0h24v24H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e") center no-repeat;
          width: 2.4rem;
          height: 2.4rem;
          display: inline-block;
        }
        select {
          border: 1px solid $black;
          height: 48px;
        }
        > .form-input {
          margin-top: 16px;
          padding-bottom: 0;
          select {
            height: 48px;
          }
        }
        .form-select {
          select {
            appearance: none;
            height: 48px;
            width: 100%;
            + .select-icon {
              position: absolute;
              right: 16px;
              transform: translateY(28px);
              pointer-events: none;
            }
          }
        }

        .custom-attachments {
          .input-container {
            position: relative;
            display: inline-block;
            width: 100%;
            .help-contact-attachments {
              position: relative;
              z-index: 2;
              opacity: 0;
            }
            .custom-attachments-input {
              z-index: 1;
              height: 48px;
              border: 1px solid $black;
              width: 100%;
              position: absolute;
              top: 8px;
              right: 0;
              bottom: 0;
              left: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 16px 0 10px;
              &-text {
                height: 12px;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: 0.5px;
                color: $dark-grey;
              }
            }
          }
        }

        .contact-us-button {
          margin-top: 16px;
        }
      }

      .underlined-item {
        text-decoration: underline;
      }
    }
  }

  table {
    color: $black;
    background: $white;
    border: 1px solid $medium-grey;
    font-size: 12pt;
    border-collapse: collapse;
  }
  table thead th,
  table tfoot th {
    color: $dark-grey;
    background: $overlay;
  }
  table caption {
    padding: 0.5em;
  }
  table th,
  table td {
    padding: 0.5em;
    border: 1px solid $medium-grey;
  }
}

.page-help-search {
  margin-bottom: 0;
  margin-top: 0;
  border-bottom: 1px solid $light-grey;
  padding-top: 24px;
  padding-bottom: 32px;
  background-color: #fff;
  .help-search {
    display: flex;
    align-items: center;

    .error-message {
      display: none;
    }

    &-icon {
      width: 24px;
      height: 24px;
    }

    &-input {
      height: 21.6px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      color: $black;
      margin-top: 0;
      width: 100%;
      border: none;
      &.error {
        border-color: $status_error;
      }
    }
  }
}

.help-contacts {
  background: $white;
  bottom: 0;
  margin-left: -15px;
  padding: 16px 0;
  padding-right: 15px;
  position: fixed;
  width: 100%;
  z-index: 1;
  .col-6 {
    text-align: center;
    a {
      .help-contacts-text {
        text-decoration: underline;
      }
    }
  }
}

.live-chat-container {
  display: none;
  position: fixed;
  bottom: 67px;
  right: 14px;
  z-index: 1;
  .live-chat-icon {
    height: 72px;
    width: 72px;
  }
}
