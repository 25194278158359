#forgot-password {
  .modal-overlay {
    margin-top: 0;
  }
  .modal-container {
    max-width: 375px;
    min-width: auto;
    height: auto;
  }
}
