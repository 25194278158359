.address-book-container {
  .address-book-wrapper {
    .card-wrapper {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .horizontal-row {
    border-top: 1px solid $light-grey;
    &.preferred-address-bottom-row {
      margin: 0;
    }
  }
}
