.slot2-component {
  .swiper-pagination {
    display: none;
    align-items: center;
    height: 3rem;
    width: 100%;
    justify-content: center;
    padding: 0 6.9rem;
    margin-top: 2.3rem;
    .swiper-pagination-bullet {
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      color: black;
      opacity: 1;
      background: transparent;
      border: 1px solid black;

      width: 0.9rem;
      height: 1.2rem;
      margin: 1rem;
      transform: rotate(-270deg);
      border-radius: 7.5px;
    }

    .swiper-pagination-bullet-active {
      color: #fff;
      background: black;
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0;
  }
}

.slot2-component {
  position: relative;
  padding: 1.6rem 0;

  .pd-video__container,
  .pd-wrapper-image-layer {
    max-height: 46.4rem;
    max-width: 59.1rem;
    margin: 0 auto;
    margin-bottom: 2.3rem;
  }

  .group-component {
    .pd-wrapper-image-layer {
      margin-bottom: 0;
    }
    margin-bottom: 5.3rem;
  }

  .pd-video__container {
    max-height: 53.5rem;
  }

  .swiper-wrapper {
    align-items: center;
    > * {
      padding: 0 4.9rem;
      width: 100%;
      height: 100%;
    }
  }
}
.slot2-component {
  .swiper-wrapper {
    flex-wrap: wrap;
  }
}
