.account-top-section {
  .wishlist-title {
    margin-top: 1.6rem;
    text-transform: uppercase;
  }

  .wishlist-text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: 0.5px;
    margin-top: 0.9rem;
  }

  .wishlist-continue-shopping {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: 0.5px;
    margin-top: 1.2rem;
  }

  .section-actions {
    margin-top: 2.42rem;
    padding-bottom: 2.38rem;
    border-bottom: 1px solid #eeedeb;
  }
}

.wishlistItemCards {
  .product-grid-container {
    margin-top: 2.32rem;

    .product {
      margin-bottom: 0;
      display: flex;
      height: 100%;

      .single-element-content.product-tile.product-tile--wishlist {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .product-tile__actions.product-wishlist_container_general {
        margin-bottom: 4.72rem;
      }

      .single-element-content-detail-description {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: 0.5px;
        text-align: center;
        margin-top: 1.6rem;
      }

      .single-element-content-detail-price {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: 0.5px;
        text-align: center;
        margin-top: 0.82rem;
      }

      .wishlist-container_select {
        margin-top: 2.38rem;

        .variation-attribute {
          &.invalid {
            select.wishlist__select {
              color: $status_error;
              border-bottom: 1px solid $status_error !important;
            }
          }

          position: relative;
          display: flex;
          flex-flow: column;
          margin-bottom: 2.4rem;

          .attribute-label {
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: 0.3px;
            text-transform: uppercase;
            margin-bottom: 0;
            display: none;
          }

          select {
            appearance: none;
            border: none;
            border-bottom: 1px solid $black;
            height: 24px;
            margin-left: 0;
            margin-top: 0;
            padding-left: 0;
            padding: 0;
            text-transform: uppercase;

            option {
              text-transform: uppercase;
              &:disabled {
                color: $medium-grey;
                pointer-events: none;
              }
            }

            + .select-icon {
              bottom: 35px;
              pointer-events: none;
              position: absolute;
              right: 4px;
              transform: translateY(28px);
            }
          }
          &.attribute-color {
            select {
              width: 160px;
            }
            .select-icon {
              right: unset;
              left: 142px;
            }
          }
          &.attribute-size {
            select {
              width: 98px;
            }
            .select-icon {
              right: unset;
              left: 82px;
            }
            &.unic-element {
              select {
                display: none !important;
              }
              .select-icon {
                display: none;
              }
            }
          }
        }
      }

      .add-to-cart--wishlist {
        &.disabled {
          background-color: $dark-grey;
          border: 1px solid $dark-grey;
          cursor: not-allowed;
        }
      }

      .product-tile__wishlist-cta {
        justify-content: center;

        .remove-from-wishlist {
          margin-top: 1.7rem;
          display: flex;
          align-items: center;
          .icon--trash {
            margin-top: 6px;
          }

          .icon--trash {
            margin-bottom: 2px;
            margin-right: 8px;
          }
        }
      }
      .error-container {
        text-align: center;
        .invalid-feedback {
          display: inline-block;
          color: $status_error !important;
          text-align: center;
        }
      }
    }
  }
}

.my-account--is-loggedin {
  .account-top-section {
    .wishlist-title {
      margin-top: 0;
    }
  }
}

.attribute-size-invalid {
  display: none;
}
.attribute-size.invalid + .attribute-size-invalid {
  display: block;
}