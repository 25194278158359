.separator {
  padding-top: 2.6rem;
  padding-bottom: 2.6rem;
  display: flex;
  .line-separator {
    border-bottom: 1px solid $light-grey;
    margin: auto;
  }
  .separator-center {
    text-align: center;
    font-size: 14px;
  }
}

.cart-empty {
  justify-content: center;
  align-content: center;
  padding: 40px 0 20px;

  .cart-head-section {
    text-align: center;
    @extend %body--small;
    padding-bottom: 24px;
  }

  .continue-shopping-link {
    margin-top: 120px;
  }
}
