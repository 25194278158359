.order-confirmation_container {
  &--guest {
    display: flex;
    flex-direction: column;
  }

  .horizontal-row {
    border-top: 1px solid $light-grey;
    margin-left: -15px;
    width: calc(100% + 30px);

    &.confirmation-accordion-top {
      margin-top: 16px;
    }
    &.wrapper-item-top {
      margin-top: 0;
      margin-bottom: 16px;
    }
  }
}

.order-confirmation_container {
  .order-confirmation_section {
    padding: 16px 0;
  }

  .title {
    @extend %heading-medium;
    text-transform: uppercase;
    & + p {
      margin-top: 8px;
    }
  }

  p {
    @extend %body--small;
  }

  .accordion {
    .title-section {
      @extend %label--small;
      text-transform: uppercase;
    }
  }

  .billing-information {
    @extend %body--small;
  }

  .title-information {
    @extend %title--regular;
    text-transform: uppercase;
  }

  .card-information {
    padding-top: 16px;
    padding-bottom: 16px;
    + .card-information {
      border-top: 1px solid $light-grey;
    }
  }
}

.order-confirmation_head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.order-confirmation-continue-shopping {
  flex-shrink: 0;
  margin-top: 16px;
}

.order-confirmation_body {
  .accordion-trigger {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 18px 0;
  }
}

.order-confirmation_products-detail {
  // border-top: 1px solid $light-grey;
  // border-bottom: 1px solid $light-grey;

  margin-left: -15px;
  margin-right: -15px;
  padding: 16px 15px;
}

.billing-section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  // border-bottom: 1px solid $light-grey;

  .billing-information,
  .billing-method-information {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  // .billing-method-information {
  //   border-top: 1px solid $light-grey;
  // }
}

.billing-method-information {
  padding-bottom: 16px;
  padding-top: 16px;
}

.order-confirmation-total-information {
  background: $light-grey;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;

  .grand-total {
    @extend %body--heavy;
    text-transform: uppercase;
    border-top: 1px solid $medium-grey;
  }

  .start-lines {
    justify-content: flex-start;
  }

  .end-lines {
    justify-content: flex-end;
    text-align: right;
  }

  .leading-lines {
    margin-top: 0;
    padding-top: 8px;
    & + .leading-lines {
      padding-top: 16px;
    }

    .duties-tax-label {
      display: flex;
      font-size: 12px;
    }

    &.taxes {
      margin-bottom: 16px;
    }
  }
  .cart-duties-tax {
    padding: 1rem 0.25rem;
  }
}

// .order-confirmation_container {
//   .bag-product {
//     .product-info {
//       height: 160px;
//       &-img {
//         width: 120px;
//         max-width: 120px;
//         margin-right: 8px;
//         padding: 0;
//         > div {
//           width: 120px;
//         }
//       }
//       .bag-product__content {
//         max-width: 100%;
//         .bag-product__name {
//           font-size: 14px;
//           font-weight: 500;
//           font-stretch: normal;
//           font-style: normal;
//           line-height: 1.6;
//           letter-spacing: 1px;
//           color: $black;
//         }
//         .bag-product__details {
//           > .row {
//             margin-top: 8px;
//             &:first-child {
//               margin-top: 16px;
//             }
//             .bag-product__details-name {
//               font-size: 12px;
//               font-weight: normal;
//               font-stretch: normal;
//               font-style: normal;
//               line-height: 1.8;
//               letter-spacing: 0.3px;
//               color: $black;
//               text-transform: uppercase;
//             }
//             .col-6 {
//               &:last-child {
//                 display: flex;
//                 justify-content: flex-end;
//                 .bag-product__details-value {
//                   height: 22px;

//                   font-size: 12px;
//                   font-weight: normal;
//                   font-stretch: normal;
//                   font-style: normal;
//                   line-height: 1.8;
//                   letter-spacing: 0.3px;
//                   color: $black;
//                 }
//               }
//             }
//           }
//           .bag-product__details-quantity {
//             margin-top: 8px;
//             > .row {
//               .col-6 {
//                 &:last-child {
//                   display: flex;
//                   justify-content: flex-end;
//                   .bag-product__details-quantity__value {
//                     height: 21.6px;

//                     font-size: 12px;
//                     font-weight: normal;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: 1.8;
//                     letter-spacing: 0.3px;
//                     color: $black;
//                   }
//                 }
//               }
//             }
//             .bag-product__details-quantity__label {
//               font-size: 12px;
//               font-weight: normal;
//               font-stretch: normal;
//               font-style: normal;
//               line-height: 1.8;
//               letter-spacing: 0.3px;
//               color: $black;
//               text-transform: uppercase;
//             }
//           }
//         }
//       }
//     }
//     .shipping-and-price {
//       margin-top: 16px;
//       .bag-product__shipping {
//         &-text {
//           height: 43px;
//           font-size: 12px;
//           font-weight: normal;
//           font-stretch: normal;
//           font-style: normal;
//           line-height: 1.8;
//           letter-spacing: 0.3px;
//           color: #096a0e;
//           text-transform: uppercase;
//         }
//       }
//       .bag-product__price {
//         display: flex;
//         justify-content: flex-end;
//         &-text {
//           height: 22.4px;
//           font-size: 14px;
//           font-weight: 500;
//           font-stretch: normal;
//           font-style: normal;
//           line-height: 1.6;
//           letter-spacing: 1px;
//           color: $black;
//           text-align: right;
//           &.previous-price {
//             color: $dark-grey;
//             text-decoration: line-through;
//           }
//         }
//       }
//     }
//   }
// }
//

.wrapper-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  // border-top: 1px solid $light-grey;

  .item-product-info {
    display: flex;
    width: 100%;

    .product-title {
      @extend %title--regular;
      text-transform: none;
    }
  }

  .item-shipping-price {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 16px;
    .bag-product__price {
      text-align: right;
    }
    // .bag-product__shipping {
    //   width: 230px;
    // }
  }

  .product-info-img {
    width: 120px;
    height: 160px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .product-info-details {
    width: 100%;
    flex: 1;

    .name-field {
      text-transform: uppercase;
    }
    .value-field {
      text-align: right;
    }
  }

  .bag-product__shipping-text {
    color: $status_success;
    text-transform: uppercase;
  }

  .detail-attribute {
    display: flex;
    justify-content: space-between;
    @extend %body--small;
    margin-top: 16px;
    + .detail-attribute {
      margin-top: 8px;
    }
  }
}

.order-confirmation_body {
  .shipping-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    .pickup-in-store-info {
      .pickup-in-store-title-section {
        display: flex;
        align-items: center;
        .pickup-in-store-title-icon {
          margin-right: 8px;
        }
      }
    }
  }
  .shipping-information {
    padding-bottom: 16px;
    .details,
    .single-shipping {
      font-size: 12px;
    }
  }
  .shipping-type {
    padding-top: 16px;
    // border-top: 1px solid $light-grey;

    .title {
      @extend %label--small;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      [class*='icon--'] {
        margin-right: 8px;
      }
    }
  }
}
.title-information {
  margin-bottom: 8px;
}

.gift-summary-container {
  margin-top: 16px;
  .gift-summary-title {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: 1px;
  }
}

.dynamic-validation-message {
  position: relative;
  margin-left: 15px;

  &.error-length {
    margin-top: 8px;
  }

  &:not(.green):before {
    content: '';
    display: block;
    width: 10px;
    height: 1px;
    background: #666;
    left: -15px;
    top: 42%;
    position: absolute;
  }

  &.green {
    color: green;

    &:before {
      content: 'L';
      position: absolute;
      left: -15px;
      font-family: arial;
      -ms-transform: scaleX(-1) rotate(-35deg);
      -webkit-transform: scaleX(-1) rotate(-35deg);
      transform: scaleX(-1) rotate(-35deg);
    }
  }
}

#registration-form-password + .error-message {
  display: none;
}

.form-group.error {
  .dynamic-validation-message:not(.green) {
    color: #ba2015;
    &::before {
      background: #ba2015;
    }
  }
}
