.container-account-popup {
  position: absolute;
  width: 375px;
  height: auto;
  top: 15px;
  right: 0;
  padding: 16px 0;
  border: 1px solid $light-grey;
  z-index: 1;
  .content-popup-account-login {
    .container-remember {
      padding-left: 0;
      margin: 12px 0 19px 0;
      .checkbox-remember {
        width: 16px;
        height: 16px;
        margin: 0 8px 0 0;
        padding: 6px;
      }
    }
  }
  .content-popup-account-registration {
    padding: 0 16px;
    border-bottom: unset;
  }
}
#login-modal {
  .modal-overlay {
    margin-top: 0;
  }
}

.login-content {
  .journey-start {
    margin-bottom: 16px;
  }
}
