.personal-details-container {
  .edit-profile-form {
    .form-input {
      .fsb-select {
        + .select-icon {
          right: 33px;
        }
      }
    }
  }
}
