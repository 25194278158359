.access-details-container {
  &:not(reset-password) {
    .page-title {
      padding: 0 20px;
    }
    .detail-description {
      padding: 0 20px;
    }
    .change-password {
      margin: 0;
    }

    .card-body {
      input {
        width: 100%;
      }
      .change-password {
        .form-input {
          padding-bottom: 24px;
          padding-left: 20px;
          padding-right: 20px;
        }
        .save-button {
          margin-top: 0;
          justify-content: flex-end;
          display: flex;
          padding: 0 20px;
          button {
            max-width: 113px;
            width: 113px;
            padding: 16px;
          }
        }
      }
    }
  }
}
