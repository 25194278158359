.product-detail {
  .add-to-cart-add-to-wishlist {
    .add-to-cart-actions,
    .notfy-me-actions {
      max-width: 360px;
      width: 360px;
      .cart-and-ipay {
        margin-left: -20px;
      }
    }
  }
}

.notify-me-container {
  .notify-me {
    &-insert-email {
      .add-to-cart-add-to-wishlist {
        .add-to-cart-actions,
        .notfy-me-actions {
          max-width: 360px;
        }
      }
    }
  }
}
