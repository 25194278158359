.wrapper-pdp {
  .bopis-btn {
    margin: 2.4rem 0;
    text-transform: uppercase;
    @extend %label--small;
  }
}

#confirmation-bopis-modal,
#findinstore-modal {
  .modal-header {
    padding: 1.6rem;
    @extend %title--large;
    text-transform: uppercase;
  }
  .modal-content {
    padding: 0;
  }
  .modal-close {
    top: 1.5rem;
    right: 1.5rem;
  }
  .modal-container {
    justify-content: unset;
  }
}
#findinstore-modal .modal-content {
  max-height: calc(100vh - 180px);
}

#findinstore-modal[aria-hidden='true'],
#confirmation-bopis-modal[aria-hidden='true'] {
  .modal-overlay {
    animation: none;
  }
  .modal-container {
    animation: none;
  }
}

#confirmation-bopis-modal {
  .modal-container {
    border: 1px solid $light-grey;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 375px;
    //
  }
  .modal-content {
    @extend %body--small;
  }
}

.box-actions-search-store {
  border-bottom: 1px solid $light-grey;
  padding: 0 1.6rem 1.6rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  background-color: $white;
  position: sticky;
  z-index: 1;
  top: 0;

  input {
    min-width: 14.5rem;
    width: 100%;
    padding: 11px 12px;
  }
}

.item-error-message-container-sticky {
  color: $status_error;
  padding: 1.6rem 0 0;
}

.btn-geolocator {
  border: 1px solid $black;
  border-right: none;
  width: 4.7rem;
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.search-form-group {
  display: flex;
  align-items: center;
  margin-right: 1.6rem;
  flex: 1;
}

.btn-filter-store {
  width: auto;
  padding: 16px 30px;
}

.store-list-empty {
  padding: 1.6rem;
}

.store-search-result {
  .store-item {
    position: relative;
    padding: 1.6rem;
    + .store-item {
      border-top: 1px solid $light-grey;
    }

    .item-error-message-container {
      color: $status_error;
      margin-top: 16px;
    }

    .store-name {
      @extend %title--small;
      text-transform: uppercase;
    }

    .store-more-info {
      position: absolute;
      right: 1.6rem;
      top: 1.6rem;
      cursor: pointer;
    }

    .store-more-info-txt {
      text-decoration: underline;
    }

    .store-address-short {
      @extend %body--small;
      color: $dark-grey;
      padding: 0.8rem 0;
    }

    .store-phone {
      @extend %label--small;
      color: $dark-grey;
    }

    .store-hours {
      padding: 1.6rem 0 0;
      @extend %label--small;
    }

    .store-extended-openinghours {
      padding: 0 0 0.8rem;
    }

    .store-extended-hours {
      color: $dark-grey;
      .single-store-hour {
        padding: 2px 0;
      }
    }

    .store-map {
      padding: 1.6rem 0 0;
      button {
        @extend %label--small;
        color: $dark-grey;
      }
    }

    .preorder-release-date-pickup {
      // padding: 1.6rem 0 0;
      text-align: left;
      margin-left: 40px;

      .icon--info {
        background: #ffb000;
        border-radius: 100px;
        height: 5px;
        width: 5px;
      }
    }

    .store-avail-message {
      @extend %label--small;
      color: $dark-grey;
      padding: 1.6rem 0 0;

      .product-bopis-second-line {
        line-height: 22px;
      }
    }

    .btn-add-to-cart {
      margin-top: 1.6rem;
      &:disabled {
        cursor: not-allowed;
      }
      &.disabled-button {
        background-color: $medium-grey;
        border: $space-line solid $medium-grey;
      }
    }

    [class*='icon--'] {
      margin-right: 8px;
    }
  }
}

// confirmation modal - swap pickup-delivery
.modal-confirmation-wrap {
  padding: 1.6rem;
}
.modal-confirmation-actions {
  margin-top: 1.6rem;
  display: flex;
  justify-content: space-between;
  button {
    width: calc(50% - 0.8rem);
  }
}

.store-selected-display {
  display: none;
  padding: 1.6rem 0 0;
}

.bopis-btn {
  .icon--shipping-medium {
    transform: scale(0.6) translateY(40%);
  }
}

#findinstore-modal {
  .modal-close {
    z-index: 5;
  }

  .modal-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #fff;
  }

  .box-actions-search-store {
    width: 100%;
    position: absolute;
    top: 57px;
  }
  .store-search-result {
    margin-top: 65px;
  }
  .stores-list {
    padding-bottom: 20px;
  }

  .label-bopis-postal-code-search {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

.storelocator-modal-open {
  .recommendations {
    position: relative;
    z-index: -1;
  }
}
