.product-tile-editorial {
  .aspect-ratio-3-4 {
    img {
      aspect-ratio: 3 / 4;
    }
  }
  .aspect-ratio-5-7 {
    img {
      aspect-ratio: 5 / 7;
    }
  }
  .single-element-content.product-tile {
    display: block;
    .tile-body.single-element-content-detail {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 8px 48px 24px 24px;
      .product-link {
        font-family: $futura-book;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-align: left;
      }

      .product-badge {
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.03em;
        padding-bottom: 4px;
        color: #0D0D0D;
      }
    }

    .single-element-content-detail-description {
      margin-bottom: 16px;
    }
  }

  .price-container {
    .value {
      font-family: $futura-book;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.02em;
      text-align: left;
    }
  }

  .tile-image-link,
  img {
    border-radius: 8px;
  }

  .hideInfo-true {
    .tile-body.single-element-content-detail {
      display: none;
    }
  }
}
