.container-account-popup,
.login-form-nav {
  background-color: #fff;
  width: 100%;
  height: 100%;
  .content-popup-account-login {
    padding: 0 16px;
    margin-bottom: 16px;
    .alert-danger {
      margin-bottom: 16px;
    }
    .container-remember {
      margin: 20px 0 28px 0;
      padding-left: 8px;
      .checkbox-remember {
        width: 24px;
        height: 24px;
        margin: 0 8px 0 0;
      }
      .label-remember {
        @extend %body--small--medium;
      }
    }
    .button-forgot-password {
      @extend %label--small;
      &::before {
        bottom: 0;
      }
    }
  }
  .content-popup-account-registration {
    padding: 0 16px 16px 16px;
    .button-register-account-popup {
      text-decoration: none;
      width: 100%;
    }
    .title-form-register-popup {
      @extend %title--regular;
      margin-bottom: 16px;
    }
  }
  .content-popup-account-connected {
    padding: 0 16px;
    .text-list-connected {
      text-decoration: none;
      @extend %title--regular;
    }
    ul {
      padding: 0;
      width: 100%;
      li {
        margin-bottom: 24px;
      }
      :last-child {
        margin-bottom: 0;
      }
    }
    .logout-button {
      @extend %label--small;
    }
  }
  .border-bottom-grey {
    border-bottom: 1px solid $light-grey;
  }
  .content-popup-account-login-title {
    padding: 0 16px;
    .description-popup-account {
      @extend %body--small;
      margin-bottom: 16.4px;
    }
    .title-form-login-popup {
      @extend %title--regular;
      margin-bottom: 24.6px;
      text-transform: uppercase;
    }
  }
  .form-login-account,
  .modal-content {
    padding: 0 !important;
  }
  .modal-header {
    padding: 0 16px !important;
  }
  .form-login-account {
    .form-control-label {
      @extend %label--small;
      margin-bottom: 8px;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 16.8px !important;
      letter-spacing: 0.015em !important;
    }
    .container-input-password {
      padding-bottom: 0;
    }
  }
  .btn-login,
  .button-register-account-popup {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
}
#login-dropdown {
  right: 0;
  z-index: 6;
}
#login-modal {
  .modal-overlay {
    margin-top: 50px;
  }
  .modal-close {
    padding: 23px 16px 23px 16px;
    text-transform: uppercase;
    position: unset;
    border-top: 1px solid $light-grey;
    display: flex;
    align-items: center;
    @extend %label--small;
    &::after {
      content: attr(data-content-back);
      background: unset;
      height: auto;
      width: auto;
    }
    &::before {
      content: '';
      margin-right: 16px;
      width: 16px;
      height: 16px;
      @include fixedSprite('arrow-back-black');
    }
  }
}

.login-content {
  .journey-start {
    .journey-content {
      margin: 16px 0 8px;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
