.error-page-404 {
  background: #f5f5f5;
  padding: 96px 0;
  .error-404-buttons {
    margin-top: 24px;
    width: 100%;
    justify-content: center;
    text-align: center;
    .go-to-home {
      margin-bottom: 16px;
      color: #111;
      font-family: $futura-book;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px; /* 150% */
      letter-spacing: 0.56px;
      text-decoration: underline;
      text-underline-offset: 6px;
    }
  }
  .error-404-header {
    img {
      height: 100px;
    }
  }
  .error-404-description {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.3px;
  }
  .error-404-title {
    color: #111;
    text-align: center;
    font-family: $futura-book;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.63px;
  }

  .product-recomendation-tile {
    width: 100%;
    display: flex;
    flex-flow: wrap;

    .product-name {
      text-align: center;
      align-self: center;
      width: 50%;
    }
  }

  .recommendations {
    .swiper-header {
      text-align: left;
      font-family: $futura-book;
      font-size: 16px;
      font-weight: 450;
      line-height: 1.8rem;
    }

    .swiper-heading-wrapper {
      padding-bottom: 24px;
    }

    .product-link {
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.1px;
      letter-spacing: 0.015em;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .add-to-wish-list {
      display: none;
    }

    .price {
      display: none;
    }

    .experience-assets-product {
      // margin-right: 5px;

      .single-element-content-image {
        margin-bottom: 8px;
      }
    }
  }

  .einstein-carousel .product-carousel-section .swiper-pagination-bullet {
    background-color: #d9d9d9;
    border: none;
    border-radius: 99px;
    width: 8px;
    height: 8px;
    margin: 0 8px 0 2px;
  }
  .einstein-carousel .product-carousel-section .swiper-pagination-bullet-active {
    background-color: #999999;
    border: none;
    border-radius: 99px;
    width: 8px;
    height: 8px;
    margin: 0 8px 0 2px;
  }
}

// .main--layout-page:has(> .error-page-404) {
//   /* styles to apply to the li tag */
//   border: 5px dashed red;
// }

// *! > input[type=text] { background: #000; }

// .main--layout-page:contains-selector(.error-page-404) {
//   background: red;
// }

.error-page-recommendations {
  margin-top: 24px;
  margin-bottom: 24px;
  .einstein-carousel {
    .product-carousel-section {
      .swiper-wrapper {
        padding-bottom: 0;
      }

      .swiper-button-prev {
        display: none;
      }

      .swiper-pagination {
        position: relative;
        margin-top: 20px;
      }

      .swiper-button-next {
        display: none;
      }

      .swiper-pagination-bullet-active {
        background: #999;
      }

      .swiper-pagination-bullet {
        margin: 0 4px;
      }

      .experience-assets-product .single-element-content {
        margin-bottom: 0;
      }
    }
  }
}

.main--layout-page {
  &.error-404 {
    min-height: 100%;
  }
}
