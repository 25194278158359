.modal {
  .modal-overlay {
    justify-content: stretch;
    align-items: stretch;
  }

  .modal-container {
    flex-grow: 1;
    max-width: 100%;
    max-height: 100vh;
  }
  .modal-close {
    top: 3.7rem;
  }
}
