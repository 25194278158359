.accordion-mini__header {
  &::after {
    @include fixedSprite('chevron-mini-down');
  }
}

.accordion-mini__panel {
  @include animate;
  > .row {
    padding-top: ($space-unit * 2);
  }
}
