.breadcrumb-component {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  .breadcrumb {
    justify-content: flex-start;
    &-item {
      &.last-element {
        display: block;
      }
    }
  }
}

.pd-breadcrumbs-hero-internal {
  display: flex;
}
