.account-dashboard {
  padding: 0 157px;
  &.contacts {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
  .horizontal-row {
    padding: 0 45px;
    margin: 0 -157px;
    .boxes-upper-row {
      margin-bottom: 8px;
    }
  }
}
