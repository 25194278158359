.editorial-product-grid {
  &__media-product-region {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    padding-bottom: 8px;

    .pd-wrapper-image-layer {
      grid-column: span 2;
      grid-row: span 2;
      overflow: hidden;

      a img {
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .image-container-selector {
      height: 100%;
    }

    .component-image {
      height: 100%;
    }

    .experience-assets-product {
      flex: auto;
      width: calc(25vw - 6px);

      &:nth-child(2) {
        grid-column: 3;
        grid-row: 1;
      }

      &:nth-child(3) {
        grid-column: 4;
        grid-row: 1;
      }

      &:nth-child(4) {
        grid-column: 3;
        grid-row: 2;
      }

      &:nth-child(5) {
        grid-column: 4;
        grid-row: 2;
      }
    }
  }

  &__product-region {
    display: flex;
    gap: 8px;
    padding-bottom: 8px;

    .experience-assets-product {
      flex: 0 0 calc(25% - 6px);
      width: calc(25% - 6px);
    }
  }

  &.image-banner-position-right {
    .experience-assets-product {
      &:nth-child(2) {
        grid-column: 1;
        grid-row: 1;
      }

      &:nth-child(3) {
        grid-column: 2;
        grid-row: 1;
      }

      &:nth-child(4) {
        grid-column: 1;
        grid-row: 2;
      }

      &:nth-child(5) {
        grid-column: 2;
        grid-row: 2;
      }
    }
  }

  &__wrapper {
    .single-element-content {
      &-image {
        .swiper-slide-overlay {
          display: block;
        }
      }
    }
  }

  .experience-assets-product {
    .single-element-content {
      margin-bottom: 24px;
    }
  }
}
