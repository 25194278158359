//colors
$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);

$status_error: #ba2015;
$status_error_light: #ee1111;
$status_alert: #f1c75a;
$status_success: #096a0e;
$status_sucess_green: #0ea80c;

$lighter-grey: #e5e5e5;
$light-grey: #eeedeb;
$medium-grey: #999999;
$dark-grey: #666666;
$secondary-grey: #757575;

$overlay: rgba(0, 0, 0, 0.5);
$overlay_base: linear-gradient(360deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);

$outline: #80bdff;
$gradient-separator: linear-gradient(180deg, #eeedeb 0%, #ffffff 100%);
$input-outline: #666666;

$blue-video: #00c9f4;

$mountain-meadow: #25d366;

$badge-red: #9b3124;
$badge-green: #1e7169;
$badge-orange: #edb562;
