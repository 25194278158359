.page-account-container {
  .account-menu-col {
    padding-left: 0;
    .page-account-title {
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1.5px;
      color: black;
      text-transform: uppercase;
    }
    .page-account-menu {
      width: calc(100% + 54px);
      > .col {
        padding: 0;
        .menu-list {
          padding: 0 10px;
          width: 100%;
          list-style: none;
          display: flex;
          overflow-x: auto;
          height: 48px;
          background: $light-grey;
          align-items: center;
          &-item {
            width: auto;
            flex: 1 0 auto;
            margin: 0 12px;
            a {
              height: 14px;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 1px;
            }
            &.active {
              a {
                text-decoration: underline;
                font-weight: 900;
              }
            }
          }
        }
      }
    }
  }

  .account-content-col {
    .account-content-box {
      &-section {
        .account-search-order {
          .user-email-label {
            padding-left: 0;
          }
        }
      }
    }
  }
}
.live-chat-container {
  bottom: 79px;
  height: 256px;
  right: 22px;
}
