.select-container {
  select {
    width: 100%;
  }
}
.hidden-field {
  display: none !important;
}

select {
  display: block !important;
  border-radius: 0;
  width: 100%;
  box-shadow: none !important;
  font-size: 16px !important;
  &.mobile-version {
    margin-top: 8px;
    border-radius: 0;
  }
  &.form-select {
    border: 1px solid $black !important;
    border-radius: 0 !important;
    margin-top: 8px;
  }
}

.fsb-select {
  display: none !important;
  + .select-icon {
    position: absolute;
    left: unset;
    right: 23px;
    bottom: 8px;
  }
}

.form-input {
  position: relative;
  select {
    height: 45px;
    border: 1px solid #ccc !important;
    border-radius: 2px !important;
    margin-top: 8px;
    padding-left: 8px;

    body:not(#checkout-begin) & {
      cursor: pointer;
      padding-left: 12px;

      &:focus {
        border: 1px solid #595959 !important;
      }
    }
  }
}
