.is-old-plp {
  display: block;
}
.is-new-plp {
  display: none;
}
.page-search-result-container.new-page-search-result {
  .d-none {
    display: none !important;
  }
  .is-old-plp {
    display: none !important;
  }
  .is-new-plp {
    display: block !important;
  }

  .mobile-filter-top {
    display: none !important;
  }
  .search-no-result-decorator-container {
    .plp-banner-container {
      .text-content {
        text-align: center;
        .page {
          &-title {
            height: 32px;
            font-size: 20px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            color: $black;
            text-transform: uppercase;
            margin-bottom: 8px;
          }
          &-description {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: 0.3px;
            color: $black;
          }
        }
        .plp-result-number {
          text-transform: lowercase;
        }

        &.top-spacer {
          margin-top: 80px;
        }
      }
    }

    .category-menu-top-title-mobile {
      display: block;
      padding: 0 16px;
      h1 {
        padding-top: 16px;
        padding-bottom: 8px;
        font-family: $futura-book;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-align: left;
      }
    }

    .category-menu-top-title-mobile.special-header {
      h1 {
        padding-block: 16px;
      }
    }

    .category-menu-top-container {
      position: sticky;
      background: $white;
      top: 0;
      display: flex;
      flex-direction: column;
      z-index: 2;
      border-bottom: none;
      transition: top 0.3s ease-in-out;

      .grid-header.js-grid-header {
        position: relative;
        &::before {
          content: '';
          width: 100%;
          border-top: 1px solid #e5e5e5;
          display: block;
          top: -1px;
          position: absolute;
          z-index: -1;
        }
      }

      .filters-bar {
        padding: 8px 16px;

        .mobile-filters .button-icons .filter-button {
          margin-bottom: 0;
        }

        .mobile-filters .button-icons button {
          height: 36px;
          padding: 10px 40px 10px 16px;
        }
      }

      .category-menu-top-title {
        display: none;
        h1 {
          padding-block: 20px;
          font-family: $futura-book;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.02em;
          text-align: left;
        }
      }
      .category-menu-top-categories {
        padding-inline: 16px;
        display: flex;
        flex-direction: row;
        gap: 24px;
        overflow-x: scroll;
        width: max-content;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
        max-width: 100%;

        &::-webkit-scrollbar {
          display: none;
        }

        .category-menu-top-categories-category {
          position: relative;
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0.04em;
          text-align: center;
          color: #757575;
          cursor: pointer;
          align-content: center;
          padding-block: 16px;
          white-space: nowrap;

          &.selected {
            color: $black;

            &::after {
              content: '';
              display: block;
              border-bottom: 2px solid $black;
              position: absolute;
              width: 100%;
              bottom: 0;
            }
          }
        }
      }
    }

    .skeleton-loader.d-none {
      display: none !important;
    }

    .skeleton-loader {
      width: 100vw;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      .product-placement {
        display: flex;
        flex-direction: column;
        width: calc(50vw - 2px);
      }

      .skeleton-image,
      .skeleton-title,
      .skeleton-second-title,
      .skeleton-price {
        background: #f5f5f5;
        animation: 1s shine infinite;
      }

      .skeleton-image {
        width: 100%;
        aspect-ratio: 3 / 4;
      }

      .skeleton-title {
        margin-left: 8px;
        margin-top: 13px;
        height: 15px;
        width: 45%;
      }

      .skeleton-second-title {
        margin-left: 8px;
        margin-top: 5px;
        height: 15px;
        width: 37%;
      }

      .skeleton-price {
        margin-left: 8px;
        margin-top: 20px;
        margin-bottom: 24px;
        height: 15px;
        width: 50px;
      }
    }

    @keyframes shine {
      to {
        background: #f0f0f0;
      }
    }
    .delivery-banner-mobile {
      border-bottom: 1px solid $light-grey;
    }
    .delivery-banner-desktop {
      display: none;
    }
    .delivery-banner-desktop-spacer {
      display: none;
    }
    .delivery-banner-mobile {
      .delivery-banner-info {
        justify-content: center;
        display: flex;
        align-items: center;
        margin: 8px 0;
        &-icon {
          margin-right: 8px;
        }
        &-text {
          width: 183px;
          height: 12px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          color: #096a0e;
        }
      }
    }

    .search-result {
      margin-top: 0;
      .js-grid-header {
        font-size: 12px;
        &.is-open {
          &.animate {
            #refinements {
              display: block;
            }
          }
        }
        &:not(.is-open) {
          &:not(.animate) {
            #refinements {
              display: none;
            }
          }
        }
      }

      .refinement-bar {
        display: none;
      }

      .grid-product {
        .version {
          .product-grid-container {
            .single-element {
              display: block;
              justify-content: center;
              max-width: 50vw;
              grid-column: span 1;

              .swiper-container {
                display: block;
                z-index: 0;

                .swiper-button-next,
                .swiper-button-prev {
                  opacity: 0;
                  color: black;

                  &:after {
                    font-size: 15px;
                  }
                }

                .swiper-pagination.swiper-pagination-bullets {
                  bottom: 0;
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  height: 2px;

                  .swiper-pagination-bullet {
                    background: #e5e5e5;
                    width: inherit;
                    border-radius: 0;
                  }

                  .swiper-pagination-bullet-active {
                    background: $black;
                  }
                }

                .swiper-slide {
                  height: auto;

                  img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                  }
                }
              }
              .login-to-buy-image-banner {
                display: none;
              }
              .tile-image {
                display: block;
                object-fit: cover;
                height: auto;
              }
              // &.login-to-buy {
              //   &:hover {
              //     .login-to-buy-image-banner {
              //       background: $black;
              //       display: block;
              //       height: 100%;
              //       opacity: 0.5;
              //       width: 100%;
              //       &-text {
              //         background: $white;
              //         color: $black;
              //         font-size: 12px;
              //         font-stretch: normal;
              //         font-style: normal;
              //         font-weight: 500;
              //         height: 60px;
              //         letter-spacing: 0.5px;
              //         line-height: 1;
              //         padding: 24px 32px;
              //         position: absolute;
              //         text-align: center;
              //         text-transform: uppercase;
              //         width: 100%;
              //       }
              //     }
              //   }
              // }
              &-content {
                margin-bottom: 0;
                &-image {
                  position: relative;
                  margin-bottom: 0;
                  .default-image {
                    height: 100%;
                    img {
                      height: 100%;
                      width: 100%;
                    }
                  }
                  &-tag {
                    background-color: red;
                    height: 20px;
                    padding: 4px 8px;
                    border-radius: 10px;
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: 0.5px;
                    text-align: center;
                    position: absolute;
                    bottom: 8px;
                    left: 8px;
                    color: $white;
                  }
                  .img {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                  }
                }
                &-detail {
                  &-price {
                    .previous-price {
                      color: $dark-grey;
                      text-decoration: line-through;
                      margin-right: 16px;
                    }
                  }
                }
              }

              .discount-label.js-discount-label {
                display: none;
              }
            }

            &.product-x-1 {
              margin: 0;
              .show-more {
                width: 100%;
                margin-inline: 15px;
                margin-bottom: 40px;

                button {
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: 2px;
                  color: $white;
                  text-transform: uppercase;
                }
              }
              .single-element {
                padding: 0;
                .single-element-content {
                  margin: 0 15px;
                  &-image {
                    margin-bottom: 0;
                    /* height: 459px;
                    width: 345px; */
                    aspect-ratio: 3 / 4;
                    margin-inline: auto;
                  }
                  &-detail {
                    margin-top: 8px;
                    text-align: center;

                    &-description {
                      margin-bottom: 8px;
                    }
                  }
                }
              }
            }

            &.product-x-2 {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              margin: 0;
              gap: 4px;
              width: 100vw;

              .show-more {
                width: 100%;
                margin-right: 15px;
                margin-left: 15px;
                margin-bottom: 40px;
                button {
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: 2px;
                  color: $white;
                  text-transform: uppercase;
                }
              }

              .product {
                overflow: hidden;
              }

              .single-element {
                padding: 0;
                max-width: calc(50vw - 2px);
                flex-basis: calc(50vw - 2px);
                width: calc(50vw - 2px);
                .single-element-content {
                  margin: 0;
                  &-image {
                    height: auto;
                    width: 100%;
                  }
                  &-detail {
                    text-align: center;
                  }
                }
              }
            }

            .products-seen {
              display: block;
              width: 100%;
              margin-left: 15px;
              margin-right: 15px;
              margin-bottom: 16px;
              .col {
                display: flex;
                justify-content: center;
                p {
                  height: 21.6px;
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.8;
                  letter-spacing: 0.3px;
                  text-align: center;
                  color: $black;
                }
              }
            }

            .pageDesignerGridContent {
              padding: 0;

              &.col-6 {
                max-width: calc(50vw - 2px);
                flex-basis: calc(50vw - 2px);
                width: calc(50vw - 2px);
                grid-column: span 1;
              }

              &.col-12 {
                grid-column: span 2;
              }

              .pd-wrapper-image-layer,
              .image-no-tv,
              .image-container-selector,
              .visualImage {
                height: 100%;
                img {
                  height: 100%;
                }
              }

              .experience-assets-product,
              .pd-page__content,
              .product-tile-editorial,
              .product-tile {
                height: 100%;

                .single-element-content-image {
                  height: 100%;
                  object-fit: cover;
                  aspect-ratio: 4 / 5 !important;
                  border-radius: 0;
                }

                .tile-body.single-element-content-detail {
                  padding: 16px 24px 16px 16px;
                  .product-badge {
                    font-family: $futura-book;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 18.4px;
                    letter-spacing: 0.015em;
                    text-align: left;
                    color: #ffffffcc;
                    padding: 0;
                    margin-bottom: 4px;
                  }
                  .single-element-content-detail-description {
                    margin-bottom: 4px;
                  }

                  .product-link {
                    font-family: $futura-book;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 18.4px;
                    letter-spacing: 0.015em;
                    color: $white;
                  }
                }

                .price {
                  font-family: $futura-book;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 18.4px;
                  letter-spacing: 0.015em;
                  color: $white;
                }
              }

              &.col-6.col-md-3 {
                .experience-assets-product,
                .pd-page__content,
                .product-tile-editorial,
                .product-tile {
                  .tile-body.single-element-content-detail {
                    .product-badge {
                      font-size: 14px;
                    }

                    .product-link {
                      font-size: 14px;
                    }
                  }

                  .price {
                    margin-top: 4px;
                    font-size: 14px;

                    .price-container {
                      .value {
                        font-size: 14px;
                      }
                    }
                  }
                }
              }

              .pd-page__content {
                .pd-text {
                  position: relative;
                  padding: 40px 40px 40px 16px;

                  span {
                    display: block;

                    h1 {
                      font-family: $futura-book;
                      font-size: 32px;
                      font-weight: 400;
                      line-height: 36.8px;
                      letter-spacing: 0.005em;
                    }

                    h3 {
                      font-family: $futura-book;
                      font-size: 18px;
                      font-weight: 400;
                      line-height: 20.7px;
                      letter-spacing: 0.01em;
                    }

                    h4 {
                      font-family: $futura-book;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 16.8px;
                      letter-spacing: 0.015em;
                      color: #7d7d7d;
                    }

                    p {
                      font-family: $futura-book;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 19.6px;
                      letter-spacing: 0.015em;
                    }
                  }

                  &.pd-text-align-center {
                    padding: 40px 16px 40px;
                    span {
                      margin: 0 auto;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .selected-filters {
        display: none;
      }

      .show-more {
        margin: 0 auto;
        padding-bottom: 16px;

        .button-load-more {
          margin: 0 auto;
          width: fit-content;
          border-radius: 100px;
        }
      }
    }

    .accessibility-filter-title {
      height: 0;
      visibility: hidden;
    }

    .horizontal-row {
      &.grid-header-bottom-row {
        display: none;
      }
    }

    .no-result-page {
      .suggested-products {
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 1.5px;
        line-height: 1.6;
        margin-bottom: 24px;
        margin-top: 40px;
        text-align: center;
        text-transform: uppercase;
      }
      .no-result-carousel {
        margin-left: 15px;
        .product-slide {
          .swiper-zoom-container {
            align-items: flex-start;
            justify-content: flex-start;
          }
          .img-details {
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 1.54;
            text-align: center;
            margin-top: 16px;
            padding: 0 8px;
            text-transform: uppercase;
            .info-detail {
              margin-bottom: 8px;
            }
          }
        }
        .image-carousel-global-container {
          .swiper-container {
            .swiper-wrapper {
              margin-left: 0;
            }
          }
          .swiper-slide {
            width: 259px;
            margin-right: 20px;
          }
        }
        .img {
          width: 259px;
          height: 345px;
          // border: solid 0.8px var(--white);
          // margin-right: 20px;
        }
      }
    }

    .back-to-top {
      visibility: hidden; /* Hidden by default */
      position: fixed; /* Fixed/sticky position */
      bottom: 80px; /* Place the button at the bottom of the page */
      right: 16px; /* Place the button 30px from the right */
      z-index: 99; /* Make sure it does not overlap */
      border: 1px solid black; /* Remove borders */
      outline: none; /* Remove outline */
      background-color: white; /* Set a background color */
      color: black; /* Text color */
      cursor: pointer; /* Add a mouse pointer on hover */
      padding: 13px;
      padding-top: 19px;
      font-size: 39px;
      width: 50px;
      height: 50px;
    }

    .breadcrumbs-hero-internal {
      width: 100vw;
      border-top: 1px solid #e5e5e5;
      justify-content: center;

      .breadcrumb-component {
        max-width: 100%;
        display: block;
        .breadcrumb {
          justify-content: center;
          margin: 16px 0;
          border-top: none;
          flex-flow: wrap;
          height: unset;
        }
        a {
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.04em;
          color: $black;

          .last-element-text {
            display: inline-block;
            text-transform: lowercase;
          }
          .last-element-text::first-letter {
            text-transform: uppercase;
            display: block;
          }
        }
        .breadcrumb-item.last-element {
          display: none;
        }
        .breadcrumb-separator {
          display: none;
        }
      }
    }
  }

  .category-description-footer {
    width: 100vw;
    padding: 40px 16px;
    border-top: 1px solid #e5e5e5;
  }

  .show-more.js_show-more {
    width: fit-content;
    margin: 24px auto 48px !important;
    padding: 0 !important;

    .you-have-seen.js_you-have-seen {
      display: none;
    }
    .button-load-more.js_button-load-more {
      text-transform: none;
      border: 1px solid #b0b0b0; //styleName: button-regular;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.03em;
      text-align: left;
      background: $white !important;
      color: $black !important;
      padding: 10px 21px;

      &:hover {
        border: 1px solid #888888;
        color: $black !important;
      }
    }
  }
}

.mobile-filter-top {
  display: none;
  position: absolute;
  top: 158px;
  width: 100%;

  .content {
    text-transform: uppercase;
    text-align: center;
    background: white;
    border: 1px solid black;
    border-radius: 30px;
    width: fit-content;
    padding: 3px 15px;
    margin: 0 auto;
    font-size: 12px;
  }
}

.filters-arrow-down {
  @include fixedSprite('chevron-mini-right');
  width: 6px;
  height: 12px;
  position: absolute;
  right: 0;
  top: 6px;
  // transform: translate(50%, 50%);
  transform: rotate(90deg);
}
.page-search-result-container.new-page-search-result {
  .single-element-content-detail-description {
    text-transform: uppercase;
    line-height: unset;
  }

  .single-element-content {
    position: relative;

    .add-to-wish-list {
      display: none;
      position: absolute;
      top: 6px;
      right: 10px;

      .white-icon {
        display: none;
      }
    }

    .product-image-badge {
      height: 24px;
      position: absolute;
      top: 8px;
      left: 8px;

      img {
        height: 100%;
        width: auto;
      }

      &.top-right {
        left: unset;
        right: 8px;
      }

      &.height-mobile-16 {
        height: 16px;
      }
      &.height-mobile-20 {
        height: 20px;
      }
      &.height-mobile-24 {
        height: 24px;
      }
      &.height-mobile-28 {
        height: 28px;
      }
      &.height-mobile-32 {
        height: 32px;
      }
      &.height-mobile-36 {
        height: 36px;
      }
      &.height-mobile-40 {
        height: 40px;
      }
    }

    .wishlist-filled {
      display: none;
    }

    .active {
      .black-icon {
        display: none;
      }

      .wishlist-filled {
        display: block;
      }
    }

    .single-element-content-detail {
      padding: 12px 16px 24px 8px;
      .single-element-content-detail-description {
        text-transform: none;
        text-align: left;
        .product-link {
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.1px;
          letter-spacing: 0.015em;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .price-container {
        display: flex;
        //flex-direction: row-reverse;
        justify-content: start;

        .sales {
          margin-right: 8px;
        }

        .prior-best-price-component, 
        .layer-tooltip-prior-best-price {
          display: none;
        }
      }

      .price {
        text-align: left;
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.03em;
        margin-top: 8px;
      }

      .badge-container {
        .product-badge {
          margin-top: 8px;
          text-align: left;
          font-family: $futura-book;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.03em;
          color: #757575;
          padding: 0;
        }
      }
    }
  }

  .wishlist,
  .wishlist-filled {
    width: 1.9rem;
    height: 1.7rem;
    display: inline-block;
  }
}
.page-search-result-container.new-page-search-result {
  .single-element-content-image {
    display: block;
    margin: 0 auto;

    .swiper-slide-overlay {
      display: none;
    }

    .image-container {
      position: relative;

      .product-badge {
        background-color: transparent;
        border-radius: 18px;
        color: $black;
        height: 24px;
        padding: 0;
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.1px;
        letter-spacing: 0.03em;
        text-align: left;

        width: max-content;
        z-index: 1;
        &.product-badge-top {
          position: absolute;
          top: 8px;
          left: 8px;
        }
        &.product-badge-middle {
          width: 100%;
          position: absolute;
          text-align: center;
          top: calc(50% - 14px);
        }
        &.product-badge-bottom {
          position: absolute;
          bottom: 8px;
          left: 8px;
        }

        &.product-badge--red {
          background-color: $badge-red;
        }
        &.product-badge--green {
          background-color: $badge-green;
        }
        &.product-badge--orange {
          background-color: $badge-orange;
        }
        &.product-badge--white {
          background-color: $white;
          color: $black;
        }
      }
    }
  }
}

.product.product-set-tile {
  .single-element-content {
    &-detail {
      .product-color {
        display: none;
      }

      .single-element-content-detail-description {
        margin-bottom: 8px;
      }

      .product-set-tile-articles {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.1px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #757575;
      }
    }
  }
}

.you-have-seen {
  text-align: center;
  margin-bottom: 2rem;
}

.refinement-size {
  .categories-filter {
    .selection-box {
      text-transform: uppercase !important;
    }
  }
}

.no-results-recommendations {
  margin-top: 96px;
  padding-top: 24px;
  border-top: 1px solid $light-grey;

  .swiper-heading-wrapper {
    padding-bottom: 24px;
  }

  .recommendations {
    margin-top: 0;
    width: 100%;
    position: relative;

    .product-carousel-section {
      padding: 0;
    }

    .swiper-header {
      font-family: $futura-book;
      font-size: 16px;
      text-align: left;
      font-weight: 450;
    }

    .swiper-button-prev {
      top: -35px !important;
      left: unset;
      right: 49px;
      background: none;
      width: fit-content;
    }

    .swiper-button-next {
      top: -35px !important;
      background: none;
      width: fit-content;
    }

    .swiper-button-prev:after {
      color: $black;
      font-size: 20px;
    }
    .swiper-button-next:after {
      color: $black;
      font-size: 20px;
      transform: rotate(180deg);
    }

    .experience-assets-product {
      .product.swiper-slide {
        width: 100%;
      }

      .product-link {
        font-family: $futura-book;
        font-weight: 400;
        font-size: 14px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .price {
        display: none;
      }
    }
  }

  .add-to-wish-list {
    display: none;
  }
}
