.help-dashboard-container {
  padding: 0 157px;
  .page-help-search {
    margin-right: 8px;
    margin-left: unset;
    padding-right: unset;
    padding-left: unset;
  }
  &.contacts {
    max-width: 100%;
  }
}
