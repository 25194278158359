:root {
  --slide-open-mobile: 0;
  --slide-close-mobile: 100vh;
}

#layer-variation-size,
#layer-variation-swatch,
#layer-product-help,
#layer-product-services,
#layer-product-packaging,
#layer-product-notify,
#layer-product-details,
#layer-notification-minicart,
#layer-minicart,
#layer-filter,
#layer-tooltip-prior-best-price {
  top: 120vh;
  transition: top linear 0.1s;
}

.dropdown {
  position: relative;
  .dropdown-trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &:not(.layer-minicart) {
    &.dropdown-trigger-icon {
      &::after {
        content: '';
        @include fixedSprite('arrow');
        transform: rotate(90deg);
        transform-origin: center;
        width: 1.6rem;
        margin-left: 0.5rem;
      }
    }

    .dropdown-content {
      transition: all ease-in 0.3s;
      display: none;
      opacity: 0;
      position: absolute;
      z-index: 1;
      min-width: 25rem;
      background-color: $white;
    }

    &.is-open {
      .dropdown-content {
        display: block;
      }

      &.animate {
        .dropdown-trigger {
          &.dropdown-trigger-icon {
            ::after {
              transform: rotate(269deg);
            }
          }
        }
        .dropdown-content {
          opacity: 1;
        }
      }
    }
  }

  &.layer-variation-size,
  &.layer-variation-swatch,
  &.layer-product-help,
  &.layer-product-services,
  &.layer-product-packaging,
  &.layer-product-details,
  &.layer-minicart,
  &.layer-filter,
  &.layer-tooltip-prior-best-price {
    &.dropdown-trigger-icon {
      &::after {
        content: '';
        @include fixedSprite('arrow');
        transform: rotate(90deg);
        transform-origin: center;
        width: 1.6rem;
        margin-left: 0.5rem;
      }
    }

    .dropdown-content {
      position: absolute;
      top: -100%;
      z-index: 1;
      min-width: 25rem;
      background-color: $white;
      top: 100vh;
      transition: top linear 0.1s;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
      visibility: hidden;
    }

    &.is-open {
      .dropdown-content {
        top: var(--slide-open-mobile) !important;
        transition: top linear 0.1s;
        visibility: visible;
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
      }
    }

    &.animate-minicart-closure {
      .dropdown-content {
        visibility: visible;
      }
    }
  }

  &.layer-product-coming-soon,
  &.layer-product-notify,
  &.layer-product-notify-size {
    .dropdown-coming-soon-content,
    .dropdown-notify-content,
    .dropdown-notify-size-content {
      position: absolute;
      top: -100%;
      z-index: 1;
      min-width: 25rem;
      background-color: $white;
      top: 100vh;
      transition: top linear 0.1s;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
      visibility: hidden;
    }

    &.is-open {
      .dropdown-coming-soon-content,
      .dropdown-notify-content,
      .dropdown-notify-size-content {
        top: var(--slide-open-mobile) !important;
        transition: top linear 0.1s;
        visibility: visible;
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
      }
    }

    &.animate-minicart-closure {
      .dropdown-content {
        visibility: visible;
      }
    }
  }
}


#layer-tooltip-prior-best-price-component {
  position: unset;
  
  #layer-tooltip-prior-best-price {
    top: 100vh;
    transition: top linear 0.5s;
    left: 0;
    width: 0;
    max-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    z-index: 100;
    position: fixed;
  }
}

.tooltip-prior-best-price-container {
  .modal-header.tooltip-prior-best-price-header  {
    margin: 1.6rem;
    .title {
      color: $black;
      font-family: $futura-book;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.64px;
    }
    align-items: center;
    display: flex;
    justify-content: space-between;
    .modal-close-container {
      height: 16px;
      cursor: pointer;
      .modal-close {
        height: 16px;
        width: 16px;
      }
    }
  }
}

.tooltip-prior-best-price-container {
  position: relative;
  height: 100%;

  br {
    display: none;
  }

  .modal-content {
    padding: 1.6rem 1.6rem;
    padding-top: 0.8rem;
    padding-bottom: 2.4rem;
    max-height: calc(100svh - 54px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.js-dropdown-content {
  z-index: 6;
}

.tooltip-prior-best-price-overlay {
  // display: none;
  width: 100vw;
  position: fixed;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  background: #000000a1;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: visibility ease-out 1s, opacity ease-out 1s;

  .dropdown-content {
    z-index: 100;
  }
}

.layer-tooltip-prior-best-price.is-open .tooltip-prior-best-price-overlay  {
  visibility: visible;
  transition: visibility ease-in 1s, opacity ease-in 1s;

  opacity: 1;
}
