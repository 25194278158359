.account-dashboard {
  padding: 0 157px;
  .account-dashboard-search {
    margin-right: 8px;
    margin-left: unset;
    padding-right: unset;
    padding-left: unset;
  }
  &.contacts {
    max-width: 100%;
  }

  .account-dashboard {
    .horizontal-row {
      padding: 0 45px;
      margin: 0 -157px;
      .boxes-upper-row {
        margin-bottom: 8px;
      }
    }
  }
}
