.address-book-container {
  padding: 0 12px;

  // .address-book-wrapper {
  //   margin: 0;
  // }
  .account-bottom-section {
    margin-bottom: 24px;
    .account-btn-addnew {
      display: flex;
      justify-content: flex-end;
      padding: 0 12px;
      .btn--primary {
        min-width: 217px;
        padding: 16px;
        width: auto;
      }
    }
  }

  .horizontal-row {
    &.upper-button-row {
      margin-left: 0;
    }
  }
}
