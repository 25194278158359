.order-confirmation-total-information {
  margin-left: -22px;
  margin-right: -22px;
  padding-left: 22px;
  padding-right: 22px;
}

// .wrapper-item {
//   max-width: 50%;
// }
