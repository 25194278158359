.mobile-filters {
  display: none;
}
.desktop-filters {
  display: flex;
  .filter-list {
    display: flex;
    list-style: none;
    margin-left: 16px;
    &-element {
      margin-right: 23px;
      .name {
        padding-right: 26px;
      }

      .dropdown-single-filter {
        &:not(.is-open),
        &:not(.animate) {
          .dropdown-content {
            display: none;
          }
        }
        .is-open,
        .animate {
          .dropdown-content {
            display: block;
          }
        }
        .dropdown-content {
          width: max-content;
          padding: 6px 11px 0 11px;
          display: flex;
          margin-top: 5px;
          min-width: unset;
          margin-left: -16px;
          .categories-filter {
            width: auto;
            .values {
              .value {
                max-width: unset;
                background: $white;
                width: auto;
                .selection-box {
                  color: $black;
                  cursor: pointer;
                  font-size: 12px;
                  line-height: 1.8;
                  padding-left: 26px;
                  position: relative;
                  text-transform: capitalize;
                  &:before {
                    border: 1px solid $black;
                    content: '';
                    left: 0;
                    position: absolute;
                    top: 0;
                    height: 16px;
                    width: 16px;
                  }
                  &.selected-box {
                    position: relative;
                    &:after {
                      color: $black;
                      position: absolute;
                      display: block;
                      height: 0.4rem;
                      width: 0.8rem;
                      border-left: 1px solid;
                      border-bottom: 1px solid;
                      -webkit-transform: rotate(-45deg);
                      transform: rotate(-45deg);
                      left: 5px;
                      top: 6px;
                      -webkit-box-sizing: border-box;
                      box-sizing: border-box;
                      content: '';
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// refactor filter
.desktop-filters {
  .filter-list {
    &-element {
      .dropdown-single-filter {
        .dropdown-content {
          .categories-filter {
            ul.values {
              columns: 2;
              list-style: none;
              padding: 1rem 1.6rem 1rem;
              .value {
                margin-bottom: 1.4rem;
                display: block;
                min-width: 8rem;
              }
            }
          }
        }
      }
    }
  }
}

.desktop-order {
  .dropdown {
    .dropdown-content {
      width: max-content;
    }
  }
}

.new-page-search-result {
  .mobile-filters {
    display: block;
    .filters-modal {
      #filters-order-modal {
        .modal-container.js-modal-container {
          opacity: 0;
          width: 554px;
          right: -100%;
          position: fixed;
          z-index: 222;
          height: 100vh;
          max-height: unset;
          animation-name: filters-hide-desktop;
          animation-duration: 0.5s;
        }

        .modal-container {
          .modal-close {
            right: 72px;
            top: 53px;
          }

          .modal-filters-order {
            .modal-header {
              padding: 48px 72px 24px;
            }

            .modal-content {
              padding: 24px 72px;
              max-height: calc(100% - 176px);
            }
          }

          .modal-footer {
            .footer-button.apply-filters-button {
              width: 100%;
            }
          }
        }
      }

      #filters-order-modal.is-open {
        .modal-container.js-modal-container {
          opacity: 1;
          right: 0;
          animation-name: filters-show-desktop;
          animation-duration: 0.5s;
        }
      }
    }

    .button-icons {
      button {
        &-icon {
          top: 5px;
        }
      }
    }
  }
}

@keyframes filters-show-desktop {
  from {
    right: -100%;
    opacity: 1;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes filters-hide-desktop {
  from {
    right: 0;
    opacity: 1;
  }
  to {
    right: -100%;
    opacity: 1;
  }
}
