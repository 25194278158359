.image-text-wrapper {
  max-width: 1352px;
  margin: 0 auto;
}

.image-text-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .media-position-right & {
    flex-direction: row-reverse;
  }
}

.image-text__text-region,
.image-text__media-region {
  width: calc(50% - 10px);
}

.image-text__text-region {
  padding: 0 60px;
}
