.pd-image-layer {
  &.darker-opacity-hover {
    &:hover {
      opacity: 0.4;
    }
  }

  img.mobile-four-by-five {
    aspect-ratio: unset;
  }
  img.mobile-nine-by-sixsteen {
    aspect-ratio: unset;
  }
}

.text-layer {
  .text-layer-title {
    font-family: $futura-book;
    font-size: 32px;
    font-weight: 400;
    line-height: 36.8px;
    letter-spacing: 0.01em;
  }

  .text-layer-countdown {
    margin-top: 12px;
    font-family: $futura-book;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.01em;
  }

  .text-layer-editorial-text {
    font-family: $futura-book;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.6px;
    letter-spacing: 0.02em;
    text-align: center;
    margin-top: 12px;
  }

  .link-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 24px;
  }

  .text-layer-link {
    font-family: $futura-book;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-decoration: underline;
    text-underline-offset: 15%;
  }
}

.vh-90 {
  height: calc(90vh - 142px);

  img {
    height: calc(90vh - 142px);
  }
}

.two-by-one {
  height: calc(100vw / 2);

  img {
    height: calc(100vw / 2);
  }
}

.text-layer-notsticky-container.center {
  .text-layer {
    max-width: 33%;
    text-align: center;
    position: absolute;
    z-index: 1;
    margin: 0 auto;
    width: 100%;
    bottom: unset;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.text-layer-notsticky-container.bottom {
  .text-layer {
    max-width: 33%;
    text-align: center;
    position: absolute;
    z-index: 1;
    bottom: 80px;
    margin: 0 auto;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: unset;
  }
}

.text-layer-notsticky-container.editorial-top.isEditorial-true {
  .text-layer {
    padding: 40px;
    width: calc(50% + 80px);

    .text-layer-editorial-text {
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      letter-spacing: 0.015em;
      text-align: left;
    }
  }
}
.text-layer-notsticky-container.center.isEditorial-true {
  .text-layer {
    width: 50%;

    .text-layer-title {
      font-family: $futura-book;
      font-size: 24px;
      font-weight: 400;
      line-height: 27.6px;
      letter-spacing: 0.005em;
    }
  }
}

.text-layer-notsticky-container.editorial-bottom.isEditorial-true {
  .text-layer {
    padding: 40px;
    width: calc(50% + 80px);

    .text-layer-editorial-text {
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      letter-spacing: 0.015em;
      text-align: left;
    }
  }
}

.editorial-card-container {
  .editorial-card-container-main {
    .text-layer {
      .top-text-container {
        top: 24px;
        left: 24px;
      }

      .text-layer-editorial-text {
        bottom: 32px;
        left: 24px;
        font-family: $futura-book;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: center;
      }

      .text-layer-sub-title {
        color: white;
        font-family: $futura-book;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: left;
      }
    }
  }

  .chip {
    top: unset;
    left: unset;
    right: 24px;
    bottom: 24px;
    padding: 12px 21px;
    font-family: $futura-book;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
  }
}
