.experience-region.experience-imageVideoCtaContent {
  position: relative;

  video-js {
    padding-top: 50% !important;
  }

  .experience-component.experience-assets-headingGroup {
    .headingGroup-container {
      width: 33.333%;
      bottom: 80px;
      padding-right: 0;
      padding-left: 0;
    }
    
    .headingGroup-inner {
      font-family: $futura-pt;
      font-weight: 400;

      .heading {
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: .01em;
      }

      .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: .02em;
        text-align: center;
        margin-top: 12px;
      }

      .link {
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-top: 24px;
        text-underline-offset: 15%;

        a {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: .04em;
        }
      }
    }
  }

  .videoBrightCove-container {
    &.desktopcrop {
      video {
        object-fit: cover;
      }
    }

    &.desktopfill {
      video {
        object-fit: fill;
      }
    }
  }
}