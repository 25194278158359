.cart-error {
  margin-left: 0;
  margin-top: -1px;
  padding: 8px 15px;
  width: 100%;
}

#editProductModal {
  .quick-view-dialog {
    .modal-content {
      padding: 0 18px;
      .horizontal-row {
        &.edit-product-modal-header-bottom {
          border-top: 1px solid $light-grey;
          margin-left: -18px;
          width: calc(100% + 36px);
        }
      }
      .modal-body {
        .product-detail {
          margin-top: 0;
          padding: 0;
          .carousel-item {
            height: 333px;
            margin: 0 auto;
            width: 250px;
            .product-image {
              height: 333px;
              width: 250px;
            }
          }
        }
      }
    }
  }
}
