.cart-empty {
  padding: 80px 0 40px;
  .separator {
    display: none;
  }

  .wrapper-login-actions {
    display: flex;
    .separator + a {
      margin-left: 24px;
    }
  }

  .continue-shopping-link-container {
    display: flex;
    justify-content: center;
    .continue-shopping-link {
      width: 50%;
      padding: 16px 32px;
    }
  }
}
