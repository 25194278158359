.experience-assets-visual {
  height: 100%;
  width: 100%;
}

.pd-wrapper-image-layer {
  position: relative;
  display: flex;

  // &,
  // & > *,
  // picture,
  // img {
  //   width: 100%;
  //   height: 100%;
  // }

  .image--tv {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .image-no-tv {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  img {
    display: block;
    object-fit: cover;
    object-position: center center;
    min-height: 1px;
  }

  img.fit {
    object-fit: contain;
  }

  img.fill {
    object-fit: fill;
  }

  img.crop {
    object-fit: cover;
  }

  img.mobile-four-by-five {
    aspect-ratio: 4/5;
  }
  img.mobile-nine-by-sixsteen {
    aspect-ratio: 9/16;
  }

  &.flex-column {
    flex-direction: column;
  }
}

.chip {
  position: absolute;
  top: 16px;
  left: 24px;
  color: $white;
  background-color: $black;
  padding: 8px 16px;
  font-family: $futura-book;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-align: left;
  z-index: 1;
}

.text-layer {
  // position: sticky;
  text-align: center;
  z-index: 1;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-end;
  // right: 1rem;
  // bottom: 3rem;
  // left: 3.5rem;
  // height: 10rem;
  // order: 2;
  // padding-top: 1.5rem;
  // margin-bottom: 3rem;
  position: relative;
  bottom: 140px;

  .text-layer-title {
    font-family: $futura-book;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.01em;
  }

  .text-layer-countdown {
    margin-top: 12px;
    font-family: $futura-book;
    font-size: 24px;
    font-weight: 400;
    line-height: 27.6px;
    letter-spacing: 0.01em;
  }

  .text-layer-editorial-text {
    font-family: $futura-book;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    letter-spacing: 0.03em;
    text-align: center;
    margin-top: 8px;
  }

  .link-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-top: 16px;
    justify-content: center;
  }

  .text-layer-link {
    font-family: $futura-book;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    width: fit-content;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 15%;

    &:before {
      border-top: 1px solid $white;
    }
  }
}

.text-layer-sticky-container {
  // position: sticky;
  // z-index: 1;
  // width: 100%;
  // top: 100vh;
  // bottom: 50vh;
  // height: 0;

  position: absolute;
  height: 100%;
  width: 100%;

  .text-layer {
    text-align: center;
    z-index: 1;
    position: sticky;
    width: 100%;
    top: calc(100vh - 140px);
    height: 0;
    bottom: 140px;
    margin-bottom: 140px;
  }
}

.text-layer-sticky-container.center {
  .text-layer {
    text-align: center;
    position: sticky;
    z-index: 1;
    width: 100%;
    top: 50%;
    height: 0;
    bottom: 140px;
    margin-bottom: 35%;
  }
}

.text-layer-sticky-container.bottom {
  .text-layer {
    text-align: center;
    position: sticky;
    z-index: 1;
    width: 100%;
    top: calc(100vh - 140px);
    height: 0;
    bottom: 140px;
    margin-bottom: 140px;
  }
}

.text-layer-notsticky-container.mobile-center {
  .text-layer {
    max-width: calc(100% - 48px);
    text-align: center;
    position: absolute;
    z-index: 1;
    margin: 0 auto;
    width: 100%;
    bottom: unset;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.text-layer-notsticky-container.mobile-bottom {
  .text-layer {
    max-width: calc(100% - 48px);
    text-align: center;
    position: absolute;
    z-index: 1;
    bottom: 56px;
    margin: 0 auto;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.text-layer-notsticky-container.editorial-top.isEditorial-true {
  .text-layer {
    position: absolute;
    top: 0;
    padding: 24px 40px 24px 24px;
    text-align: left;
    width: 100%;
    max-width: 100%;

    .text-layer-editorial-text {
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      letter-spacing: 0.015em;
      text-align: left;
    }

    .link-container {
      display: none;
    }
  }
}
.text-layer-notsticky-container.center.isEditorial-true {
  .text-layer {
    max-width: calc(100% - 48px);
    text-align: center;
    position: absolute;
    z-index: 1;
    margin: 0 auto;
    width: 100%;
    bottom: unset;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .text-layer-title {
      font-family: $futura-book;
      font-size: 24px;
      font-weight: 400;
      line-height: 27.6px;
      letter-spacing: 0.005em;
    }
  }
}

.text-layer-notsticky-container.editorial-bottom.isEditorial-true {
  .text-layer {
    position: absolute;
    bottom: 0;
    padding: 24px 40px 24px 24px;
    text-align: left;
    width: 100%;
    max-width: 100%;

    .text-layer-editorial-text {
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      letter-spacing: 0.015em;
      text-align: left;
    }

    .link-container {
      display: none;
    }
  }
}

.pd-image-layer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  opacity: 0.2;
  background-color: #000;

  &.white-layer {
    background-color: #fff;
  }
}

.editorial-card-container {
  position: relative;
  width: 100%;
  height: 100%;

  .editorial-card-container-main {
    position: absolute;
    height: 100%;
    width: 100%;

    .text-layer {
      width: 100%;
      position: absolute;
      height: 100%;
      bottom: 0;

      .top-text-container {
        text-align: left;
        position: absolute;
        top: 16px;
        left: 16px;
      }

      .text-layer-sub-title {
        color: white;
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.03em;
        text-align: left;
        margin-bottom: 4px;
      }

      .text-layer-title {
        color: white;
        font-family: $futura-book;
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0.01em;
      }

      .text-layer-editorial-text {
        color: white;
        position: absolute;
        bottom: 24px;
        left: 16px;
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.03em;
      }
    }
  }

  .editoral-link {
    height: 100%;
    display: block;

    .visualImage {
      height: 100%;
      display: block;

      img {
        height: 100%;
      }
    }
  }

  .chip {
    top: unset;
    left: unset;
    right: 16px;
    bottom: 16px;
    border-radius: 100px;
    color: #000;
    background-color: #fff;
    padding: 10px 21px;
    font-family: $futura-book;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-align: left;
  }
}
