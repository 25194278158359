.registration {
  padding-top: 8px;
  padding-bottom: 16px;

  .form-select {
    select {
      appearance: none;
      width: 100%;
      + .select-icon {
        position: absolute;
        right: 25px;
        transform: translateY(28px);
        pointer-events: none;
      }
    }
  }
  .privacy-intro {
    @extend %body--small;
  }
  .checkbox {
    display: inline-flex;
    align-items: center;
    input[type='checkbox'] {
      width: 1.6rem;
      height: 1.6rem;
      padding: 0;
      margin: 12px;
      position: relative;

      &:checked::after {
        left: 4px;
        top: 4px;
        position: absolute;
      }
    }
    .privacy-label {
      @extend %body--small;
    }
  }
}

.requirement-container {
  background: #F9F9F9;
  padding: 16px 12px;
  margin-bottom: 24px;
  position: relative;

  .requirement-icon {
    width: 16px;
    height: 16px;
    vertical-align: text-top;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

  .requirement-text {
    font-family: $futura-book;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #0D0D0D;
    padding-left: 28px;
  }
}

#checkout-begin {
  .requirement-container {
    margin-bottom: 0;
    margin-top: 24px;
  }
}
