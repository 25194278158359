input,
textarea,
select {
  background-color: transparent;
  border: 1px solid #ccc;
  color: #0D0D0D;
  // padding: 3px 10px;
  font-size: 1.6rem;
  line-height: 21.6px;
  letter-spacing: 0.015em;

  border-radius: 2px;

  margin: 8px 0 0;
  padding: 0 12px;
  &:focus {
    border-color:#595959;
  }

  &::placeholder {
    color: #B4B4B4;
  }
  font-family: $futura-book;
}

.checkoutpage {
  input,
  textarea,
  select {
    background-color: transparent;
    border: 1px solid $black;
    color: $black;
    // padding: 3px 10px;
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0.5px;

    border-radius: 0;

    margin: 8px 0 0;
    padding: 11px 10px;

    &:focus {
      border-bottom-color: $black;
    }

    &::placeholder {
      color: $dark-grey;
    }
    font-family: $futura-book;
  }

  textarea {
    min-height: auto;
  }
}

body:not(#checkout-begin) {
  .form-input {
    select {
      line-height: normal;

      &:invalid {
        color: #B4B4B4;
      }
    }
  }
}

// select {
//   appearance: none;
//   height: 48px;

//   + .select-icon {
//     position: absolute;
//     transform: translateY(28px);
//     pointer-events: none;
//   }
// }

%input-invalid-feedback {
  color: $status_error;
  margin-top: 8px;
  font-family: $futura-book;
  font-size: 1.2rem;
  line-height: 2.2rem;
  letter-spacing: 0.3;
}

.form-input {
  width: 100%;
  padding-bottom: 2.5rem;
  .form-control-label {
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: 0.01em;
    color: #262626;
    font-weight: normal;
  }
  input {
    width: 100%;
    height: 45px;
    text-overflow: ellipsis;
    &.invalid,
    &.is-invalid,
    &.error {
      border-color: #E74C3C;
    }
    &.valid {
      border-color: $status_success;
    }

    &[type='date'],
    &#registration-form-birthday {
      appearance: none;
      -webkit-appearance: none;
      position: relative;

      &.empty {
        color: #B4B4B4;
      }

      &::-webkit-calendar-picker-indicator {
        opacity: 0;
      }

      &::-webkit-date-and-time-value {
        text-align: left;
      }

      &:before {
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkgMVYzSDE1VjFIMTdWM0gyMUMyMS41NTIzIDMgMjIgMy40NDc3MiAyMiA0VjIwQzIyIDIwLjU1MjMgMjEuNTUyMyAyMSAyMSAyMUgzQzIuNDQ3NzIgMjEgMiAyMC41NTIzIDIgMjBWNEMyIDMuNDQ3NzIgMi40NDc3MiAzIDMgM0g3VjFIOVpNMjAgMTFINFYxOUgyMFYxMVpNNyA1SDRWOUgyMFY1SDE3VjdIMTVWNUg5VjdIN1Y1WiIgZmlsbD0iIzBEMEQwRCIvPgo8L3N2Zz4K");
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        width: 24px;
        height: 24px;
      }
    }
  }
  textarea {
    padding: 12px 24px 12px 12px;
    min-height: 45px;
    resize: vertical;
    &.invalid,
    &.error {
      border-color: #E74C3C;
    }
  }

  .error-message,
  .invalid-feedback {
    @extend %input-invalid-feedback;

    color: #E74C3C;
    margin-top: 4px;
    margin-left: 4px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.015em;
    font-weight: normal;
  }
  .valid-feedback {
    color: $status_success;
    margin-top: 8px;
    font-family: $futura-book;
    font-size: 1.2rem;
    line-height: 2.2rem;
    letter-spacing: 0.3;
  }
}



.form-group {
  &.is-invalid {
    input {
      border-color: $status_error;
    }
  }
  .invalid-feedback {
    @extend %input-invalid-feedback;
  }
}

.adyen-container {
  .error-message {
    @extend %input-invalid-feedback;
  }
  input {
    &.invalid {
      border-color: $status_error;
    }
  }
}

form {
  .invalid-feedback {
    @extend %input-invalid-feedback;
  }
}

.field-description {
  @extend %body--small;
  color: $dark-grey;
}

.search-content-form {
  .alert {
    @extend %input-invalid-feedback;
  }
}
