.edit-add-address-container {
  .edit-add-address {
    .edit-address-form {
      .form-input {
        position: relative;
        .select-icon {
          right: 25px;
          bottom: 31px;
        }
      }
      .edit-address-buttons-wrapper {
        display: flex;
        flex-direction: row-reverse;
        .cancel-button {
          margin-top: 0;
          margin-right: 24px;
          width: 173px;
        }
        .save-button {
          width: 173px;
        }
      }
    }
  }
}
