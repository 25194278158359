.no-zindex {
  .header {
    position: sticky !important;
    z-index: 2;
  }
}

#product-show {
  .header {
    position: relative;
    z-index: 21;
  }

  &.no-zindex {
    .header {
      position: unset !important;
    }
    .header .right-actions,
    .header .logo {
      z-index: 0;
    }
  }
}
.header {
  border-bottom: none;
  position: sticky;
  z-index: 21;

  .hidden-sm {
    display: block;
  }

  .visible-md {
    display: block;
  }
  .hidden-md {
    display: none;
  }

  .header-top {
    justify-content: space-between;
    position: unset;
    padding: 0 24px;
    &.no-menu {
      .minicart-action {
        margin-right: 5px;
      }
    //   .logo {
    //     a {
    //       &:after {
    //         margin-top: 18px;
    //         margin-bottom: 19px;
    //       }
    //     }
    //   }
    //   // .right-actions {
    //   //   a {
    //   //     margin: 12px 0 18px;
    //   //   }
    //   // }
    }
  }
  .header-search,
  .right-actions .action {
    width: 1.8rem;
    height: 1.8rem;
  }
  .header-search,
  .right-actions .action {
    .js-quantity {
      left: calc(100% + 2px);
      top: 6px;
    }
  }
  &.z-index-0 {
    z-index: 0 !important;
  }
}

.header,
.footer {
  .logo {
    text-align: left;
    a::after {
      content: '';
      @include fixedSprite('logo-desktop-red');
    }
  }
}
