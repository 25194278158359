.gallery-component {
  padding: 30px 15px;
}

.gallery__media-region {
  display: flex;
  column-gap: 1.7rem;
  row-gap: 1.6rem;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery-item {
  width: 16.4rem;
  height: 24.6rem;

  picture {
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      display: block;
      object-fit: cover;
      object-position: center center;
    }
  }
}

.gallery-overlay {
  display: none;
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;

  &.is-open {
    display: block;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 100;
  }

  .gallery__title-region {
    @extend %heading-medium;
    padding-left: 1.5rem;
    padding-top: 2.4rem;
  }

  .swiper-container {
    height: 100%;
    max-height: 90vh;
  }
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-slide {
    width: 25.9rem;
    height: 38.9rem;
  }

  .swiper-arrows {
    display: none;
  }

  .swiper-pagination {
    @extend %heading-medium;
  }
}

.gallery-close {
  position: absolute;
  z-index: 2;
  top: 2.4rem;
  right: 4.5rem;
  width: auto;
  height: auto;
  line-height: 1;
  &::after {
    content: '';
    @include fixedSprite(close);
  }
}

.layer-gallery-open {
  overflow: hidden;
}

.image-carousel-global-container .swiper-pagination {
  z-index: 1 !important;
}
