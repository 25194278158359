.pdp-new.product-detail {
  margin: 0;

  padding: 0 !important;
  // padding-right: 100px;

  .product-slider-container {
    height: 100%;
    display: block;

    .product-slides {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      width: 100%;

      .product-slide {
        height: fit-content;
        width: 50%;

        .swiper-zoom-container {
          width: 100%;
          height: auto;
          margin: 0 auto;
          padding: 4px 8px 4px 0;

          img {
            width: 100% !important;
          }
        }
      }
    }

    .primary-images {
      padding-left: 22px;
      padding-right: 22px;
    }

    .col-images {
      padding: 0;
    }

    .sticky-col {
      max-width: 550px;
      padding: 115px 64px;
      height: calc(100vh - 142px);
      .details-column-content {
        width: 100%;
        &.bottom-position {
          bottom: 0;
          position: absolute;
          right: 72px;
          z-index: 2;
          background: $white;
          flex-basis: 33.33333%;
          max-width: 33.33333%;
        }
        &.default-position {
          position: relative;
          z-index: 2;
          background: $white;
        }
        &.top-position {
          top: 0;
          position: fixed;
          right: 72px;
          background: $white;
          z-index: 2;
          flex-basis: 33.33333%;
          max-width: 33.33333%;
        }
      }
    }

    .add-to-cart-add-to-wishlist {
      flex-direction: row-reverse;
      margin-left: 0;
      margin-right: 0;
      // .add-to-cart-actions {
      //   max-width: 360px;
      //   width: 360px;
      // }
    }
    .desktop-images {
      margin-left: 60px;
      .single-image {
        height: 948px;
        margin: 30px 0;

        max-width: 711px;
        padding: 0;
        width: 711px;
        &:first-child {
          margin-top: 60px;
        }
        img {
          width: 711px;
          height: 948px;
        }
      }
    }
  }

  .notify-me-container {
    .notify-me {
      &-insert-email {
        .add-to-cart-add-to-wishlist {
          .add-to-cart-actions,
          .notfy-me-actions {
            max-width: 304px;
          }
        }
      }
    }
  }
}
