.pd-image-component {
  .twoColumnContainer {
    .twoColumnContent {
      height: auto;
      aspect-ratio:auto;
      gap: 0;

      .pd-wrapper-image-layer {
        flex: 0 0 50%;
      }
      
      .visualImage {
        height: auto;
      }
    }
  }
}