.reset-password {
  &.access-details-container {
    padding: 16px 44px;
    .form-group.required .form-control-label::before {
      content: '';
    }
  }

  .new-password-form {
    margin-left: 0;
    margin-right: 0;
  }
}

.reset-password-help-boxes {
  margin-top: 32px;
  padding: 0 88px;
  .content-asset-box {
    .single-box-element {
      display: flex;
      justify-content: center;

      .single-box-element-content {
        text-align: center;
      }
      &:first-child {
        padding-right: 24px;
      }
      &:last-child {
        padding-left: 24px;
      }
    }
  }
}
