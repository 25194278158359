.order-detail-data-container {
  .horizontal-row {
    &.bopis-row-top {
      border-top: 1px solid $light-grey;
      width: 100%;
    }
    &.shipping-type-top {
      display: none;
    }
  }
  .order-download {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .order-confirmation-total-information {
    padding: 1.6rem;
  }

  .title-section {
    @extend %heading-medium;
    text-transform: uppercase;
  }

  .order-detail-dl {
    padding-top: 2.4rem;
    display: flex;
    flex-direction: column;
    .order-detail-dl-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex: 1 0 auto;
    }

    .order-status {
      color: $status_success;
    }
  }

  .order-actions:not(.order-actions-mobile) {
    display: none;
    padding: 2.4rem 0 1.6rem 0;
    align-items: center;
  }

  .shipping-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.6rem;
  }
  .shipping-information {
    padding-bottom: 16px;
  }
  .shipping-type {
    padding-top: 16px;

    .title {
      @extend %label--small;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      [class*='icon--'] {
        margin-right: 8px;
      }
    }
  }

  .title-information {
    @extend %title--regular;
    text-transform: uppercase;
    padding-top: 1.6rem;
  }

  .detail-head-actions {
    display: flex;
    flex-direction: column;
  }

  .shipping-restrictions {
    max-width: 50rem;
    flex: 1;
    padding: 2.4rem 0 1.6rem;

    #shipping-restriction-accordion-control {
      @extend %label--small;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      width: 100%;
      //
    }
    .container-shipping-restrictions {
      padding-top: 1.6rem;
    }
  }

  .order-confirmation-total-information {
    .order-receipt-label {
      font-size: 14px;
    }
  }

  .title-list {
    @extend %title--large;

    padding-top: 1.6rem;
    border-top: 1px solid $light-grey;

    &.order-details-title {
      text-transform: uppercase;
    }
  }
}
