.form-select {
  position: relative;
  .row {
    align-items: center;
    .col-11 {
      background: transparent;
      max-width: 100%;
      flex-basis: 100%;
      select {
        // height: 22px;
        height: 48px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.3px;
        width: 100%;
        background: transparent;
        border: solid 1px $black;
        padding: 0;
        padding-left: 13px;
        padding-right: 48px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: block;
        margin: 5px 0;
        background-image: none;
        -ms-word-break: normal;
        word-break: normal;
        &::-ms-expand {
          display: none;
        }
        &.date-field,
        &.time-field {
          // width: calc(100% + 39px);
          margin-bottom: 20px;
          padding-right: 24px;
        }
      }
    }
    .col-1 {
      height: 0;
      width: 0;
      max-width: 0;
      position: absolute;
      right: 25px;
      top: 45%;
      z-index: -1;
      .icon--chevron-mini-down {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
