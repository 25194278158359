//MOBILE
%display-heading,
.display-heading {
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 4rem;
  letter-spacing: 2px;
}

%heading-large,
.heading-large {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.4rem;
  letter-spacing: 2px;
}
%heading-medium,
.heading-medium {
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.2rem;
  letter-spacing: 1px;
}
%heading-small,
.heading-small {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.8rem;
  letter-spacing: 1px;
}

%title--xlarge,
.title--xlarge {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.8rem;
  letter-spacing: 1px;
}
%title--large,
.title--large {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.5rem;
  letter-spacing: 1px;
}
%title--regular,
.title--regular {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 1px;
}

%title--small,
.title--small {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.9rem;
  letter-spacing: 0.5px;
}

%nav__title,
.nav__title {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: 1px;
}

%nav__title--hover,
.nav__title--hover {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: 1.3px;
}

%nav__subtitle,
.nav__subtitle {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.5px;
}
%nav__subtitle--hover,
.nav__subtitle--hover {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.8px;
}
%label,
.label {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: 1px;
}
%label--small,
.label--small {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.2rem;
  letter-spacing: 0.5px;
}

%primary-regular,
%body--regular,
.body--regular {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.3px;
}
%body--medium,
.body--medium {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.5px;
}

%body--small,
.body--small {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2.2rem;
  letter-spacing: 0.3px;
}
%body--small--medium,
.body--small--medium {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.3px;
}

%body--heavy,
.body--heavy {
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

%cta,
.cta {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: 2px;
}
%mini-button,
.mini-button {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.2rem;
  letter-spacing: 0.3px;
}
