.layer-minicart.is-open {
  #layer-notification-minicart,
  #layer-minicart {
    display: block;
  }
}

.minicart-notification-overlay,
.minicart-overlay,
.filter-overlay {
  position: fixed;
}

#layer-notification-minicart,
#layer-minicart,
#layer-filter {
  left: unset;
  position: fixed;

  .emtpy-minicart-modal-content {
    padding: 24px;
  }
}

.layer-minicart {
  position: unset;
}

.minicart-recommender-mobile {
  display: none;
}

#layer-notification-minicart,
#layer-minicart {
  top: 0;
  right: -527px;
  height: 100vh;
  min-width: 527px;
  max-width: 527px;

  .main-overlay {
    z-index: 21;
    top: 0;
  }
}

#layer-filter {
  top: 0;
  right: -554px;
  height: 100vh;
  min-width: 554px;
  max-width: 554px;

  .main-overlay {
    z-index: 21;
    top: 0;
  }
}

.minicart-notification-container,
#minicart-modal,
.minicart-modal {
  .modal-footer {
    position: absolute;
    padding-top: 24px;
    margin-bottom: 24px;

    .minicart-continue-shopping {
      padding: 0 24px;
    }

    .link-shopping-bag,
    .minicart-continue-shopping-link {
      font-family: $futura-book;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.64px;
      height: unset;
      align-items: unset;
    }

    .minicart-delivery-info {
      // margin-top: 40px;
      padding: 16px 24px 16px;
    }
  }
}

.minicart-modal {
  .modal-footer {
    .minicart-continue-shopping {
      padding-bottom: 40px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

.minicart-notification-overlay {
  position: fixed;
}

.minicart-notification-container,
.minicart-modal {
  height: 100vh;
}

.minicart-notification-container {
  #overlay-minicart.modal-content.product-content {
    padding: 0;
  }
  .minicart-content {
    .minicart-recommendations {
      .minicart-item-grid {
        padding: 0;
        margin-bottom: 32px;
      }
    }
  }
}

#minicart-modal,
.minicart-modal {
  .modal-content {
    padding: 2.4rem;

    .minicart-prior-best-price-disclaimer {
      margin-bottom: 0;
    }

  }
}

.minicart-notification-container {
  .product-content {
    .product-wrapper {
      .minicart-item-grid {
        padding: 0 24px;
      }
    }

    .minicart-recommendations {
      .product-wrapper {
        .minicart-item-grid {
          padding: 0;
        }
      }
    }
  }
}

.minicart-recommendations {
  .row {
    padding: 0 24px;
  }

  .row.cart-and-ipay {
    padding: 0;
  }
  .product-wrapper {
    padding-top: 0;
  }

  .row {
    .modal-header.minicart-header {
      margin-left: 0;
      margin-bottom: 16px;
      margin-top: 24px;
    }
  }
}

.minicart-notification-container {
  .minicart-content {
    .minicart-item-grid {
      padding: 24px;
    }
  }
}

.minicart-notification-container,
#minicart-modal,
.minicart-modal {
  .minicart-recommendations {
    .product-wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.minicart-notification-container,
.minicart-modal,
.modal {
  .modal-header.minicart-header {
    margin: 24px;
  }
  .product-wrapper {
    + .product-wrapper {
      padding-top: 8px;
      border-top: none;
    }
  }

  .minicart-item-grid {
    padding-bottom: 0;
    padding-top: 0;
  }

  .modal-footer {
    .button-container {
      padding: 0 24px;
    }

    .btn-container {
      padding: 0 24px;
    }

    .minicart-totals {
      padding: 0 24px;
      .line-totals-item {
        display: flex;
      }
    }
  }
}

.minicart-notification-container {
  .minicart-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .minicart-recommender-desktop {
      bottom: 0;
    }
  }

  .modal-footer {
    .minicart-continue-shopping {
      padding: 0 16px 16px;
    }

    .minicart-continue-shopping-link {
      height: 48px;
      align-items: center;
    }
  }
}

.minicart-notification-container {
  .minicart-content {
    .minicart-item-grid {
      overflow-y: auto;

      .attributes-container {
        position: relative;
        height: 140px;
      }

      .product-prices-wrapper {
        position: absolute;
        margin-top: 41px;
      }
    }
  }
}

.minicart-content {
  .minicart-item-grid {
    .product-attributes {
      color: $black;
    }
    .product-quantity-wrapper {
      color: $black;
      margin: 0;
    }
    .product-actions-wrapper {
      display: flex;
      .wish-delete-wrapper {
        justify-content: flex-end;
        .btn-cart-remove {
          span {
            line-height: 140%;
          }
        }
      }
    }
  }
}


.minicart-item-grid {
  grid-template-columns: 170px 1fr;

  .product-info-img {
    width: 154px;
  }

  .product-title,
  .pricing,
  .detail-attribute .name-field,
  .detail-attribute .value-field,
  .quantity .name-field,
  .quantity .value-field {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.21px;
  }

  .product-title {
    text-overflow: unset;
    white-space: normal;
    overflow: unset;
  }

  .product-prices-wrapper {
    .line-item-total-price {
      .price span, 
      .minicart-line-item-disclaimer {
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.21px;
      }
    }
  }
  .product-actions-wrapper {
    margin-bottom: 8px;
  }
}

// Add to Cart Recommender

#layer-minicart-component.layer-notification-minicart.is-open {

  .minicart-notification-container {
    overflow-y: scroll;

    .modal-footer {
      .minicart-continue-shopping {
        padding: 0 24px;
      }
    }
  }

  #overlay-minicart {
    height: auto !important;
  }

  .product-wrapper {
    .attributes-container {
      .product-name-wrapper,
      .color-label,
      .size-label {
        font-size: 14px;
      }

    }
  }

  .modal-footer {
    position: relative;
    padding-top: 24px;
    margin-bottom: 24px;

    .minicart-continue-shopping {
      padding-bottom: 0;
    }
  }
  
  .minicart-recommendations {
    &_wrapper {
      padding: 16px 24px;

      .product-image-wrapper {
        width: 100%;
        height: 100%;

        & > a {
          width: 100%;
          height: 100%;
        }
      }

      &_title {
          padding: 16px 16px 0 16px;
      }
    }
  
    &_grid > .swiper-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
    }

    .skeleton-loading {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      width: auto;

      &_item {
        height: 199.11px;
        width: auto;
      }

      &_progression-bar {
        display: none;
      }
    }

    &_title {
      font-size: 16px;
      font-weight: 400;
      color: #111;
      padding: 16px 24px 0 24px;
    }

    .swiper {
      &-slide {
        margin-right: 0;
      }
      &_progression-bar {
        display: none;
      }
    }
  }
}
