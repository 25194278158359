.page-search-result-container.new-page-search-result {
  .search-no-result-decorator-container {
    .no-result-page {
      .no-result-carousel {
        padding: 0 44px;
        .image-carousel-global-container {
          .swiper-slide {
            width: 308px;
            margin-right: 20px;
          }
        }
        .img {
          width: 308px;
          height: 411px;
          // margin-right: 20px;
        }
      }
    }
  }
}
