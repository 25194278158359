.cookies-modal-popup {
  &-content {
    width: 375px;
    bottom: 40px;
    right: 44px;
  }
}

.cookies-management-modal-popup {
  background-color: #00000087;

  .cookie-modal-footer {
    .wrapper-actions {
      flex-direction: row;
    }
    button {
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
      & + button {
        margin-top: 0;
      }
      &.btn--primary {
        margin-left: 8px;
      }
      &.btn--secondary {
        margin-right: 8px;
      }
    }
  }

  .modal-content {
    height: 678px;
    position: relative;
    width: 612px;
  }

  .modal-dialog {
    background-color: $white;
    height: 678px;
    margin: 0 auto;
    margin-top: 24px;
    max-width: 825px;
    padding: 0;
    width: 612px;
  }
}
