.wheel-carousel-component {
  max-width: 1352px;
  margin: 0 auto;
  overflow: visible;
  padding-top: 2rem;
  padding-bottom: 4rem;

  .swiper-container {
    margin-left: 40px;
  }

  .swiper-wrapper {
    flex-wrap: nowrap;
    > * {
      padding: 0;
    }
  }

  .swiper-pagination {
    position: static;
    display: flex;
    justify-content: center;
  }
  .experience-groupContent {
    > *:not(.pd-wrapper-image-layer):not(.pd-video__container) {
      display: none;
    }
  }
  .swiper-wrapper {
    align-items: center;
    min-height: 46.2rem;
  }

  .video--tv {
    .video-js:not(.vjs-fullscreen) {
      .vjs-control-bar {
        display: none;
      }
    }
  }

  .pd-video__container {
    &.video--tv {
      padding-bottom: 0;
    }
  }

  .swiper-slide-active {
    .experience-groupContent {
      > *:not(.pd-wrapper-image-layer):not(.pd-video__container) {
        display: block;
      }
    }
    .video--tv {
      .video-js:not(.vjs-fullscreen) {
        .vjs-control-bar {
          display: flex;
        }
      }
    }
    .pd-video__container {
      &.video--tv {
        padding-bottom: 80px;
      }
    }
  }

  .swiper-slide {
    transition: all 0.5s ease;

    a {
      pointer-events: none;
    }

    &:not(.swiper-slide-active),
    &:not(.swiper-slide-next),
    &:not(.swiper-slide-prev) {
      transform: scale(0.4);
    }

    .pd-wrapper-image-layer {
      .image--tv {
        margin: 0;
      }
    }

    &.swiper-slide-active {
      width: calc(50% - 40px) !important;
      transform: scale(1);
      a {
        pointer-events: visible;
      }
    }
    &.swiper-slide-next,
    &.swiper-slide-prev {
      width: calc(25% - 40px) !important;
      transform: scale(1);
    }
  }
}

.wheel-carousel-component {
  .pd-video__container,
  .pd-wrapper-image-layer {
    margin: 0 auto;
    margin-bottom: 2.3rem;
  }
  .pd-video__container {
    margin-bottom: 0;
  }

  .group-component {
    + .group-component {
      margin-top: 0;
    }
  }
}
//
