:root {
  --filters-maxHeight: 1000px;
}

.mobile-filters {
  .button-icons {
    .results-label-container {
      display: flex;
    }
    .results-label-results,
    .results-label {
      color: $black;
      font-family: $futura-book;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.7px;
      margin-right: 4px;
    }
    .filter-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .change-view {
        justify-content: space-between;
        align-items: center;
        display: none;
        &-toggle {
          display: flex;
          align-items: center;
          padding: 0 15px;
          opacity: 0.5;
          &.active {
            opacity: 1;
          }
          &.grid-x-1 {
            padding-right: 0;
          }
        }
      }
    }
    button {
      align-items: center;
      display: flex;
      border: solid 1px #b0b0b0;
      border-radius: 24px;
      height: 48px;
      justify-content: space-between;
      padding: 18px 40px 18px 16px;
      position: relative;
      .mobile-filters {
        &-label {
          color: #111;
          font-family: $futura-book;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px; /* 150% */
          letter-spacing: 0.7px;
          margin-right: 4px;
        }

        &-icon {
          @include fixedSprite('filters-setting');
          width: 15px;
          height: 15px;
          position: absolute;
          right: 25px;
          top: 8px;
          transform: translate(50%, 50%);
        }
      }

      &:hover {
        border: 1px solid #888888;
      }
    }
  }

  @keyframes filters-show {
    from {
      top: 100%;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  @keyframes filters-hide {
    from {
      top: 0;
      opacity: 1;
    }
    to {
      top: 100%;
      opacity: 1;
    }
  }

  @keyframes overlay-show {
    from {
      display: none;
      opacity: 1;
      background: rgba(0, 0, 0, 0);
    }
    to {
      display: block;
      opacity: 100%;
      background: rgba(0, 0, 0, 0.6);
    }
  }

  @keyframes overlay-hide {
    from {
      display: block;
      opacity: 100%;
      background: rgba(0, 0, 0, 0.6);
    }
    to {
      display: block;
      opacity: 1;
      background: rgba(0, 0, 0, 0);
    }
  }

  .modal {
    display: block;
    .modal-overlay {
      display: none;
      animation-name: overlay-show;
      animation-duration: 0.5s;
    }
    .js-modal-container {
      top: 100%;
      opacity: 1;
      animation-name: filters-hide;
      animation-duration: 0.5s;
    }
  }

  .modal.is-closed {
    display: block;
    .modal-overlay {
      display: block;
      background: rgba(0, 0, 0, 0);
      animation-name: overlay-hide;
      animation-duration: 0.5s;
    }

    .modal-overlay.d-none {
      display: none !important;
    }

    .js-modal-container {
      top: 100%;
      opacity: 1;
      animation-name: filters-hide;
      animation-duration: 0.5s;
    }
  }

  .modal.is-open {
    .modal-overlay {
      opacity: 100%;
      display: block;
      animation-name: overlay-show;
      animation-duration: 0.5s;
    }
    .js-modal-container {
      top: 0;
      opacity: 1;
      animation-name: filters-show;
      animation-duration: 0.5s;
    }
  }

  #filters-order-modal {
    .modal-overlay {
      width: 100vw;
      height: 100%;
      .modal-container {
        position: relative;
        width: 100vw;
        max-width: 100vw;
        .modal-close {
          z-index: 1;
          right: 15px;
          top: 21px;
          &:after {
            height: 15px;
            width: 15px;
          }
        }
        .modal-filters-order {
          position: relative;
          height: 100%;
          .modal-header {
            padding: 16px 16px 16px;
            display: flex;
            justify-content: space-between;
            .modal-title {
              width: fit-content;
              color: $black;
              font-family: $futura-book;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.63px;
            }

            .reset-filters-button {
              margin-right: 40px;
              font-family: $futura-book;
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.02em;
            }

            .link-underline:before {
              bottom: 4px;
            }
          }

          .modal-content {
            overflow-x: hidden;
            overflow-y: scroll;
            padding-left: 14px;
            padding-top: 24px;
            padding-right: 14px;
            max-height: calc(100% - 153px);
            .sort-title {
              color: $black;
              font-family: $futura-book;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: 0.56px;
              margin-bottom: 12px;
            }
            .order-by-radios {
              .radio {
                display: flex;
                align-items: center;
                padding: 8px 0;
                margin-bottom: 0;

                label {
                  color: #111;
                  font-family: $futura-book;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 21px;
                  letter-spacing: 0.56px;
                }
              }
            }
            .modal-content-filters {
              &-title {
                padding: 24px 0;
                color: $black;
                font-family: $futura-book;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0.56px;
                border-bottom: 1px solid #e5e5e5;
              }

              .filters-order-accordion {
                &-content {
                  .single-filter-section {
                    border-bottom: 1px solid #eeedeb;
                    .button-open-filter {
                      align-items: center;
                      display: flex;
                      justify-content: space-between;
                      width: 100%;
                      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                      margin: 12px 0;
                      height: 45px;
                      .accordion-button-label {
                        padding: 12px 0;
                        color: $black;
                        font-family: $futura-book;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px; /* 150% */
                        letter-spacing: 0.56px;

                        .grey-span {
                          color: #999999;
                        }
                      }
                    }

                    .icon--minus-icon {
                      display: none;
                    }

                    .accordion-trigger[aria-expanded='false'] {
                      .icon--minus-icon {
                        display: none;
                      }
                    }

                    .accordion-trigger[aria-expanded='true'] {
                      .icon--plus-icon {
                        display: none;
                      }

                      .icon--minus-icon {
                        display: block;
                      }
                    }

                    .filter-list-container {
                      .values {
                        .value {
                          .selection-box {
                            color: $black;
                            position: relative;
                            font-size: 12px;
                            cursor: pointer;
                            line-height: 1.8;
                            text-transform: capitalize;

                            .icon--close {
                              display: none !important;
                              width: 12px;
                              height: 12px;
                              position: absolute;
                              right: 8px;
                              top: 13px;
                            }
                            &:before {
                              display: none;
                              border: 1px solid $black;
                              content: '';
                              left: 8px;
                              position: absolute;
                              top: 8px;
                              height: 20px;
                              width: 20px;
                            }

                            &.selected-box {
                              position: relative;
                              &:before {
                                display: none;
                                background-color: $black;
                              }
                            }
                          }

                          .selection-box.selected-box {
                            .icon--close {
                              display: block !important;
                            }
                          }
                        }

                        .color-attribute {
                          .value {
                            .selection-box {
                              padding-left: 36px;
                              &:before {
                                display: block;
                              }

                              &.selected-box {
                                &:before {
                                  display: block;
                                }
                              }
                            }
                          }
                        }
                      }

                      .beige {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: none;
                              background-color: #cdb492;
                            }

                            &.selected-box {
                              &:before {
                                background-color: #cdb492;
                              }
                              &:after {
                                color: $black;
                              }
                            }
                          }
                        }
                      }

                      .black {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: none;
                              background-color: $black;
                            }

                            &.selected-box {
                              &:before {
                                background-color: $black;
                              }
                              &:after {
                                color: $white;
                              }
                            }
                          }
                        }
                      }
                      .blue {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: none;
                              background-color: #17008c;
                            }

                            &.selected-box {
                              &:before {
                                background-color: #17008c;
                              }
                              &:after {
                                color: $white;
                              }
                            }
                          }
                        }
                      }
                      .brown {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: none;
                              background-color: #5b3a16;
                            }

                            &.selected-box {
                              &:before {
                                background-color: #5b3a16;
                              }
                              &:after {
                                color: $white;
                              }
                            }
                          }
                        }
                      }
                      .green {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: none;
                              background-color: #006633;
                            }

                            &.selected-box {
                              &:before {
                                background-color: #006633;
                              }
                              &:after {
                                color: $white;
                              }
                            }
                          }
                        }
                      }
                      .grey {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: none;
                              background-color: #808080;
                            }

                            &.selected-box {
                              &:before {
                                background-color: #808080;
                              }
                              &:after {
                                color: $white;
                              }
                            }
                          }
                        }
                      }
                      .multicolor {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: none;
                              background: conic-gradient(
                                from 180deg at 50% 50%,
                                #ff1f1f 0deg,
                                #ffe91f 114.37500357627869deg,
                                #2bd700 211.87500715255737deg,
                                #0057ff 292.5deg,
                                #ff1fe9 360deg,
                                #ff1fe9 360deg
                              );
                            }

                            &.selected-box {
                              &:before {
                                background: conic-gradient(
                                  from 180deg at 50% 50%,
                                  #ff1f1f 0deg,
                                  #ffe91f 114.37500357627869deg,
                                  #2bd700 211.87500715255737deg,
                                  #0057ff 292.5deg,
                                  #ff1fe9 360deg,
                                  #ff1fe9 360deg
                                );
                              }
                              &:after {
                                color: $white;
                              }
                            }
                          }
                        }
                      }
                      .orange {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: none;
                              background-color: #f2590c;
                            }

                            &.selected-box {
                              &:before {
                                background-color: #f2590c;
                              }
                              &:after {
                                color: $white;
                              }
                            }
                          }
                        }
                      }
                      .pink {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: none;
                              background-color: #ffccd5;
                            }

                            &.selected-box {
                              &:before {
                                background-color: #ffccd5;
                              }
                              &:after {
                                color: $black;
                              }
                            }
                          }
                        }
                      }
                      .purple {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: none;
                              background-color: #4a1b59;
                            }

                            &.selected-box {
                              &:before {
                                background-color: #4a1b59;
                              }
                              &:after {
                                color: $white;
                              }
                            }
                          }
                        }
                      }
                      .red {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: none;
                              background-color: #b22424;
                            }

                            &.selected-box {
                              &:before {
                                background-color: #b22424;
                              }
                              &:after {
                                color: $white;
                              }
                            }
                          }
                        }
                      }
                      .white {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: 1px solid #ccc;
                              background-color: #ffffff;
                            }

                            &.selected-box {
                              &:before {
                                background-color: #ffffff;
                              }
                              &:after {
                                color: $black;
                              }
                            }
                          }
                        }
                      }
                      .yellow {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: none;
                              background-color: #f9fb62;
                            }

                            &.selected-box {
                              &:before {
                                background-color: #f9fb62;
                              }
                              &:after {
                                color: $black;
                              }
                            }
                          }
                        }
                      }
                      .gold {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: none;
                              background: linear-gradient(180deg, #ecc600 0%, #fff6cc 52.08%, #ecc600 100%);
                            }

                            &.selected-box {
                              &:before {
                                background: linear-gradient(180deg, #ecc600 0%, #fff6cc 52.08%, #ecc600 100%);
                              }
                              &:after {
                                color: $black;
                              }
                            }
                          }
                        }
                      }
                      .silver {
                        .value {
                          .selection-box {
                            color: $black;
                            &:before {
                              border: none;
                              background: linear-gradient(180deg, #a8a9ab 0%, #f2f2f2 48.96%, #a8a9ab 100%);
                            }

                            &.selected-box {
                              &:before {
                                background: linear-gradient(180deg, #a8a9ab 0%, #f2f2f2 48.96%, #a8a9ab 100%);
                              }
                              &:after {
                                color: $black;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .modal-footer {
            position: fixed;
            left: 0;
            bottom: 0;
            padding: 16px;
            width: 100%;
            background: $white;
            .buttons {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 16px;
              .footer-button {
                width: 100%;
                height: 48px;
                border-radius: 24px;
                font-family: $futura-book;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0.56px;
                &.reset-filters-button {
                  border: solid 1px #e5e5e5;
                  background: transparent;
                }
                &.apply-filters-button {
                  position: relative;
                  background: $black;
                  color: $white;

                  &:hover {
                    background: #3d3d3d;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #filters-order-modal.active-filters {
    .modal-overlay .modal-container {
      .modal-footer {
        bottom: 0;
      }
    }
  }

  .modal-content-filters.js-mobile-filters {
    margin-top: 36px;
  }

  .horizontal-row {
    border-top: 1px solid $light-grey;
    &.filters-title-bottom {
      margin-bottom: 0;
    }
    &.order-by-radios-bottom {
      margin-left: -14px;
      width: calc(100% + 28px);
    }
    &.single-filter-bottom {
      margin-bottom: 0;
      margin-top: 0;
      &:last-child {
        display: none;
      }
    }
    &.single-filter-title-bottom {
      display: none;
    }
  }

  .button--loading .button__text {
    visibility: hidden;
    opacity: 0;
  }

  .button--loading::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid #d9d9d980;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }

  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
}
.desktop-filters {
  display: none;
}

.filters-open {
  .inside_chatTabImage {
    display: none !important;
  }  
}
//refactor and fix mobile filter
//
.mobile-filters {
  #filters-order-modal {
    .modal-overlay {
      .modal-content-filters {
        .filters-order-accordion {
          &-content {
            .single-filter-section {
              overflow: hidden;
              .filter-list-container {
                ul.values {
                  list-style: none;
                  padding: 0 1px 0;
                  display: flex;
                  flex-wrap: wrap;
                  gap: 8px;
                  margin-bottom: 32px;
                  width: 100%;

                  .regular-attribute,
                  .special-attribute {
                    width: calc(50% - 4px);
                  }

                  .value {
                    display: block;

                    .selection-box {
                      padding: 8px 24px 8px 8px;
                      border: 1px solid #e5e5e5;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;

                      &:hover {
                        border: 1px solid #cccccc;
                      }
                    }

                    .selection-box.selected-box {
                      border: 1px solid $black;
                      .icon--close {
                        display: block !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .accordion.is-enabled .accordion-content {
          display: flex;
          transition: max-height 0.4s ease-in;
          opacity: 1;
          max-height: 0;
        }

        .accordion.is-enabled .is-active.no-animation .accordion-content {
          display: flex;
          max-height: var(--filters-maxHeight);
          animation: none !important;
        }

        .accordion.is-enabled .is-active .accordion-content {
          display: flex;
          transition: max-height 0.5s ease-in;
          max-height: var(--filters-maxHeight);
        }

        .accordion.is-enabled .is-inactive .accordion-content {
          display: flex;
          transition: max-height 0.5s ease-out;
          max-height: 0;
        }

        @keyframes acc-hide {
          0% {
            max-height: var(--filters-maxHeight);
          }
          100% {
            max-height: 0;
          }
        }

        @keyframes acc-show {
          0% {
            max-height: 0;
          }
          50% {
            max-height: calc(var(--filters-maxHeight / 2));
          }
          100% {
            max-height: var(--filters-maxHeight);
          }
        }
      }
    }
  }
}

.page-search-result-container.new-page-search-result {
  .desktop-filters.js-desktop-filters {
    display: none;
  }
  .mobile-filters {
    display: block;
    .button-icons {
      button {
        .mobile-filters {
          &-icon {
            top: 3px;
          }
        }
      }
    }
  }
}
