.account-top-section {
  .wishlist-title {
    margin-top: 0;
  }

  .wishlist-text {
    line-height: 1.6;
    letter-spacing: 0.3px;
    margin-top: 1.6rem;
  }

  .wishlist-continue-shopping {
    font-size: 12px;
    line-height: 1.8;
    letter-spacing: 0.3px;
  }

  .section-actions {
    margin-top: 2.48rem;
    padding-bottom: 0;
    border-bottom: 0;

    .add-all-to-cart {
      padding: 16px 20px;
      width: auto;
    }
  }
}

.wishlistItemCards {
  .product-grid-container {
    margin-top: 4.93rem;

    .product {
      .wishlist-container_select {
        margin-top: 2.42rem;
        .variation-attribute {
          select {
            display: none !important;
          }
          .fsb-select {
            display: block !important;
            border: none !important;
            border-bottom: 1px solid $black !important;
          }
          .select-icon {
            display: none;
          }
          &.attribute-color {
            .fsb-select {
              width: 160px !important;
            }
          }
          &.attribute-size {
            .fsb-select {
              width: 98px !important;
            }
            &.unic-element {
              .fsb-select {
                border: none !important;
                z-index: -1;
                color: transparent;
              }
            }
          }
          &.invalid {
            .fsb-select {
              border-bottom: 1px solid $status_error !important;
              .fsb-button {
                color: $status_error !important;
              }
            }
          }
        }
      }

      .add-to-cart--wishlist {
        padding: 16px 10px;
      }
    }
  }
}

.my-account--is-loggedin {
  .account-top-section {
    .wishlist-text {
      margin-top: 0.8rem;
    }
  }
}
