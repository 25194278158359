.return-content {
  .order-step {
    margin: 0;
    padding-bottom: 24px;

    .default-steps-container {
      display: none;
    }

    .desktop-steps-container {
      display: flex;
      height: 48px;
      .first-element {
        padding-left: 0;
      }

      .last-element {
        > .row {
          justify-content: flex-end;
          > .col-9 {
            max-width: 100%;
          }
        }
      }

      .middle-element {
        > .row {
          > .col-9 {
            max-width: max-content;
            padding-right: 0;
          }
        }
      }
      .status-description {
        height: 100%;
      }
    }

    .horizontal-row {
      width: calc(100% + 42px);
      border-top: 1px solid $light-grey;
      margin: 0;
      margin-left: -42px;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .return-quantity {
      &.show {
        display: block;
        position: absolute;
        left: 207px;
        top: 186px;
      }
    }

    .keep-article-question {
      &.show {
        display: block;
      }
    }

    .question-and-options {
      width: 205px;
      padding: 0 12px;
    }

    .return-quantity-desktop {
      &.show {
        display: block;
      }
    }

    .select-return-change-type {
      .radio {
        padding: 0;
        margin-bottom: 16px;
      }
    }

    .return-products-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      position: relative;

      .return-quantity-select {
        border: 1px solid $black;
        border-radius: 0;
        bottom: unset;
        left: 208px;
        top: 168px;
        width: 308px;
        + .fsb-select {
          + .select-icon {
            bottom: unset;
            left: 491px;
            top: 185px;
          }
        }
      }
      .return-option-quantity {
        position: unset;
      }
    }

    .product-line-item-details {
      .item-attributes {
        width: calc(100% - 208px);
        min-width: 205px;

        .line-item-attributes {
          display: flex;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          justify-content: unset;
          line-height: 1.8;
          letter-spacing: 0.3px;
          margin: 16px 0;
          width: 100%;
          &-label {
            text-transform: uppercase;
            min-width: 130px;
          }
          .item-quantity-value {
            display: flex;
          }
        }
      }
      .item-image {
        height: 256px;
        margin-right: 16px;
        width: 192px;
      }
    }

    .select-where-to-return {
      > .form-group {
        display: flex;
        .radio {
          margin-right: 40px;
        }
      }
    }

    .step-actions {
      display: flex;
      justify-content: flex-end;
      button {
        width: 133px;
        padding: 16px 8px;
      }
    }

    &.js_order-step3 {
      .product-line-item-details {
        .item-attributes {
          width: calc(100% - 288px);
        }
        .price {
          display: none;
        }
      }
      .price-desktop {
        min-width: 80px;
        display: flex;
        justify-content: flex-end;
      }
    }

    .variation-attributes {
      &.return-flow {
        ul {
          li {
            select,
            .select-icon {
              display: none !important;
            }

            .fsb-select {
              display: block !important;
              border: none !important;
              border-bottom: 1px solid $black !important;
              margin-top: 0;
              text-transform: uppercase;
              .fsb-button {
                height: unset !important;
                padding-bottom: 0 !important;
                &:after {
                  top: 19px;
                }
              }
            }

            &.error {
              .fsb-select {
                top: 0 !important;
              }
            }

            &.color {
              .single-val {
                display: block;
              }
              .fsb-select {
                width: 160px !important;
              }
            }
            &.size {
              // position: relative;
              display: block;
              .single-val {
                display: block;
              }
              .size-change-option {
                // margin-top: 20px;
                .size-label-section {
                  flex-direction: row-reverse;
                }
              }
              .fsb-select {
                width: 98px !important;
              }
            }
          }
        }
      }
    }
  }
  .status-circle {
    border: 1px solid $black;
    &.active {
      background-color: $black;
      color: $white;
    }
  }
}

.return-confirmation-content {
  .content-text {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .return-confirmation-img {
    img {
      height: 200px;
    }
  }

  .return-confirmation-title {
    font-size: 24px;
    width: 888px;
  }

  .return-confirmation-steps {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.3px;
    line-height: 1.8;
    width: 888px;
    padding: 0 16px;
    h2 {
      margin-bottom: 8px;
    }
    .step {
      margin: 16px 0;
    }
  }
}

.return-confirmation-button {
  width: 888px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-right: 0;

  .btn--primary {
    padding: 16px 22px;
    width: 332px;
  }
}

.account-content-col {
  &.not-logged {
    .return-content {
      .order-step {
        .horizontal-row {
          margin-left: -116px;
          width: calc(100% + 144px);
          &.upper-return-produtc-row {
            width: 100%;
            margin-left: 0;
          }
          &.return-confirmation-product-bottom {
            width: 100%;
            margin-left: 0;
          }
        }
        .desktop-steps-container {
          padding-right: 104px;
          .first-element {
            padding-left: 12px;
          }
        }
      }
    }
  }
  .select-return-change-type {
    .return-option-value {
      .return-error {
        margin-top: 16px;
      }
      select {
        &.select-color,
        &.size.product-variation-attribute {
          border: none;
          // margin-left: -3px;
          padding-left: 0;
        }
      }
    }
  }
}
