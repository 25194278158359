.editorial-swiper {
  .pd-swiper-slide {
    width: 100%;
    .pd-wrapper-image-layer {
      height: auto;
      max-height: 875px;
      overflow: hidden;
    }
  }

  .carousel-control {
    display: flex;
    justify-content: center;
    padding: 32px 0;
    align-items: center;
  }
}

//
