.pd-whiteSpace {
  width: 100%;
}
.pd-whiteSpace-0-5x {
  height: $base_margin_mobile * 0.5;
}
.pd-whiteSpace-1x {
  height: $base_margin_mobile;
}
.pd-whiteSpace-1-5x {
  height: $base_margin_mobile * 1.5;
}
.pd-whiteSpace-2x {
  height: $base_margin_mobile * 2;
}
.pd-whiteSpace-2-5x {
  height: $base_margin_mobile * 2.5;
}
.pd-whiteSpace-3x {
  height: $base_margin_mobile * 3;
}
.pd-whiteSpace-3-5x {
  height: $base_margin_mobile * 3.5;
}
.pd-whiteSpace-4x {
  height: $base_margin_mobile * 4;
}
.pd-whiteSpace-4-5x {
  height: $base_margin_mobile * 4.5;
}
.pd-whiteSpace-5x {
  height: $base_margin_mobile * 5;
}
