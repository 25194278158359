// show carousel when initialized
.swiper-container {
  display: none;
  &.swiper-initialized,
  &.swiper-container-initialized {
    display: block;
  }
}

main {
  &.main--layout-page {
    border-top: 1px solid $light-grey;
    margin-top: 0;
  }
  &.main--checkout {
    margin-top: 0;
    position: relative;
  }
}

.page-template-homepage {
  main {
    &.main--layout-page {
      padding-top: 0;
    }
  }
}

.prior-best-price-component {
  .prior-best-price-tooltip {
    .modal-header {
      display: none;
    }

    position: absolute;
    bottom: -13.6rem;
    right: -5rem;
    width: 32rem;
    text-align: center;
    font-size: 1.4rem;
    padding: 1.6rem;
    background-color: #fff;
    z-index: 10;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    border-radius: 2px;

    .price {
      display: inline;
      font-size: 14px;
    }
  }
}

.wrapper-pdp {
  .prior-best-price-component {
    .prior-best-price-tooltip {
      bottom: -13.5rem;
      right: -15.5rem;
    }
  }
}
