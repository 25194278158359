.mobile-order {
  display: flex;
}
.desktop-order {
  display: none;
  .js-dropdown-trigger {
    > .name {
      padding-right: 21px;
    }
  }
}

.filter-clearall-container {
  visibility: hidden;
  height: 0;
}
