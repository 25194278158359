//DESKTOP
%display-heading,
.display-heading {
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.4rem;
  letter-spacing: 2px;
}

%heading-large,
.heading-large {
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 4rem;
  letter-spacing: 2px;
}
%heading-medium,
.heading-medium {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.8rem;
  letter-spacing: 1.5px;
}
%heading-small,
.heading-small {
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.2rem;
  letter-spacing: 1px;
}

%title--regular,
.title--regular {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 1px;
}

%label,
.label {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: 1px;
}

%label--small,
.label--small {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.2rem;
  letter-spacing: 0.5px;
}

%body--small--medium,
.body--small--medium {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.5px;
}
