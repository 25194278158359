.help-dashboard-container {
  // .page-help-search {
  //   margin-right: -15px;
  //   margin-left: -15px;
  //   padding-right: 15px;
  //   padding-left: 15px;
  // }
  .dashboard-help-items {
    padding-bottom: 24px;
    margin-right: 0;
    margin-left: 0;
  }

  &.contacts {
    padding: 0;
    .dashboard-help-contacts {
      margin-left: 0;
      margin-right: 0;

      .horizontal-row {
        &.contact-separator {
          margin-left: -15px;
          width: calc(100% + 30px);
        }
      }
    }
  }
}
.help-contacts {
  // height: 256px;
  margin-left: -22px;
}
