.navigation {
  a {
    text-decoration: none;
  }
  .menu-item {
    list-style: none;
  }

  // .first-level {
  //   &.is-selected {
  //     // .primary-link {
  //     //   > span {
  //     //     @extend %nav__title--hover;
  //     //   }
  //     // }
  //   }
  // }

  .wrapper-nav {
    padding: 24px 16px 24px 24px;
    justify-content: space-between;
  }

  .primary-menu {
    border: none;
  }

  .primary-link {
    padding: 12px 0;
    font-family: $futura-book;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.01em;
    text-align: center;
    text-transform: none;
    position: relative;
  }

  .mobile-actions {
    //
    .country-selector {
      @extend %label--small;
      border: none;
      // padding: 1.6rem 1.5rem 1.6rem;
      padding: 1.5rem 1.5rem 8rem 1.5rem;
      justify-content: space-between;
      .country-detail {
        @extend %label--small;
      }
    }
  }
  .mobile-action {
    @extend %label--small;
  }

  .column-title {
    @extend %nav__title;
  }

  .mobile-back {
    font-family: $futura-book;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.015em;
    text-align: center;
    margin-left: 15px;

    text-transform: none;
  }

  .image--tv {
    margin: 0;
  }

  .menu-banner {
    @extend %label--small;
    text-transform: uppercase;
  }

  .sub-menu-title-column {
    @extend %nav__title--hover;
    text-transform: uppercase;
  }

  .sub-menu-item {
    .primary-link {
      font-family: $futura-book;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.01em;
      text-align: left;
    }
  }
}

.menu-pd-content-container.menu-pd-content-desktop {
  display: none;
}

.menu-pd-content-container.menu-pd-content-mobile {
  padding-bottom: 8px;
  border-bottom: 1px solid #e0e0e0;

  .text-layer-link {
    padding: 8px 0 16px;
    text-align: center;
    font-family: $futura-book;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.1px;
    letter-spacing: 0.02em;
  }

  .twoColumnContent {
    flex-direction: row;
    aspect-ratio: unset;
    gap: 0;

    a {
      padding: 0;
    }

    .text-layer-link {
      padding: 8px 0 16px;
      text-align: center;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.1px;
      letter-spacing: 0.02em;
      align-self: center;
    }
  }
}
