.personal-details-container {
  .page-title {
    margin-top: 0;
  }
  .edit-profile-form {
    .form-input {
      #gender {
        + .fsb-select {
          + .select-icon {
            bottom: auto;
            top: 36px;
          }
        }
      }
      .fsb-select {
        + .select-icon {
          right: 25px;
        }
      }
    }
    .submit-button {
      display: flex;
      justify-content: flex-end;
      .button {
        // width: 113px;
        min-width: 113px;
        padding: 16px;
        width: auto;
      }
    }
  }
}
