.maintenance-container {
  .maintenance-header {
    display: flex;
    justify-content: center;
    // img {
    //   height: 100px;
    // }
  }
  .maintenance-title {
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.6;
    margin-bottom: 16px;
    margin-top: 16px;
    text-transform: uppercase;
  }
  .maintenance-description {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.3px;
    margin-bottom: 32px;
    text-transform: uppercase;
  }

  .maintenance-social-links {
    &.social-icons {
      margin-bottom: 16px;
    }
    .container-social {
      text-align: left;
      .section-icon-social {
        a {
          padding-right: 0;
          margin-right: 40px;
        }
      }
    }
    .follow-us {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      margin-bottom: 24px;
      text-transform: uppercase;
    }
  }
}
