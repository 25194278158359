.checkout-login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.checkout-login-box {
  padding: 16px 0;
  & + .checkout-login-box {
    border-top: 1px solid $light-grey;
  }

  .login-account {
    width: 100%;
  }

  .title {
    @extend %heading-medium;
    text-transform: uppercase;
    & + p {
      margin-top: 8px;
    }
  }

  p {
    @extend %body--medium;
  }

  .box-body {
    padding-top: 16px;
  }

  .form-input {
    padding-bottom: 0;
    & + .form-input {
      padding-top: 2.5rem;
    }
  }
  .checkbox {
    margin-bottom: 0;
    input[type='checkbox'] {
      margin: 12px;
    }
  }

  .container-input-password {
    padding-bottom: 8px;
  }

  // .btn-login {
  //   margin-top: 24px;
  // }

  .guest-newsletter-wrapper {
    display: inline-flex;
    align-items: center;
  }

  .guest-login-form-disclaimer {
    @extend %body--small;
    padding-left: 48px;
    padding-bottom: 24px;
    color: $dark-grey;
    a {
      display: inline-flex;
    }
    .link-underline {
      &:before {
        border-color: inherit;
        bottom: 4px;
      }
    }
  }

  .forgot-password {
    margin-top: 16px;
  }
}
