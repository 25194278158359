$base_margin_mobile: 4px;

.pd-spacing-01 {
  height: $base_margin_mobile;
}
.pd-spacing-02 {
  height: $base_margin_mobile * 2;
}
.pd-spacing-03 {
  height: $base_margin_mobile * 3;
}
.pd-spacing-04 {
  height: $base_margin_mobile * 4;
}
.pd-spacing-05 {
  height: $base_margin_mobile * 6;
}
.pd-spacing-06 {
  height: $base_margin_mobile * 8;
}
.pd-spacing-07 {
  height: $base_margin_mobile * 10;
}
.pd-spacing-08 {
  height: $base_margin_mobile * 12;
}
.pd-spacing-09 {
  height: $base_margin_mobile * 16;
}
.pd-spacing-10 {
  height: $base_margin_mobile * 20;
}
.pd-spacing-11 {
  height: $base_margin_mobile * 24;
}
.pd-spacing-12 {
  height: $base_margin_mobile * 40;
}