input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.header-search {
  opacity: 1;
  position: fixed;
  background: white;
  z-index: 50;
  width: 100%;
  top: 0;
  transition: height 0.4s ease-in-out, top 0.3s ease-in-out;
  height: 100svh;
  overflow: hidden;
  transform: translateX(100%);

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  } 
  
  

  .icon--recent,
  .icon--suggestion {
    align-self: center;
  }
}

.page-template-homepage {
  .header-search {
    z-index: 35;
    top: var(--header-height);
    height: 74px;
    transform: none;
  }
}

.error-404,
.header-search {
  .icon--search-red {
    display: none;
  }
  .search-input {
    position: relative;
    .search-field {
      margin-top: 16px;
      padding: 10px 40px;
      background-color: #f4f4f4;
      font-family: $futura-book;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.24px;
      text-align: left;
      border-radius: 48px;
      background: var(--Surface-Input, #f4f4f4);
      border: none;
      width: 100%;

      &:focus {
        &::placeholder {
          color: var(--Text-Secondary, #B4B4B4);
        }
        
        &::-ms-input-placeholder { /* Edge 12 -18 */
          color: var(--Text-Secondary, #B4B4B4);
        }

        color: #0D0D0D;
      }

      &:active {
        color: #0D0D0D;
      }
    }

    .icon--close-mini-icon {
      height: 22px;
      cursor: pointer;
      position: absolute;
      right: 23px;
      top: 16px;
      width: 22px;
      background: none;
    }

    .icon--search {
      position: absolute;
      left: 0;
      top: 16px;
      width: 1.6rem;
    }
  }
  .suggestions-wrapper {
    .suggestions {
      background: $white;
      position: relative;
      width: 100vw;
      left: 50%;
      transform: translate(-50%);
      z-index: 20;
      padding-top: 40px;
      border-top: none;

      .main-container {
        overflow: hidden;
      }

      // .title-section-search-suggestions {
      //   display: none;
      // }
    }
    .items {
      gap: 24px;
      .item {
        width: 100%;
        padding: 0 8px;    
        transition: margin-top 0.5s linear, opacity 0.5s linear;
        margin-top: -37px;
        opacity: 0;
        &.selected {
          text-decoration: underline;
        }
      }
    }

    .zero-images {
      display: none !important;
    }
  }

  .product-recomendation-tile {
    width: 100%;
    display: flex;
    flex-flow: wrap;

    .product-name {
      text-align: left;
      align-self: center;
      width: 50%;
    }
  }

  .recommendations {
    .einstein-carousel {
      padding: 24px 0 100px;
    }

    .swiper-header {
      text-align: left;
      font-family: $futura-book;
      font-size: 16px;
      font-weight: 450;
      line-height: 1.8rem;
    }

    .swiper-heading-wrapper {
      padding-bottom: 24px;
    }

    .product-link {
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.1px;
      letter-spacing: 0.015em;
      text-align: left; 
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .add-to-wish-list {
      display: none;
    }

    .price {
      display: none;
    }

    .experience-assets-product {
      // margin-right: 5px;

      .single-element-content-image {
        margin-bottom: 8px;
      }
    }
  }

  .einstein-carousel .product-carousel-section .swiper-pagination-bullet {
    background-color: #d9d9d9;
    border: none;
    border-radius: 99px;
    width: 8px;
    height: 8px;
    margin: 0 8px 0 2px;
  }
  .einstein-carousel .product-carousel-section .swiper-pagination-bullet-active {
    background-color: #999999;
    border: none;
    border-radius: 99px;
    width: 8px;
    height: 8px;
    margin: 0 8px 0 2px;
  }
}

.no-recommender-available {
  .recommendations {
    border-left: none !important;
  }
}

.header-search.is-closed {
  transition: 0.3s ease-in-out;
  opacity: 1 !important;
  transform: translateX(100%);
  z-index: 50;
  top: 0;
}
.header-search.is-open {
  opacity: 1;
  transition: 0.3s ease-in-out;
  transform: translateX(0%);
  height: 100svh;
  overflow-y: scroll;
}

.page-template-homepage  {
  .header-search.is-closed {
    transition: 0.3s ease-in-out, top 0.3s linear;
    opacity: 1 !important;
    transform: none;
    z-index: 50;
    top: var(--header-height);
    height: 74px;
  }
  .header-search.is-open {
    opacity: 1;
    transition: height 0.3s ease-in-out, top 0.2s linear;
    transform: none;
    top: 0;
    max-height: 100svh;
    z-index: 50;
    height: 100svh;
    overflow: scroll;
  }

  .header {
    .account-header-link {
      display: block;
    }
  }

  .header-search.is-open .input-search-container {
    transition: width cubic-bezier(0.74, 0.01, 0.75, 0.31) .6s;
  }

}

.input-search-container {
  position: relative;
  width: calc(100% - var(--searchClose-width));

  .icon--search-new {
    position: absolute;
    left: 16px;
    top: 28px;
  }

  // .icon--clear {
  //   position: absolute;
  //   right: 16px;
  //   top: 28px;
  //   cursor: pointer;
  //   z-index: 70;
  // }
  .icon-clear-container {
    display: none;
    position: absolute;
    right: 0;
    top: 16px;
    cursor: pointer;
    z-index: 70;
    width: 41px;
    height: 41px;
    text-align: center;

    .icon--clear {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      margin: 12.5px auto;
    }
  }
}

span#search-bar-close {
  width: calc(var(--searchClose-width) - 16px);
  position: absolute;
  right: 0;
  left: unset;
  text-align: right;
  top: 26px;
  cursor: pointer;
}


// -------------------------------- Start: Mobile Opening animation --------------------------------

.header-search {
  .search-input {
    overflow-x: hidden;
  }
}

  .input-search-container {
    width: 100%;
    transition: width 0.4s linear;
  }

  .header-search.is-open .input-search-container {
    width: calc(100% - var(--searchClose-width));
    /* width: 100%; */
    transition: width cubic-bezier(0.56, -0.9, 1, 1) .6s;
  }



  span#search-bar-close {
    width: fit-content;
    position: absolute;
    right: 0;
    left: unset;
    text-align: right;
    transform: translate(100%);
    transition: transform 0.4s linear;
    top: 26px;
  }
  .header-search.is-open span#search-bar-close {
    width: fit-content;
    position: absolute;
    right: 0;
    left: unset;
    text-align: right;
    transform: translate(0, 0%);
    transition: transform .6s cubic-bezier(0.73, -1.01, 0.81, -0.7), -webkit-transform .6s cubic-bezier(0.73, -1.01, 0.76, -0.4);
    top: 26px;
  }


// -----------------------------------------------------------------------------------------------

.suggestions-wrapper-no-results {
  display: block !important;

  .suggestions {
    display: block !important;
  }
}

.search-no-results-message {
  display: none;
}

.no-results-in-search {
  .search-load-more-button {
    display: none;
  }

  .search-results-title {
    display: none;
  }

  .recommendations {
    display: inline !important;
  }

  .suggestions-wrapper {
    display: none;
  }

  .search-no-results-message {
    display: block;
    position: relative;
    padding: 24px 0;
    z-index: 21;
    color: var(--General-UI-Primary, #0d0d0d);
    text-align: center;

    /* Ecom/body-regular */
    font-family: $futura-book;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.21px;

    // .container {
    //   padding: 0;
    // }
  }

  .suggestions-wrapper .suggestions {
    display: none;
  }
}

.search-results-title {
  color: var(--Text-Tertiary, #b4b4b4);
  font-family: $futura-book;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.21px;
  padding: 0 16px 8px;

}


.title-section-search-suggestions {
  opacity: 0;
  transition: opacity 0.4s linear;
}

.container-list-search-suggestions {

  .unstyled-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    transition: gap 0.5s ease-in-out;

    .search-suggestion-list-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      padding: 4px 8px;
      transition: margin-top 0.4s linear, opacity 0.4s linear;
      margin-top: -37px;
      opacity: 0;

      a {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      &:hover {
        background: var(--Grays-Gray-10, #f9f9f9);
      }
    }
  }
}

.header-search.is-open {
  .animation {
    .title-section-search-suggestions {
      opacity: 1;
      transition: opacity .5s cubic-bezier(0.55, -0.34, 0.8, 0.01);
    }
    .container-list-search-suggestions {
      .unstyled-list {
        transition: gap .4s cubic-bezier(0.41, -0.49, 0.81, 0.34);
        gap: 24px;
    
        .search-suggestion-list-item {
          padding: 4px 8px;
          transition: margin-top 0.5s cubic-bezier(0.59, -0.31, 0.76, 0.51), opacity .5s cubic-bezier(0.55, -0.34, 0.8, 0.01);
          margin-top: 0;
          opacity: 1;
        }
      }
    }


    .editorial-search-suggestions {
      opacity: 1;
      transition: opacity 0.5s linear;
    }

    &.suggestions-wrapper {
      .items {
        .item {
          transition: margin-top .3s cubic-bezier(0.58, 0.23, 0.58, 1),opacity .4s cubic-bezier(0.58, 0.23, 0.58, 1);
          margin-top: 0;
          opacity: 1;
        }
      }
    }
  }
}

.editorial-search-suggestions {
  opacity: 0;
  transition: opacity 1s linear;
  .title-section-search-suggestions {
    color: var(--Text-Tertiary, #B4B4B4);
    text-align: left;
  
    /* Ecom/body-regular */
    font-family: $futura-book;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.21px;
    margin-bottom: 16px;
    padding-left: 4px;
    display: block;
  }
  
  .search-suggestion-list-item {
    color: var(--Text-Primary, #0D0D0D);

    /* Ecom/body-large */
    font-family: $futura-book;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.24px;
  }
}

.search-suggestion-list-item {
  color: var(--General-UI-Primary, #7d7d7d);
  display: flex;
  justify-content: space-between;

  /* Ecom/body-large */
  font-family: $futura-book;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.24px;

  .suggestion-label {
    color: var(--Gray-Black, #0D0D0D);
  }

  &:visited {
    color: var(--General-UI-Primary, #7d7d7d) !important;
  }
}

.suggestions-wrapper {
  #search-results {
    width: 100%;
    // padding: 0 16px 0 16px;

    .name {
      width: 100%;
    }
  }

  .suggestions {
    display: flex;
    flex-direction: column;

    .search-images {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 16px 24px 16px;
      gap: 8px;
      flex-flow: wrap;
      justify-content: space-between;



      .product-recomendation-tile {
        width: 100%;
        max-width: calc(50% - 10px);
      }


      .marni-menu-search-result-item__container {
        width: 100%;
        padding: 0;
        height: fit-content;

        img {
          height: fit-content;
          aspect-ratio: 4 / 5;
        }
      }

      .js_add-to-wish-list {
        display: none;
      }
    }

    .search-load-more-button {
      width: calc(100% - 32px);
      margin: 0 16px 100px;
      cursor: pointer;
      color: var(--Gray-900, #262626) !important;
      /* Ecom/button-regular */
      font-family: $futura-book;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      letter-spacing: 0.21px;
      padding: 14px 24px;
      border-radius: 100px;
      border: 1px solid var(--Gray-200, #E0E0E0);
      background: var(--Gray-White, #FFF);
      text-transform: none;

      &:hover {
        background: #F9F9F9;
      }
    }

    .right-container {
      margin-top: 40px;
    }

    .product-recomendation-tile {
      margin-top: 0;
      gap: 8px;
      padding-bottom: 24px;
      
      .product-price,
      .product-name {
        width: 100%;
        text-align: left;
        color: var(--General-UI-Primary, #0D0D0D);
        font-family: $futura-book;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 115%; /* 16.1px */
        letter-spacing: 0.28px;
        padding-left: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.error-404 {
  .recommendations {
    .einstein-carousel {
      padding: 0;
    }
  }
}

.display-results-search {
  #search-results {
    padding: 0 16px 0 16px;

    .row {
      margin: 0 !important;
      width: 100%;
      display: flex;
      flex-flow: column;

      .col-sm-10 {
        padding: 0 !important;
      }
      .col,
      [class^='col-'] {
        padding-left: 0 !important;
      }
    }
  }
}

.background-results {
  display: none;
}

.hide-recommendations {
  .recommendations {
    display: none;
  }

  .icon-clear-container {
    display: block !important;
  }
}
