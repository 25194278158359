.return-content {
  .order-step {
    margin: 8px 0;
    padding-bottom: 24px;

    .return-products {
      > div {
        &:first-child {
          &.upper-return-produtc-row {
            display: none;
          }
        }
      }
    }

    .confirm-return-note {
      margin: 24px 0;
    }

    .desktop-steps-container {
      display: none;
    }

    .horizontal-row {
      width: calc(100% + 37px);
      border-top: 1px solid $light-grey;
      margin: 16px 0;
      margin-left: -18px;

      &.product-separator {
        margin: 16px 0;
        width: 100%;
      }

      &.upper-return-produtc-row {
        width: 100%;
        margin-left: 0;
      }

      &.product-inner-separator {
        display: none;
      }
    }

    .item-to-change,
    .item-to-return {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
    }

    .question-note {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.3px;
      line-height: 1.8;
      margin-bottom: 28px;
      margin-top: 16px;
    }

    .return-type-label {
      flex-direction: column;
      .option-title {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.3px;
        line-height: 1.8;
        text-transform: uppercase;
      }
      .option-desc {
        color: $dark-grey;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.3px;
        margin-top: 13px;
      }
    }

    .product-line-item-details {
      margin-top: 16px;
      .item-image {
        width: 120px;
        height: 160px;
        margin-right: 8px;
      }
      .item-attributes {
        width: calc(100% - 120px);
        .line-item-attributes {
          display: flex;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          justify-content: space-between;
          line-height: 1.8;
          letter-spacing: 0.3px;
          margin: 16px 0;
          width: 100%;
          &-label {
            text-transform: uppercase;
          }
        }

        .line-item-name {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 1px;
          line-height: 1.6;
          margin-bottom: 8px;
          text-transform: uppercase;
        }
        .item-quantity-value {
          display: flex;
        }
      }

      .price {
        display: none;
      }
    }

    .keep-article-question {
      display: none;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.5px;
      line-height: 1;
      margin-top: 32px;
      text-decoration: underline;
      cursor: pointer;
      a {
        text-decoration: none;
      }
      &.show {
        display: block;
      }
    }

    .return-quantity {
      display: none;
    }

    .return-products-wrapper {
      position: relative;
      .return-option-quantity {
        position: relative;
      }
      .return-quantity-select {
        border: 1px solid;
        border-radius: 0;
        bottom: 0;
        //position: absolute;
        width: 100%;
        + .fsb-select + .select-icon {
          bottom: unset;
          top: 18px;
        }
      }
    }

    .return-reason-option,
    .return-quantity {
      margin-bottom: 20px;
      position: relative;
      .return-option-select {
        margin-top: -9px;
        width: 100%;
      }
      &.show {
        display: block;
      }
    }

    .select-return-change-type {
      margin-top: 24px;
      padding-bottom: 0;
      .radio {
        padding: 0 12px;
      }

      .return-option-value {
        select {
          width: 100%;
          border: 1px solid $black !important;
          border-radius: 0 !important;
          &.select-color,
          &.size.product-variation-attribute {
            border: none;
            // margin-left: -3px;
            padding-left: 0;
          }
        }
        .select-icon {
          position: absolute;
          bottom: 14px !important;
        }
      }
    }

    .size-change-option,
    .color-change-option {
      margin-top: 20px;
      &.show {
        display: block;
      }
      .size-label-section,
      .color-label-section {
        display: flex;
        justify-content: space-between;
        .size-label {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.3px;
          line-height: 1.8;
          text-transform: uppercase;
        }
        .size-guidelines {
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.5px;
          line-height: 1;
        }
      }
      .select-size,
      .select-color {
        border: 1px solid $black !important;
        border-radius: 0 !important;
        margin-left: 0;
        padding-left: 0;
        width: 100%;
        option {
          text-transform: uppercase;
        }
      }
    }

    .step-subtitle {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 1px;
      line-height: 1.6;
      text-transform: uppercase;
    }

    .return-quantity-desktop {
      display: none;
    }

    &.js_order-step3 {
      .product-line-item-details {
        .price {
          display: flex;
          justify-content: space-between;
        }
      }
      .price-desktop {
        display: none;
      }
    }

    .variation-attributes {
      &.return-flow {
        ul {
          list-style-type: none;
          position: relative;
          li {
            // position: relative;
            font-size: 12px;
            label,
            .attribute-label {
              font-size: 12px;
              text-transform: uppercase;
              display: none;
            }
            select {
              border: none !important;
              border-bottom: 1px solid $black !important;
              padding: 0;
              text-transform: uppercase;
            }
            .unic-element {
              display: none;
            }
            &.color {
              .single-val {
                border-bottom: 1px solid $black;
                display: block;
                width: 160px;
              }
              .select-icon {
                left: 142px;
                right: unset;
                top: 7px;
              }
              select {
                width: 160px;
              }
            }
            &.size {
              // position: relative;
              display: flex;
              flex-direction: row-reverse;
              justify-content: space-between;
              margin-top: 8px;
              .single-val {
                border-bottom: 1px solid $black;
                display: block;
                width: 98px;
              }
              .size-change-option {
                margin-top: 10px;
                // position: absolute;
                // right: 0;
                // bottom: 0;
              }
              .select-icon {
                bottom: 7px !important;
                left: 82px;
                right: unset;
              }
              select {
                width: 98px;
              }
            }
          }
        }
      }
    }
  }
  .card-body {
    display: none;
  }
}

.js_order-step2 {
  display: none;
}
.js_order-step3 {
  display: none;
  .default-steps-container {
    .status-description-title {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.return-confirmation-content {
  .return-confirmation-title {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1px;
    line-height: 1.6;
    margin: 16px 0;
    padding: 0 16px;
    text-transform: uppercase;
  }
  .return-confirmation-img {
    img {
      height: 100px;
    }
  }

  .return-confirmation-steps {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.3px;
    line-height: 1.8;
    padding: 0 16px;
    h2 {
      margin-bottom: 8px;
    }
    .step {
      margin: 16px 0;
    }
  }
}

.return-confirmation-button {
  padding: 10px;
}

.page-account-content {
  &.not-logged {
    .return-content {
      .order-step {
        .desktop-steps-container {
          .col-4 {
            .col-3 {
              margin-right: 8px;
              max-width: 48px;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
