.link-underline {
  text-decoration: none;
  position: relative;
  overflow: hidden;
  display: inline-flex;

  &:before {
    content: '';
    display: block;
    border-top: 1px solid $black;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &:hover,
  &:focus {
    &:before {
      animation: link-animation-underline 0.8s ease-in-out;
    }
  }
}

.link-item {
  text-decoration: none;
  position: relative;
  overflow: hidden;
  display: inline-block;

  &:before {
    content: '';
    display: block;
    border-top: 1px solid $black;
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    // animation: link-animation-underline 0.8s ease-in-out;
  }

  &:hover,
  &:focus {
    &:before {
      animation: link-animation-underline 0.8s ease-in-out;
    }
  }
}

@keyframes link-animation-underline {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
  51% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
