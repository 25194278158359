@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateX(50%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-15%);
  }
}

.modal {
  display: none;
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: row;
    will-change: transform;
    z-index: 999;
    max-width: 100vw;
  }

  .modal-container {
    position: relative;
    background-color: $white;
    padding: 0;
    max-width: 500px;
    max-height: 80vh;
    overflow-y: hidden;
    box-sizing: border-box;
    will-change: transform;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    overflow: hidden;
    height: 100%;

    &.notification {
      height: auto;
    }
  }

  .modal-close {
    position: absolute;
    top: 4rem;
    right: 3rem;
    width: auto;
    height: auto;
    line-height: 1;
    &::after {
      content: '';
      @include fixedSprite(close);
    }
  }

  .modal-header {
    padding: 3.2rem 2.4rem;
  }

  .modal-content {
    padding: 0 2.4rem;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    flex-grow: 1;
  }

  .modal-footer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 1.6rem 2.4rem;
    padding-bottom: 0;
    border-top: 1px solid $light-grey;
  }

  &.notification {
  }

  &.is-open {
    display: block;
  }

  &[aria-hidden='false'] {
    .modal-overlay {
      animation: mmfadeIn 0.4s cubic-bezier(0, 0, 0.2, 1);
    }
    .modal-container {
      animation: mmfadeIn 0.4s cubic-bezier(0, 0, 0.2, 1);
    }
  }

  &[aria-hidden='true'] {
    .modal-overlay {
      animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
    .modal-container {
      animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }
}
