.experience-assets-product {
  .image-container {
    &:hover {
      .second-image {
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
      }
    }
  }

  .single-element {
    margin-bottom: 32px;

    &-content {
      &-detail {
        text-align: center;
        &-price {
          .previous-price {
            color: $dark-grey;
            text-decoration: line-through;
            margin-right: 16px;
          }
        }
      }
      &-image {
        &-tag {
          background-color: red;
          height: 20px;
          padding: 4px 8px;
          border-radius: 10px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          text-align: center;
          position: absolute;
          bottom: 8px;
          left: 8px;
          color: $white;
        }
        &-img {
          max-width: 100%;
          width: 100%;
          margin: 0;
          padding: 0;
          display: block;
          height: 100%;
        }
      }
    }
  }
}

.pd-carousel__title-region {
  padding-top: 0;
}

.product-carousel-section {
  padding: 60px 0;

  .product-carousel {
    max-width: 1352px;
    margin: 0 auto;
  }
}

.product-carousel-container {
  .product-carousel-title {
    padding: 40px 0;
  }
  .swiper-pagination {
    display: flex;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: flex;
  }
}

.product-carousel {
  .swiper-slide {
    width: auto;
  }
}

.einstein-carousel {
  .product-carousel-section {
    .swiper-button-prev,
    .swiper-button-next {
      display: flex;
    }
  }
}
.experience-layouts-cosmosCarousel,
.experience-layouts-productCarousel,
.experience-layouts-sliderGallery {
  .product-carousel-section {
    padding: 0;
  }

  .product-carousel-container {
    &.product-carousel {
      max-width: unset;
    }

    .single-element {
      &-content {
        &-detail {
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          padding: 12px;
        }

        &-image {
          min-width: 25%;

          .image-container {
            .mm-product-card__hero__hover.second-image {
              display: block;
            }
          }
        }
      }
    }

    .swiper-heading-wrapper {
      padding-top: 24px;
      padding-bottom: 24px;
      .swiper-header {
        font-size: 32px;
        font-weight: 400;
        line-height: 28px;
        text-transform: none;
        padding-left: 24px;
      }
    }

    .swiper-pagination {
      display: none;
    }
  }

  .badge-wrapper-carousel-details {
    .badge-container {
      padding-left: 12px;

      .product-badge {
        padding-top: 0;
      }
    }
  }

  .pd-carousel__products-region {
    .experience-component.experience-assets-product {
      max-width: unset;
    }
  }

  .pd-carousel__products-region.not-two-view-mobile {
    .experience-component.experience-assets-product {
      max-width: unset;
    }
  }

  .pd-carousel__products-region.not-two-view-mobile {
    .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
      display: none;
    }
  }
}
.experience-layouts-cosmosCarousel,
.experience-layouts-productCarousel,
.experience-layouts-sliderGallery {
  .is-new-carousel-true {
    .product-carousel-container {
      .swiper-heading-wrapper {
        padding: 24px;
        display: flex;
        justify-content: space-between;
        .swiper-header {
          align-self: center;
          font-family: $futura-book;
          font-size: 24px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0.015em;
          text-align: left;
          padding: 0;
        }
      }

      img {
        min-width: unset;
      }

      .new-carousel-arrows {
        padding: unset;
        justify-content: unset;
        .swiper-button-next {
          margin-right: 40px;
        }
      }
      .pd-carousel__products-region {
        padding: 0 24px;
      }

      .new-carousel-arrows-desktop {
        display: flex;
      }
      .new-carousel-arrows-mobile {
        display: none;
      }
    }
  }
}

.experience-layouts-cosmosCarousel,
.experience-layouts-sliderGallery {
  .image-container-selector {
    aspect-ratio: 16 / 9;
  }

  .is-new-carousel-true .product-carousel-container img {
    aspect-ratio: 16 / 9;
  }

  .is-new-carousel-true .product-carousel-container .new-carousel-arrows {
    .swiper-button-prev,
    .swiper-button-next {
      display: block;
    }
  }
  .text-layer {
    .link-container {
    margin-top: 16px;
    }

    .text-layer-link {
      font-family: $futura-book;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.015em;
    }
  }

  .product-carousel-container {
    .swiper-pagination {
      margin: 4rem auto;
      width: 20%;
    }
  }
}

.experience-layouts-sliderGallery {
  .display-heading {
    font-size: 24px;
    line-height: 28px;
  }

  .image-container-selector {
    aspect-ratio: auto;
  }

  .is-new-carousel-true .product-carousel-container img {
    aspect-ratio: auto;
  }
}
