.order-confirmation_container {
  &--guest {
    flex-direction: row-reverse;
    align-items: stretch;
  }

  .col-md-7 {
    border-right: 1px solid $light-grey;
    padding-right: 44px;
    padding-left: 16px;
  }
  .col-md-5 {
    padding-left: 39px;
  }

  .horizontal-row {
    border-top: 1px solid $light-grey;
    margin-left: 0;
    width: calc(100% + 44px);
    &.shipping-type-top {
      display: none;
    }
    &.confirmation-accordion-top {
      display: none;
    }
    &.sth-section-top {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &.wrapper-item-top {
      margin-top: 8px;
      margin-bottom: 24px;
    }
  }
}

.order-confirmation_body {
  &.accordion {
    padding-top: 24px;
  }
  .accordion-trigger {
    display: none;
  }
}

.billing-section {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  .billing-method-information {
    text-align: right;
    border: none;
  }
}

.order-confirmation-total-information {
  background: none;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;

  .grand-total {
    border-top: none;
  }

  .leading-lines {
    margin-top: 0;
    padding-top: 16px;
    & + .leading-lines {
      padding-top: 24px;
    }
    &.taxes {
      margin-bottom: 0;
      font-size: 12px;
    }

    &.subtotal-item {
      padding-top: 8px;
    }
  }
}

.wrapper-item {
  flex-direction: row;
  max-width: unset;

  .product-info-img {
    // width: 192px;
    // height: 256px;
    // margin-right: 40px;
    width: 108px;
    height: 144px;
    margin-right: 16px;
  }

  .product-info-details {
    min-width: unset;

    .name-field {
      min-width: 130px;
    }
    .value-field {
      text-align: left;
    }
  }

  .bag-product__shipping {
    text-align: right;
    margin-top: 24px;
  }

  .item-product-info {
    // min-width: 450px;
    min-width: unset;
  }
  .item-shipping-price {
    flex-direction: column-reverse;
    align-items: flex-end;
    // padding-top: 24px;
    padding-top: 0;
    width: 30%;
  }

  .detail-attribute {
    margin-top: 24px;
    + .detail-attribute {
      margin-top: 16px;
    }
  }
}

.order-confirmation_body {
  .shipping-section {
    flex-direction: row;
    justify-content: space-between;
  }
  .shipping-type {
    max-width: 200px;
    text-align: right;
    border: none;
    .title {
      justify-content: flex-end;
    }
  }
  .shipping-information {
    padding-bottom: 0;
  }
}

.accordion {
  .title-section {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: 1px;
  }
}
