.product-detail.pdp-new {
  #layer-product-coming-soon,
  #layer-product-notify,
  #layer-product-notify-size,
  #layer-variation-size,
  #layer-variation-swatch,
  #layer-product-help,
  #layer-product-services,
  #layer-product-packaging,
  #layer-product-details {
    right: calc(0px - 57px);
  }
}
