.order-detail-data-container {
  .order-confirmation-total-information {
    padding: 1.6rem 0;
  }

  .order-detail-dl {
    display: flex;
    flex-direction: row;
    .order-detail-dl-group {
      display: flex;
      flex-direction: column;
    }
  }

  .order-actions:not(.order-actions-mobile) {
    display: flex;
    padding: 2.4rem 2.4rem 2.4rem 0;
    .create-return-btn {
      width: auto;
    }
  }

  .order-actions-mobile {
    display: none;
  }

  .shipping-section {
    flex-direction: row;
    justify-content: space-between;
  }
  .shipping-type {
    max-width: 200px;
    text-align: right;
    border: none;
    .title {
      justify-content: flex-end;
    }
  }
  .shipping-information {
    padding-bottom: 0;
  }

  .detail-head-actions {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}
