.modal-country-selector {
  padding-bottom: 16px;
  position: unset;
  width: auto;
  height: auto;
  .modal-content {
    .country-selector-continent-container {
      border-right: 1px solid $light-grey;
      border-bottom: 0;
      margin: 0 16px 0 0;
      li {
        margin-bottom: 16px;
        width: 150px;
        display: block;
      }
    }
    :last-child {
      border-right: 0;
      margin-right: 0;
    }
  }
  .modal-header-country-selector {
    margin-bottom: 12px;
  }
  .countries {
    border: 0;
  }
}

//refactor
.countries--europe {
  columns: 3;
}

#country-selector-modal {
  .modal-container {
    max-width: 872px;
    height: unset;
  }
  .modal-close {
    top: 1.7rem;
  }
}
.container-column-list-shop {
  border: 0;
  margin: 0;
}
