.product-display-component {
  .single-element-content-image {
    display: block;
    margin: 0 auto;

    .swiper-slide-overlay {
      display: block;
    }

    .image-container {
      position: relative;
      // .badge-container {
      //   height: 100%;
      //   position: absolute;
      //   width: 100%;

      // }

      .product-badge {
        background-color: transparent;
        border-radius: 18px;
        color: $black;
        height: 24px;
        padding: 0;
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.1px;
        letter-spacing: 0.03em;
        text-align: left;
      }
    }
  }
  .experience-component {
    width: 25%;
  }
  .product-display-title {
    font-family: $futura-book;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.015em;

    text-align: center;
  }

  .product-content {
    .productdisplay-content {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      position: relative;
      gap: 8px;
      justify-content: space-between;

      .experience-component {
        width: calc(25% - 8px);
      }
    }
  }

  .product-display-options {
    justify-content: center;
  }

  .explore-range {
    display: block;
    width: calc(25% - 8px);
    margin: 0;
    background: #eeedeb;
    text-align: center;
    position: relative;

    .explore-range-container {
      display: block;
      width: 100%;
      height: 100%;
      color: #666666;
    }

    .explore-title {
      font-family: $futura-book;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-decoration: underline;
    }
  }

  .swiper-container {
    margin: 0;
  }

  .swiper-container {
    display: block !important;
  }

  .explore-range-container-mobile {
    display: none;
  }
}

.newTile-true.product {
  // margin-bottom: 0;
  // max-width: calc(25% - 8px);
  // flex-basis: calc(25% - 8px);
  // width: calc(25% - 8px);
  // padding: 0;

  .swiper-container {
    z-index: -1;
    .swiper-pagination.swiper-pagination-bullets {
      opacity: 0;
    }
  }

  &:hover {
    .swiper-container {
      z-index: 0;
      .swiper-pagination.swiper-pagination-bullets {
        opacity: 1;
      }
      .swiper-button-next,
      .swiper-button-prev {
        opacity: 1;
      }

      .swiper-button-disabled {
        opacity: 0.7;
      }
    }
  }

  .login-to-buy-image-banner {
    display: none;
  }
  .tile-image {
    display: block;
  }
  &.login-to-buy {
    &:hover {
      .login-to-buy-image-banner {
        display: block;
        &-overlay {
          background: $black;
          height: 100%;
          opacity: 0.5;
          width: 100%;
          position: absolute;
          top: 0;
          z-index: 0;
        }
        &-text {
          background: $white;
          color: $black;
          font-size: 12px;
          font-stretch: normal;
          font-style: normal;
          font-weight: 500;
          height: 60px;
          letter-spacing: 0.5px;
          line-height: 1;
          padding: 24px 32px;
          position: absolute;
          text-align: center;
          text-transform: uppercase;
          top: 0;
          width: 100%;
          z-index: 2;
        }
      }
    }
  }
  &.show-second-image:hover,
  .single-element-content-image:focus {
    .tile-image {
      display: block;
      position: unset;
      height: auto;
      width: 100%;
    }
  }
  &-content {
    .single-element-content-detail {
      padding: 12px 24px 24px 12px;
      .badge-container {
        .product-badge {
          margin-top: 12px;
          text-align: left;
          font-family: $futura-book;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.1px;
          letter-spacing: 0.03em;
        }
      }
      .single-element-content-detail-description {
        .product-link {
          font-size: 14px;
          font-weight: 400;
          line-height: 16.1px;
          letter-spacing: 0.015em;
          text-align: left;       
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
    &-detail {
      text-align: center;
      &-price {
        .previous-price {
          color: $dark-grey;
          text-decoration: line-through;
          margin-right: 16px;
        }
      }
    }
    &-image {
      height: 100%;
      // margin: 0 auto;
      margin-bottom: 0;
      // margin-right: 0;
      position: relative;
      // width: 216px;
      width: 100%;
      &-tag {
        background-color: red;
        height: 20px;
        padding: 4px 8px;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        text-align: center;
        position: absolute;
        bottom: 8px;
        left: 8px;
        color: $white;
      }
      &-img {
        max-width: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        display: block;
        height: 100%;
      }
    }
  }

  .swiper-slide-overlay {
    position: absolute;
    z-index: 0;
  }
  .product-badge.product-badge-top {
    z-index: 1;
    top: 12px;
    left: 12px;
  }

  .swiper-container {
    z-index: -1;
  }
  &:hover {
    .swiper-slide-overlay {
      display: none;
      z-index: -1;
    }

    .swiper-container {
      z-index: 0;
    }
  }
}

.newTile-true {
  .single-element-content {
    .single-element-content-detail {
      padding: 12px 24px 24px 12px;
      .single-element-content-detail-description {
        .product-link {
          -webkit-line-clamp: 1;
        }
      }
    }
  }

  .single-element-content-image .image-container .product-badge.product-badge-top {
    top: 12px;
    left: 12px;
  }
}

.newTile-true.product,
.newTile-false.product {
  .single-element-content {
    .product-image-badge {
      height: 32px;
      top: 16px;
      left: 16px;

      &.top-right {
        left: unset;
        right: 16px;
      }

      &.height-desktop-16 {
        height: 16px;
      }
      &.height-desktop-20 {
        height: 20px;
      }
      &.height-desktop-24 {
        height: 24px;
      }
      &.height-desktop-28 {
        height: 28px;
      }
      &.height-desktop-32 {
        height: 32px;
      }
      &.height-desktop-36 {
        height: 36px;
      }
      &.height-desktop-40 {
        height: 40px;
      }
    }
  }
}