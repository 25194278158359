.section-geoip {
  padding: 0;
  
  .section-description-geoip {
    margin: 0 0 24px;
  }

  p {
    @extend %body--small;
  }
}

#geoip-modal {
  border: 1px solid $black;
 
  .geoip-title {
    text-transform: uppercase;
    @extend %title--regular;
  }

  .modal-container {
    height: auto;
  }

  .modal-header {
    padding: 1.6rem;
  }
  .modal-content {
    padding: 0 1.6rem;
  }

  .modal-close {
    top: 1.6rem;
    right: 1.6rem;
  }

  .actions .btn {
    margin-bottom: 20px;
  }
}
