.navigation-head {
  display: none;
}
.wishlist-icon-selector {
  position: relative;
  &.icon--heart-new-red {
    &::after {
      content: '';
      @include fixedSprite('red-notify');
      position: absolute;
      right: 0;
      bottom: 1px;
    }
  }
}

.page-template-homepage  {
  .header .right-actions {
    .mobile-icon-search-container {
      display: block;
    }
  }
}

.mobile-icon-search-container {
  height: 1.8rem;
}

.main-navigation-link {
  margin: 0;
  cursor: pointer;

  .first-level-link {
    padding: 14px 12px;
  }
}

.primary-link {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
}

.main-nav-link-image {
  display: none;
}

.wrapper-nav.is-active {
  .menu-item.first-level.main-navigation-link {
    .primary-link {
      color: #7d7d7d;
      transition: color linear 0.3s;
    }

    &.is-open {
      .primary-link {
        color: $black;
        transition: color linear 0.2s;
      }

      .sub-menu-title-column {
        color: #7d7d7d;
      }
    }
  }
}

.primary-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
}

.sub-menu-third-level,
.sub-menu {
  .menu-second-level-container,
  .menu-third-level-container {
    width: 100%;
  }
}

.sub-menu {
  // border-top: 1px solid $light-grey;
  // transition: transform ease-in-out 0.1s, opacity ease-in-out 0.1s;
  position: absolute;
  // top: calc(100% - 3px);
  left: 0;
  width: 100vw;
  overflow: hidden;
  padding: 0;
  max-height: 0;
  transition: max-height cubic-bezier(0.4, 0, 0.6, 1) 0.5s;
  background: $white;
  z-index: 1;
  justify-content: center;

  .sub-menu-content {
    opacity: 0.65;
    transition: opacity cubic-bezier(0.94, 0, 0.58, 1) 0.6s;
    margin: 0;
    width: 100%;

    .menu-links-area {
      gap: 24px;
      width: 100%;
      justify-content: center;

      .sub-categories-col {
        width: calc(12.5% - 24px);
      }

      .menu-pd-content-container {
        max-width: calc(12.5% - 24px);
      }
    }
  }
}

.wrapper-nav.is-active {
  .sub-menu {
    &.is-open {
      max-height: 2000px !important;
      transition: none;
    }
    .sub-menu-content {
      opacity: 1;
    }
  }
}

.navigation {
  position: absolute;
  left: 0;
  width: 100vw;

  .wrapper-nav {
    padding: 0;
  }

  .sub-menu-title-column,
  .column-title,
  .primary-link {
    //styleName: Ecom/body-regular;
    font-family: $futura-book;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.015em;
    text-align: left;
    color: $black;
    text-transform: none;
    transition: color linear 0.5s;
  }

  .sub-menu-title-column {
    color: #7d7d7d;
  }

  .sub-menu-item {
    .primary-link {
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.015em;
      text-align: left;
      color: $black;
      padding: 0 0 16px;
      text-transform: none;
    }
  }

  .is-open {
    .sub-menu {
      // opacity: 0;
      // transform: translateY(-20px);
      display: flex;
      z-index: 11;
      overflow: hidden;
      padding: 0;
      // transition: all cubic-bezier(0.61, 0.03, 0.27, 1.55) 1s;

      // .sub-menu-content {
      //   opacity: 0.2;
      //   transition: opacity cubic-bezier(0.61, 0.03, 0.27, 1.55) 1s;
      // }
    }

    .primary-link.first-level-link {
      color: $black;
    }

    &.animate {
      .sub-menu {
        // opacity: 1;
        // transform: translateY(0);
        transition: max-height cubic-bezier(0.4, 0, 0.6, 1) 0.7s;
        z-index: 11;
        max-height: 2000px !important;

        .sub-menu-content {
          opacity: 1;
          transition: opacity cubic-bezier(0.07, 1.04, 0.6, 1) 0.4s;
        }
      }
    }
  }

  // .is-selected {
  //   .primary-link::after {
  //     width: 100%;
  //   }
  // }

  .menu-blocked {
    .sub-menu {
      display: none;
    }
  }
}

.no-transitions-wrapper {
  .sub-menu {
    transition: none;

    .sub-menu-content {
      opacity: 1;
      transition: none;
    }
  }

  .is-open {
    &.animate {
      .sub-menu {
        transition: none;

        .sub-menu-content {
          opacity: 1;
          transition: none;
        }
      }
    }
  }
}

.sub-menu-content {
  display: flex;
  justify-content: flex-start;
  cursor: auto;
}

.container.sub-menu-content {
  padding: 24px;
  width: 100%;
  margin: 0 auto;
}

.editorial-banner-area {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;

  &.offset-md-1 {
    padding-right: 20px;
  }
}

.menu-links-area {
  display: flex;
  flex-direction: row;
  flex: auto;
  width: 100%;
}

.menu-banner-wrapper {
  min-width: 190px;
  width: calc(50% - 20px);
  margin-right: 20px;
}

.menu-banner {
  text-align: center;

  font-family: $futura-medium;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.5px;

  .image--tv {
    max-width: 192px;
    margin-bottom: 1.6rem;
  }
}

.sub-menu-third-level {
  .sub-menu-content {
    justify-content: flex-start;
    padding-top: 16px;

    // li {
    //   + li {
    //     margin-top: 5px;
    //   }
    // }
  }
}

.menu-links-area {
  display: flex;
  // justify-content: flex-start;
  width: 100%;

  &.justify-end {
    + .editorial-banner-area {
      margin-left: 40px;
    }
  }

  .sub-categories-col {
    max-width: 192px;
    width: 100%;
    .sub-menu-item {
      .primary-link {
        color: $black;
      }
    }
  }

  .sub-categories-col {
    > ul {
      .sub-menu-item {
        + .sub-menu-item {
          margin-top: 1.2rem;
        }
      }
    }
  }
}

.menu-pd-content-container.menu-pd-content-desktop {
  display: block;
  .twoColumnContent {
    width: 100%;
    height: auto;
    flex-direction: column;

    .text-layer-link {
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: left;
      padding: 4px 35px 4px 4px;
      position: relative;
    }

    .text-layer-link::after {
      content: '';
      @include fixedSprite('chevron-right-new');
      width: 1rem;
      height: 1rem;
      position: absolute;
      right: 20px;
      top: 8px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.menu-pd-content-container.menu-pd-content-mobile {
  display: none;
}
