.description-box {
  padding-left: 0;
}
.account-content-col {
  &.not-logged {
    .return-content {
      .order-step {
        .order-step-content {
          padding-right: 44px;
        }
        .question-and-options {
          width: 308px;
        }
        .desktop-steps-container {
          .first-element {
            padding-left: 12px;
          }
        }
      }
    }
  }
  .return-quantity-select {
    position: absolute;
  }
}
