.slot2-component {
  padding: 4rem 0;
  margin-bottom: 2rem;
  .swiper-pagination {
    display: flex;
  }
  .swiper-wrapper {
    flex-wrap: nowrap;
    > * {
      padding: 0;
    }
  }

  .swiper-slide {
    max-width: 59.1rem;
  }

  .group-component {
    margin-bottom: 6rem;
  }
}
//

.slot2-component {
  &.type-default {
    max-width: 1352px;
    margin: 0 auto;
    margin-bottom: 2rem;

    .swiper-slide {
      max-width: 50%;
      width: 50%;
    }
  }
}
