.fsb-select {
  border: 1px solid $black !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  display: none !important;
  font-family: $futura-book !important;
  font-size: 12px !important;
  margin-top: 8px;
  width: 100% !important;

  .fsb-button {
    height: 47px !important;
    text-transform: uppercase;
    padding-left: 0 !important;
    padding-bottom: 3px !important;
    &:after {
      right: 16px;
    }
  }
  .fsb-list {
    border: 1px solid $black;
    border-radius: 0;
    height: unset !important;
    max-height: 400px;
    .fsb-option {
      border-bottom: 1px solid $light-grey;
      cursor: default;
      &:last-child {
        border-bottom: none;
      }
      &.disabled-element {
        color: $medium-grey;
        pointer-events: none;
      }
    }
  }
}

.fsb-top .fsb-list {
  top: auto !important;
  bottom: auto !important;
}

.invalid {
  .fsb-select {
    .fsb-button {
      border: 1px solid $status_error;
    }
  }
}

.error {
  .fsb-select {
    border: 1px solid $status_error !important;
    position: relative;
    top: -30px;
  }
  select {
    + .error-message {
      position: relative;
      top: 57px;
    }
  }
}
