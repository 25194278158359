.pd-left-top {
  position: absolute;
  top: 0;
  left: 0;
}
.pd-left-center {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
}
.pd-left-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}

.pd-center-top {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.pd-center-center {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.pd-center-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.pd-right-top {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-50%, 0);
}
.pd-right-center {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
.pd-right-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-50%, 0);
}

.pd-flex-justify-left {
  display: flex;
  justify-content: flex-start;
}
.pd-flex-justify-center {
  display: flex;
  justify-content: center;
}
.pd-flex-justify-right {
  display: flex;
  justify-content: flex-end;
}

.pd-hide-for-small {
  @media screen and (max-width:#{map-get($grid-breakpoints,md)}) {
    display: none;
  }
}
.pd-hide-for-medium {
  @media screen and (max-width:#{map-get($grid-breakpoints,lg)}) {
    display: none;
  }
}
.pd-hide-for-large {
  @media screen and (min-width:#{map-get($grid-breakpoints,lg)}) {
    display: none;
  }
}

.pd-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.pd-flex-center-alignItemNormal {
  display: flex;
  justify-content: center;
  align-items: normal;
  align-content: center;
  flex-wrap: wrap;
}

.pd-wrapper-relative {
  position: relative;
}
