#vertex-modal {
  .modal-container {
    max-width: 825px;
    height: min-content;
    .modal-body-top {
      .modal-header {
        padding-top: 0;
      }
    }
    .validation-result-wrapper {
      margin: 0;
    }
    .original-address,
    .address-error-details {
      padding-right: 10px;
    }
    .suggested-address,
    .address-details {
      &:not(.address-error-details) {
        padding-left: 10px;
      }
    }

    .address-rectified {
      .address-details {
        padding-left: 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 40px;
      }
    }

    .address-error,
    .address-error-rectified {
      padding-bottom: 35px;
      .address-details,
      .address-error-details {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .address-error-rectified {
      .address-details {
        &.suggested-address {
          padding-left: 0;
          padding-right: 10px;
        }
      }
    }
  }
}
