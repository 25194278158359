.breadcrumb-component {
  .breadcrumb {
    justify-content: flex-start;
    &-item {
      &.last-element {
        display: block;
      }
    }
  }
}
.pd-breadcrumbs-hero-internal {
  display: flex;
}
