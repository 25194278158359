.product-highlight {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__image,
  &__product {
    width: 100%;
  }

  &__image {
    overflow: hidden;

    img {
      height: 100%;

      &.fit {
        object-fit: contain;
      }

      &.fill {
        object-fit: fill;
      }

      &.crop {
        object-fit: cover;
      }
    }

    a {
      img { 
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  &__product {
    display: flex;
    padding: 50px 40px;

    &-container {
      width: 100%;
    }

    .experience-assets-product {
      display: flex;

      .product {
        flex: 0 0 100%;

        .single-element-content {
          margin-bottom: 0;
        }
      }
    }
  }
}
