.dw-apple-pay-button {
  &.apple-pay-cart-button,
  &.apple-pay-pdp-button,
  &.apple-pay-pdp-button-fake,
  &.apple-pay-button {
    &,
    &:hover {
      -webkit-appearance: -apple-pay-button;
      cursor: pointer;
      margin: 0;
      height: 50px;
      border-radius: 25px;
    }
  }
  &.apple-pay-button {
    &.apple-pay-cart-button,  &.apple-pay-pdp-button , &.apple-pay-pdp-button-fake {
      /* background-image: -webkit-named-image(apple-pay-logo-black);
        &:hover {
            background-image: -webkit-named-image(apple-pay-logo-white);
        } */
      width: 100%;
      display: block;
      -apple-pay-button-type: plain;
      -apple-pay-button-style: white-outline;
      &:hover,
      &:focus {
        -apple-pay-button-style: black;
      }
    }
    &.apple-pay-only-pay-button {
      /* background-image: -webkit-named-image(apple-pay-logo-white);
        &:hover {
        } */
      -apple-pay-button-type: buy;
      -apple-pay-button-style: white-outline;
      &:hover,
      &:focus {
        -apple-pay-button-style: black;
      }
    }
  }
}

isapplepay {
  display: none;
}

.apple-pay-button:not(.dw-apple-pay-button) {
  display: none;
}

.applepay-tab-wrapper {
  display: none;
}
.payment-option-item[data-method-id='DW_APPLE_PAY'] {
  display: none;
}
.apple-pay-enabled {
  .applepay-tab-wrapper,
  .payment-option-item[data-method-id='DW_APPLE_PAY'] {
    display: block;
  }
}

.no-padding-right {
  padding-right: 0;
  padding-left: 10px;
}

.no-padding {
  padding: 0;
}

.padding-right {
  padding-right: 10px;
}
