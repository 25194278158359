.return-content {
  .order-step {
    .horizontal-row {
      border-top: 1px solid $light-grey;
      margin: 16px 0;
      margin-left: -32px;
      width: calc(100% + 64px);
    }
  }
}
