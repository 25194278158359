.no-scroll {
  overflow: hidden;
}

#survey {
  width: 100%;
  height: 100dvh;
  display: flex;

  &.started {
    align-items: center;
  }

  .header-bar {
    height: 50px;
    width: 100%;
    padding: 16px 24px;
    position: absolute;
    top: 0;
    display: flex;
    transition: all 0.5s;
    transform: translateY(-100%);
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    background-color: #fff;
    z-index: 10;

    #x-button {
      margin-left: auto;
      cursor: pointer;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
    }

    #back-button {
      display: none;
      width: auto;
      font-size: 16px;
      cursor: pointer;
      align-items: center;

      span {
        margin-left: 8px;
      }

      &:disabled {
        background-color: #f0f0f0;
        color: #aaa;
        cursor: not-allowed;
      }
    }
  }

  .surveylanding-container {
    width: 100%;

    .img-container {
      order: 2;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    .actions-container {
      order: 1;
      .actions-container-box {
        width: 100%;
        max-width: 472px;
        margin: 0 auto;
        // max-height: calc(100vh - 85px);
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 8px;
        padding: 40px 16px 24px;

        .actions-text {
          width: 100%;
          font-size: 24px;
          line-height: 28px;
          margin-bottom: 40px;
          text-align: left;

          p {
            width: 100%;
            max-width: 320px;
          }
        }

        .primary-button {
          &.start-button {
            padding: 15px 32px;
            width: 100%;
            background-color: #0d0d0d;
            color: #fff;
            border-radius: 100px;
            transition: 0.5s;

            &:hover {
              background-color: #404040;
            }
          }
        }

        .secondary-button {
          padding: 15px 32px;
          display: flex;
          align-items: center;

          span {
            margin-right: 8px;
          }
        }
      }
    }
  }

  #questions-container {
    width: 100%;
    margin: 0 auto;
    padding: 24px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-position: center;
    background-size: cover;

    .question {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      transform: translateY(100%);
      transition: opacity 0.8s ease-in-out, transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
      width: 100%;
      max-width: calc(100vw - 48px);

      &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        position: relative;
        transition: opacity 0.8s ease-in-out, transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
      }

      .question-number {
        font-size: 12px;
        color: #b4b4b4;
      }

      .question-title {
        font-size: 18px;
        margin-bottom: 40px;
      }
    }

    .answerButton {
      display: flex;
      width: 100%;
      padding: 14px 21px;
      margin-bottom: 1rem;
      border: 1px solid #e0e0e0;
      background-color: #ffffff;
      color: #333;
      border-radius: 50px;
      font-size: 14px;
      text-align: left;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;

      &.animate-border {
        animation: borderFlash 0.4s steps(1) infinite;
        .tick {
          display: flex;
        }

        &:disabled {
          color: #333;
        }
      }

      .tick {
        display: none;
      }

      &.selected,
      &.selected:disabled {
        border-color: #000;
        outline: 1px solid #000;
        color: #333;

        .tick {
          display: flex;
        }
      }

      &:disabled {
        color: #e0e0e0;
      }

      &:hover {
        background-color: #f9f9f9;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      }
    }
  }
  #final-message {
    text-align: center;
    display: none;
    max-width: 504px;
    width: 100%;
    margin: 0 auto;

    &.active {
      display: block;
    }

    .final-copy {
      font-size: 1.2rem;
      margin: 24px 0;
    }

    .marni-logo {
      fill: #e5e5e5;

      &.red {
        fill: #ba0c2f;
      }
    }
  }

  #progress-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #e0e0e0;
  }

  #progress-bar-fill {
    width: 0;
    height: 100%;
    background-color: #0d0d0d;
    transition: width 0.4s ease;
  }
}

// animations

@keyframes borderFlash {
  0%,
  100% {
    outline: 1px solid #000;
    border-color: #000;
  }
  50% {
    outline: 1px solid #e0e0e0;
    border-color: #e0e0e0;
  }
}
