.default-steps-container {
  display: flex;
  align-items: center;
  margin-top: 11px;
  > .col-3 {
    padding-right: 0;
    padding-left: 0;
    max-width: 80px;
  }

  .back-button {
    height: 20px;
    margin-right: 24px;
    max-width: 20px;
    padding: 0;
    width: 20px;

    &-icon {
      width: 20px;
      height: 20px;
    }
    &:before {
      display: none;
      width: 20px;
      height: 20px;
      left: 0;
    }
  }
  .status {
    &-circle {
      color: $dark-grey;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        width: 100%;
        height: 100%;
      }
      .o-progress-circle {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .o-progress-circle__fill {
        circle {
          fill: none;
          stroke-width: 3px;
          stroke: $light-grey;
        }
        circle:nth-child(2) {
          animation: load-circle 1s;
          stroke: green;
          stroke-dasharray: 100;
        }
      }
      .o-progress-circle__number {
        font-size: 16px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        .total-steps {
          color: $dark-grey;
          text-transform: lowercase;
        }
      }
      .o-progress-circle--rounded {
        circle:nth-child(2) {
          stroke-linecap: round;
        }
      }
    }
    &-description {
      padding-left: 0;
      margin-bottom: 0;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left: 5px;
      &-title {
        width: 100%;
        // height: 22.4px;
        margin: 29px 15px 8px 10px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        color: $black;
        text-transform: uppercase;
        &.review-step {
          margin-top: 36px;
        }
      }
      &-next-step {
        width: 100%;
        height: 21.6px;
        margin: 0 15px 30px 10px;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.3px;
        color: $black;
      }
    }
  }

  @-moz-keyframes load-circle {
    0% {
      stroke-dashoffset: 100;
    }
  }
  @-webkit-keyframes load-circle {
    0% {
      stroke-dashoffset: 100;
    }
  }
  @-o-keyframes load-circle {
    0% {
      stroke-dashoffset: 100;
    }
  }
  @keyframes load-circle {
    0% {
      stroke-dashoffset: 100;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .status {
      &-circle {
        .o-progress-circle {
          display: inline-block;
          position: relative;
          width: 90px;
          height: 90px;
        }
      }
      &-description {
        height: 90px;
      }
    }
  }
}
