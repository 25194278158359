.modal-country-selector {
  position: fixed;
  z-index: 99;
  background-color: #fff;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Hide scroll bar in IE and Edge */
  scrollbar-width: none; /* Hide scroll bar in Firefox */

  .country-selector-title {
    @extend %title--large;
    text-transform: uppercase;
  }
  .modal-content {
    .country-selector-continent-title {
      @extend %title--regular;
      margin-bottom: 8px;
      text-transform: uppercase;
    }
    .country-selector-continent-container {
      border-bottom: 1px solid $light-grey;
      margin-bottom: 16px;
      li {
        margin-bottom: 26px;
        width: 48%;
        float: unset;
        display: inline-block;
        .country-item {
          @extend %body--small;
          text-decoration: none;
        }
        .flag-state {
          margin-right: 8px;
        }
      }

      &:last-child {
        border: 0;
        margin: 0;
      }
    }
  }
  .modal-header-country-selector {
    padding: 16px !important;
    margin-bottom: 16px;
    border-bottom: 1px solid $light-grey;
  }
  .modal-content-country-selector {
    padding: 0 16px !important;
  }
  // .countries {
  //   border-bottom: 1px solid $light-grey;
  // }
}
#layer-country {
  padding: 0;
}
#country-selector-modal {
  .modal-close {
    z-index: 9999;
    top: 2rem;
    right: 2rem;
  }
}

.modal-country-selector::-webkit-scrollbar {
  display: none;
}

.container-column-list-shop {
  border-bottom: 1px solid $light-grey;
  margin-bottom: 16px;
  &:last-child {
    border: 0;
    margin: 0;
  }
}
