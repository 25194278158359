#vertexModal {
  .modal-body-top {
    // padding: 40px 55px 35px;
    padding: 40px 0 35px 0;
    .modal-header {
      padding-bottom: 0;
    }
  }
  .vertex-title {
    font-size: 22px;
    letter-spacing: 2px;
    line-height: 1.5;
    margin: 0 0 15px;
    text-transform: uppercase;
  }

  .original-address,
  .address-error-details {
    margin-bottom: 40px;
  }
  .address-title {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 14px;
  }
  .btn--primary {
    padding: 16px 30px;
    margin: 30px 0 0;
    &:hover {
      background: #494949;
    }
  }

  .btn--secondary {
    padding: 16px 30px;
    margin: 30px 0 0;
    &:hover {
      color: $white !important;
      background-color: $black;
    }
  }

  .back-select-original {
    cursor: pointer;
  }

  .first,
  .second {
    margin-bottom: 8px;
  }
}
