.personal-details-container {
  .page-title {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 1px;
    color: $black;
    text-transform: uppercase;
    margin: 16px 0;
    margin-top: 0;
  }
  .edit-profile-form {
    .form-input {
      padding-bottom: 15px;
      position: relative;

      .form-select {
        width: 100%;
        appearance: none;
        line-height: 21.6px;
      }
      .fsb-select {
        + .select-icon {
          bottom: 30px;
        }
      }
    }

    .requirement-container {
      background: #F9F9F9;
      padding: 16px 12px;
      margin-bottom: 0;
      position: relative;
    }

    .privacy-fields {
      .form-group {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.3px;
        margin: 8px 0;
        color: $black;
        margin-bottom: 0;
        padding: 8px 0;
        .checkbox__title {
          margin-bottom: 8px;
        }
        .checkbox__field {
          align-items: center;
          label {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: 0.3px;
            color: $black;
          }
        }
        &.privacy-intro {
          padding-top: 0;
          margin-top: 0;
        }
      }
    }
    .submit-button {
      margin: 8px 0;
    }
    padding-bottom: 16px;
  }
}

.phone-account-1 {
  padding-right: 8px;
}
.phone-account-2 {
  padding-left: 8px;
  padding-right: 8px;
}
.phone-account-3 {
  padding-left: 8px;
}

.phone-margin {
  position: relative;
  padding-top: 12px;
  .phone-dash {
    display: block;
    top: 37px;
    left: -2px;
    position: absolute;
  }
}
