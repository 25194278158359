.edit-add-address-container {
  .edit-add-address {
    .edit-address-form {
      .form-input {
        .form-select {
          + .select-icon {
            right: 48px;
          }
        }
      }
    }
  }
}
