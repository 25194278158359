.personal-details-container {
  .edit-profile-form {
    .form-input {
      .form-select {
        width: 100%;
        appearance: none;
        + .select-icon {
          position: absolute;
          right: 26px;
          transform: translateY(28px);
          pointer-events: none;
        }
      }
    }
  }
}