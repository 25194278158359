.product-detail.pdp-new {
  .layer-variations-size.is-open,
  .layer-variations-swatch.is-open,
  .layer-product-help.is-open,
  .layer-product-coming-soon.is-open,
  .layer-product-notify.is-open,
  .layer-product-notify-size.is-open,
  .layer-product-services.is-open,
  .layer-product-packaging.is-open,
  .layer-product-details.is-open {
    #layer-variation-size,
    #layer-variation-swatch,
    #layer-product-help,
    #layer-product-coming-soon,
    #layer-product-notify,
    #layer-product-notify-size,
    #layer-product-packaging,
    #layer-product-services,
    #layer-product-details {
      display: block;

      .pdp-help-client-pop-up {
        margin-top: 40px;
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400 !important;
        line-height: 21px;
        letter-spacing: 0.04em;
        text-align: left;
        color: #757575;

        a {
          display: none;
        }
      }

      .open-chat-container {
        width: fit-content;
        text-align: left;
      }
    }
  }

  .variation-size-overlay,
  .variation-swatch-overlay,
  .product-coming-soon-overlay,
  .product-notify-overlay,
  .product-notify-size-overlay,
  .product-help-overlay,
  .product-services-overlay,
  .product-packaging-overlay,
  .product-details-overlay {
    position: fixed;
    z-index: 99;
  }

  #layer-variation-size,
  #layer-variation-swatch,
  #layer-product-help,
  #layer-product-services,
  #layer-product-coming-soon,
  #layer-product-notify,
  #layer-product-notify-size,
  #layer-product-packaging,
  #layer-product-details {
    left: unset;
    position: fixed;

    .product-help-content {
      .pdp-contacts-container {
        .pdp-contacts-mobile {
          display: none;
        }
      }

      .pdp-contacts-desktop {
        display: flex;
      }
    }
  }

  .layer-minicart {
    position: unset;
  }

  #layer-variation-size,
  #layer-variation-swatch,
  #layer-product-help,
  #layer-product-coming-soon,
  #layer-product-notify,
  #layer-product-notify-size,
  #layer-product-services,
  #layer-product-packaging,
  #layer-product-details {
    top: 0;
    right: -527px;
    height: 100vh;
    min-width: 527px;
    max-width: 527px;

    .main-overlay {
      z-index: 21;
      top: 0;
    }
  }

  .variation-size-content {
    .attribute-selector {
      margin-bottom: 32px;
    }
  }

  .variation-size-container,
  .variation-swatch-container,
  .product-help-container,
  .product-services-container,
  .product-coming-soon-container,
  .product-notify-container,
  .product-notify-size-container,
  .product-packaging-container,
  .product-details-container {
    .modal-header.variation-size-header,
    .modal-header.variation-swatch-header,
    .modal-header.product-coming-soon-header,
    .modal-header.product-notify-header,
    .modal-header.product-notify-size-header,
    .modal-header.product-help-header,
    .modal-header.product-services-header,
    .modal-header.product-packaging-header,
    .modal-header.product-details-header {
      margin: 4.8rem 7.2rem 2.4rem 7.2rem;
    }
    height: 100vh;

    .modal-content {
      padding: 2.4rem 7.2rem;
    }

    .modal {
      .modal-header.minicart-header {
        margin: 24px;
      }
    }
    .modal-footer {
      position: absolute;
      padding-top: 24px;
      margin-bottom: 24px;
    }
  }

  .pdp-contacts-container {
    .pdp-contacts-desktop {
      display: flex;
      flex-direction: column;

      &-title {
        font-family: $futura-book;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.04em;
        text-align: left;
        color: #757575;
      }

      .phone,
      .email {
        width: fit-content;
        margin-top: 8px;
      }

      .phone {
        &:after {
          @include fixedSprite('phone-pdp');
        }
      }

      .email {
        &:after {
          @include fixedSprite('email-pdp');
        }
      }
    }

    .pdp-contacts-mobile {
      display: none;
    }
  }
}
