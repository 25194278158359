.page-account-container {
  .page-account-content {
    margin: 0;
  }
  .account-menu-col {
    padding-left: 0;
    .page-account-title {
      .main-title {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1.5px;
        margin-bottom: 8px;
        margin-top: 16px;
      }
      .main-subtitle {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.3px;
        text-transform: none;
        &-link {
          cursor: pointer;
          font-weight: 500;
          margin-left: 8px;
          text-decoration: underline;
        }
      }
    }
    .page-account-title {
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1.5px;
      color: black;
      text-transform: uppercase;
      margin-top: 0;
    }

    .page-account-menu {
      margin: 0 -15px;
      margin-top: 28px;
      margin-bottom: 16px;
      overflow-x: hidden;

      > .col {
        padding: 0;
        .menu-list {
          padding: 0 10px;
          width: 100%;
          list-style: none;
          display: flex;
          overflow-x: auto;
          height: 48px;
          background: $light-grey;
          align-items: center;
          cursor: pointer;
          &-item {
            width: auto;
            flex: 1 0 auto;
            margin: 0 12px;
            .icon-link {
              width: 11px;
              height: 12px;
            }
            a {
              text-transform: uppercase;
              height: 14px;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 1px;
            }
            &.active {
              position: relative;
              a {
                position: relative;
                // text-decoration: underline;
                font-weight: 900;
              }
            }
          }
        }
      }
    }
  }

  .help-content-col {
    .help-content-box {
      margin-bottom: 48px;

      &-title {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1.5px;
        color: $black;
        text-transform: uppercase;
        margin-top: 24px;
      }

      &-subtitle {
        margin-top: 24px;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1.3px;
        color: $black;
        text-transform: uppercase;
      }
      &-subtitle-no-bold {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1px;
        color: $black;
        text-transform: uppercase;
        margin-top: 24px;
        margin-bottom: 16px;
      }

      &-section {
        &-paragraph {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 0.3px;
          color: $black;
          margin-top: 16px;
          &.uppercased {
            text-transform: uppercase;
          }
        }
        &-unordered-list {
          margin-top: 24px;
          padding-left: 16px;
        }
        .help-search-order {
          > .row {
            margin-bottom: 16px;
            margin-left: 0;
            margin-right: 0;
            .search-order-label {
              padding-left: 0;
            }
          }
          .user-email {
            height: 48px;
            border-bottom: 1px solid $black;
            display: flex;
            align-items: flex-end;
            width: 100%;
            padding-bottom: 8px;
            justify-content: space-between;
            padding-left: 0;
            &-icon {
              width: 30px;
              height: 18px;
              transform: rotate(180deg);
            }
            &-number-input {
              margin: 0;
              padding: 0;
              padding-left: 4px;
              border: none;
              width: 100%;
            }
            .error-message {
              display: none;
            }
          }
          .search-order {
            height: 48px;
            border-bottom: 1px solid $black;
            display: flex;
            align-items: flex-end;
            width: 100%;
            padding-bottom: 8px;
            justify-content: space-between;
            padding-right: 0;
            padding-left: 0;
            &-icon {
              width: 30px;
              height: 18px;
              transform: rotate(180deg);
            }
            &-number-input {
              margin: 0;
              padding: 0;
              padding-left: 4px;
              border: none;
              width: 100%;
            }
          }
        }
      }

      .help-content-box-section-accordion-group {
        margin-top: 16px;
        .accordion {
          .single-accordion {
            margin: 8px 0;

            button {
              width: 100%;
              display: flex;
              justify-content: space-between;
              height: 14px;
              font-size: 14px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 1.3px;
              color: $black;
              text-transform: uppercase;
            }

            .accordion-content {
              &-title {
                height: 23px;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                letter-spacing: 0.3px;
                color: black;
                margin-top: 8px;
                text-transform: unset;
              }
              &-table {
                overflow-x: auto;
                .table {
                  width: 100%;
                  margin-top: 8px;
                  padding-bottom: 8px;
                  thead {
                    tr {
                      th {
                        text-align: center;
                        height: 26px;
                        font-size: 16px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.6;
                        letter-spacing: 1px;
                        color: $black;
                        text-transform: uppercase;

                        &:not(:first-child) {
                          padding: 0 16px;
                        }
                        &:first-child {
                          padding-right: 16px;
                        }
                      }
                    }
                  }
                  tbody {
                    tr {
                      td {
                        padding: 0 16px;
                        text-align: center;
                        height: 23px;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.6;
                        letter-spacing: 1px;
                        color: $dark-grey;
                        text-transform: uppercase;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .help-shipping-methods {
        .single-method {
          align-items: center;
          display: flex;
          border: solid 1px $light-grey;
          flex-direction: column;
          height: 135px;
          justify-content: center;
          width: 100%;
          margin: 8px 0;
          p {
            margin-top: 0;
          }
        }
      }

      .help-contact-form {
        margin-top: 8px;
        .form-control {
          width: 100%;
        }
        > .form-input {
          margin-top: 16px;
          padding-bottom: 0;
        }
        .form-select {
          select {
            appearance: none;
            height: 48px;
            width: 100%;
            + .select-icon {
              position: absolute;
              right: 16px;
              transform: translateY(28px);
              pointer-events: none;
            }
          }
        }

        .custom-attachments {
          .input-container {
            position: relative;
            display: inline-block;
            width: 100%;
            .help-contact-attachments {
              position: relative;
              z-index: 2;
              opacity: 0;
            }
            .custom-attachments-input {
              z-index: 1;
              height: 48px;
              border: 1px solid $black;
              width: 100%;
              position: absolute;
              top: 8px;
              right: 0;
              bottom: 0;
              left: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 16px 0 10px;
              &-text {
                height: 12px;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: 0.5px;
                color: $dark-grey;
              }
            }
          }
        }

        .contact-us-button {
          margin-top: 16px;
        }
      }

      .underlined-item {
        text-decoration: underline;
      }
    }
  }
}

.page-help-search {
  margin-bottom: 16px;
  margin-top: 24px;
  border-bottom: 1px solid $light-grey;
  padding-bottom: 16px;
  .help-search {
    display: flex;
    align-items: center;

    .error-message {
      display: none;
    }

    &-icon {
      width: 24px;
      height: 24px;
    }
    &-input {
      height: 21.6px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      color: $black;
      margin-top: 0;
      width: 100%;
      border: none;
    }
  }
}

.help-contacts {
  background: $white;
  bottom: 0;
  margin-left: -15px;
  padding: 16px 0;
  padding-right: 15px;
  position: fixed;
  width: 100%;
  z-index: 1;
  .col-6 {
    text-align: center;
    a {
      .help-contacts-text {
        text-decoration: underline;
      }
    }
  }
}

.live-chat-container {
  display: none;
  position: fixed;
  bottom: 67px;
  right: 14px;
  z-index: 1;
  .live-chat-icon {
    height: 72px;
    width: 72px;
  }
}

.account-dashboard {
  .page-account-title {
    .main-title {
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1.5px;
      margin-bottom: 21px;
      margin-top: 16px;
    }
    .main-subtitle {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      margin-bottom: 21px;
      letter-spacing: 0.3px;
      text-transform: unset;
      &-link {
        cursor: pointer;
        font-weight: 500;
        margin-left: 8px;
        text-decoration: underline;
      }
    }
  }
}
