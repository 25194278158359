.product-detail {
  padding: 0 28px;
  // padding-right: 100px;
  .primary-images {
    padding-left: 22px;
    padding-right: 22px;
  }

  .details-column-content {
    width: 100%;
    &.bottom-position {
      bottom: 0;
      position: absolute;
      right: 72px;
      z-index: 2;
      background: $white;
      flex-basis: 33.33333%;
      max-width: 33.33333%;
    }
    &.default-position {
      position: relative;
      z-index: 2;
      background: $white;
    }
    &.top-position {
      top: 0;
      position: fixed;
      right: 72px;
      background: $white;
      z-index: 2;
      flex-basis: 33.33333%;
      max-width: 33.33333%;
    }
  }

  .add-to-cart-add-to-wishlist {
    flex-direction: row-reverse;
    margin-left: 0;
    margin-right: 0;
    // .add-to-cart-actions {
    //   max-width: 360px;
    //   width: 360px;
    // }
  }
  .desktop-images {
    margin-left: 60px;
    .single-image {
      height: 948px;
      margin: 30px 0;

      max-width: 711px;
      padding: 0;
      width: 711px;
      &:first-child {
        margin-top: 60px;
      }
      img {
        width: 711px;
        height: 948px;
      }
    }
  }
}

.notify-me-container {
  .notify-me {
    &-insert-email {
      .add-to-cart-add-to-wishlist {
        .add-to-cart-actions,
        .notfy-me-actions {
          max-width: 304px;
        }
      }
    }
  }
}
