// /* demo style */
.image-carousel-global-container {
  margin-left: 0;

  .swiper-wrapper {
    align-items: unset;
    &.product-slides {
      width: calc(100% - 50px);
      z-index: unset;
    }
  }

  .swiper-container {
    .swiper-wrapper {
      margin-left: 50px;
    }
  }

  .swiper-pagination {
    padding: 0 174px;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    flex-direction: column;
    height: 100%;
    // background: #fff;
    left: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    right: 16px;
    width: 47px;
    justify-content: flex-start;
    padding-top: 106px;
    .swiper-pagination-bullet {
      margin: 8px 0;
      transform: rotate(-360deg);
    }
  }

  .pd-video__container {
    margin: 0 3rem;
  }
}

.details-column {
  margin-top: 54px;
}

.product-fullscreen-images {
  + .product-fullscreen-images {
    margin-top: 8px;
  }
}

.image-carousel-global-container .swiper-slide .image--tv {
  margin-left: 30px;
  margin-right: 30px;

  img {
    width: 100%;
    height: auto;
  }
}
