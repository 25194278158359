.order-confirmation_container {
  .col-md-5 {
    padding-left: 39px;
  }
  .col-md-7 {
    padding-right: 44px;
  }
}

.wrapper-item {
  flex-direction: row;
  max-width: unset;

  .product-info-img {
    width: 192px;
    height: 256px;
    margin-right: 40px;
    // width: 108px;
    // height: 144px;
    // margin-right: 16px;
  }

  .product-info-details {
    min-width: unset;

    .name-field {
      min-width: 130px;
    }
    .value-field {
      text-align: left;
    }
  }

  .bag-product__shipping {
    text-align: right;
  }

  .item-product-info {
    min-width: 450px;
    // min-width: unset;
  }
  .item-shipping-price {
    flex-direction: column-reverse;
    align-items: flex-end;
    padding-top: 24px;
    width: 30%;
  }

  .detail-attribute {
    margin-top: 24px;
    + .detail-attribute {
      margin-top: 16px;
    }
  }
}
