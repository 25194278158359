#layer-customer-care {
  top: 31px;
}
.modal-customer-care {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .modal-title {
    @extend %title--regular;
    text-transform: uppercase;
    padding: 19px 0 17px;
  }

  .modal-content {
  }

  .customer-care-list {
    padding: 8px 0;

    li + li {
      margin-top: 16px;
    }
    a {
      padding: 8px 0;
      @extend %nav__subtitle;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .customer-care-divider {
    border-top: 1px solid $light-grey;
  }

  .hours-section {
    padding: 17px 0 15px;
    text-transform: uppercase;
    @extend %title--regular;
    span {
      text-transform: none;
    }
  }
  .contact-us-section {
    display: flex;
    flex-direction: row;
    flex: 1 0 50%;
    justify-content: center;
    .link-underline {
      margin: 8px 0;
    }
  }

  .contact-us-item {
    padding: 8px 0;
    display: flex;
    justify-content: center;
    width: 50%;
    align-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    & + .contact-us-item {
      &::after {
        content: '';
        width: 1px;
        height: 100%;
        background: $light-grey;
        position: absolute;
        left: 0;
      }
    }

    [class*='icon--'] {
      margin-right: 10px;
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .contact-us-section:not(.live-chat-enabled) {
    button.contact-us-item {
      .link-underline:before {
        border-top: none;
      }
    }
  }
  .contact-us-section.customer-care-divider > .contact-us-item:nth-child(1) {
    cursor: pointer;
  }
}
