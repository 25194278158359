.pd-whiteSpace {
  width: 100%;
}
.pd-whiteSpaceDesktop-0-5x {
  height: $base_margin * 0.5;
}
.pd-whiteSpaceDesktop-1x {
  height: $base_margin;
}
.pd-whiteSpaceDesktop-1-5x {
  height: $base_margin * 1.5;
}
.pd-whiteSpaceDesktop-2x {
  height: $base_margin * 2;
}
.pd-whiteSpaceDesktop-2-5x {
  height: $base_margin * 2.5;
}
.pd-whiteSpaceDesktop-3x {
  height: $base_margin * 3;
}
.pd-whiteSpaceDesktop-3-5x {
  height: $base_margin * 3.5;
}
.pd-whiteSpaceDesktop-4x {
  height: $base_margin * 4;
}
.pd-whiteSpaceDesktop-4-5x {
  height: $base_margin * 4.5;
}
.pd-whiteSpaceDesktop-5x {
  height: $base_margin * 5;
}
