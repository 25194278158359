.address-book-container {
  padding: 0 20px;

  .address-book-wrapper {
    .card-wrapper {
      padding: 0 20px;
    }
  }
  .account-bottom-section {
    .account-btn-addnew {
      padding: 0 20px;
    }
  }
}
