.access-details-container {
  &:not(reset-password) {
    padding: 0;
    .page-title {
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1.5px;
      color: $black;
    }

    .field-description {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      color: $dark-grey;
    }
    .save-button {
      padding: 0;
    }
  }
}
