.page-help-menu {
  overflow-x: unset;
  margin: 0;
  > .col {
    padding: 0;
    .menu-list {
      padding: 0;
      width: 100%;
      list-style: none;
      display: block;
      overflow-x: unset;
      height: 100%;
      background: none;
      align-items: center;
      &-item {
        width: 100%;
        flex: unset;
        margin: 16px 0;
        a {
          height: 14px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 1px;
        }
        &.active {
          a {
            text-decoration: underline;
            font-weight: 900;
          }
        }
      }
    }
  }
}
.page-help-container {
  .page-help-menu .menu-list.sticky {
    position: initial;
  }
  .help-menu-col {
    top: 0;
    position: sticky;
    height: min-content;
    z-index: 0;
    .page-help-title {
      height: 38.4px;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1.5px;
      color: black;
      text-transform: uppercase;
      margin-top: 24px;
    }
    .page-help-search {
      margin-bottom: 16px;
      margin-top: 24px;
      border-bottom: none;
      padding-bottom: 0;
      padding-top: 0;
    }

    .page-help-menu {
      overflow-x: unset;
      margin: 0;
      > .col {
        padding: 0;
        .menu-list {
          padding: 0;
          width: 100%;
          list-style: none;
          display: block;
          overflow-x: unset;
          height: 100%;
          background: none;
          align-items: center;
          &-item {
            width: 100%;
            flex: unset;
            margin: 16px 0;
            a {
              height: 14px;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 1px;
            }
            &.active {
              a {
                text-decoration: underline;
                font-weight: 900;
              }
            }
          }
        }
      }
    }
  }

  .help-content-col {
    padding-top: 24px;
    border-left: 1px solid $light-grey;
    .help-content-box {
      &-title {
        margin-top: 0;
      }
      .help-shipping-methods {
        margin: 0;
        .single-method {
          align-items: center;
          display: flex;
          border: solid 1px $light-grey;
          flex-direction: column;
          height: 135px;
          justify-content: center;
          width: 100%;
          margin: 8px 12px;
          text-align: center;
          &.col-md-3 {
            flex-basis: 30%;
            max-width: 31%;
          }
          p {
            margin-top: 0;
          }
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }

      .help-contact-form {
        select {
          display: none !important;
        }
        .select-arrow {
          display: none;
        }
        .fsb-select {
          display: block !important;
          border: 1px solid #ccc !important;
          height: 45px !important;

          &:has(> [aria-expanded='false']) {
            border: 1px solid #ccc !important;
          }

          &:has(> [aria-expanded='true']) {
            border: 1px solid #595959 !important;
            border-radius: 2px 2px 0 0 !important;
          }

          .fsb-button {
            height: 43px !important;
            padding-left: 12px !important;
            padding-bottom: 7px !important;
            font-size: 16px !important;
            line-height: 21.6px !important;
            color: #0D0D0D !important;
            font-weight: normal;
            letter-spacing: 0.015em;

            &:after {
              width: 2.4rem;
              height: 2.4rem;
              background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3e%3cg clip-path='url(%23clip0_289_349)'%3e%3cpath d='M12 13.171l4.95-4.95 1.414 1.415L12 16 5.636 9.636 7.05 8.222l4.95 4.95z' fill='%23404040'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_289_349'%3e%3cpath fill='white' d='M0 0h24v24H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e") center no-repeat;
              display: inline-block;
              transform: none;
              border: none;
              top: 10px;
            }

            & > span {
              text-transform: none;
            }

            &[aria-expanded='true'] {
              &:after {
                color: #000;
                transform: translateY(0) rotateZ(180deg);
              }
            }
          }

          .fsb-list {
            box-shadow: none;
            border: 1px solid;
            border-color: #E0E0E0 #595959 #595959;
            box-sizing: content-box;
            left: -1px;
            max-height: 270px;
            font-size: 16px;
            font-weight: 400;
            line-height: 21.6px;
            letter-spacing: 0.015em;
            border-radius: 0 0 2px 2px !important;

            &::-webkit-scrollbar-thumb {
              background: #CCCCCC;
              border-radius: 15px;
              border: 4px solid #fff;
            }

            &::-webkit-scrollbar {
              width: 12px;
            }
          }

          .fsb-option {
            height: 45px;
            padding: 0 12px;

            :hover {
              background-color: #F9F9F9;
            }

            &:not([aria-disabled='true']):focus {
              background-color: #E0E0E0;
            }
          }
        }
      }
    }
    .help-content-box-section {
      .help-search-order {
        > .row {
          margin-left: 0;
          margin-right: 0;
        }
        .user-email {
          padding-left: 0;
        }
        .search-order {
          padding-right: 0;
          padding-left: 0;
        }
        .search-order-row {
          .search-order-label {
            padding-left: 0;
          }
        }
      }
    }

    .help-search-order-col {
      padding: 0;
    }
  }
}

.help-contacts {
  position: unset;
  height: 44px;
  margin: 0;
  border-bottom: 1px solid $light-grey;
  border-top: 1px solid $light-grey;
  display: flex;
  align-items: center;
  padding: 0;

  .col-6 {
    a {
      display: flex;
      justify-content: center;
      .help-contacts-icon {
        width: 10px;
        height: 12px;
        margin-right: 8px;
      }
      .help-contacts-text {
        height: 12px;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        text-decoration: underline;
      }
    }
  }
}

.live-chat-container {
  bottom: 32px;
  height: min-content;
  right: 28px;
}
