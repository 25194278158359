.mobile-filters {
  .button-icons {
    button {
      width: 620px;
    }
  }

  #filters-order-modal {
    .modal-overlay {
      .modal-container {
        .modal-filters-order {
          .modal-footer {
            .buttons {
              .footer-button {
                width: 354px;
              }
            }
          }
        }
      }
    }
  }

  .horizontal-row {
    &.filters-title-bottom {
      display: none;
    }
    &.single-filter-title-bottom {
      display: block;
      margin-bottom: 5px;
    }
  }
}

//refactor and fix mobile filter
.mobile-filters {
  #filters-order-modal {
    .modal-overlay {
      .modal-content-filters {
        .filters-order-accordion {
          &-content {
            .single-filter-section {
              .filter-list-container {
                ul.values {
                  columns: 3;
                }
              }
            }
          }
        }
      }
    }
  }
}
