.aspect-ratio--4-3 {
  &::before {
    @extend .default;
    padding-top: 75%;
    @extend .mobile;
  }
}

.aspect-ratio--3-4 {
  &::before {
    @extend .default;
    padding-top: 133%;
    @extend .mobile;
  }
}

.aspect-ratio--2-3 {
  &::before {
    @extend .default;
    padding-top: 150%;
    @extend .mobile;
  }
}

.aspect-ratio--3-2 {
  &::before {
    @extend .default;
    padding-top: 66.66%;
    @extend .mobile;
  }
}

.aspect-ratio--1-1 {
  &::before {
    @extend .default;
    padding-top: 100%;
  }
}

.aspect-ratio--16-9 {
  &::before {
    @extend .default;
    padding-top: 56.25%;
    @media (max-width: 992px) {
      padding-top: 133%;
    }
    @extend .mobile;
  }
}

.mobile {
  @media (max-width: 599px) {
    padding-top: 150%;
  }
}

.default {
  content: '';
  display: block;
  width: 100%;
}
