.help-dashboard-container {
  .help-dashboard-title {
    text-align: center;
  }
  .dashboard-help-items {
    padding-bottom: 24px;
    margin-right: -12px;
    margin-left: -12px;

    .help-item {
      align-items: flex-start;
      border: 1px solid $light-grey;
      flex-basis: 30%;
      height: 250px;
      margin: 12px;
      max-width: 31%;
      width: 359px;
      &-arrow {
        display: none;
      }
      &-content {
        // display: block;
        flex-direction: column;
        width: 100%;
        &-icon {
          margin-right: 0;
          margin-bottom: 16px;
        }

        &-text {
          &-description {
            display: block;
            margin-top: 8px;
          }
          &-title {
            text-align: center;
          }
        }
      }
    }
  }

  .page-help-search {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    > .col {
      padding-left: 0;
    }
  }

  &.contacts {
    padding: 0;
    .dashboard-help-contacts {
      margin-left: 0;
      margin-right: 0;
      padding: 16px 0;
      &-element {
        padding: 16px 58px;
        border-left: 1px solid $medium-grey;
        &:first-child {
          padding: 16px 58px;
          border-left: 0;
        }
        &:last-child {
          padding-bottom: 16px;
        }
      }

      .horizontal-row {
        &.contact-separator {
          display: none;
        }
      }
    }
  }
}
