.modal {
  .modal-overlay {
    justify-content: center;
    align-items: center;
  }

  .modal-container {
    flex-grow: 1;
    min-width: 46.5rem;
  }
}

.modal.fade.show {
  z-index: 20;
}
