#layer-logged {
  border: 1px solid $light-grey;
  max-height: 618px;
  min-width: 375px;
  max-width: 375px;
  padding: 8px 16px;
  right: 0;
  top: calc(100% + 15px);
  z-index: 6;
}

.logged-menu-account {
  position: relative;
  &-item {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 8px 0;
    &.logout {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.5px;
      position: absolute;
      right: 0;
      text-transform: unset;
      text-decoration: underline;
      // width: 72px;
      width: min-content;
      padding: 0 16px;
      max-width: min-content;
      min-width: 72px;
    }
  }
}
