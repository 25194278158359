/** CART ITEM GRID setup -->START*/
.cart-item-grid {
  grid-template-columns: 176px 3fr 2fr;
}

/** CART ITEM GRID setup --> END*/

/** CART ITEM  style -->START*/
.cart-item-grid {
  min-height: 213px;
}
.product-info-img {
  width: 160px;
  height: 213px;
}

.product-quantity-wrapper {
  .name-field {
    width: 120px;
  }
}

.product-info-details {
  .detail-attribute {
    .name-field {
      width: 120px;
    }
  }
}

/** CART ITEM  style -->END*/
