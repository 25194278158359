main {
  &.main--layout-page {
    border-top: none !important;
    position: relative;
  }
}

.product-detail.pdp-new {
  padding: 0;
  margin: 0;
  .fsb-select {
    display: block !important;
    .fsb-list {
      .fsb-option {
        &.disabled-element {
          pointer-events: unset;
        }
      }
    }
  }

  .wrapper-pdp {
    overflow: unset;
  }

  video-js {
    padding-top: 132.25% !important;
  }

  .page-designer-container {
    .experience-region.experience-imageVideoCtaContent video-js {
      padding-top: 50% !important;
    }
  }

  span.private-sales,
  .preorder-release-date {
    text-align: center;
    font-family: $futura-book;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.04em;
    color: #757575;
    width: 100%;
    display: block;
    padding: 0 16px;
  }

  .product-image-badge {
    height: 32px;

    &.height-desktop-16 {
      height: 16px;
    }
    &.height-desktop-20 {
      height: 20px;
    }
    &.height-desktop-24 {
      height: 24px;
    }
    &.height-desktop-28 {
      height: 28px;
    }
    &.height-desktop-32 {
      height: 32px;
    }
    &.height-desktop-36 {
      height: 36px;
    }
    &.height-desktop-40 {
      height: 40px;
    }
  }

  .image-carousel-col {
    .add-to-cart-add-to-wishlist {
      display: none !important;
    }
  }

  .wrapper-pdp {
    width: 100vw;

    .col-images {
      width: 60vw;
      max-width: 60vw;
    }
  }

  .product-slider-container {
    height: 100%;
    display: block;

    .product-slides {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      width: 100%;

      .product-slide {
        height: fit-content;
        width: 50%;

        .swiper-zoom-container {
          width: 100%;
          height: auto;
          margin: 0 auto;
          padding: 4px 8px 4px 0;

          img {
            width: 100% !important;
          }
        }
      }
    }
  }

  .col-images {
    padding: 0;
  }

  .col-details {
    padding: unset;

    .product-name-and-price {
      padding: 0;
    }

    .add-to-cart-add-to-wishlist {
      background: white;
      position: unset;
      padding: 0;
      width: 100%;
      margin-top: 24px;
      height: unset;
      .add-to-cart-actions {
        position: relative;
        width: 100%;
        padding: 16px 0 8px 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        .cart-and-ipay {
          margin: 0;
        }
      }
    }
    .pdp-apple-pay-wrapper {
      height: auto;
      padding: 0 0 8px 0;
    }
    .product-notify.product-notify-feature,
    .product-coming-soon.coming-soon.coming-soon-feature {
      .notify-me-container {
        height: unset;
        margin-top: 24px;

        .layer-product-notify#layer-product-notify-component,
        .layer-product-coming-soon {
          position: unset;
          padding: 16px 0;
          width: 100%;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }

    .variation-attribute,
    .product-options,
    .product-notify,
    .product-coming-soon,
    .add-to-cart-error-messaging,
    .bopis-wrraper,
    .product-collapsible-sections {
      padding: 0;
    }

    .product-collapsible-sections-list {
      padding-top: 8px;
    }
  }

  .sticky-col {
    width: 40vw;
    max-width: 550px;
    padding: 115px 64px;
    height: fit-content;
    margin: 0 auto;
    .details-column-content {
      width: 100%;
      &.bottom-position {
        bottom: 0;
        position: absolute;
        right: 0;
        z-index: 2;
        background: $white;
        flex-basis: 41.66667%;
        max-width: 41.66667%;
      }
      &.default-position {
        position: relative;
        z-index: 2;
        background: $white;
      }
      &.top-position {
        top: 0;
        position: fixed;
        right: 0;
        background: $white;
        z-index: 2;
        flex-basis: 41.66667%;
        max-width: 41.66667%;
      }
    }
  }

  .primary-images {
    padding-left: 12px;
    padding-right: 12px;
  }

  .image-title-price {
    margin-left: 0;
    margin-right: 0;
  }

  .product-description {
    margin-bottom: 0;
    .description-content {
      color: $black;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.03em;
      width: 100%;
    }
  }

  .product-image {
    width: 345px;
    height: 460px;
    margin: 0;
    // border: solid 1px $white;
    margin-bottom: 16px;
  }

  .product-name {
    padding: 0;
    display: flex;
    width: 100%;
    max-width: unset;
    h1 {
      font-family: $futura-book;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.04em;
      text-align: left;
    }

    .add-to-wish-list {
      display: block;
      margin-left: 24px;
      height: 20px;
      width: 20px;

      .icon--heart-filled {
        display: none;
      }

      &.active {
        .icon--heart-filled {
          display: block;
        }
        .icon--heart-round {
          display: none;
        }
      }
    }
  }

  .product-number-rating {
    margin: 0;

    .product-number {
      color: $dark-grey;
      font-size: 12px;
      padding: 0;
    }
  }

  .product-price {
    padding: 0;
    text-align: right;
    max-width: 100%;

    .prices {
      color: $black;
      font-family: $futura-book;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.04em;

      text-align: left;
      width: 100%;
      &.previous-price {
        text-decoration: line-through;
      }
    }
  }

  .product-see-also {
    margin-bottom: 24px;
    width: fit-content;
    text-transform: uppercase;
    .col {
      display: flex;
      span {
        // width: 78px;
        height: 22px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.3px;
        color: $black;
      }

      .see-also-value {
        text-decoration: underline;
        margin: 0 8px;
      }
    }
  }

  .attributes {
    padding: 0;

    .requirement-container {
      margin: 24px 0 24px;
    }
  }

  .product-options {
    margin-top: 40px;
  }

  .variation-attribute {
    margin-bottom: 0;

    .fsb-select {
      text-transform: uppercase;
    }

    .custom-select-wrap {
      width: 100%;
    }

    &.attribute-color {
      .fsb-select {
        border: none !important;
        border-top: 1px solid #e5e5e5 !important;
        border-bottom: 1px solid #e5e5e5 !important;
        width: 100% !important;
        margin: 0;
        .fsb-button {
          height: unset !important;
          padding: 25.5px 0 !important;

          &:after {
            transform: translateY(-65%) rotateZ(315deg);
          }
        }
      }
      &.invalid {
        .fsb-select {
          border-bottom: 1px solid $status_error_light !important;
        }
      }
    }

    &.attribute-size {
      .fsb-select {
        border: none !important;
        border-bottom: 1px solid #e5e5e5 !important;
        width: 100% !important;
        margin: 0;
        .fsb-button {
          height: unset !important;
          text-transform: none;
          padding: 25.5px 0 !important;

          &:after {
            transform: translateY(-65%) rotateZ(315deg);
          }
        }
      }
      &.invalid {
        .fsb-select {
          border-bottom: 1px solid $status_error_light !important;
        }
      }
    }

    &.invalid {
      margin-bottom: 40px;
      label {
        color: $status_error_light !important;
      }
      .error-message {
        top: 56px;
      }
      .fsb-button {
        color: $status_error_light !important;
        &:after {
          color: $black;
        }
      }
    }

    .color-label {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      color: $black;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .attribute-label-value {
      border-bottom: 1px solid;
      padding: 0 11px 3px;
      padding-left: 0;
      font-size: 12px;
    }

    .custom-select-size-guide {
      align-items: center;
      display: flex;
      justify-content: space-between;
      .custom-select-container {
        width: 98px;
      }
      .size-guidelines-button {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }

    select {
      width: 100%;
      // max-width: 58.33333%;
      border: none;
      padding-left: 0;
      margin-top: 0;
      padding: 0;
      option {
        text-transform: uppercase;
      }
    }
    .custom-select-wrap {
      &.invalid {
        .error-message {
          top: 50px;
        }
      }
    }
  }

  .size-label-section {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 22px;
    padding: 0;

    .size-guidelines {
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.5px;
      color: $black;
    }

    .size-label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.3px;
      color: $black;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  .add-to-cart-add-to-wishlist {
    flex-direction: row-reverse;
    margin-left: 0;
    margin-right: 0;
    position: unset;
    background: none;
    .add-to-cart-actions,
    .notfy-me-actions {
      position: unset;
      max-width: 100%;
      width: 100%;
      margin: 0;
      .cart-and-ipay {
        margin-left: 0;
        margin-right: 0;
      }
      .btn--primary {
        &:hover {
          cursor: pointer;
          background-color: #404040;
          -webkit-transition: background-color 500ms linear;
          -ms-transition: background-color 500ms linear;
          transition: background-color 500ms linear;
        }
      }
    }

    .add-to-whishlist {
      max-width: 48px;
      padding-left: 0;
      width: 48px;

      button {
        border: 1px solid $medium-grey;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        .add-to-wish-list-icon {
          height: 20px;
        }
      }
    }
  }

  .bopis-wrraper {
    .initialized {
      text-align: center;

      .bopis-btn {
        margin: 0 0 16px 0;
      }
    }
  }

  .free-shipping {
    color: $dark-grey;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    height: 22px;
    letter-spacing: 0.3px;
    line-height: 1.8;
    margin: 24px 0;
  }

  .pickup-in-store-option {
    height: 12px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    color: $black;
    text-decoration: underline;
    text-transform: uppercase;
    margin: 24px 0 24px 0;
  }

  .product-collapsible-sections {
    .col-12 {
      &.col-md-5 {
        padding: 0;
      }
    }
    .product-info-accordion {
      // margin-top: 24px;
      .accordion {
        .js-accordion-trigger {
          display: flex;
          justify-content: space-between;
          width: 100%;
          &-title {
            height: 48px;
            padding: 18px 0;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.5px;
            color: $black;
            text-transform: uppercase;
          }
        }
      }
    }

    h2 {
      align-items: center;
      display: flex;
      height: 48px;
      justify-content: space-between;

      .title {
        color: $black;
        height: 12px;
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 1;
        text-transform: uppercase;
      }
    }
  }

  .desktop-images {
    margin-left: 60px;
    .single-image {
      height: 948px;
      margin: 30px 0;

      max-width: 711px;
      padding: 0;
      width: 711px;
      &:first-child {
        margin-top: 60px;
      }
      img {
        width: 489px;
        height: 652px;
      }
    }
  }
  &.product-wrapper {
    .recommendations {
      margin-top: 0;
      width: 100vw;

      .pd-carousel__products-region {
        // .swiper-button-next {
        //   display: none;
        // }
        // .swiper-button-prev {
        //   display: none;
        // }
      }

      .product-carousel-section {
        padding: 0;
        position: relative;
      }
      .swiper-heading-wrapper {
        padding: 64px 24px 24px;
        .swiper-header {
          font-family: $futura-book;
          font-size: 24px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0.02em;
          text-align: left;
        }
      }

      .swiper-slide {
        min-width: unset;

        .product {
          width: 100%;
        }
      }

      .swiper-button-next {
        display: block;
        top: -21px;
        right: 24px;
        position: absolute;
        left: unset;
        &:after {
          @include fixedSprite('arrow-new');
          content: '';
        }
      }
      .swiper-button-prev {
        display: block;
        top: -21px;
        right: 64px;
        position: absolute;
        left: unset;
        &:after {
          @include fixedSprite('arrow-new');
          content: '';
          transform: rotate(180deg);
        }
      }

      .icon--arrow-back-black-default {
        background: none !important;
      }

      .single-element-content-detail {
        padding: 12px 24px 24px 12px;
        .single-element-content-detail-description {
          .product-link {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }

      &.stl-recommendation {
        .swiper-button.swiper-button-prev,
        .swiper-button.swiper-button-next {
          display: none;
        }
      }
    }
  }

  .product-breadcrumb {
    .breadcrumb-component {
      .breadcrumb {
        margin: 24px 0;
      }
      a {
        font-family: $futura-book;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.04em;
        color: $black;
      }
    }
  }

  #findinstore-modal {
    visibility: hidden;
    .modal-container {
      position: fixed;
      transition: right ease-in 0.3s;
      right: -574px;
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      top: 0;
      min-width: 527px;
      max-width: 527px;
    }

    .modal-content {
      max-height: calc(100vh - 57px);
    }

    &.is-open {
      visibility: visible;
      .modal-container {
        right: 0;
        animation-name: slideIn;
        animation-duration: 1s;
        animation-fill-mode: forwards;
      }
    }

    &.animate-minicart-closure {
      .modal-container {
        animation-name: slideOut;
        animation-duration: 1s;
        animation-fill-mode: forwards;
      }
    }
  }

  #findinstore-modal {
    .modal-close {
      top: 5rem !important;
      right: 7.2rem !important;
    }
    .modal-header {
      padding: 48px 72px 24px !important;
      height: unset;
    }

    .bopis-product-info-outer-container {
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .bopis-product-info-container {
      padding: 24px 72px 40px !important;
    }

    .box-actions-search-store {
      padding: 0 72px !important;
      border: none !important;
    }
    .store-search-result {
      height: calc(100vh - 446px);
      position: relative;
      .store-search-result-header {
        padding: 40px 72px 16px !important;
      }

      .stores-list {
        overflow-y: unset;
        height: calc(100% - 78px);
        background: white;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
        .stores {
          padding: 0 72px !important;
        }
      }

      .btn-confirm-address {
        border-top: 1px solid #e5e5e5;
        position: fixed;
        bottom: 0;
        margin: 16px 72px;
        width: calc(100% - 142px);
      }
    }
  }
}

.product-detail.product-wrapper.pdp-new.product-set-pdp {
  .image-carousel-col {
    position: sticky;
  }
  .col-details {
    padding: 0;
    .details-column-content {
      padding: 40px;
    }
  }

  .recommendations {
    .swiper-heading-wrapper {
      padding: 24px;
    }
    .product-set-recomendation-container-inner-products {
      gap: 8px;

      .experience-assets-product {
        max-width: calc(25% - 4px);
        margin-bottom: 0;
        width: calc(25vw - 8px);
        flex-basis: calc(25vw - 6px);
        padding: 0;
        display: block;
      }
    }
  }
}

#size-guidelines-modal.new-pdp-size-guidelines {
  visibility: hidden;
  .modal-container {
    position: fixed;
    transition: none;
    right: -574px;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    top: 0;
    min-width: 527px;
    max-width: 527px;
  }

  .product-help-content {
    .pdp-contacts-container {
      .pdp-contacts-mobile {
        display: none;
      }
    }

    .pdp-contacts-desktop {
      display: flex;
    }
  }

  .choose-size-radio-input-row {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .modal-header {
    padding: 48px 72px 24px;
  }

  .modal-content {
    max-height: calc(100svh - 96px);
    padding: 24px 72px;

    .fitguide__content.size-conversion-accordion {
      padding: 0 !important;
    }

    .row.choose-size-radio-input-row {
      padding: 0;
    }

    .measuring-guide__size-table {
      flex-basis: 100%;
      max-width: 100%;
      padding: 0;
      padding-left: 0 !important;
    }

    .measuring-guide__size-table-content {
      max-width: unset;
      flex-basis: unset;
      padding: 0;
    }
  }

  .modal-content-gudielines .fitguide__container--desktop #fitguide-content-02 {
    padding: 0;
    .measuring-guide .measuring-guide-content {
      justify-content: left;
    }

    .title-table {
      padding: 0 0 16px 0;
    }
  }
  &.is-open {
    visibility: visible;
    .modal-container {
      right: 0;
      animation-name: none;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
  }

  &.animate-minicart-closure {
    .modal-container {
      animation-name: none;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
  }
}

.notify-me-container {
  .notify-me {
    &-insert-email {
      .add-to-cart-add-to-wishlist {
        flex-direction: row;
        .add-to-cart-actions,
        .notfy-me-actions {
          position: unset;
          max-width: 100%;
          width: 100%;
          font-size: 14px;
        }
        .add-to-whishlist {
          padding-right: 0;
        }
      }
    }
  }
}

// page structure
.sticky-col {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100%;
}

.product-slider-container {
  height: 100vh;
}

.image-carousel-global-container {
  position: relative;
}

.product-slide {
  .swiper-zoom-container {
    align-items: flex-start;
  }
}
.product-fullscreen-images-wrapper {
  &.active {
    flex-wrap: nowrap;
    overflow: hidden auto;
  }
  .product-fullscreen-images {
    height: auto;
    width: 100vw;
  }
}

html.product-main-overlay,
body.product-main-overlay {
  overflow: hidden;
}

@keyframes slideIn {
  0% {
    right: var(--slide-close);
  }
  100% {
    right: var(--slide-open);
  }
}
@keyframes slideOut {
  0% {
    right: var(--slide-open);
  }
  100% {
    right: var(--slide-close);
  }
}

.wishlist-alert {
  &-container {
    border-radius: 2px;
    right: auto;
    top: 20px;
    left: 50%;
    justify-content: flex-start;
  }

  &-content {
    padding-right: 100px;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
}
