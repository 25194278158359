.account-content-col {
  &.not-logged {
    .return-content {
      .horizontal-row-container {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 144rem;
        .horizontal-row {
          margin-left: -48px;
          width: calc(100% + 73px);
          &.return-confirmation-product-bottom {
            width: 100%;
            margin-left: 0;
          }
        }
      }
      .order-step {
        .horizontal-row {
          margin-left: -48px;
          width: calc(100% + 73px);
          &.return-confirmation-product-bottom {
            width: 100%;
            margin-left: 0;
          }
        }
        .desktop-steps-container {
          .first-element {
            padding-left: 20px;
          }
        }
      }
    }
  }
}
