.pd-text--font {
  &-16-20 {
    font-size: 2rem;
    line-height: 3rem;
  }
  &-20-38 {
    font-size: 3.8rem;
    line-height: 5.8rem;
  }
}
