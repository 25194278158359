.checkoutpage {
  padding-left: 0;
  > .row {
    margin-right: 0;
  }
  .header-bottom-row {
    display: none;
    border-top: 1px solid $light-grey;
    width: calc(100% + 56px);
    margin-top: 18px;
    margin-bottom: 16px;
    margin-left: -28px;
  }
  .shipping-form-data {
    border-right: 1px solid $light-grey;
    .stepper-container {
      height: 72px;
    }
  }
  .order-summary-box {
    &-info {
      margin-top: 30px;
      height: 100%;
    }
  }
  .checkout-shipping__address {
    .checkout-shipping__address__address-book {
      > .form-group {
        margin-top: 24px;
        .form-group__label {
          width: 125px;
          height: 23px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          color: $black;
          text-transform: uppercase;
        }
      }
      .form-select {
        &.stateCode {
          .row {
            .col-1 {
              top: 1.8rem;
            }
          }
        }
      }
      .address_form {
        .form-group {
          margin-bottom: 21px;
        }
      }
      #addresses {
        margin-bottom: 24px;
      }
      &-title {
        height: 39px;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1.5px;
        color: $black;
        display: block;
        &.no-addresses {
          margin-bottom: 17px;
        }
      }
    }
    .checkout-shipping__options {
      fieldset {
        margin: 0 -22px;
        padding: 0 22px;
        .form-group {
          margin-top: 22px;
          margin-bottom: 22px;
          .radio {
            border-bottom: none;
            padding-left: 0;

            &:nth-child(even) {
              padding-left: 24px;
            }

            .checkout-shipping__options__option {
              &-name {
                margin-right: 5px;
              }
            }
          }
          .horizontal-row {
            &.checkout-option-bottom {
              display: block;
              margin-top: 5px;
            }
          }
        }
      }
    }

    .horizontal-row {
      &.header-bottom-row {
        margin-left: -28px;
        width: calc(100% + 56px);
      }
      &.upper-title-row {
        margin-bottom: 24px;
        width: calc(100% + 56px);
        margin-left: -28px;
        margin-top: 40px;
      }
      &.shipping-data {
        width: calc(100% + 56px);
        margin-left: -28px;
      }
      &.pickup-checkout-address-bottom {
        width: calc(100% + 56px);
        margin-left: -28px;
      }
      &.checkout-option-bottom {
        width: calc(100% + 56px);
        margin-left: -28px;
      }
      &.checkout-option-top {
        width: calc(100% + 56px);
        margin-left: -28px;
      }
      &.checkout-navigations-bottom {
        display: block;
        width: calc(100% + 56px);
        margin-left: -28px;
      }
    }
  }

  .checkout-navigations {
    margin-top: 0;
    .checkbox {
      margin-bottom: 0;
    }
    &.navigations-buttons {
      padding-bottom: 80px;
    }
    .checkout-navigations__steps {
      &-current {
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        font-weight: 500;
        color: $black;
      }
      &-total {
        color: $dark-grey;
      }
    }
    .proceed-to-payment {
      justify-content: flex-end;
      display: flex;
    }
  }

  .order-summary-box {
    &-info {
      &-text {
        padding-left: 0;
        padding-right: 13px;
      }
    }
  }

  &.payment {
    .payment-method {
      padding-left: 0;
      .container-raio-payments {
        .radio__icon {
          .single-payment-box {
            padding-right: 18px;
            .col-3 {
              padding-left: 8px;
            }
          }
          &.credit-card {
            .single-payment-box {
              margin-bottom: 0;
              padding-left: calc(2.4rem + 22px);
              padding-right: 7px;
              &-card-type {
                border: 3px solid;
                margin-right: -17px;
              }
            }
          }
          &:not(.credit-card) {
            .single-payment-box {
              padding-right: 21px;
              &-card-type {
                border: 3px solid;
                margin-right: -38px;
              }
            }
          }
        }
      }

      .single-payment-box {
        position: relative;
        &-name {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: 0.3px;
          color: $black;
        }
        &-card-type {
          display: flex;
          flex-direction: row-reverse;
          margin: 0;

          div {
            width: calc(63px - 0.4rem);
            height: 40px;
            flex-basis: unset;
            max-width: calc(63px - 0.4rem);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 0;

            i {
              display: flex;
              align-items: center;
              height: 100%;
              width: 89px;
              margin: 0;
            }
          }

          .card-type-icon {
            margin: 0 16px;
            height: 100%;
            width: 110px;
          }
        }

        .d-none {
          display: none;
        }
      }

      .single-payment-box-card-type-icons {
        position: absolute;
        justify-content: center;
        margin-top: 8px;
      }

      .icon--payment-card-creditcardicon {
        margin: 0;
      }
      &:not(:last-child) {
        .payment-method-bottom {
          margin-left: -28px;
          width: calc(100% + 56px);
          margin-top: 16px;
        }
      }
      &:last-child {
        .payment-method-bottom {
          margin-left: -28px;
          width: calc(100% + 56px);
        }
      }
      .credit-card-fields-box {
        .save-payment {
          margin-top: 24px;
          margin-bottom: 12px;
        }

        &.sub-radio-group {
          .sub-radio__element {
            padding-left: 0;
          }
          .radiobox {
            .radiobox__option {
              button {
                margin-right: -10px;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
    .checkout-payment {
      form {
        > .form-group {
          margin-top: 24px;
        }
        .billingAddressForm-checkbox {
          padding-left: 12px;
        }
        .payment-methods-title {
          height: 39px;
          font-size: 24px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1.5px;
          color: $black;
          text-transform: uppercase;
          margin-bottom: 16px;
        }
      }
      #addresses {
        margin: 16px 0 0 0;
      }
      .addresses-label {
        height: 23px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        color: $black;
        text-transform: uppercase;
      }
      .horizontal-row {
        width: calc(100% + 30px);
        border-top: 1px solid $light-grey;
        margin-left: -15px;
        margin-bottom: 16px;

        &.upper-title-row {
          margin-top: 40px;
          width: calc(100% + 28px);
          margin-left: 0;
        }
      }
      .form-select {
        &.addresses {
          .row {
            .icon--chevron-mini-down {
              top: 6px;
            }
          }
        }
      }
      &-title {
        display: block;
        height: 39px;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1.5px;
        color: $black;
        text-transform: uppercase;
      }
      &-subtitle {
        height: 23px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 16px;
        padding-left: 12px;
      }
    }
    .billingAddressForm {
      .checkout-billing__address__address-book__new {
        .address_form {
          .accordion-title {
            &-text {
              margin-top: 0;
            }
            &-icon {
              margin-top: 0;
            }
          }
          .checkout-navigations {
            .checkbox {
              margin-bottom: 0;
            }
          }

          #billing-address-accordion-content {
            .col-12 {
              .form-group {
                margin-top: 24px;
                .form-adress-phone-number {
                  .col-12 {
                    margin-top: 0;
                  }
                }
              }
            }
            .checkout-navigations {
              margin-top: 24px;
            }
          }
        }
      }
      .form-title {
        height: 12px;
        margin: 0 16px 0 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: black;
        text-transform: uppercase;
      }
    }
    .paymentmethods {
      .checkout-shipping__address__address-book {
        &-title {
          height: 23px;
          margin: 16px 0 18px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1px;
          color: $black;
          text-transform: uppercase;
        }
        > .form-group {
          .form-group__label {
            height: 23px;
            margin: 24px 46px 16px 0;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            color: $black;
            text-transform: uppercase;
          }
        }
      }
      .billingAddressForm-checkbox {
        .checkbox {
          margin-bottom: 26.8px;
        }
      }

      .horizontal-row {
        width: calc(100% + 30px);
        border-top: 1px solid $light-grey;
        margin-left: -15px;
        margin-bottom: 16px;
        &.payment-form-bottom {
          margin-top: 24px;
          width: calc(100% + 29px);
          margin-left: 0;
        }
        &.upper-title-row {
          margin-top: 16px;
          width: calc(100% + 56px);
          margin-left: -28px;
        }
      }
    }
    .form-select {
      select {
        width: 100%;
        margin: 16px 0 0;
        &#stateCode {
          margin-top: 3px;
          margin-bottom: 16px;
        }
      }
      .col-1 {
        top: 3rem;
      }
      &.stateCode {
        .col-1 {
          top: 1.8rem;
        }
      }
    }

    .country-wrapper {
      &.is-select {
        .form-select {
          .col-1 {
            top: 1.8rem;
          }
          select {
            margin: 3px 0 0;
          }
        }
      }
    }
    .horizontal-row {
      width: calc(100% + 30px);
      border-top: 1px solid $light-grey;
      margin-left: -15px;
      margin-bottom: 16px;

      &.estimated-total-row-bottom {
        width: calc(100% + 12px);
        margin-left: -24px;
      }
      &.order-summary-elements-top {
        margin-top: 24px;
        width: calc(100% + 12px);
        margin-left: -24px;
        margin-bottom: 8px;
      }
      &.shipping-restriction-box-bottom {
        width: calc(100% + 24px);
        margin-left: -24px;
      }
      &.sub-radio-element-bottom {
        width: calc(100% + 24px);
        margin: 17px 0;
        margin-left: -24px;
      }
      &.upper-billing-form {
        margin-left: 0;
        margin-top: 24px;
        margin-bottom: 18px;
        width: calc(100% + 29px);
      }
    }

    .proceed-to-review {
      margin-bottom: 80px;
      padding-left: 0;
      > .row {
        justify-content: space-between;
      }
      .checkout-navigations {
        .col-12 {
          display: flex;
          justify-content: flex-end;
          padding-right: 0;
        }
      }
      button {
        width: 145px;
      }
    }
  }

  &.review {
    border: 1px solid $light-grey;
    #reviewTitle {
      height: 38.4px;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1.5px;
      color: $black;
      text-transform: uppercase;
    }
    .checkout-review {
      .order-review {
        margin-top: 16px;
      }
      .billing-review {
        margin-top: 16px;
      }
      .horizontal-row {
        &.shipping-method-top-row {
          margin-left: 0;
          width: calc(100% + 28px);
        }
        &.payment-method-top-row {
          margin-left: 0;
          width: calc(100% + 28px);
        }
        &.rules-top-row {
          margin-left: 0;
          width: calc(100% + 28px);
        }
        &.rules-bottom-row {
          margin-left: 0;
          width: calc(100% + 28px);
          margin-bottom: 24px;
        }
        &.upper-title-row {
          margin-top: 40px;
          width: calc(100% + 29px);
          margin-left: 0;
        }
      }
    }
    .accordion__group {
      .step__header__title {
        display: none;
      }
    }
    .checkout-review-title {
      display: block;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1.5px;
      color: $black;
      text-transform: uppercase;
    }
  }

  .shipping-form-data {
    padding-right: 28px;
    padding-left: 39px;
  }

  #addressbookid {
    > div {
      &:not(:last-child) {
        height: 80px;
        width: calc(100% + 52px);
        padding: 24px 0;
        margin-left: -12px;
        padding-left: 24px;
        &.radio-item-checked {
          background: $light-grey;
        }
        &:not(.radio-item-checked) {
          width: calc(100% + 52px);
          padding: 24px 0;
          padding-left: 24px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .gift-option-container {
    padding-bottom: 21px;
    .gift-option-subheader {
      display: flex;
      .gift-option-radios {
        margin-left: 40px;
        .form-group {
          margin-top: 0;
          .radio {
            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
    .gift-option-fields {
      padding-left: 40px;
      .gift-input-field {
        label {
          &.giftTo-label + .form-control {
            padding-right: 0;
          }
          &.labelforGifto {
            right: -1rem;
          }
        }
      }
    }
  }
}

.order-summary-box-container {
  padding-left: 24px;
  padding-right: 28px;

  .order-summary-box {
    padding: 0;
    &-title {
      justify-content: space-between;
      display: flex;
      width: 100%;
      &-icon {
        margin-top: 1.8rem;
        display: none;
      }
    }
    .order-summary-box-content {
      .order-summary-box-info {
        &-icon {
          margin-top: 1px;
          max-width: 20px;
          width: 20px;
        }
      }
    }
  }

  .accordion {
    .order-summary-box-review {
      padding: 0;
      padding-left: 12px;
      .js-accordion-trigger {
        padding: 0;
        &-title {
          height: 38.4px;
          font-size: 24px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 1.5px;
          color: $black;
          text-transform: uppercase;
        }
      }
      .order-summary-box-info {
        padding: 0;
        margin-right: 0;
      }

      .accordion-content {
        display: block;
        opacity: 1;
        max-height: 100%;
      }
    }
    .shipping-restriction-accordion {
      margin-top: 24px;
      padding-left: 1px;
      #shipping-restriction-accordion-control {
        align-items: center;
        display: flex;
        margin-bottom: 8px;
        padding-right: 13px;
      }
      #shipping-restriction-accordion-content {
        display: none;
        .row {
          margin-right: 0;
        }
      }
      &.is-active {
        #shipping-restriction-accordion-content {
          display: block;
        }
      }
    }
  }

  .order-summary-box-info-text {
    .section-footer-box {
      padding-right: 0;
      .accordion-content {
        padding-left: 0;
        padding-right: 0;
      }
      .js-accordion-trigger {
        padding: 0;
        align-items: center;
        .section-footer-box {
          padding-left: 0;
        }
      }
    }
  }

  .bag-review-container {
    padding-right: 12px;
    .bag-review-title {
      display: block;
      padding: 0;
      margin-bottom: 24px;
      margin-top: 16px;
    }

    .shipping-restriction {
      margin-bottom: 16px;
      padding: 0;
      &-title {
        display: none;
      }
    }
  }

  .horizontal-row {
    &.details-box-upper-row {
      width: calc(100% + 12px);
      margin-left: -24px;
      margin-bottom: 8px;
    }
    &.estimated-total-row-bottom {
      width: calc(100% + 12px);
      margin-left: -24px;
      margin-top: 24px;
    }
    &.order-summary-elements-top {
      width: calc(100% + 12px);
      margin-left: -24px;
      margin-bottom: 16px;
      margin-top: 16px;
    }
  }

  .customer-care {
    padding: 0;
    margin-bottom: 58px;
    &-title {
      justify-content: space-between;
      display: flex;
      width: 100%;
      &-text {
        height: 48px;
        padding: 18px 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        text-transform: uppercase;
      }
      &-icon {
        margin-top: 1.8rem;
      }
    }
  }

  .return-and-services {
    padding: 0;
    &-title {
      justify-content: space-between;
      display: flex;
      width: 100%;
      &-text {
        height: 48px;
        padding: 18px 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black;
        text-transform: uppercase;
      }
      &-icon {
        margin-top: 1.8rem;
      }
    }
  }

  .bag-review {
    &-title {
      height: 26px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $black;
      text-transform: uppercase;
    }
    .bag-product {
      .product-info {
        height: 160px;
        margin-left: 0;
        &-img {
          height: 100%;
          > div {
            width: 108px;
            height: 144px;
          }
        }
        .bag-product__content {
          .bag-product__name {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            color: $black;
          }
          .bag-product__details {
            > .row {
              margin-top: 8px;
              &:first-child {
                margin-top: 16px;
              }
              .bag-product__details-name {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.8;
                letter-spacing: 0.3px;
                color: $black;
                text-transform: uppercase;
              }
              .col-6 {
                &:last-child {
                  display: flex;
                  justify-content: flex-start;
                  .bag-product__details-value {
                    height: 22px;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.8;
                    letter-spacing: 0.3px;
                    color: $black;
                  }
                }
              }
            }
            .bag-product__details-quantity {
              margin-top: 8px;
              > .row {
                .col-6 {
                  &:last-child {
                    display: flex;
                    justify-content: flex-start;
                    .bag-product__details-quantity__value {
                      height: 21.6px;
                      font-size: 12px;
                      font-weight: normal;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.8;
                      letter-spacing: 0.3px;
                      color: $black;
                    }
                  }
                }
              }
              .bag-product__details-quantity__label {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.8;
                letter-spacing: 0.3px;
                color: $black;
                text-transform: uppercase;
              }
            }
          }
        }
      }
      .shipping-and-price {
        margin-top: 16px;
        .bag-product__shipping {
          padding-right: 0;
          &-text {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: 0.3px;
            color: #096a0e;
            text-transform: uppercase;
          }
        }
        .bag-product__price {
          display: flex;
          justify-content: flex-end;
          &-text {
            height: 22.4px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            text-align: right;
            color: $black;
          }
        }
      }
    }
  }

  .pickup-in-store {
    padding: 0;
    &-title {
      height: 26px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $black;
      text-transform: uppercase;
    }
    &-location {
      margin-bottom: 24px;
      margin-top: 16px;
      .row {
        .col {
          height: 12px;
          display: flex;
          align-items: center;
          .icon--pin {
            height: 12px;
            width: 8px;
            margin-right: 10px;
          }
          .shop {
            height: 12px;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.5px;
            color: $black;
          }
        }
      }
    }
    .bag-review {
      width: 100%;
      .bag-product {
        .shipping-and-price {
          .bag-product__shipping {
            &-text {
              display: none;
            }
          }
        }
      }
    }
  }

  .details-box-upper-row {
    display: block;
  }

  .details-box {
    background: $white;
    margin-bottom: 16px;
    margin-left: -12px;
    margin-top: 16px;
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 26px;
    width: calc(100% + 24px);
    > .row {
      padding-right: 0;
    }

    .items__subtotal-description {
      .items__label {
        .tooltip-global-container {
          .tooltip-box {
            bottom: -14px;
          }
        }
      }
    }
  }

  .estimated-total-row {
    padding: 0;
    padding-right: 12px;
    &-title {
      p {
        height: 22.4px;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
    &-price {
      p {
        height: 22.4px;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        text-transform: uppercase;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.cvv-expiration-fields {
  #component-container {
    .adyen-checkout__field--storedCard {
      top: -56px;
      left: -8px;
    }
  }
  &.shared-component-adyen {
    .adyen-checkout__field--storedCard {
      position: static !important;
      width: 90px !important;
    }
  }
}

.review-summary-box {
  &.box-inside-form {
    display: none;
  }
  .order-summary-box-container {
    .accordion {
      .order-summary-box-review {
        .js-accordion-trigger {
          padding: 0;
        }
        .order-summary-box-info {
          padding: 0;
        }
      }
    }
    .bag-review-container {
      .bag-review-title {
        padding: 0;
      }

      .shipping-restriction {
        margin-bottom: 16px;
        padding: 0;
      }
    }

    .horizontal-row {
      &.estimated-total-row-bottom {
        display: block;
      }
    }

    &-title {
      height: 26px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $black;
      text-transform: uppercase;
    }
    .bag-product {
      .product-info {
        .bag-product__content {
          .bag-product__details {
            > .row {
              .col-6 {
                &:last-child {
                  display: flex;
                  justify-content: flex-start;
                }
              }
            }
            .bag-product__details-quantity {
              margin-top: 8px;
              > .row {
                .col-6 {
                  &:last-child {
                    display: flex;
                    justify-content: flex-start;
                  }
                }
              }
            }
          }
        }
      }
    }

    .details-box {
      margin-bottom: 0;
      margin-left: -24px;
      margin-top: 0;
      padding-left: 24px;
      padding-right: 28px;
      padding-top: 8px;
      width: calc(100% + 40px);
    }
    .estimated-total-row {
      padding: 0;
      margin-right: 0;
    }
  }
}

.button-and-terms-conditions {
  margin-top: 40px;
  .component-container-button {
    padding: 0;
    padding-left: 12px;
    padding-right: 16px;
  }
  .place-order-top & {
    #terms-and-conditions {
      margin-bottom: -6px;
    }
  }
  .place-order-bottom & {
    margin-bottom: -6px;
    margin-top: 8px;
  }
  #terms-and-conditions {
    padding-left: 24px;
    padding-right: 28px;
  }
}

.stepper-container {
  .default-steps-container {
    display: none;
  }
  .desktop-steps-container {
    display: flex;
  }
}

.method-deliveryTimeslot {
  width: 100%;
  .timeslot-selector {
    margin-left: 0;
    width: 100%;
    padding-left: 13px;
    .timeslot-field {
      padding: 0;
      .form-group {
        flex-direction: column;
      }
    }
  }
}

.checkoutpage.payment .payment-method .container-radio-payment .radio__icon.credit-card .single-payment-box {
  margin-bottom: 0;
}
