.checkout-login-container {
  flex-direction: row-reverse;
  min-height: 731px;
  align-items: stretch;
}

.checkout-login-box {
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 18px;
  padding-top: 132px;
  & + .checkout-login-box {
    padding-left: 18px;
    padding-right: 20px;
    position: relative;
    border: none;
    &::before {
      content: '';
      height: 100%;
      width: 1px;
      background: $light-grey;
      position: absolute;
      right: -25%;
      left: auto;
      top: 0;
    }
  }
}

// requested specif alignment
.checkout-login-box {
  + .checkout-login-box--guest {
    padding-left: 0;
  }
}

.checkout-login-box:not(.checkout-login-box--guest) {
  padding-right: 0;

  // .btn-login {
  //   margin-top: 121px;
  // }
}
