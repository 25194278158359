.section-form-newsletter {
  .radio {
    margin: 0 24px 24px 0;
  }
}

#newsletter-modal {
  .modal-container {
    height: auto;
    bottom: 4.4rem;
    right: 4.4rem;
    left: auto;
  }
}
//
//
