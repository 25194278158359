.pd-full-bleed {
  overflow: hidden;
  &,
  & * {
    box-sizing: border-box;
  }
}
.pd-wrapper-relative {
  position: relative;
}

.pd-container {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  height: 100%;
}

.pd-full-bleed-fixed {
  height: 810px;
  overflow: hidden;
}

.pd-full-bleed-media-wrapper {
  height: 100%;
}

.pd-full-bleed-content {
  padding: 15px 15px 45px;
  width: 100%;

  & > * {
    display: block;
    width: 100%;
  }

  &.pd-full-bleed-content-white {
    color: #fff;
  }
  &.pd-center-bottom,
  &.pd-center-center,
  &.pd-center-top {
    .pd-text-align-undefined {
      text-align: center;
    }
  }
}
.pd-full-bleed-cta {
  display: flex;
  flex-flow: row wrap;
  &.pd-full-bleed-cta--multiple {
    .inline_btn {
      margin: 0, 10, 10;
    }
    .block_btn {
      flex-basis: 100%;
    }
  }
  .pd-full-bleed-content[class*='pd-center'] & {
    .block_btn {
      text-align: center;
    }
  }
  .pd-full-bleed-content[class*='pd-left'] & {
    .block_btn {
      text-align: left;
    }
  }
  .pd-full-bleed-content[class*='pd-right'] & {
    .block_btn {
      text-align: right;
    }
  }
  .pd-full-bleed-content[class*='pd-left'] & {
    justify-content: flex-start;
  }
  .pd-full-bleed-content[class*='pd-right'] & {
    justify-content: flex-end;
  }
  .pd-full-bleed-content[class*='pd-center'] & {
    justify-content: center;
  }
}

.pd-full-bleed-content[class*='pd-left'] {
  .pd-full-bleed-heading {
    .pd-text-align-undefined {
      text-align: left;
    }
  }
}
.pd-full-bleed-content[class*='pd-right'] {
  .pd-full-bleed-heading {
    .pd-text-align-undefined {
      text-align: right;
    }
  }
}
.pd-full-bleed-content[class*='pd-center'] {
  .pd-full-bleed-heading {
    .pd-text-align-undefined {
      text-align: center;
    }
  }
}
