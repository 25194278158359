.register-benefit {
  display: block;
  padding-top: 40px;
}

.login-register-page {
  .register-benefit {
    .title {
      font-size: 24px;
    }
    p {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2.2rem;
      letter-spacing: 0.5px;
    }
  }
}

.login-register-page {
  padding-top: 25px;
  padding-bottom: 65px;
  .subtitle {
    display: none;
  }
}

.login-page {
  .login-box {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 18px;
    flex-direction: column;
    & + .login-box {
      padding-left: 18px;
      padding-right: 20px;
      position: relative;
      border: none;
      &::before {
        content: '';
        height: 100%;
        width: 1px;
        background: $light-grey;
        position: absolute;
        left: -25%;
        top: 0;
      }
    }
  }
}
