.container-forgot-password {
  background-color: #ffff;
  padding: 16px;
  width: 100%;
  .request-password-title {
    @extend %title--large;
  }
  .description-forgot-password {
    margin: 16.4px 0 24px 0;
    @extend %body--small;
  }
  .form-login-forgot-password {
    .form-input {
      padding-bottom: 16px;
    }
  }
  .modal-content,
  .modal-header {
    padding: 0 !important;
  }
}
#forgot-password {
  .modal-overlay {
    margin-top: 50px;
  }
  .modal-container {
    border: 1px solid $light-grey;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    //
    transform: translate(-50%, -50%);
  }
  .modal-close {
    z-index: 9999;
    top: 15px;
    right: 15px;
  }
}

.modal {
  #modal-forgot-password-confirm {
    .modal-header {
      padding: 1.5rem 2.4rem;
      .modal-title {
        text-transform: uppercase;
      }
    }
    .modal-content {
      .modal-forgot-password-confirm-button {
        margin: 16px 0;
      }
    }
  }
}
