.pd-video__container {
  > div {
    margin: 0 auto;
  }
  &.video--tv {
    padding-bottom: 80px;
    .video-js {
      overflow: visible;
    }
  }

  &.video--no-controls {
    padding-bottom: 0;
    overflow: hidden;
    & + .experience-assets-heading {
      margin-top: 20px;
    }
  }

  &.video--embed-controls:not(.video--tv):not(.video--no-controls) {
    .vjs-control-bar {
      transform: none !important;
      background-color: transparent;
      pointer-events: all;

      .vjs-play-control .vjs-icon-placeholder,
      .vjs-icon-play {
        &:before {
          @include fixedSprite('embed-player-play');
        }
      }

      .vjs-play-control.vjs-playing .vjs-icon-placeholder,
      .vjs-icon-pause {
        &:before {
          @include fixedSprite('embed-player-pause');
        }
      }

      .vjs-mute-control .vjs-icon-placeholder,
      .vjs-icon-volume-high {
        &:before {
          @include fixedSprite('embed-player-volume-on');
        }
      }

      .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder,
      .vjs-icon-volume-mute {
        &:before {
          @include fixedSprite('embed-player-volume-off');
        }
      }

      .vjs-fullscreen-control,
      .vjs-progress-control {
        display: none;
      }
    }
  }
}

.video--tv {
  .video-js:not(.vjs-fullscreen) {
    margin: 0 auto;
    width: 100%;
    background: transparent;
  }

  video,
  .vjs-poster {
    background: none;
    border-radius: 42% / 14%;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .video-js:not(.vjs-fullscreen) {
    .vjs-control-bar {
      position: absolute;
      top: 100%;
      display: flex;
      transform: translateY(30px) !important;
      border-radius: 24px;
      border: 1px solid $black;
      padding: 0 12px;
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
      height: 4.8rem;
      font-size: inherit;
    }

    .vjs-button > .vjs-icon-placeholder:before {
      line-height: 4.8rem;
    }

    .vjs-progress-control {
      width: calc(100% - 24px);
      margin: 0 auto;
      background-color: $black;
    }

    .vjs-load-progress div {
      background-color: $black;
    }
  }
  .video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
  .video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
  .video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
  .video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
  .video-js.vjs-has-started.vjs-paused.vjs-ad-playing.vjs-user-inactive .vjs-control-bar,
  .video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible) {
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
  }
}

[class*='bc-player-'],
.bc-player-default_default {
  .vjs-control-bar {
    background-color: #fff;
    color: #000;
  }

  .vjs-time-control {
    display: none;
  }

  .vjs-play-progress,
  .vjs-volume-level {
    background-color: $blue-video;
  }

  .vjs-progress-control {
    position: static;
    height: 2px;
    align-self: center;
  }

  .vjs-dock-text {
    display: none;
  }
  .vjs-big-play-button {
    display: none;
  }
}

.vjs-volume-panel {
  order: 5;
}
.vjs-fullscreen-control {
  order: 6;
}

.main-link-wrap-video {
  position: relative;
  .link-wrap-video {
    display: block;
    z-index: 1;
    pointer-events: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  video {
    pointer-events: none;
  }
}

.pd-video__container {
  .vjs-icon-placeholder {
    position: relative;
    width: 24px;
    height: 24px;
  }

  .video-js .vjs-play-control .vjs-icon-placeholder,
  .vjs-icon-play {
    &:before {
      content: '';
      transform: 0;
      @include fixedSprite('player-play');
    }
  }

  .video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder,
  .vjs-icon-pause {
    &:before {
      content: '';
      transform: 0;
      @include fixedSprite('player-pause');
    }
  }

  .video-js .vjs-mute-control .vjs-icon-placeholder,
  .vjs-icon-volume-high {
    &:before {
      content: '';
      transform: 0;
      @include fixedSprite('player-volume-on');
    }
  }

  .video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder,
  .vjs-icon-volume-mute {
    &:before {
      content: '';
      transform: 0;
      @include fixedSprite('player-volume-off');
    }
  }

  .video-js .vjs-fullscreen-control .vjs-icon-placeholder,
  .vjs-icon-fullscreen-enter {
    &:before {
      content: '';
      transform: translate(50%, -50%);
      @include fixedSprite('player-full-screen');
    }
  }

  .video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder,
  .vjs-icon-fullscreen-exit {
    &:before {
      content: '';
      transform: translate(50%, -50%);
      @include fixedSprite('player-full-screen-close');
    }
  }
}

button.vjs-subs-caps-button {
  display: flex;
  justify-content: center;
}

.bc-player-default_default .vjs-menu-button-popup.vjs-subs-caps-button .vjs-menu .vjs-menu-content {
  background-color: $black;
  border-radius: 10px;
  color: #fff;
  padding-bottom: 0;
  li + li {
    position: relative;
    &::before {
      content: '';
      background-color: #fff;
      height: 1px;
      width: calc(100% - 20px);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      transform: translateX(10px);
    }
  }
}

.bc-player-default_default .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected {
  color: #fff;
  background-color: $black;

  &:hover {
    color: #fff;
    background-color: $black;
  }
}
