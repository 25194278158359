.page-search-result-container {
  .search-no-result-decorator-container {
    .plp-banner-container {
      .text-content {
        text-align: center;
        .page {
          &-title {
            height: 32px;
            font-size: 20px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 1px;
            color: $black;
            text-transform: uppercase;
            margin-bottom: 8px;
          }
          &-description {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: 0.3px;
            color: $black;
          }
        }
        .plp-result-number {
          text-transform: lowercase;
        }

        &.top-spacer {
          margin-top: 80px;
        }
      }
    }
    .delivery-banner-mobile {
      border-bottom: 1px solid $light-grey;
    }
    .delivery-banner-desktop {
      display: none;
    }
    .delivery-banner-desktop-spacer {
      display: none;
    }
    .delivery-banner-mobile {
      .delivery-banner-info {
        justify-content: center;
        display: flex;
        align-items: center;
        margin: 8px 0;
        &-icon {
          margin-right: 8px;
        }
        &-text {
          width: 183px;
          height: 12px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.5px;
          color: #096a0e;
        }
      }
    }

    .search-result {
      margin-top: 16px;
      .js-grid-header {
        font-size: 12px;
        &.is-open {
          &.animate {
            #refinements {
              display: block;
            }
          }
        }
        &:not(.is-open) {
          &:not(.animate) {
            #refinements {
              display: none;
            }
          }
        }
      }

      .refinement-bar {
        display: none;
      }

      .grid-product {
        .version {
          .product-grid-container {
            .single-element {
              display: flex;
              justify-content: center;
              .login-to-buy-image-banner {
                display: none;
              }
              .second-image {
                display: none;
              }
              // &.login-to-buy {
              //   &:hover {
              //     .login-to-buy-image-banner {
              //       background: $black;
              //       display: block;
              //       height: 100%;
              //       opacity: 0.5;
              //       width: 100%;
              //       &-text {
              //         background: $white;
              //         color: $black;
              //         font-size: 12px;
              //         font-stretch: normal;
              //         font-style: normal;
              //         font-weight: 500;
              //         height: 60px;
              //         letter-spacing: 0.5px;
              //         line-height: 1;
              //         padding: 24px 32px;
              //         position: absolute;
              //         text-align: center;
              //         text-transform: uppercase;
              //         width: 100%;
              //       }
              //     }
              //   }
              // }
              &-content {
                margin-bottom: 32px;
                &-image {
                  position: relative;
                  margin-bottom: 16px;
                  .default-image {
                    height: 100%;
                    img {
                      height: 100%;
                      width: 100%;
                    }
                  }
                  &-tag {
                    background-color: red;
                    height: 20px;
                    padding: 4px 8px;
                    border-radius: 10px;
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: 0.5px;
                    text-align: center;
                    position: absolute;
                    bottom: 8px;
                    left: 8px;
                    color: $white;
                  }
                  .img {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                  }
                }
                &-detail {
                  &-price {
                    .previous-price {
                      color: $dark-grey;
                      text-decoration: line-through;
                      margin-right: 16px;
                    }
                  }
                }
              }
            }

            &.product-x-1 {
              margin: 0 -30px;
              .show-more {
                width: 100%;
                margin-inline: 15px;
                margin-bottom: 40px;

                button {
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: 2px;
                  color: $white;
                  text-transform: uppercase;
                }
              }
              .single-element {
                padding: 0;
                .single-element-content {
                  margin: 0 15px 32px;
                  &-image {
                    height: 459px;
                    width: 345px;
                    margin-inline: auto;
                    margin-bottom: 16px;
                  }
                  &-detail {
                    margin-top: 8px;
                    text-align: center;

                    &-description {
                      margin-bottom: 8px;
                    }
                  }
                }
              }
            }

            &.product-x-2 {
              .show-more {
                width: 100%;
                margin-inline: 15px;
                margin-bottom: 40px;
                button {
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: 2px;
                  color: $white;
                  text-transform: uppercase;
                }
              }

              .product {
                overflow: hidden;
              }

              .single-element {
                padding: 0;
                max-width: 50vw;
                flex: 0 0 50vw;
                .single-element-content {
                  margin-bottom: 32px;
                  &-image {
                    aspect-ratio: 3 / 4;
                    height: auto;
                    width: 100%;
                    margin-inline: auto;
                  }
                  &-detail {
                    text-align: center;
                  }
                }
              }
            }

            .products-seen {
              display: block;
              width: 100%;
              margin-inline: 15px;
              margin-bottom: 16px;
              .col {
                display: flex;
                justify-content: center;
                p {
                  height: 21.6px;
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.8;
                  letter-spacing: 0.3px;
                  text-align: center;
                  color: $black;
                }
              }
            }
          }
        }
      }

      .selected-filters {
        display: none;
      }

      .show-more {
        margin: 0 -12px;
        padding-bottom: 16px;
      }
    }

    .accessibility-filter-title {
      height: 0;
      visibility: hidden;
    }

    .horizontal-row {
      &.grid-header-bottom-row {
        display: none;
      }
    }

    .no-result-page {
      .suggested-products {
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 1.5px;
        line-height: 1.6;
        margin-bottom: 24px;
        margin-top: 40px;
        text-align: center;
        text-transform: uppercase;
      }
      .no-result-carousel {
        margin-left: 15px;
        .product-slide {
          .swiper-zoom-container {
            align-items: flex-start;
            justify-content: flex-start;
          }
          .img-details {
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 1.54;
            text-align: center;
            margin-top: 16px;
            padding: 0 8px;
            text-transform: uppercase;
            .info-detail {
              margin-bottom: 8px;
            }
          }
        }
        .image-carousel-global-container {
          .swiper-container {
            .swiper-wrapper {
              margin-left: 0;
            }
          }
          .swiper-slide {
            width: 259px;
            margin-right: 20px;
          }
        }
        .img {
          width: 259px;
          height: 345px;
        }
      }
    }

    .back-to-top {
      visibility: hidden; /* Hidden by default */
      position: fixed; /* Fixed/sticky position */
      bottom: 80px; /* Place the button at the bottom of the page */
      right: 16px; /* Place the button 30px from the right */
      z-index: 99; /* Make sure it does not overlap */
      border: 1px solid black; /* Remove borders */
      outline: none; /* Remove outline */
      background-color: white; /* Set a background color */
      color: black; /* Text color */
      cursor: pointer; /* Add a mouse pointer on hover */
      padding: 13px;
      padding-top: 19px;
      font-size: 39px;
      width: 50px;
      height: 50px;
    }
  }
}

.mobile-filter-top {
  display: none;
  position: absolute;
  top: 158px;
  width: 100%;

  .content {
    text-transform: uppercase;
    text-align: center;
    background: white;
    border: 1px solid black;
    border-radius: 30px;
    width: fit-content;
    padding: 3px 15px;
    margin: 0 auto;
    font-size: 12px;
  }
}

.filters-arrow-down {
  @include fixedSprite('chevron-mini-right');
  width: 6px;
  height: 12px;
  position: absolute;
  right: 0;
  top: 6px;
  transform: rotate(90deg);
}

.single-element-content-detail-description {
  text-transform: uppercase;
}

.single-element-content {
  position: relative;

  .add-to-wish-list {
    position: absolute;
    top: 6px;
    right: 10px;

    .white-icon {
      display: none;
    }
  }

  .wishlist-filled {
    display: none;
  }

  .active {
    .black-icon {
      display: none;
    }

    .wishlist-filled {
      display: block;
    }
  }
}

.wishlist,
.wishlist-filled {
  width: 1.9rem;
  height: 1.7rem;
  display: inline-block;
}

.single-element-content-image {
  display: block;
  margin: 0 auto;

  .image-container {
    position: relative;

    .product-badge {
      background-color: $black;
      border-radius: 18px;
      color: $white;
      height: 24px;
      width: max-content;
      z-index: 2;
      &.product-badge-top {
        position: absolute;
        top: 8px;
        left: 8px;
      }
      &.product-badge-middle {
        width: 100%;
        position: absolute;
        text-align: center;
        top: calc(50% - 14px);
      }
      &.product-badge-bottom {
        position: absolute;
        bottom: 8px;
        left: 8px;
      }

      &.product-badge--red {
        background-color: $badge-red;
      }
      &.product-badge--green {
        background-color: $badge-green;
      }
      &.product-badge--orange {
        background-color: $badge-orange;
      }
      &.product-badge--white {
        background-color: $white;
        color: $black;
      }
    }
  }
}
.you-have-seen {
  text-align: center;
  margin-bottom: 2rem;
}

.refinement-size {
  .categories-filter {
    .selection-box {
      text-transform: uppercase !important;
    }
  }
}

.no-results-recommendations {
  margin-top: 96px;
  padding-top: 24px;
  border-top: 1px solid $light-grey;

  .swiper-heading-wrapper {
    padding-bottom: 24px;
  }

  .recommendations {
    margin-top: 0;
    width: 100%;
    position: relative;

    .product-carousel-section {
      padding: 0;
    }

    .swiper-header {
      font-family: $futura-book;
      font-size: 16px;
      text-align: left;
      font-weight: 450;
    }

    .swiper-button-prev {
      top: -35px !important;
      left: unset;
      right: 49px;
      background: none;
      width: fit-content;
    }

    .swiper-button-next {
      top: -35px !important;
      background: none;
      width: fit-content;
    }

    .swiper-button-prev:after {
      color: $black;
      font-size: 20px;
    }
    .swiper-button-next:after {
      color: $black;
      font-size: 20px;
      transform: rotate(180deg);
    }

    .experience-assets-product {
      .product.swiper-slide {
        width: 100%;
      }

      .product-link {
        font-family: $futura-book;
        font-weight: 400;
        font-size: 14px;
      }

      .price {
        display: none;
      }
    }
  }

  .add-to-wish-list {
    display: none;
  }
}
