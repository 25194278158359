.edit-add-address-container {
  .edit-add-address {
    .address-title {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $black;
      text-transform: uppercase;
      margin: 16px 0;
      margin-top: 0;
    }
    .edit-address-form {
      padding-bottom: 16px;
      .form-input {
        padding-bottom: 15px;
        .select-icon {
          bottom: 31px;
        }
        .form-select {
          width: 100%;
          height: 48px;
          appearance: none;
        }
      }
      .cancel-button {
        margin-top: 24px;
      }
    }
    .address-form-na {
      .address-title-field {
        padding-bottom: 15px;
        .row {
          margin: 0;
          #addressId {
            width: 100%;
          }
        }
      }
      .form-group {
        &.error {
          .icon--chevron-mini-down {
            position: absolute;
            top: 4rem;
            right: 3.3rem;
          }
        }
      }
    }
  }
}
