#dynamic-modal {
  border: 1px solid $black;

  .js-modal-container {
    position: fixed;
    z-index: 15;
  }

  .modal-close::after {
    width: 1.4rem !important;
    height: 1.4rem !important;
  }

  .popup-title {
    font-weight: 600 !important;
  }

  .modal-overlay {
    justify-content: flex-end;
    align-items: flex-end;
    background: none;
    z-index: 0;
  }
  .modal-container {
    height: auto;
    bottom: 0;
    right: 0;
    left: 0;
    width: 1000px;
  }
  .modal-content {
    padding: 0 1.6rem 1.6rem;
  }

  .modal-close {
    top: 1.6rem;
    right: 1.6rem;
    &::after {
      content: '';
      @include closingDynamic(close);
    }
  }
  .popup-title {
    text-transform: uppercase;
    @extend %title--regular;
    text-align: center;
  }

  .popup-body {
    font-size: 12px;
    text-align: center;
    margin-bottom: 21px;
  }

  .action-button {
    border: none;
    margin: 7px auto;
    max-width: 250px;
  }

  .modal-header {
    padding: 1.7rem 3.3rem 1.3rem;
  }

  .btn--primary {
    padding: 12px 40px;
  }
}
