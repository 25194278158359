:root {
  --min-rec-height: 159px;
}

body {
  overflow-x: hidden;
}

.header-search {
  border-top: 1px solid $light-grey;
  height: fit-content;
  max-height: 100vh;
  min-height: var(--min-rec-height);
  position: fixed;
  opacity: 1;
  transform: translate(0, -200%);
  transition: 1s ease-in-out;
  overflow-y: scroll;

  .container-search {
    padding: 0 24px ;
    margin: 0;
    max-width: unset;
  }

  .search-input {
    border-top: none;
    position: relative;
    text-align: center;
    overflow: hidden;
    .search-field {
      padding: 12px 46px;
      margin-top: 16px;
      width: 100%;
      max-width: 472px;
      border-radius: 48px;
      border: none;
      color: var(--Text-Secondary, #7d7d7d);
      text-align: left;
      font-family: $futura-book;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      letter-spacing: 0.21px;

      &:hover {
        background-color: #ececec;
      }
    }
    .icon--close-mini-icon {
      height: 22px;
      position: absolute;
      right: 21px;
      top: 25px;
      width: 22px;
      background: none;
    }

    .icon--search {
      top: 23px;
    }
  }

  .recommendations-container {
    // padding: 0 44px;
    margin: 0 auto;
    width: 100%;
    max-width: 144rem;
  }

  .recommendations {
    margin-top: 0;
    width: 70%;
    margin-left: 30%;
    padding: 40px 0 10px 44px;
    border-left: 1px solid $light-grey;
    z-index: 23;
    position: relative;
    border-top: none;

    .einstein-carousel {
      padding: 0;
    }

    .product-carousel-section {
      padding: 0;
    }

    .swiper-header {
      text-align: left;
      margin: 0;
      text-transform: uppercase;
      font-size: 16px;
      font-family: $futura-book;
      font-weight: 450;
    }

    .swiper-button-prev {
      top: -35px !important;
      left: unset;
      right: 49px;
      background: none;
      width: fit-content;
    }

    .swiper-button-next {
      top: -35px !important;
      background: none;
      width: fit-content;
    }

    .swiper-button-prev:after {
      color: $black;
      font-size: 20px;
    }
    .swiper-button-next:after {
      color: $black;
      font-size: 20px;
      transform: rotate(180deg);
    }

    .experience-assets-product {
      // width: 25% !important;

      .product.swiper-slide {
        width: 100%;
      }
    }
  }

  .suggestions-wrapper {
    text-align: initial;
    overflow: scroll;
    max-height: calc(100vh - 40px);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    #search-results {
      max-width: 472px;
      width: 100%;
      padding: 24px 0 64px;
    }

    .display-results-search {
      #search-results {
        padding: 40px 0 0;
        width: 100%;
        flex-flow: column;

        .row {
          margin: 0 !important;
          width: 100%;
          display: flex;
          flex-flow: column;

          .col-sm-10 {
            padding: 0 !important;
          }
          .col,
          [class^='col-'] {
            padding-left: 0 !important;
          }
        }
      }
    }
    .suggestions {
      flex-direction: row;
      padding: 0;
      top: 0;
      width: 100vw;
      z-index: 21;
      left: 50%;
      transform: translate(-50%);
      border-bottom: none;

      .container {
        .row {
          margin: 0;
        }

        .col-xs-12.col-sm-10 {
          padding: 0;
        }
        .col,
        [class^='col-'] {
          padding-left: 0 !important;
        }
      }

      .right-container {
        border-top: none;
        width: 100%;
        border-left: none;
        padding: 0 0 44px;

        .search-results-title {
          display: block;
          color: var(--Grays-Gray-80, #7d7d7d);

          /* Ecom/body-regular */
          font-family: $futura-book;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.21px;
          padding: 0;
        }
      }

      .search-images {
        display: flex;
        width: 100%;
        padding: 24px 0;
        gap: 16px;
        flex-flow: wrap;
        justify-content: flex-start;

        .product-recomendation-tile {
          width: 100%;
          margin-top: 0;
          max-width: calc(33% - 10px);

          .product-name {
            width: 100%;
            text-align: left;
            height: unset;
          }
        }

        div:first-child {
          padding-left: 0;
        }

        div:last-child {
          padding-right: 0;
        }

        .marni-menu-search-result-item__container {
          padding: 0;
          margin-right: 0;
          width: 100%;

          img {
            height: fit-content;
            aspect-ratio: 4 / 5;
          }
        }
      }

      .search-load-more-button {
        width: fit-content;
        margin: 16px auto 0;
        cursor: pointer;
      }

      .zero-images {
        display: none;
      }
    }

    .display-results-search {
      width: 100vw;

      .main-container {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding: 0;
        margin: 0 auto;
        width: 100%;
        max-width: 472px;
      }
    }
    .items {
      gap: 16px;

      .item,
      .product-item {
        width: 100%;
        padding: 4px 8px;
        border-radius: 8px;
        &:hover {
          background: var(--Grays-Gray-10, #f9f9f9);
        }
      }
    }
  }

  .icon--recent,
  .icon--suggestion {
    align-self: center;
  }
}

.header-search.is-open {
  .animation {
    .container-list-search-suggestions {
      .unstyled-list {
        transition: gap .4s cubic-bezier(0.41, -0.49, 0.81, 0.34);
        gap: 24px;
    
        .search-suggestion-list-item {
          padding: 4px 8px;
          transition: margin-top 0.5s cubic-bezier(0.59, -0.31, 0.76, 0.51), opacity .5s cubic-bezier(0.55, -0.34, 0.8, 0.01);
          margin-top: 0;
        }
      }
    }
  }
}

.page-template-homepage  {
  .header-search {
    z-index: 50;
    top: unset;
    height: fit-content;
    transform: translate(0, -200%);
  }
}

// .header-search.is-closed {
//   transition: 1s ease-in-out;
//   opacity: 1 !important;
//   transform: translate(0, -100%);
// }

.input-search-container {
  max-width: 472px;
  margin: 0 auto;
  position: relative;

  .icon--search-new {
    position: absolute;
    left: 16px;
    top: 28px;
  }

  .icon-clear-container.icon-clear-container {
    position: absolute;
    right: 0;
    top: 16px;
    cursor: pointer;
    z-index: 70;
  }
  
}

.header-search.is-open {
  opacity: 1;
  top: 0;
  z-index: 40;
  transition: 0.5s ease-in-out;
  transform: translate(0, 0%);
  height: unset;
  overflow: hidden;
  max-height: 100vh;
}

.header-search.is-closed {
  transition: 0.5s ease-in-out;
  opacity: 1 !important;
  transform: translate(0, -200%);
  height: unset;
  max-height: 100vh;
}

.hide-recommendations {
  .search-form {
    height: fit-content;
  }

  .display-results-search {
    width: 100vw;
  }

  .recommendations {
    display: none;
  }

  .background-results {
    display: block;
    width: 100vw;
    background: $white;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// -------------------------------- Reset: Mobile Opening animation --------------------------------

.header-search {
  .search-input {
    overflow-x: hidden;
  }
}

.input-search-container {
  width: 100%;
}

.header-search.is-open .input-search-container {
  width: 100%;
}

span#search-bar-close {
  width: fit-content;
  position: absolute;
  right: 0;
  left: unset;
  text-align: right;
  transform: none;
}
.header-search.is-open span#search-bar-close {
  width: fit-content;
  position: absolute;
  right: 0;
  left: unset;
  text-align: right;
  transform: none;
}

.page-template-homepage  {
  .header-search.is-open {
    opacity: 1;
    top: 0;
    z-index: 40;
    transition: 0.5s ease-in-out;
    transform: translate(0, 0%);
    height: unset;
    overflow: hidden;
    max-height: 100vh;
  }
  
  .header-search.is-closed {
    transition: 0.7s ease-in-out;
    opacity: 1 !important;
    transform: translate(0, -200%);
    height: unset;
    max-height: 100vh;
  }

  span#search-bar-close {
    width: fit-content;
    position: absolute;
    right: 0;
    left: unset;
    text-align: right;
    transform: none;
  }
  .header-search.is-open span#search-bar-close {
    width: fit-content;
    position: absolute;
    right: 0;
    left: unset;
    text-align: right;
    transform: none;
  }
}

// -----------------------------------------------------------------------------------------------

.container-list-search-suggestions {
  .unstyled-list {
    gap: 0;
    transition: gap 0.5s ease-in-out;
  }
}
.header-search.is-open {
  .animation {
    .container-list-search-suggestions {
      .unstyled-list {
        gap: 16px;
        transition: gap .4s cubic-bezier(0.41, -0.49, 0.81, 0.34);
      }
    }
  }
}

.editorial-search-suggestions {
  .title-section-search-suggestions {
    margin-bottom: 24px;
    color: var(--Text-Tertiary, #B4B4B4);
    
    /* Ecom/body-regular */
    font-family: $futura-book;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.21px;
  }

  .search-suggestion-list-item {
    color: var(--Text-Primary, #0D0D0D);
    
    /* Ecom/body-regular */
    font-family: $futura-book;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.21px;
  }
}

.search-suggestion-list-item {
  color: var(--Grays-Gray-80, #7d7d7d);

  /* Ecom/body-regular */
  font-family: $futura-book;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.21px;

  .suggestion-label {
    color: var(--Gray-Black, #0d0d0d);
  }
}

.no-results-in-search {
  .search-no-results-message {
    margin-bottom: 24px;
    padding: 24px 0;
    // .container {
    //   padding: 0 44px;
    // }
  }
  .search-results-title {
    display: none !important;
  }

  .recommendations {
    border-left: none;
  }
}
