.section-form-newsletter {
  padding: 0;
  .title-section-newsletter {
    text-transform: uppercase;
    @extend %nav__title--hover;
  }
  .section-description-newsletter {
    margin: 24px 0 22px 0;
  }
  .privacy-newsletter {
    color: $dark-grey;
    a {
      display: inline-flex;
    }
    .link-underline {
      &:before {
        border-color: inherit;
        bottom: 4px;
      }
    }
  }
  p {
    @extend %body--small;
  }
  .form-input {
    margin-bottom: 18px;
    padding: 0;
  }
  .input-newsletter {
    margin: 0;
    border: 0;
  }
  .radio {
    margin: 0 12px 24px 12px;
  }
  .js-gender-newsletter {
    transition: 0.3s;
  }
  .section-open-gender {
    display: flex;
  }
  .newsletter-btn {
    padding-right: 16px;
    position: relative;
  }
  .arrow-cta-newsletter {
    @include fixedSprite('chevron-mini-right');
    width: 12px;
    height: 12px;
    position: absolute;
    right: 5px;
    top: 0;
    transform: translate(50%, 50%);
  }
  .border-bottom-black {
    border: 1px solid $black;
    border-width: 0 0 1px 0;
  }
}

#newsletter-modal {
  border: 1px solid $black;
  .title-section-newsletter {
    display: none;
  }
  .newsletter-title {
    text-transform: uppercase;
    @extend %title--regular;
  }

  .modal-overlay {
    justify-content: flex-end;
    align-items: flex-end;
  }
  .modal-container {
    height: auto;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .modal-header {
    padding: 1.6rem;
  }
  .modal-content {
    padding: 0 1.6rem 1.6rem;
  }

  .modal-close {
    top: 1.6rem;
    right: 1.6rem;
  }

  .section-form-newsletter .section-description-newsletter {
    margin-top: 0;
  }
}
