.benefit-bar-right,
.benefit-bar-left {
  display: flex;
  > ul {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}

.benefit-bar-right {
  > ul {
    justify-content: flex-end;
  }
}
