.editorial-image-grid {
  padding: 0 16px 24px;

  &__title {
    text-align: center;
    padding: 24px 0;

    .display-heading {
      font-size: 24px;
      line-height: 28px;
      font-weight: normal;
      letter-spacing: 0.01em;
    }
  }

  &__gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding-bottom: 4px;
  }

  &__show-button {
    display: flex;
    justify-content: center;
    padding: 24px 24px 0;

    .show-more {
      display: flex;
      align-items: center;
      gap: 4px;
      border: 1px solid #E0E0E0;
      border-radius: 100px;
      padding: 10px 20px;
      font-size: 14px;
      line-height: 16px;
      color: #262626;

      &:hover {
        border: 1px solid #888;
      }

      .icon--plus-icon {
        width: 16px;
        height: 16px;
      }
    }
  }

  .gallery-wrapper {
    max-width: none;
  }

  .gallery-item {
    flex: 0 0 calc(50% - 2px);
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .gallery__picture-label {
    padding: 8px 8px 16px;
  }
}